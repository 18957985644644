import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const defaultOptions = {
  queries: {
    staleTime: Infinity,
    cacheTime: 15 * 60 * 1000, // 15 min. before garbage collection
  },
};
const queryClient = new QueryClient({ defaultOptions });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
        {/* <ReactQueryDevtools /> */}
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById("root")
);

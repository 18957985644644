/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

// Material Dashboard 2 React components

export default function data() {
  return {
    columns: [
      {
        Header: "Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Date),
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
      },
      {
        Header: "Percentage Change",
        accessor: (d) => {
          const open = Math.abs(((d.SourceOpen - d.SocratesOpen) / d.SocratesOpen) * 100).toFixed(
            2
          );
          const high = Math.abs(((d.SourceHigh - d.SocratesHigh) / d.SocratesHigh) * 100).toFixed(
            2
          );

          const low = Math.abs(((d.SourceLow - d.SocratesLow) / d.SocratesLow) * 100).toFixed(2);

          const close = Math.abs(
            ((d.SourceClose - d.SocratesClose) / d.SocratesClose) * 100
          ).toFixed(2);

          const highlow = Math.abs(((d.SourceHigh - d.SocratesLow) / d.SocratesLow) * 100).toFixed(
            2
          );

          return Math.max(open, high, low, close, highlow);
        },
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SocratesOpen",
        accessor: "SocratesOpen",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SourceOpen",
        accessor: "SourceOpen",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SocratesHigh",
        accessor: "SocratesHigh",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SourceHigh",
        accessor: "SourceHigh",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SocratesLow",
        accessor: "SocratesLow",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SourceLow",
        accessor: "SourceLow",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SocratesClose",
        accessor: "SocratesClose",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
      {
        Header: "SourceClose",
        accessor: "SourceClose",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        Filter: "",
        isSorted: false,
      },
    ],
  };
}

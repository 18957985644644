import checkout from "layouts/widgets/schemas/formTicket";

const {
  formFieldTicket: { title, description },
} = checkout;

const initialValues = {
  [title.name]: "",
  [description.name]: "",
};

export default initialValues;

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MultiSelect } from "react-multi-select-component";

// search libraries
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewUser page components
import FormFieldNoIcon from "components/FormFieldNoIcon";
import columns from "layouts/tables/data/addedMarketsTableData";
import DataTableRowSelect from "examples/Tables/DataTableRowSelect";
// import DataTable from "../DataTable";

import "../../../index.css";

function TagInfo({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    mode,
    marketTags,
    handleUpdateTag,
    markets,
    handleNewTag,
    suggestions,
    setSuggestions,
    marketAlreadyAdded,
    setMarketAlreadyAdded,
    setSearchTerm,
    getSuggestions,
    searchTerm,
    valueRadio,
    setValueRadio,
    distinctSector,
    distinctCategory,
    distinctSectorGics,
    distinctSectorGicsIndustry,
    selectedSector,
    setSelectedSector,
    selected,
    setSelected,
    selectedGics,
    setSelectedGics,
    selectedGicsIndustry,
    setSelectedGicsIndustry,
    handleAddMarket,
    handleAddMarketBySector,
    handleAddMarketByCategory,
    handleAddMarketBySectorGics,
    handleAddMarketBySectorGicsIndustry,
    removeMarkets,
    setFieldValue,
    valueDefaultSorting,
    validateAddedMarkets,
    validateSectorDisabled,
    validateCategoryDisabled,
    validateSectorGICSDisabled,
    validateSectorGICSIndustryDisabled,
  } = formData;
  const { TagName } = formField;
  const { TagName: TagNameV, Tag: TagV } = values;

  const handleRadioChange = (value) => {
    setValueRadio(value);
    validateAddedMarkets();
  };

  const handleClick = () => {
    if (valueRadio === "socrates") {
      handleAddMarketByCategory();
    } else if (valueRadio === "gics") {
      handleAddMarketBySectorGics();
    } else if (valueRadio === "gicsIndustry") {
      handleAddMarketBySectorGicsIndustry();
    } else {
      handleAddMarketBySector();
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5" color="info" fontWeight="regular">
          Add New or Select Saved Tag
        </MDTypography>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={3}>
            <Autocomplete
              id="Tag"
              name="Tag"
              value={TagV || null}
              onChange={(e, value) => {
                setFieldValue("Tag", value !== null ? value : "");
                if (e.target.innerText === "New Tag Name") handleNewTag(value, TagV);
                else handleUpdateTag(value);
              }}
              getOptionLabel={(option) => option}
              // error={errors.exchange && touched.exchange}

              // defaultValue="New Tag Name"
              // defaultValue={paramNewTagName !== "" ? paramNewTagName : ""}
              options={["New Tag Name", ...marketTags.data.map((el) => el.MarketTagName)]}
              renderInput={(params) => (
                <MDInput {...params} label="Add New / Select Saved" variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <MDButton variant="gradient" color="info" disabled>
              <Icon fontSize="small" color="inherit">
                east
              </Icon>
            </MDButton>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormFieldNoIcon
              type={TagName.type}
              label={mode === "UPDATE TAG" ? "Rename Tag Name" : TagName.label}
              name={TagName.name}
              value={TagNameV}
              placeholder={TagName.placeholder}
              error={errors.TagName && touched.TagName}
              success={TagNameV.length > 0 && !errors.TagName}
              // inputProps={{ style: { textTransform: "uppercase" } }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={4} mt={0} ml={1} mr={1}>
            <MDBox lineHeight={0} mt={1}>
              <MDTypography variant="h5" color="info" fontWeight="regular" ml={-1}>
                Add Associated Markets
              </MDTypography>
              <MDTypography variant="subtitle2" fontWeight="regular" mt={3}>
                By Market Name
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} mt={0}>
              <MDTypography
                variant="caption"
                fontWeight="regular"
                mt={0}
                color="error"
                hidden={marketAlreadyAdded}
                ml={3}
              >
                This market has already been added.
              </MDTypography>
              <Grid item xs={12} sm={8} mt={-3}>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsClearRequested={() => setSuggestions([])}
                  onSuggestionsFetchRequested={({ value }) => {
                    if (!marketAlreadyAdded) {
                      setMarketAlreadyAdded(true);
                    }
                    setSearchTerm(value);
                    setSuggestions(getSuggestions(value));
                  }}
                  // eslint-disable-next-line no-unused-vars
                  onSuggestionSelected={(_, { suggestionValue }) => {
                    const marketAdded = markets.filter((mkt) => mkt.Market === suggestionValue);
                    if (marketAdded.length > 0) setMarketAlreadyAdded(false);
                    else setMarketAlreadyAdded(true);
                  }}
                  getSuggestionValue={(suggestion) => suggestion.Name}
                  renderSuggestion={(suggestion, { query }) => {
                    const matches = AutosuggestHighlightMatch(
                      `${suggestion.PEITicker} - ${suggestion.Name}`,
                      query,
                      { insideWords: true }
                    );
                    const parts = AutosuggestHighlightParse(
                      `${suggestion.PEITicker} - ${suggestion.Name}`,
                      matches
                    );
                    return (
                      <span>
                        {parts.map((part, index) => {
                          const className = part.highlight
                            ? "react-autosuggest__suggestion-match"
                            : null;

                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <span className={className} key={index}>
                              {part.text}
                            </span>
                          );
                        })}
                      </span>
                    );
                  }}
                  inputProps={{
                    placeholder: "Search market",
                    value: searchTerm,
                    // eslint-disable-next-line no-unused-vars
                    onChange: (_, { newValue, method }) => {
                      setSearchTerm(newValue);
                    },
                  }}
                  highlightFirstSuggestion
                />
              </Grid>
              <Grid item xs={12} sm={4} mt={-3}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleAddMarket}
                  disabled={!marketAlreadyAdded}
                >
                  Add Market
                </MDButton>
              </Grid>
            </Grid>
            <MDBox lineHeight={0} mt={1}>
              <MDTypography variant="subtitle2" fontWeight="regular" mt={3}>
                Add Markets By Socrates Category
              </MDTypography>
            </MDBox>
            <Grid container spacing={3} mt={0}>
              <Grid item xs={12} sm={8}>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="socrates"
                  name="radio-buttons-group"
                  value={valueRadio}
                  onChange={(e) => handleRadioChange(e.target.value)}
                >
                  <FormControlLabel
                    value="socrates"
                    control={<Radio />}
                    label={
                      <MDTypography color="dark" variant="h7" fontWeight="regular">
                        Category
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    value="gics"
                    control={<Radio />}
                    label={
                      <MDTypography color="dark" variant="h7" fontWeight="regular">
                        GICS Sector
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    value="gicsIndustry"
                    control={<Radio />}
                    label={
                      <MDTypography color="dark" variant="h7" fontWeight="regular">
                        GICS Industry
                      </MDTypography>
                    }
                  />
                  <FormControlLabel
                    value="sector"
                    control={<Radio />}
                    label={
                      <MDTypography color="dark" variant="h7" fontWeight="regular">
                        Sector
                      </MDTypography>
                    }
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0}>
              <Grid item xs={12} sm={8}>
                {valueRadio === "socrates" ? (
                  <div className="multi-select-wrapper">
                    <MultiSelect
                      options={validateCategoryDisabled(
                        distinctCategory.data.map((el) => ({
                          label: el,
                          value: el,
                        }))
                      )}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </div>
                ) : null}
                {valueRadio === "gics" ? (
                  <div className="multi-select-wrapper">
                    <MultiSelect
                      // className="dark"
                      options={validateSectorGICSDisabled(
                        distinctSectorGics.data.map((el) => ({
                          label: el,
                          value: el,
                        }))
                      )}
                      value={selectedGics}
                      onChange={setSelectedGics}
                      labelledBy="Select"
                    />
                  </div>
                ) : null}
                {valueRadio === "gicsIndustry" ? (
                  <div className="multi-select-wrapper">
                    <MultiSelect
                      // className="dark"
                      options={validateSectorGICSIndustryDisabled(
                        distinctSectorGicsIndustry.data.map((el) => ({
                          label: el,
                          value: el,
                        }))
                      )}
                      value={selectedGicsIndustry}
                      onChange={setSelectedGicsIndustry}
                      labelledBy="Select"
                    />
                  </div>
                ) : null}
                {valueRadio === "sector" ? (
                  <div className="multi-select-wrapper">
                    <MultiSelect
                      // className="dark"
                      options={validateSectorDisabled(
                        distinctSector.data.map((el) => ({
                          label: el,
                          value: el,
                        }))
                      )}
                      value={selectedSector}
                      onChange={setSelectedSector}
                      labelledBy="Select"
                    />
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDButton variant="gradient" color="info" size="small" onClick={handleClick}>
                  Add Markets
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} mt={1} ml={-1} mr={-1}>
            <Card>
              <MDBox px={3} pt={1} lineHeight={1}>
                <MDTypography variant="subtitle2" fontWeight="regular">
                  Added Markets
                </MDTypography>
              </MDBox>
              <DataTableRowSelect
                table={{
                  columns,
                  rows: markets,
                }}
                canSearch
                isSorted
                entriesPerPage={{ defaultValue: 5 }}
                showTotalEntries
                noEndBorder
                removeMarkets={removeMarkets}
                valueDefaultSorting={valueDefaultSorting}
              />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for TagInfo
TagInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TagInfo;

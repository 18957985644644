// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/tags/new-tag/components/FormField";

function TagInfo() {
  return (
    <MDBox>
      <MDTypography variant="h5">Tag Info</MDTypography>
      <MDBox mb={9}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} sx={6} mt={2}>
            <Autocomplete
              id="tag"
              name="tag"
              // value={tagV || null}
              // onChange={(e, value) => {
              //   // console.log(value);
              //   setFieldValue("country", value !== null ? value : "");
              // }}
              getOptionLabel={(option) => option}
              // error={errors.exchange && touched.exchange}
              defaultValue="Add New / Select Saved"
              options={["New Tag Name", "CNY", "EUR", "GBP", "INR", "USD"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormField type="text" label="SKU" placeholder="71283476591" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default TagInfo;

/* eslint-disable prefer-destructuring */
// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card } from "@mui/material";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PieChartSearch from "examples/Charts/PieChartSearch";
import VerticalBarChartSearch from "examples/Charts/BarCharts/VerticalBarChartSearch";

// Data
import "./index.css";
import DateRangePickerCompAnalysis from "examples/DateRangePickerAnalysis";
import { addDays } from "date-fns";
import defineds from "examples/DateRangePicker/datesConfiguration";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const themeColors = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
];

function extractInfo(data) {
  // Initialize arrays to store labels and counts
  const labels = [];
  const counts = [];
  const pielabels = [];
  const piecounts = [];
  const userlabels = [];

  // Iterate through the data
  data
    // eslint-disable-next-line no-underscore-dangle
    .sort((a, b) => a._id.year - b._id.year || a._id.month - b._id.month) // Sort by year and month
    .forEach((item) => {
      const count = item.count;

      if (count === 0) return;

      // eslint-disable-next-line no-underscore-dangle
      const year = item._id.year;
      // eslint-disable-next-line no-underscore-dangle
      const month = item._id.month;

      // const count = 1;
      const month2digitFormat = month.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      const label = `${month2digitFormat}-${year}`;
      const pielabel = item.UserCountry || "Other";
      // eslint-disable-next-line no-underscore-dangle
      const userlabel = item._id.UserId;

      // Check if label already exists, if not, add it
      if (!labels.includes(label)) {
        labels.push(label);
      }
      // Check if label already exists, if not, add it
      if (!pielabels.includes(pielabel)) {
        pielabels.push(pielabel);
      }
      // Check if label already exists, if not, add it
      if (!userlabels.includes(userlabel)) {
        userlabels.push(userlabel);
      }

      // Find the index of the label and store the count
      const index = labels.indexOf(label);
      if (counts[index]) {
        counts[index] += count;
      } else {
        counts[index] = count;
      }
      // Find the index of the label and store the count
      const index2 = pielabels.indexOf(pielabel);
      if (piecounts[index2]) {
        // piecounts[index2] += count;
        piecounts[index2] += 1;
      } else {
        // piecounts[index2] = count;
        piecounts[index2] = 1;
      }
    });

  return { labels, counts, pielabels, piecounts, userlabels };
}

function TagVolumeReport() {
  const navigate = useNavigate();
  const { TagId } = useParams();
  const [TagV, setTagV] = useState(null);
  // comes from markettag
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [isLoading, setisLoading] = useState(true);

  const [rangeFrom, setRangeFrom] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [usersInfo, setUsersInfo] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [allNames, setAllNames] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [pieChartLabels, setPieChartLabels] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [dataToExcel, setDataToExcel] = useState([]);
  const [numberOfSearches, setNumberOfSearches] = useState(0);
  const [uniqueUsersWhoSearched, setUniqueUsersWhoSearched] = useState(0);

  const sideBarCustomObject = [
    {
      label: "Today Only",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday Only",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "1 Week",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 Month",
      range: () => ({
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 year",
      range: () => ({
        startDate: defineds.startOflastYear,
        endDate: defineds.endOfToday,
      }),
    },
  ];

  const getUsersInfo = async (inTagId) => {
    const result = await http({
      path: `/searchhistory/${inTagId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getAllNames = async () => {
    const result = await http({
      path: `/searchhistory`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const processUserData = (data) => {
    const { labels, counts, pielabels, piecounts, userlabels } = extractInfo(data);
    setChartLabels(labels);
    setChartData(counts);
    setPieChartLabels(pielabels);
    setPieChartData(piecounts);
    setUniqueUsersWhoSearched(userlabels.length);

    const result = labels.map((month, index) => ({ month, count: counts[index] }));
    setDataToExcel(result);
  };

  const clearCharts = () => {
    setChartLabels([]);
    setChartData([]);
    setPieChartLabels([]);
    setPieChartData([]);
    setUniqueUsersWhoSearched(0);
    setDataToExcel([]);
  };

  useEffect(() => {
    if (rangeFrom[0].startDate && rangeFrom[0].endDate) {
      const Desde = new Date(rangeFrom[0].startDate);
      const Hasta = new Date(rangeFrom[0].endDate);

      const filteredData = usersInfo.map((item) => {
        const filteredSearchedAt = item.SearchedAt.filter((dateString) => {
          const date = new Date(dateString);
          return date >= Desde && date <= Hasta;
        });

        return {
          ...item,
          count: filteredSearchedAt.length,
          SearchedAt: filteredSearchedAt,
        };
      });
      clearCharts();
      processUserData(filteredData);
    }
  }, [rangeFrom]);

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          setisLoading(true);
          clearCharts();
          if (TagV) {
            const loadedUsersInfo = await getUsersInfo(TagV.TagId);
            setUsersInfo(loadedUsersInfo);
            processUserData(loadedUsersInfo);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          setisLoading(false);
        }
      };
      load();
    }
  }, [TagV]);

  useEffect(() => {
    const suma = chartData.reduce((acumulador, numero) => acumulador + numero, 0);
    setNumberOfSearches(suma);
  }, [chartData]);

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          setisLoading(true);
          const loadedAllNames = await getAllNames();
          setAllNames(loadedAllNames);
          const item = loadedAllNames.find((obj) => obj.TagId === TagId);
          setTagV(item);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        } finally {
          setisLoading(false);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={2} pl={3}>
                <MDTypography variant="h5">FILTERS</MDTypography>
                <MDBox mt={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <MDBox mb={0}>
                        <MDBox display="flex" justifyContent="left" alignItems="flex-start" mb={0}>
                          <MDBox ml={1}>
                            <MDBox pt={1} px={1}>
                              <MDTypography variant="h6" fontWeight="light">
                                Select Tag Name:
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              component="ul"
                              display="flex"
                              flexDirection="column"
                              pt={1}
                              mx={1}
                            >
                              <Stack spacing={2} width="350px">
                                <Autocomplete
                                  value={TagV}
                                  onChange={(event, newValue) => {
                                    setTagV(newValue);
                                    if (newValue) {
                                      navigate(`/tag-volume-report/${newValue.TagId}`);
                                    }
                                  }}
                                  getOptionLabel={(option) => option.Tag}
                                  id="demo"
                                  options={allNames}
                                  sx={{ width: 300 }}
                                  renderInput={(params) => (
                                    <MDInput
                                      {...params}
                                      // label="Add New / Select Saved"
                                      variant="standard"
                                    />
                                  )}
                                />
                              </Stack>
                            </MDBox>
                          </MDBox>
                          <MDBox ml={1}>
                            <MDBox pt={1} px={1}>
                              <MDTypography variant="h6" fontWeight="light">
                                Set date range:
                              </MDTypography>
                            </MDBox>
                            <MDBox pt={0.5} pb={0} mx={2}>
                              <MDBox component="ul" display="flex" flexDirection="column">
                                <DateRangePickerCompAnalysis
                                  range={rangeFrom}
                                  setRange={setRangeFrom}
                                  sideBarCustomObject={sideBarCustomObject}
                                  // setFilterByRange={setFilterByRange}
                                />
                              </MDBox>
                            </MDBox>
                            <MDBox
                              display="flex"
                              justifyContent="left"
                              alignItems="flex-start"
                              mb={1}
                              mt={1}
                              ml={0}
                            >
                              <MDButton
                                size="small"
                                variant="text"
                                fontWeight="regular"
                                color="info"
                                onClick={() => {
                                  setRangeFrom([
                                    {
                                      startDate: addDays(new Date(), -30),
                                      endDate: new Date(),
                                      key: "selection",
                                    },
                                  ]);
                                }}
                              >
                                1 Month
                              </MDButton>
                              <MDTypography variant="h6" fontWeight="light" mx={0}>
                                /
                              </MDTypography>
                              <MDButton
                                size="small"
                                variant="text"
                                fontWeight="regular"
                                color="info"
                                onClick={() => {
                                  setRangeFrom([
                                    {
                                      startDate: addDays(new Date(), -7),
                                      endDate: new Date(),
                                      key: "selection",
                                    },
                                  ]);
                                }}
                              >
                                1 Week
                              </MDButton>
                              <MDTypography variant="h6" fontWeight="light" mx={0}>
                                /
                              </MDTypography>
                              <MDButton
                                size="small"
                                variant="text"
                                fontWeight="regular"
                                color="info"
                                onClick={() => {
                                  setRangeFrom([
                                    {
                                      startDate: addDays(new Date(), -1),
                                      endDate: new Date(),
                                      key: "selection",
                                    },
                                  ]);
                                }}
                              >
                                1 Day
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              {chartData && chartData.length > 0 ? (
                <VerticalBarChartSearch
                  icon={{ color: "dark", component: "leaderboard" }}
                  title="Search Volume by Users"
                  tagName={TagV.Tag}
                  rangeFrom={rangeFrom}
                  numberOfSearches={numberOfSearches}
                  uniqueUsersWhoSearched={uniqueUsersWhoSearched}
                  description=""
                  chart={{
                    labels: chartLabels,
                    datasets: [
                      {
                        label: "Number of Searches (clicks on Market Tag)",
                        color: "dark",
                        data: chartData,
                      },
                    ],
                  }}
                  fullData={dataToExcel}
                />
              ) : (
                <div>No records found</div>
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              {pieChartData && pieChartData.length > 0 ? (
                <PieChartSearch
                  icon={{ color: "info", component: "leaderboard" }}
                  title="User Location Who Searched"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: pieChartLabels,
                    datasets: {
                      label: "Projects",
                      backgroundColors: themeColors,
                      data: pieChartData,
                    },
                  }}
                  categoryLabel="users"
                />
              ) : (
                <div>No records found</div>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TagVolumeReport;

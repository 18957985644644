import { useEffect, useState } from "react";
import { useMaterialUIController, setOpenMarketMessage } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest page components
import UserInfo from "layouts/editmarketrequest/components/UserInfo";

// NewMarketRequest layout schemas for form and form feilds
import validations from "layouts/editmarketrequest/schemas/validations";
import form from "layouts/editmarketrequest/schemas/form";
import initialValues from "layouts/editmarketrequest/schemas/initialValues";

import countriesData from "layouts/data/countries";

const { currencies } = countriesData();

function getStepContent(formData) {
  return <UserInfo formData={formData} />;
}

function EditMarketRequest() {
  const location = useLocation();
  const { marketInfoRequest } = location.state;
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const queryClient = useQueryClient();

  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const patchMarketInfoRequest = async (values) => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketinforequest/${marketInfoRequest._id}`,
      method: "PUT",
      raw: JSON.stringify({
        ...values,
        MarketName: values.marketName,
        Sector: values.sector,
        ExchangeSymbol: values.exchangeSymbol,
        Category: values.category,
        Currency: values.currency,
        Exchange: values.exchange,
        Country: values.country,
        IsBusinessUser: values.isBusinessUser,
        OnHold: values.onHold,
        ExternalRequester: values.externalRequester,
        ExternalEmail: values.externalEmail,
        Comments: values.comments,
      }),
      accessToken: userData.token,
    });
    return result;
  };

  const getDistinctCategories = async () => {
    const result = await http({
      path: `/marketimportinfo/categories`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getCountries = async () => {
    const result = await http({
      path: `/country`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const submitForm = async (values, actions) => {
    const result = await patchMarketInfoRequest({
      ...values,
      // status: "Submitted",
      StatusId: 1,
    });

    if (result.ok && result.body) {
      queryClient.invalidateQueries({ queryKey: ["marketInfoRequest"] });
      await setOpenMarketMessage(dispatch, { open: true, content: "Request has been updated" });
    }

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    navigate("/view-all-request");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/view-all-request");
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedCategories = await getDistinctCategories();
          const loadedCountries = await getCountries();
          setCategories(loadedCategories);
          setCountries(loadedCountries);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                ...initialValues,
                ...marketInfoRequest,
                marketName: marketInfoRequest.MarketName,
                exchangeSymbol: marketInfoRequest.ExchangeSymbol,
                exchange: marketInfoRequest.Exchange,
                isBusinessUser: marketInfoRequest.IsBusinessUser || false,
                onHold: marketInfoRequest.OnHold || false,
                externalRequester: marketInfoRequest.ExternalRequester,
                externalEmail: marketInfoRequest.ExternalEmail,
                comments: marketInfoRequest.Comments,
                sector: marketInfoRequest.Sector,
                category: marketInfoRequest.Category,
                currency: marketInfoRequest.Currency,
                country: marketInfoRequest.Country,
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Edit Market Request
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          categories,
                          countries,
                          currencies,
                          setFieldValue,
                          marketInfoRequest,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton
                            disabled={isSubmitting}
                            type="button"
                            variant="gradient"
                            color="error"
                            sx={{ marginLeft: "40px" }}
                            onClick={handleCancel}
                          >
                            cancel
                          </MDButton>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            sx={{ marginLeft: "40px", marginRight: "40px" }}
                          >
                            update request
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditMarketRequest;

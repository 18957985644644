/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react
import { useState } from "react";
import { useMaterialUIController } from "context";

import httpPut from "services/httpPut";

// react router
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Profile page components
import Header from "layouts/profile/components/Header";

function Profile() {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData, darkMode } = controller;
  const [edit, setEdit] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [newPass2, setNewPass2] = useState("");

  const putPassword = async () => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/users/password/`,
      method: "PUT",
      raw: JSON.stringify({
        login: userData.username,
        password: newPass,
        userId: userData.userId,
      }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleEdit = () => {
    setNewPass("");
    setNewPass2("");
    setEdit(!edit);
  };

  const handleSave = async () => {
    if (newPass === "" || newPass2 === "") {
      alert("Please enter a password");
      return;
    }
    if (newPass !== newPass2) {
      alert("Password doesn't match, please capture again");
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const result = await putPassword();
    setEdit(!edit);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Card sx={{ height: "100%", boxShadow: "none" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    profile information
                  </MDTypography>
                </MDBox>
                <MDBox p={2}>
                  <MDBox>
                    <MDBox key="email" display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        email: &nbsp;
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{userData.username}
                      </MDTypography>
                    </MDBox>
                    <MDBox key="role" display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        role: &nbsp;
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{userData.role}
                      </MDTypography>
                    </MDBox>
                    <MDBox key="fullName" display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        fullName: &nbsp;
                      </MDTypography>
                      <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{userData.name}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
              <Divider orientation="vertical" sx={{ ml: 12, mr: 1 }} />
              <Card sx={{ height: "100%", boxShadow: "none" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={1}
                  px={1}
                >
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    password change
                  </MDTypography>
                  <MDBox ml={4}>
                    <MDButton
                      variant="text"
                      color={darkMode ? "white" : "dark"}
                      disabled={!edit}
                      onClick={handleSave}
                    >
                      <Icon>save</Icon>&nbsp;save
                    </MDButton>
                  </MDBox>
                  <MDButton
                    variant="text"
                    color={darkMode ? "white" : "dark"}
                    disabled={edit}
                    onClick={handleEdit}
                  >
                    <Icon>edit</Icon>&nbsp;edit
                  </MDButton>
                </MDBox>
                <MDBox p={2}>
                  <MDBox>
                    <MDBox key="newPassword" display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        New Password: &nbsp;
                      </MDTypography>
                      <MDInput
                        type="password"
                        label="Password"
                        fullWidth
                        name="password"
                        size="small"
                        disabled={!edit}
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                      />
                    </MDBox>
                    <MDBox key="confirmPassword" display="flex" py={1} pr={2}>
                      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        Confirm NewPassword: &nbsp;
                      </MDTypography>
                      <MDInput
                        type="password"
                        label="Password"
                        fullWidth
                        name="password"
                        size="small"
                        disabled={!edit}
                        value={newPass2}
                        onChange={(e) => setNewPass2(e.target.value)}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;

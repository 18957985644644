// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLink from "components/MDLink";

function LastUpdate({ /* totalOfMarkets */ nulltags, nullcategories }) {
  return (
    <Card sx={{ height: "25%" }}>
      <MDBox pt={1} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Last Update
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6}>
              <MDBox display="flex" justifyContent="left" alignItems="flex-start" mb={0} mt={1}>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  Number of markets in the system:
                  <MDTypography variant="button" color="text" fontWeight="medium">
                    {" "}
                    {totalOfMarkets}
                  </MDTypography>{" "}
                </MDTypography>
              </MDBox>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                Number of Markets with Tag as null :
                <MDTypography variant="button" color="text" fontWeight="medium">
                  {" "}
                  <MDLink
                    to="/markets-with-tag-as-null"
                    text={nulltags ? nulltags.length.toString() : ""}
                  />
                </MDTypography>{" "}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                Number of Markets with Category as null :
                <MDTypography variant="button" color="text" fontWeight="medium">
                  {" "}
                  <MDLink
                    to="/markets-with-category-as-null"
                    text={nullcategories ? nullcategories.length.toString() : ""}
                  />
                </MDTypography>{" "}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the Footer
LastUpdate.defaultProps = {
  // totalOfMarkets: 0,
  nulltags: [],
  nullcategories: [],
};

// Typechecking props for the Footer
LastUpdate.propTypes = {
  // totalOfMarkets: PropTypes.number,
  nulltags: PropTypes.arrayOf(PropTypes.object),
  nullcategories: PropTypes.arrayOf(PropTypes.object),
};

export default LastUpdate;

/* eslint-disable no-underscore-dangle */
// react
// import { useEffect } from "react";
import { useMaterialUIController, setOpenMarketMessage } from "context";
import { useNavigate, Link } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import http from "services/http";
import httpPost from "services/httpPost";

// @mui material components
import { Grid, Card, Icon } from "@mui/material";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Dashboard components
import DataTable from "examples/Tables/DataTable";

import MDLoader from "components/MDLoader";

import columns from "layouts/tables/data/marketTagsDetailTableData";

// eslint-disable-next-line react/prop-types
function ViewTagsDetailII({ tagid, marketTags, setTagid }) {
  const navigate = useNavigate();
  // contextapi const [controller, dispatch] = useMaterialUIController();
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const [modal, setModal] = useState(false);

  const handleCloseModal = () => {
    setModal(!modal);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const getTag = async () => {
    const result = await http({
      path: `/markettag/${tagid}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [tag] = useQueries({
    queries: [
      {
        queryKey: ["tag"],
        queryFn: getTag,
      },
    ],
  });

  const deleteTag = async () => {
    const result = await httpPost({
      path: `/markettag/${tagid || tag.data[0]._id}`,
      method: "DELETE",
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const sortedDataBySectorByMarket = (data) =>
    data.sort((a, b) => {
      if (a.Category < b.Category) {
        return -1;
      }
      if (a.Category > b.Category) {
        return 1;
      }
      if (a.Market < b.Market) {
        return -1;
      }
      if (a.Market > b.Market) {
        return 1;
      }
      return 0;
    });

  const handleEditTag = async (e) => {
    e.preventDefault();
    navigate("/manage-tags", {
      state: {
        action: "edit",
        tagid: tagid || tag.data[0]._id,
        markettagname: tag.data[0].MarketTagName,
        tagmarkets: sortedDataBySectorByMarket(tag.data[0].Markets),
      },
    });
  };

  const handleRedirect = async () => {
    setModal(false);
    await deleteTag();
    await setOpenMarketMessage(dispatch, {
      open: true,
      color: "success",
      icon: "check",
      title: "Success",
      content: `${tag.data[0].MarketTagName} has been deleted`,
    });
    // eslint-disable-next-line react/prop-types
    marketTags.refetch();
    setTagid("");
    // navigate(`/view-all-tags`);
  };

  useEffect(() => {
    tag.refetch();
  }, [tagid]);

  if (tag.isLoading) return <MDLoader />;

  return (
    <>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <MDBox mt={1} mx={0.5}>
            <MDBox mb={1}>
              <MDTypography
                component={Link}
                to="{action.route}"
                variant="h5"
                textTransform="capitalize"
                justifyContent="center"
                alignItems="center"
              >
                Delete Tag
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="h6" fontWeight="light" color="text">
                Are you sure you want to delete the tag
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="h6" fontWeight="light" color="text">
                &quot;{tag.data[0].MarketTagName}&quot; ?
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
              <MDButton variant="gradient" color="info" size="small" onClick={handleCancel}>
                No
              </MDButton>
              <MDButton variant="gradient" color="error" size="small" onClick={handleRedirect}>
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                Yes, delete it
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal>
      <MDBox my={11}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Tag Details
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={2}
                  ml={4}
                  mr={4}
                >
                  <MDTypography
                    color="dark"
                    variant="h5"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {tag.data[0].MarketTagName}
                  </MDTypography>

                  <MDBox display="flex">
                    <MDBox ml={1}>
                      <MDButton variant="gradient" color="info" onClick={handleEditTag}>
                        <Icon>description</Icon>
                        Edit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={0}
                  ml={4}
                  mr={4}
                >
                  <MDTypography color="dark" variant="h6" fontWeight="regular">
                    Associated Markets
                  </MDTypography>
                </MDBox>
                <DataTable
                  table={{
                    columns,
                    rows: sortedDataBySectorByMarket(tag.data[0].Markets),
                  }}
                  isSorted
                  canSearch
                  entriesPerPage={{ defaultValue: 5 }}
                  showTotalEntries
                />
              </MDBox>
            </Card>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={2}
              mt={3}
            >
              <MDBox ml={1}>
                <MDButton variant="gradient" color="error" onClick={() => setModal(true)}>
                  <Icon>delete</Icon>
                  Delete
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default ViewTagsDetailII;

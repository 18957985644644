/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";
// Images
export default function data() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "center",
        maxWidth: 85,
        minWidth: 85,
      },
      { Header: "Sector", accessor: "Sector", align: "center", maxWidth: 115, minWidth: 115 },
      { Header: "Region", accessor: "Region", align: "center", maxWidth: 70, minWidth: 70 },
      {
        Header: "DatePurchased",
        accessor: (d) => formatDateInNewYorkNoSec(d.DatePurchased),
        align: "center",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Age, days",
        accessor: (d) => {
          const myDate = new Date();
          // return moment(myDate.getTime() - new Date(d.DatePurchased).getTime()).format(
          //   "MM/DD/YYYY hh:mm A"
          // );
          const difference = myDate.getTime() - new Date(d.MarketAdditionDate).getTime();
          const days = difference / (1000 * 3600 * 24);
          return Math.ceil(days);
        },
        align: "center",
        maxWidth: 150,
        minWidth: 150,
      },
    ],
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      { Header: "Sector", accessor: "Sector", align: "left", maxWidth: 115, minWidth: 115 },
      { Header: "Region", accessor: "Region", align: "left", maxWidth: 70, minWidth: 70 },
      {
        Header: "Purchased Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.DatePurchased),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Age, days",
        accessor: (d) => {
          const myDate = new Date();
          // return moment(myDate.getTime() - new Date(d.DatePurchased).getTime()).format(
          //   "MM/DD/YYYY hh:mm A"
          // );
          const difference = myDate.getTime() - new Date(d.MarketAdditionDate).getTime();
          const days = difference / (1000 * 3600 * 24);
          return Math.ceil(days);
        },
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "count",
        accessor: "calculatedCount",
        align: "left",
        maxWidth: 65,
        minWidth: 65,
      },
    ],
  };
}

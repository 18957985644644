// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";
import httpPost from "services/httpPost";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";
import Stack from "@mui/material/Stack";
// Material Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableAnalysis from "components/Tables/DataTableAnalysis";

// Data
import analysisTableData from "layouts/tables/data/analysisTableData";

import { MultiSelect } from "react-multi-select-component";
import "./index.css";
import DateRangePickerCompAnalysis from "examples/DateRangePickerAnalysis";
import { addDays } from "date-fns";
import defineds from "examples/DateRangePicker/datesConfiguration";

// eslint-disable-next-line no-unused-vars
import Steps from "layouts/snapshotsoverview/components/Steps";

const { columns2 } = analysisTableData();

function Analysis() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [isLoading, setisLoading] = useState(true);
  const [snapshotsRegions, setSnapshotsRegions] = useState([]);
  const [snapshotsSectors, setSnapshotsSectors] = useState([]);
  const [snapshotsData, setSnapshotsData] = useState({});
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [rangeFrom, setRangeFrom] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [rangeTo, setRangeTo] = useState([
    {
      startDate: addDays(new Date(), -30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const sideBarCustomObject = [
    {
      label: "Today Only",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday Only",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "3 Days",
      range: () => ({
        startDate: defineds.startOfLastThreeDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "5 Days",
      range: () => ({
        startDate: defineds.startOfLastFiveDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 Week",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "2 Weeks",
      range: () => ({
        startDate: defineds.startOfLastFourteenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 Month",
      range: () => ({
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "90 Days",
      range: () => ({
        startDate: defineds.startOfLastNintyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 year",
      range: () => ({
        startDate: defineds.startOflastYear,
        endDate: defineds.endOfToday,
      }),
    },
  ];

  const getSnapshotsRegions = async () => {
    const result = await http({
      path: `/subscriptions/regions`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getSnapshotsSectors = async () => {
    const result = await http({
      path: `/subscriptions/sectors`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const postSubscriptions = async () => {
    const result = await httpPost({
      path: `/subscriptions/analysis`,
      method: "POST",
      raw: JSON.stringify({ selectedRegions, selectedSectors, rangeFrom, rangeTo }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const [loadedSnapshotsRegions, loadedSnapshotsSectors] = await Promise.all([
            getSnapshotsRegions(),
            getSnapshotsSectors(),
          ]);
          // eslint-disable-next-line no-underscore-dangle
          setSnapshotsRegions(loadedSnapshotsRegions.map((el) => el._id));
          // eslint-disable-next-line no-underscore-dangle
          setSnapshotsSectors(loadedSnapshotsSectors.map((el) => el._id));
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedSnapshots = await postSubscriptions();
          setSnapshotsData(loadedSnapshots);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [selectedRegions, selectedSectors, rangeFrom, rangeTo]);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={3} pb={1}>
                <MDBox pb={2}>
                  <MDTypography variant="h5">FILTERS</MDTypography>
                </MDBox>
                <MDBox px={1}>
                  <MDBox
                    py={1}
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      PICK THE TIME PERIOD
                    </MDTypography>
                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          <DateRangePickerCompAnalysis
                            range={rangeFrom}
                            setRange={setRangeFrom}
                            sideBarCustomObject={sideBarCustomObject}
                            // setFilterByRange={setFilterByRange}
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    py={1}
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      TIME PERIOD TO COMPARE
                    </MDTypography>
                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          <DateRangePickerCompAnalysis
                            range={rangeTo}
                            setRange={setRangeTo}
                            sideBarCustomObject={sideBarCustomObject}
                            // setFilterByRange={setFilterByRange}
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    py={1}
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      SELECT REGION TO FILTER THE MARKETS
                    </MDTypography>
                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <Stack spacing={2} width="250px">
                          <MultiSelect
                            // className="dark"
                            options={snapshotsRegions.map((el) => ({
                              label: el,
                              value: el,
                            }))}
                            value={selectedRegions}
                            onChange={setSelectedRegions}
                            labelledBy="Select"
                            label=""
                          />
                        </Stack>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    py={1}
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      SELECT SECTOR TO FILTER THE MARKETS
                    </MDTypography>
                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <Stack spacing={2} width="250px">
                          <MultiSelect
                            // className="dark"
                            options={snapshotsSectors.map((el) => ({
                              label: el,
                              value: el,
                            }))}
                            value={selectedSectors}
                            onChange={setSelectedSectors}
                            labelledBy="Select"
                            label=""
                          />
                        </Stack>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox p={3}>
                <MDBox py={1}>
                  <MDBox px={1}>
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      Performance Overview
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2.4}>
                        <Steps
                          title={
                            snapshotsData &&
                            snapshotsData.dataGroupedFrom &&
                            snapshotsData.dataGroupedFrom.length > 0
                              ? snapshotsData.dataGroupedFrom.length
                              : "0"
                          }
                          description={
                            snapshotsData &&
                            snapshotsData.dataGroupedFrom &&
                            snapshotsData.dataGroupedFrom.length > 0
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total Purchases`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2.4}>
                        <Steps
                          title={
                            snapshotsData && snapshotsData.totalCalculatedCancellationsCount
                              ? snapshotsData.totalCalculatedCancellationsCount
                              : "0"
                          }
                          description={
                            snapshotsData && snapshotsData.totalCalculatedCancellationsCount
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total Cancellations`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2.4}>
                        <Steps
                          title={
                            snapshotsData && snapshotsData.totalCalculatedSubscriptionsCount
                              ? snapshotsData.totalCalculatedSubscriptionsCount
                              : "0"
                          }
                          description={
                            snapshotsData && snapshotsData.totalCalculatedSubscriptionsCount
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total Newly Added`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2.4}>
                        <Steps
                          title={
                            snapshotsData && snapshotsData.totalCalculatedCancellationsCount
                              ? "0"
                              : "0"
                          }
                          description={
                            snapshotsData &&
                            snapshotsData.totalCalculatedCancellationsCount &&
                            snapshotsData.totalCalculatedSubscriptionsCount
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total Newly Cancelled`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={2.4}>
                        <Steps
                          title={
                            snapshotsData &&
                            snapshotsData.totalCalculatedCancellationsCount &&
                            snapshotsData.totalCalculatedSubscriptionsCount
                              ? snapshotsData.totalCalculatedSubscriptionsCount -
                                snapshotsData.totalCalculatedCancellationsCount
                              : "0"
                          }
                          description={
                            snapshotsData && snapshotsData.totalCalculatedCancellationsCount
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total Net Difference`
                              : "No records found"
                          }
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={1} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Product Usage Analysis
            </MDTypography>
          </MDBox>
          <DataTableAnalysis
            table={{
              columns: columns2,
              rows: snapshotsData?.merged?.length > 0 ? snapshotsData.merged : [],
            }}
            isSorted
            entriesPerPage
            showTotalEntries
            noEndBorder
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analysis;

import React from "react";
import { Link } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MDTypography from "components/MDTypography";

function MDLink({ to, text }) {
  return (
    <Link to={to}>
      <MDTypography
        component="span"
        variant="h7"
        color="info"
        fontWeight="medium"
        sx={{ lineHeight: 0 }}
      >
        {text}
      </MDTypography>
    </Link>
  );
}

// Setting default values for the props of MDLink
MDLink.defaultProps = {
  to: "/",
  text: "dashboard",
};

// Typechecking props for the MDLink
MDLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
};

export default MDLink;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

function DataTableBodyCellWidthAnalysis({ noBorder, align, children, width, minWidth, maxWidth }) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        padding: 0,
        background: "rgb(230,230,230)",
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        width={width || "max-content"}
        minWidth={minWidth || undefined}
        maxWidth={maxWidth || undefined}
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCellWidthAnalysis
DataTableBodyCellWidthAnalysis.defaultProps = {
  noBorder: false,
  align: "left",
  width: "max-content",
  minWidth: undefined,
  maxWidth: undefined,
};

// Typechecking props for the DataTableBodyCellWidthAnalysis
DataTableBodyCellWidthAnalysis.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

export default DataTableBodyCellWidthAnalysis;

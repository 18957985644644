// prop-type is a library for typechecking of props
import { useState, useEffect } from "react";
import io from "socket.io-client";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import Checkbox from "components/FormsUI/Checkbox";

import DataTableRequesters from "components/Tables/DataTableRequesters";

import requestersTableData from "layouts/tables/data/requestersTableData";

// NewUser page components
import FormField from "components/FormField";

const socket = io.connect(
  process.env.REACT_APP_baseURL.substring(0, process.env.REACT_APP_baseURL.length - 4)
  // 'http://localhost:3006/api' --> 'http://localhost:3006' without ".../api"
);

function Part2({ formData }) {
  const { columns } = requestersTableData();
  const {
    formField,
    values,
    errors,
    touched,
    sectors,
    countries,
    distinctMarketInfo,
    setFieldValue,
  } = formData;
  const { distinctPrimarySource } = distinctMarketInfo;
  const {
    Status,
    PrimarySource,
    SecondarySource,
    MarketHistoryYears,
    MarketHistoryMonths,
    SourceTicker,
    MarketCap,
    NAV,
    MarketName,
    GeneralTicker,
    StockExchange,
    Currency,
    Sector,
    Country,
    TotalRequests,
    Comments,
    // eslint-disable-next-line no-unused-vars
    Requesters,
  } = formField;
  const {
    Status: StatusV,
    PrimarySource: PrimarySourceV,
    SecondarySource: SecondarySourceV,
    MarketHistoryYears: MarketHistoryYearsV,
    MarketHistoryMonths: MarketHistoryMonthsV,
    SourceTicker: SourceTickerV,
    IsTradingVolumeEnough: IsTradingVolumeEnoughV,
    MarketCap: MarketCapV,
    NotifyMe: NotifyMeV,
    NAV: NAVV,
    MarketName: MarketNameV,
    GeneralTicker: GeneralTickerV,
    StockExchange: StockExchangeV,
    Currency: CurrencyV,
    Sector: SectorV,
    Country: CountryV,
    TotalRequests: TotalRequestsV,
    Comments: CommentsV,
    Requesters: RequestersV,
  } = values;

  const [lightsParams, setLightsParams] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [itemsParams, setItemsParams] = useState([]);

  useEffect(() => {
    socket.on("receive_script_params", (msg) => {
      setItemsParams((oldArray) => [...oldArray, msg]);
    });
    socket.on("receive_script_params_finished", (msg) => {
      // console.log(msg);
      // eslint-disable-next-line no-param-reassign
      const result = msg.split("|")[1];
      const resultErrorCode = msg
        .split("|")[0]
        .split(",")[1]
        .charAt(msg.split("|")[0].split(",")[1].length - 1);

      if (resultErrorCode !== "1") {
        setFieldValue("MarketCap", result);
        // console.log(result);
        const res1 = result.split(":")[0];
        const res2 = result.split(":")[1];
        // console.log(res2);
        if (res2 !== undefined) {
          setFieldValue("MarketCap", res1);
          setFieldValue("NAV", res2);
        }
      } else {
        setFieldValue("MarketCap", "");
        setFieldValue("NAV", "");
      }
      setItemsParams((oldArray) => [...oldArray, msg]);
      setLightsParams(false);
    });
  }, [socket]);

  useEffect(() => {
    if (SourceTickerV !== "") {
      socket.emit("send_message_python_params", SourceTickerV);
      // console.log("sending the request script to the nodejs server");
      setLightsParams(!lightsParams);
    } else {
      setFieldValue("MarketCap", "Please type a Source Ticker!");
    }
  }, []);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Part2
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="status"
              name={Status.name}
              options={["On Review", "Data Team", "Reviewed"]}
              value={StatusV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Status", value !== null ? value : "");
              }}
              disabled
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Status"
                  error={errors.Status && touched.Status}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Autocomplete
              id="PrimarySource"
              name={PrimarySource.name}
              // options={distinctPrimarySource.map((s) => s.Name)}
              options={distinctPrimarySource}
              value={PrimarySourceV || null}
              getOptionLabel={(option) => option.toString()}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("PrimarySource", value !== null ? value : "");
              }}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="PrimarySource" />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={SecondarySource.type}
              label={SecondarySource.label}
              name={SecondarySource.name}
              value={SecondarySourceV}
              placeholder={SecondarySource.placeholder}
              error={errors.SecondarySource && touched.SecondarySource}
              success={SecondarySourceV.length > 0 && !errors.SecondarySource}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormField
              type={MarketHistoryYears.type}
              label={MarketHistoryYears.label}
              name={MarketHistoryYears.name}
              value={MarketHistoryYearsV}
              placeholder={MarketHistoryYears.placeholder}
              error={errors.MarketHistoryYears && touched.MarketHistoryYears}
              success={MarketHistoryYearsV.length > 0 && !errors.MarketHistoryYears}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              type={MarketHistoryMonths.type}
              label={MarketHistoryMonths.label}
              name={MarketHistoryMonths.name}
              value={MarketHistoryMonthsV}
              placeholder={MarketHistoryMonths.placeholder}
              error={errors.MarketHistoryMonths && touched.MarketHistoryMonths}
              success={MarketHistoryMonthsV.length > 0 && !errors.MarketHistoryMonths}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={SourceTicker.type}
              label={SourceTicker.label}
              name={SourceTicker.name}
              value={SourceTickerV}
              placeholder={SourceTicker.placeholder}
              error={errors.SourceTicker && touched.SourceTicker}
              success={SourceTickerV.length > 0 && !errors.SourceTicker}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Checkbox
              name="IsTradingVolumeEnough"
              label="IsTradingVolumeEnough"
              checked={IsTradingVolumeEnoughV}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={MarketCap.type}
              label={MarketCap.label}
              name={MarketCap.name}
              value={MarketCapV}
              placeholder={MarketCap.placeholder}
              error={errors.MarketCap && touched.MarketCap}
              success={MarketCapV.length > 0 && !errors.MarketCap}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Micro"
                color="info"
                variant={MarketCapV.split(":")[0] === "MICRO CAP" ? "" : "outlined"}
              />
              <Chip
                label="Small"
                color="info"
                variant={MarketCapV.split(":")[0] === "SMALL CAP" ? "" : "outlined"}
              />
              <Chip
                label="Mid"
                color="info"
                variant={MarketCapV.split(":")[0] === "MID CAP" ? "" : "outlined"}
              />
              <Chip
                label="Large"
                color="info"
                variant={MarketCapV.split(":")[0] === "LARGE CAP" ? "" : "outlined"}
              />
              <Chip
                label="Mega"
                color="info"
                variant={MarketCapV.split(":")[0] === "MEGA CAP" ? "" : "outlined"}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={6}>
            <Checkbox name="NotifyMe" label="NotifyMe" checked={NotifyMeV} />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={NAV.type}
              label={NAV.label}
              name={NAV.name}
              value={
                parseFloat(NAVV).toFixed(2) === "NaN"
                  ? "Error calculating trading volume"
                  : parseFloat(NAVV).toFixed(2)
              }
              placeholder={NAV.placeholder}
              error={errors.NAV && touched.NAV}
              success={NAVV.length > 0 && !errors.NAV}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              type={MarketName.type}
              label={MarketName.label}
              name={MarketName.name}
              value={MarketNameV}
              placeholder={MarketName.placeholder}
              error={errors.MarketName && touched.MarketName}
              success={MarketNameV.length > 0 && !errors.MarketName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={Currency.type}
              label={Currency.label}
              name={Currency.name}
              value={CurrencyV}
              placeholder={Currency.placeholder}
              error={errors.Currency && touched.Currency}
              success={CurrencyV.length > 0 && !errors.Currency}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              type={GeneralTicker.type}
              label={GeneralTicker.label}
              name={GeneralTicker.name}
              value={GeneralTickerV}
              placeholder={GeneralTicker.placeholder}
              error={errors.GeneralTicker && touched.GeneralTicker}
              success={GeneralTickerV.length > 0 && !errors.Type}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="sector"
              name={Sector.name}
              options={sectors.map((s) => s.Name)}
              value={SectorV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Sector", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Sector" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              type={StockExchange.type}
              label={StockExchange.label}
              name={StockExchange.name}
              value={StockExchangeV}
              placeholder={StockExchange.placeholder}
              error={errors.StockExchange && touched.StockExchange}
              success={StockExchangeV.length > 0 && !errors.StockExchange}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Country"
              name={Country.DisplayName}
              options={countries.map((s) => s.DisplayName)}
              value={CountryV || null}
              getOptionLabel={(option) => option.toString()}
              style={{ width: 200 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Country", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Country" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={Comments.type}
              label={Comments.label}
              name={Comments.name}
              value={CommentsV}
              placeholder={Comments.placeholder}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <FormField
              type={TotalRequests.type}
              label={TotalRequests.label}
              name={TotalRequests.name}
              value={TotalRequestsV}
              placeholder={TotalRequests.placeholder}
              error={errors.TotalRequests && touched.TotalRequests}
              success={TotalRequestsV.length > 0 && !errors.TotalRequests}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTableRequesters
                  table={{ columns, rows: RequestersV }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Part2
Part2.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Part2;

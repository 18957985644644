/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";

export default function dataGmwCommentsDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="medium"
          >
            {row.values.Name}
          </MDTypography>
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "sector", accessor: "Sector", align: "center" },
      { Header: "run region", accessor: "RunRegion", align: "center" },
      { Header: "source", accessor: "Source", align: "center" },
      {
        Header: "length",
        accessor: (d) =>
          d["Length of Daily"] ||
          d["Length of Weekly"] ||
          d["Length of Monthly"] ||
          d["Length of Quarterly"] ||
          d["Length of Yearly"],
        align: "center",
      },
      // { Header: "reason", accessor: (d) => d.Message.replace(" 00:00:00", ""), align: "center" },
      // {
      //   Header: "last. refresh time",
      //   accessor: (d) => moment(d.DateTime).format("MM/DD/YYYY hh:mm A"),
      //   align: "center",
      // },
    ],
  };
}

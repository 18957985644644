import { useMemo, useState, useEffect } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChartSnapshots configurations
import configs from "examples/Charts/PieChartSearch/configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

function PieChartSearch({ icon, title, description, height, chart, categoryLabel }) {
  // eslint-disable-next-line no-unused-vars
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: chart.labels,
      datasets: [
        {
          data: data.datasets[0].data,
          backgroundColor: ["#1A73E8", "#D81B60", "#fb8c00", "#66BB6A", "#EF5350", "#42424A"],
          hoverOffset: 11,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          fill: false,
          // datalabels: {
          //   color: "#FFCE56",
          // },
        },
      ],
    });
    setChartOptions({
      // animation to false because an issue with the library is not fixed yet
      animation: true,
      responsive: true,
      maintainAspectRatio: false,
      layout: { padding: { bottom: 25, left: 25, right: 25, top: 25 } },
      type: "pie",
      plugins: {
        legend: {
          display: true,
          position: "right",
          align: "start",
          textAlign: "left",
          labels: {
            font: {
              size: 10,
            },
          },
        },
        datalabels: {
          color: "#42424A",
          labels: {
            index: {
              align: "end",
              anchor: "end",
              offset: 7,
              // display: "auto",
              // font: { size: 18 },
              formatter(value, ctx) {
                // let total = 0;
                // ctx.dataset.data.forEach((item) => {
                //   total += item;
                // });
                return ctx.active
                  ? `${
                      ctx.chart.legend.legendItems[ctx.dataIndex].text
                    } [${value}] ${categoryLabel}`
                  : ``;
                // return ctx.active
                // ? `${ctx.chart.legend.legendItems[ctx.dataIndex].text} ${(
                //     value / total
                //   ).toLocaleString(undefined, {
                //     style: "percent",
                //     maximumFractionDigits: 2,
                //   })}`
                // : `${ctx.chart.legend.legendItems[ctx.dataIndex].text}  [${value}] markets`;
              },
              opacity(ctx) {
                return ctx.active ? 1 : 0.5;
              },
            },
          },
        },
        tooltip: {
          callbacks: {
            label: (item) => {
              let total = 0;
              item.dataset.data.forEach((el) => {
                total += el;
              });
              return `${item.chart.legend.legendItems[item.dataIndex].text} ${(
                item.dataset.data[item.dataIndex] / total
              ).toLocaleString(undefined, {
                style: "percent",
                maximumFractionDigits: 2,
              })}`;
              // return data['datasets'][0]['data'][tooltipItem['index']];
            },
          },
        },
      },
    });
  }, [data.datasets[0].data]);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      <MDBox
        mx={2}
        mt={-3}
        py={1.5}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {title}
        </MDTypography>
      </MDBox>
      {useMemo(
        () => (
          <MDBox height={height}>
            <Pie options={chartOptions} data={chartData} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChartSearch
PieChartSearch.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  categoryLabel: "",
};

// Typechecking props for the PieChartSearch
PieChartSearch.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  categoryLabel: PropTypes.string,
};

export default PieChartSearch;

// react
import { useMaterialUIController } from "context";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Checkbox from "components/FormsUI/Checkbox";

// NewUser page components
import FormField from "components/FormField";
import { useState } from "react";

function MarketRequestDataTeamInfo({ formData }) {
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const {
    formField,
    values,
    errors,
    touched,
    categories,
    currencies,
    countries,
    setFieldValue,
    marketInfoRequest,
  } = formData;
  const {
    MarketName,
    Category,
    ExchangeSymbol,
    Exchange,
    Country,
    SourceTicker,
    ExternalRequester,
    ExternalEmail,
  } = formField;
  const {
    MarketName: MarketNameV,
    Category: CategoryV,
    ExchangeSymbol: ExchangeSymbolV,
    Exchange: ExchangeV,
    Country: CountryV,
    SourceTicker: SourceTickerV,
    Currency: CurrencyV,
    IsBusinessUser: IsBusinessUserV,
    ExternalRequester: ExternalRequesterV,
    ExternalEmail: ExternalEmailV,
  } = values;

  const [visible, setVisible] = useState(marketInfoRequest.ExternalRequester);

  const handleSetVisible = (e) => {
    setVisible(!visible);
    setFieldValue("externalRequester", e.target.checked, false);
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={MarketName.type}
              label={MarketName.label}
              name={MarketName.name}
              value={MarketNameV}
              placeholder={MarketName.placeholder}
              error={errors.MarketName && touched.MarketName}
              success={MarketNameV.length > 0 && !errors.MarketName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="category"
              name={Category}
              // options={sectors.map((s) => s.Name)}
              options={categories}
              value={CategoryV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Category", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Category" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={ExchangeSymbol.type}
              label={ExchangeSymbol.label}
              name={ExchangeSymbol.name}
              value={ExchangeSymbolV}
              placeholder={ExchangeSymbol.placeholder}
              error={errors.ExchangeSymbol && touched.ExchangeSymbol}
              success={ExchangeSymbolV.length > 0 && !errors.ExchangeSymbol}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="Currency"
              name="Currency"
              // options={countries.map((s) => s.name)}
              options={currencies.map((s) => s.cc)}
              value={CurrencyV || null}
              // getOptionLabel={(option) => option}
              getOptionLabel={(option) => currencies.filter((e) => e.cc === option)[0].name}
              // style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Currency", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Currency" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={Exchange.type}
              label={Exchange.label}
              name={Exchange.name}
              value={ExchangeV}
              placeholder={Exchange.placeholder}
              error={errors.Exchange && touched.Exchange}
              success={ExchangeV.length > 0 && !errors.Exchange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country"
              name={Country.DisplayName}
              options={countries.map((s) => s.DisplayName)}
              value={CountryV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Country", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Country" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={SourceTicker.type}
              label={SourceTicker.label}
              name={SourceTicker.name}
              value={SourceTickerV}
              placeholder={SourceTicker.placeholder}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Checkbox
              name="IsBusinessUser"
              label="IsBusinessUser"
              checked={IsBusinessUserV}
              disabled={userData && userData.role === "admin"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3.5} lg={3.5}>
            <MDBox
              display="flex"
              // justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDBox ml={0}>
                <Switch
                  checked={visible}
                  onChange={(e) => handleSetVisible(e)}
                  name={ExternalRequester.name}
                  value={ExternalRequesterV}
                  disabled={userData && userData.role === "admin"}
                />
              </MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                External request
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            {visible && (
              <FormField
                type={ExternalEmail.type}
                label={ExternalEmail.label}
                name={ExternalEmail.name}
                value={ExternalEmailV}
                placeholder={ExternalEmail.placeholder}
                error={errors.ExternalEmail && touched.ExternalEmail}
                success={ExternalEmailV.length > 0 && !errors.ExternalEmail}
                disabled={!visible}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MarketRequestDataTeamInfo
MarketRequestDataTeamInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MarketRequestDataTeamInfo;

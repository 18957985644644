/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
// import MDLink from "components/MDLink";
// import MDTypography from "components/MDTypography";
import { Checkbox } from "@mui/material";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns: [
      {
        Header: "Requester",
        accessor: "Name",
        align: "left",
        maxWidth: 60,
        minWidth: 60,
        // Cell: ({ row }) => (
        //   <MDLink to={`/markets/${row.original.MarketName}`} text={row.values.MarketName} />
        // ),
      },
      {
        Header: "Submission Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.InsertedDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "External Requester",
        accessor: (d) => <Checkbox checked={d.ExternalRequester} />,
        align: "left",
        maxWidth: 20,
        minWidth: 20,
      },
      {
        Header: "External Email",
        accessor: "ExternalEmail",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        // Cell: ({ row }) => (
        //   <MDLink to={`/markets/${row.original.MarketName}`} text={row.values.MarketName} />
        // ),
      },
      // {
      //   Header: "",
      //   accessor: "Action",
      //   align: "left",
      //   maxWidth: 140,
      //   minWidth: 140,
      //   Cell: ({ row }) => (
      //     <MDTypography variant="h4" color="text">
      //       <Link
      //         to="/Market-Management/View-Market-Request"
      //         state={{ marketInfoRequest: row.original }}
      //       >
      //         <Icon>visibility</Icon>
      //       </Link>
      //     </MDTypography>
      //   ),
      // },
    ],
  };
}

// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import marketsTableData from "layouts/tables/data/marketsTableData";
import DataTableRowSelectDownload from "examples/Tables/DataTableRowSelectDownload";

function DownloadDBSFile() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const getMarkets = async () => {
    const result = await http({
      path: `/markets`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketInfoCharts = async () => {
    const result = await http({
      path: `/markets/marketinfocharts`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return {};
  };

  const [markets, marketInfoCharts] = useQueries({
    queries: [
      {
        queryKey: ["markets"],
        queryFn: getMarkets,
      },
      {
        queryKey: ["marketInfoCharts"],
        queryFn: getMarketInfoCharts,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const { columns3 } = marketsTableData();

  if (marketInfoCharts.isLoading) return <MDLoader />;
  if (markets.isLoading) return <MDLoader />;

  if (marketInfoCharts.isError) return <div>Error at loading marketInfoCharts</div>;
  if (markets.isError) return <div>Error at loading markets</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {markets?.data?.length > 0 ? (
        <DataTableRowSelectDownload
          table={{ columns: columns3, rows: markets.data }}
          isSorted
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
          marketInfoCharts={marketInfoCharts.data.source}
        />
      ) : null}

      <Footer />
    </DashboardLayout>
  );
}

export default DownloadDBSFile;

import { useState, useEffect } from "react";
import { useQueries } from "@tanstack/react-query";
import { useMaterialUIController, setOpenMarketMessage, validateRole } from "context";
import { useNavigate, useLocation } from "react-router-dom";

// formik components
import { Formik, Form } from "formik";

import http from "services/http";
import httpPost from "services/httpPost";
import httpPut from "services/httpPut";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDLoader from "components/MDLoader";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import TagInfo from "layouts/newtag/components/TagInfo";

// NewUser layout schemas for form and form feilds
import validations from "layouts/newtag/schemas/validations";
import form from "layouts/newtag/schemas/form";
import initialValues from "layouts/newtag/schemas/initialValues";

function NewTag() {
  const location = useLocation();
  const paramNewTagName = location.state?.markettagname;
  const paramNewTagId = location.state?.tagid;
  const paramMarkets = location.state?.tagmarkets || [];
  const paramAction = location.state?.action;
  let initialMode = "";
  let initialTag = "";
  let initialTagId = "";
  if (paramNewTagName !== undefined) {
    initialMode = "UPDATE TAG";
    initialTag = paramNewTagName;
  }
  if (paramNewTagId !== undefined) {
    initialTagId = paramNewTagId;
  }
  if (paramAction === "New Tag Name") {
    initialMode = "CREATE TAG";
    initialTag = "New Tag Name";
  }
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["MANAGE TAGS"];
  const { formId, formField } = form;
  const isLastStep = activeStep === steps.length - 1;

  const [suggestions, setSuggestions] = useState([]);
  const [marketAlreadyAdded, setMarketAlreadyAdded] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSector, setSelectedSector] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedGics, setSelectedGics] = useState([]);
  const [selectedGicsIndustry, setSelectedGicsIndustry] = useState([]);
  const [valueRadio, setValueRadio] = useState("socrates");
  const [valueDefaultSorting, setvalueDefaultSorting] = useState([
    {
      id: "Category",
      desc: false,
    },
    {
      id: "Market",
      desc: false,
    },
  ]);

  const [mode, setMode] = useState(initialMode);
  const [markets, setMarkets] = useState(paramMarkets || []);
  const [tagid, setTagid] = useState(initialTagId || "");

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  useEffect(() => {
    let firstColumn = "";
    if (valueRadio === "socrates") {
      firstColumn = "Category";
    }
    if (valueRadio === "gics") {
      firstColumn = "GICS";
    }
    setvalueDefaultSorting([
      {
        id: firstColumn,
        desc: false,
      },
      {
        id: "Market",
        desc: false,
      },
    ]);
  }, [valueRadio]);

  const postTag = async (obj) => {
    const result = await httpPost({
      path: `/markettag`,
      method: "POST",
      raw: JSON.stringify(obj),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const putTag = async (obj) => {
    const result = await httpPut({
      path: `/markettag`,
      method: "PUT",
      raw: JSON.stringify(obj),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  // eslint-disable-next-line no-unused-vars
  const submitForm = async (values, actions) => {
    let newTag = {};
    if (mode === "") {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "error",
        title: "Validation Error",
        content: "Please choose an action Add New or Select Saved Tag",
      });
    }
    if (mode === "CREATE TAG") {
      newTag = {
        Markets: markets,
        MarketTagName: values.TagName,
        Active: true,
      };
      const result = await postTag(newTag);
      if (result && result.message === "tag created!") {
        await setOpenMarketMessage(dispatch, {
          open: true,
          color: "success",
          icon: "check",
          title: "Success",
          content: "New Tag Added!",
        });
        navigate("/view-all-tags");
      } else {
        await setOpenMarketMessage(dispatch, {
          open: true,
          color: "error",
          icon: "error",
          title: "Database Error",
          content: JSON.stringify(result.message),
        });
      }
    }

    if (mode === "UPDATE TAG") {
      newTag = {
        Markets: markets,
        TagId: tagid,
      };
      if (values.TagName !== undefined && values.TagName !== "") {
        newTag.MarketTagName = values.TagName;
      }
      const result = await putTag(newTag);
      if (result && result.message === "tag updated!") {
        await setOpenMarketMessage(dispatch, {
          open: true,
          color: "success",
          icon: "check",
          title: "Success",
          content: "Tag Updated!",
        });
        navigate("/view-all-tags");
      } else {
        await setOpenMarketMessage(dispatch, {
          open: true,
          color: "error",
          icon: "error",
          title: "Database Error",
          content: JSON.stringify(result.message),
        });
      }
    }
    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getMarketSuggestions = async () => {
    const result = await http({
      path: `/markets/marketsuggestions`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctSector = async () => {
    const result = await http({
      path: `/market/distinctsector`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctCategory = async () => {
    const result = await http({
      path: `/market/distinctcategory`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctSectorGics = async () => {
    const result = await http({
      path: `/market/distinctsectorgics`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctSectorGicsIndustry = async () => {
    const result = await http({
      path: `/market/distinctsectorgicsindustry`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketTags = async () => {
    const result = await http({
      path: `/markettag`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [
    distinctSector,
    distinctCategory,
    marketTags,
    distinctSectorGics,
    distinctSectorGicsIndustry,
    marketSuggestions,
  ] = useQueries({
    queries: [
      {
        queryKey: ["distinctSector"],
        queryFn: getDistinctSector,
        select: (sector) => sector.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      },
      {
        queryKey: ["distinctCategory"],
        queryFn: getDistinctCategory,
        select: (category) =>
          category.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      },
      {
        queryKey: ["marketTags"],
        queryFn: getMarketTags,
      },
      {
        queryKey: ["distinctSectorGics"],
        queryFn: getDistinctSectorGics,
        select: (sector) =>
          sector
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .reduce((result, element) => {
              const normalize = (x) => (typeof x === "string" ? x.toLowerCase() : x);

              const normalizedElement = normalize(element);
              if (result.every((otherElement) => normalize(otherElement) !== normalizedElement))
                result.push(element);

              return result;
            }, []),
      },
      {
        queryKey: ["distinctSectorGicsIndustry"],
        queryFn: getDistinctSectorGicsIndustry,
        select: (sector) =>
          sector
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
            .reduce((result, element) => {
              const normalize = (x) => (typeof x === "string" ? x.toLowerCase() : x);

              const normalizedElement = normalize(element);
              if (result.every((otherElement) => normalize(otherElement) !== normalizedElement))
                result.push(element);

              return result;
            }, []),
      },
      {
        queryKey: ["marketSuggestions"],
        queryFn: getMarketSuggestions,
      },
    ],
  });

  const getSuggestions = (value) =>
    marketSuggestions.data.filter(
      (market) =>
        market.Name.toUpperCase().includes(value.trim().toUpperCase()) ||
        market.PEITicker.toUpperCase().includes(value.trim().toUpperCase())
    );

  const handleUpdateTag = (value) => {
    if (value === null) {
      setMode("");
      setMarkets([]);
      setTagid("");
    } else {
      setMode("UPDATE TAG");
      const marketTag = marketTags.data.filter((el) => el.MarketTagName === value)[0];
      const { Markets } = marketTag;
      setMarkets(Markets || []);
      // eslint-disable-next-line no-underscore-dangle
      setTagid(marketTag?._id);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleNewTag = (value, TagV) => {
    setMode("CREATE TAG");
    if (TagV !== "") setMarkets([]);
  };

  const removeDuplicatesByPEITicker = (data) =>
    data.filter(
      (thing, index, self) => index === self.findIndex((t) => t.PEITicker === thing.PEITicker)
    );

  const sortedDataBySectorByMarket = (data) =>
    data.sort((a, b) => {
      if (a.Category < b.Category) {
        return -1;
      }
      if (a.Category > b.Category) {
        return 1;
      }
      if (a.Market < b.Market) {
        return -1;
      }
      if (a.Market > b.Market) {
        return 1;
      }
      return 0;
    });

  const handleAddMarket = () => {
    if (searchTerm === "") return;
    const searchPEITicker = marketSuggestions.data.find((market) => market.Name === searchTerm); // .PEITicker;
    if (searchPEITicker === undefined) return;
    const marketToAdd = {
      // eslint-disable-next-line no-underscore-dangle
      ResearchItemId: searchPEITicker._id,
      Market: searchPEITicker.Name,
      PEITicker: searchPEITicker.PEITicker,
      // Category: searchPEITicker.Sector || searchPEITicker.Sector.Name,
      Category: searchPEITicker.Category,
      GICS: searchPEITicker.GICSSector,
    };
    const newMarkets = [marketToAdd, ...markets];
    const removedDuplicates = removeDuplicatesByPEITicker(newMarkets);
    const sortedData = sortedDataBySectorByMarket(removedDuplicates);
    setMarkets(sortedData);
    setSearchTerm("");
  };

  const handleAddMarketBySector = () => {
    const selectedValues = selectedSector.map((el) => el.label.toLowerCase());
    const filteredMarkets = marketSuggestions.data.filter((el) => {
      const sectorlowercase = el.Sector ? el.Sector.toLowerCase() : "";
      return selectedValues.includes(sectorlowercase);
    });
    const marketsToAdd = filteredMarkets.map((el) => ({
      // eslint-disable-next-line no-underscore-dangle
      ResearchItemId: el._id,
      Market: el.Name,
      PEITicker: el.PEITicker,
      // Category: el.Sector,
      Category: el.Category,
      GICS: el.GICSSector || "",
    }));
    const newMarkets = [...marketsToAdd, ...markets];
    const removedDuplicates = removeDuplicatesByPEITicker(newMarkets);
    const sortedData = sortedDataBySectorByMarket(removedDuplicates);
    setMarkets(sortedData);
  };

  const handleAddMarketByCategory = () => {
    const selectedValues = selected.map((el) => el.label.toLowerCase());
    const filteredMarkets = marketSuggestions.data.filter((el) => {
      const categorylowercase = el.Category ? el.Category.toLowerCase() : "";
      return selectedValues.includes(categorylowercase);
    });
    const marketsToAdd = filteredMarkets.map((el) => ({
      // eslint-disable-next-line no-underscore-dangle
      ResearchItemId: el._id,
      Market: el.Name,
      PEITicker: el.PEITicker,
      // Category: el.Sector,
      Category: el.Category,
      GICS: el.GICSSector || "",
    }));
    const newMarkets = [...marketsToAdd, ...markets];
    const removedDuplicates = removeDuplicatesByPEITicker(newMarkets);
    const sortedData = sortedDataBySectorByMarket(removedDuplicates);
    setMarkets(sortedData);
  };

  const handleAddMarketBySectorGics = () => {
    const selectedValues = selectedGics.map((el) => el.label.toLowerCase());
    const filteredMarkets = marketSuggestions.data.filter((el) => {
      const gicssectorlowercase = el.GICSSector ? el.GICSSector.toLowerCase() : "";
      return selectedValues.includes(gicssectorlowercase);
    });
    const marketsToAdd = filteredMarkets.map((el) => ({
      Market: el.Name,
      PEITicker: el.PEITicker,
      // Category: el.Sector,
      Category: el.Category,
      GICS: el.GICSSector || "",
    }));
    const newMarkets = [...marketsToAdd, ...markets];
    const removedDuplicates = removeDuplicatesByPEITicker(newMarkets);

    const sortedData = sortedDataBySectorByMarket(removedDuplicates);
    setMarkets(sortedData);
  };

  const handleAddMarketBySectorGicsIndustry = () => {
    const selectedValues = selectedGicsIndustry.map((el) => el.label.toLowerCase());
    const filteredMarkets = marketSuggestions.data.filter((el) => {
      const gicsindustrylowercase = el.GICSIndustry ? el.GICSIndustry.toLowerCase() : "";
      return selectedValues.includes(gicsindustrylowercase);
    });
    const marketsToAdd = filteredMarkets.map((el) => ({
      Market: el.Name,
      PEITicker: el.PEITicker,
      // Category: el.Sector,
      Category: el.Category,
      GICS: el.GICSSector || "",
      GICSIndustry: el.GICSIndustry || "",
    }));
    const newMarkets = [...marketsToAdd, ...markets];
    const removedDuplicates = removeDuplicatesByPEITicker(newMarkets);

    const sortedData = sortedDataBySectorByMarket(removedDuplicates);
    setMarkets(sortedData);
  };

  const removeMarkets = (itemsToRemove) => {
    const newMarkets = markets.filter((el) => !itemsToRemove.includes(el.PEITicker));
    setMarkets(newMarkets);
  };

  const validateSectorDisabled = (array) =>
    array.map((el) => {
      const elementMarketSuggestions = marketSuggestions.data.filter(
        (ell) => ell.Sector === el.label
      );
      const elementMarkets = markets.filter((ell) => ell.Sector === el.label);
      return { ...el, disabled: elementMarketSuggestions.length === elementMarkets.length };
    });

  const validateCategoryDisabled = (array) =>
    array.map((el) => {
      const elementMarketSuggestions = marketSuggestions.data.filter(
        (ell) => ell.Category === el.label
      );
      const elementMarkets = markets.filter((ell) => ell.Category === el.label);
      return { ...el, disabled: elementMarketSuggestions.length === elementMarkets.length };
    });

  const validateSectorGICSDisabled = (array) =>
    array.map((el) => {
      const elementMarketSuggestions = marketSuggestions.data.filter((ell) => {
        const gicssectorlowercase = ell.GICSSector ? ell.GICSSector.toLowerCase() : "";
        return gicssectorlowercase === el.label.toLowerCase();
      });
      const elementMarkets = markets.filter((ell) => ell.GICS === el.label);
      return { ...el, disabled: elementMarketSuggestions.length === elementMarkets.length };
    });

  const validateSectorGICSIndustryDisabled = (array) =>
    array.map((el) => {
      const elementMarketSuggestions = marketSuggestions.data.filter((ell) => {
        const gicsindustrylowercase = ell.GICSIndustry ? ell.GICSIndustry.toLowerCase() : "";
        return gicsindustrylowercase === el.label.toLowerCase();
      });
      const elementMarkets = markets.filter((ell) => ell.GICS === el.label);
      return { ...el, disabled: elementMarketSuggestions.length === elementMarkets.length };
    });

  useEffect(() => {
    const newSelected = selected.filter((el) =>
      markets.some((market) => market.Category === el.label)
    );
    setSelected(newSelected);
    const newSelectedGics = selectedGics.filter((el) =>
      markets.some((market) => market.GICS === el.label)
    );
    setSelectedGics(newSelectedGics);
  }, [markets]);

  const validateAddedMarkets = () => {
    const newSelected = selected.filter((el) =>
      markets.some((market) => market.Category === el.label)
    );
    setSelected(newSelected);
    const newSelectedGics = selectedGics.filter((el) =>
      markets.some((market) => market.GICS === el.label)
    );
    setSelectedGics(newSelectedGics);
  };

  if (marketTags.isLoading) return <MDLoader />;
  if (distinctSector.isLoading) return <MDLoader />;
  if (distinctCategory.isLoading) return <MDLoader />;
  if (distinctSectorGics.isLoading) return <MDLoader />;
  if (marketSuggestions.isLoading) return <MDLoader />;

  let cardTitle = "Manage Tags";
  if (mode === "UPDATE TAG") cardTitle = "Manage Tags / Edit Tag";
  if (mode === "CREATE TAG") cardTitle = "Manage Tags / New Tag";

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                ...initialValues,
                Tag: initialTag,
              }}
              validationSchema={mode === "UPDATE TAG" ? validations[1] : validations[0]}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, resetForm, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        {cardTitle}
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        <TagInfo
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            mode,
                            marketTags,
                            setMode,
                            handleUpdateTag,
                            markets,
                            handleNewTag,
                            suggestions,
                            setSuggestions,
                            marketAlreadyAdded,
                            setMarketAlreadyAdded,
                            setSearchTerm,
                            getSuggestions,
                            searchTerm,
                            valueRadio,
                            setValueRadio,
                            distinctSector,
                            distinctCategory,
                            distinctSectorGics,
                            distinctSectorGicsIndustry,
                            selectedSector,
                            setSelectedSector,
                            selected,
                            setSelected,
                            selectedGics,
                            setSelectedGics,
                            selectedGicsIndustry,
                            setSelectedGicsIndustry,
                            handleAddMarket,
                            handleAddMarketBySector,
                            handleAddMarketByCategory,
                            handleAddMarketBySectorGics,
                            handleAddMarketBySectorGicsIndustry,
                            removeMarkets,
                            setFieldValue,
                            valueDefaultSorting,
                            validateAddedMarkets,
                            validateSectorDisabled,
                            validateCategoryDisabled,
                            validateSectorGICSDisabled,
                            validateSectorGICSIndustryDisabled,
                          }}
                        />
                        <MDBox
                          display="flex"
                          justifyContent="right"
                          alignItems="center"
                          mt={2}
                          mx={12}
                        >
                          <MDButton
                            disabled={
                              markets.length === 0 ||
                              (errors.TagName && touched.TagName) ||
                              (values.TagName === "" && mode === "CREATE TAG")
                            }
                            type="submit"
                            variant="gradient"
                            color="success"
                          >
                            {mode !== "" ? mode : "CREATE TAG"}
                          </MDButton>
                          {userData && userData.role === "admin" && (
                            <MDBox ml={4}>
                              <MDButton
                                type="submit"
                                variant="gradient"
                                color="secondary"
                                onClick={async () => {
                                  resetForm();
                                  setMarkets([]);
                                  navigate("/view-all-tags");
                                }}
                              >
                                CANCEL
                              </MDButton>
                            </MDBox>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewTag;

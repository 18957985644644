/* eslint-disable react/prop-types */
// @mui material components
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import moment from "moment";

function TimeFrame({ timeframe, setTimeframe, setPurchasedDate }) {
  const setDates = (value) => {
    switch (value) {
      case "yesterday":
        setPurchasedDate([
          moment().subtract(1, "days").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      case "last5days":
        setPurchasedDate([
          moment().subtract(5, "days").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      case "last30days":
        setPurchasedDate([
          moment().subtract(30, "days").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      case "last90days":
        setPurchasedDate([
          moment().subtract(90, "days").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      case "lastyear":
        setPurchasedDate([
          moment().startOf("year").subtract(1, "years").format("MM-DD-YYYY"),
          moment().startOf("year").subtract(1, "days").format("MM-DD-YYYY"),
        ]);
        break;
      case "ytd":
        setPurchasedDate([
          moment().startOf("year").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      case "ttm":
        setPurchasedDate([
          moment().subtract(365, "days").format("MM-DD-YYYY"),
          moment().format("MM-DD-YYYY"),
        ]);
        break;
      default:
        setPurchasedDate(["", ""]);
        break;
    }
  };

  return (
    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
      <ToggleButtonGroup
        size="small"
        color="info"
        value={timeframe}
        exclusive
        onChange={(e, value) => {
          setTimeframe(value);
          setDates(value);
        }}
        aria-label="text alignment"
      >
        <ToggleButton value="yesterday" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Yesterday
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="last5days" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Last 5 days
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="last30days" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Last 30 days
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="last90days" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Last 90 days
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="lastyear" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Last Year
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="ytd" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            YTD
          </MDTypography>
        </ToggleButton>
        <ToggleButton value="ttm" aria-label="centered">
          <MDTypography variant="caption" color="text" fontWeight="regular">
            TTM
          </MDTypography>
        </ToggleButton>
      </ToggleButtonGroup>
    </MDBox>
  );
}

export default TimeFrame;

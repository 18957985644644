// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard React context
import { useMaterialUIController } from "context";

import * as Loader from "react-loader-spinner";

function ControllerCardScriptParams({
  color,
  state,
  onChange,
  PEITicker,
  setPEITicker,
  microCap,
  microCapLabel,
  classification,
  isLoading,
  labelText,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <MDBox
        p={3}
        height="100%"
        bgColor={state ? color : "white"}
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={({ palette: { background } }) => ({
          background: darkMode && !state && background.card,
        })}
      >
        <Grid item xs={12}>
          <MDInput
            fullWidth
            label={labelText}
            inputProps={{ type: "text", autoComplete: "", maxLength: "20" }}
            value={PEITicker}
            onChange={(e) => setPEITicker(e.target.value)}
            disabled={state}
            variant="standard"
            type="text"
            size="small"
          />
        </Grid>

        <MDBox mt={1} lineHeight={1}>
          <MDBox width="100%" display="flex" justifyContent="right">
            <MDButton
              disabled={state}
              type="button"
              variant="gradient"
              color="info"
              sx={{ marginLeft: "40px" }}
              onClick={onChange}
            >
              Find Trading Volume
            </MDButton>
          </MDBox>
          {isLoading && <Loader.ThreeDots color="#1A73E8" />}
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          mb={2}
          lineHeight={1}
        >
          <MDTypography variant="body2" color={state ? "white" : "text"}>
            {microCapLabel}
          </MDTypography>
          <MDTypography variant="body2" color={state ? "white" : "text"}>
            {microCap}
          </MDTypography>
          <MDTypography variant="body2" color={state ? "white" : "text"}>
            {classification}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ControllerCardScriptParams
ControllerCardScriptParams.defaultProps = {
  color: "info",
  state: false,
  PEITicker: "",
  microCap: "",
  microCapLabel: "",
  classification: "",
  isLoading: false,
  labelText: "",
};

// Typechecking props for the ControllerCardScriptParams
ControllerCardScriptParams.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  state: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  PEITicker: PropTypes.string,
  setPEITicker: PropTypes.func.isRequired,
  microCap: PropTypes.string,
  microCapLabel: PropTypes.string,
  classification: PropTypes.string,
  isLoading: PropTypes.bool,
  labelText: PropTypes.string,
};

export default ControllerCardScriptParams;

import * as Yup from "yup";
import checkout from "layouts/nullcategoriesedit/schemas/form";

const {
  formField: { category },
} = checkout;

const validations = [
  Yup.object().shape({
    [category.name]: Yup.string().required(category.errorMsg),
  }),
];

export default validations;

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import httpPost from "services/httpPost";

import io from "socket.io-client";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ControllerCardScriptParams from "examples/Cards/ControllerCardScriptParams";

const socket = io.connect(
  process.env.REACT_APP_baseURL.substring(0, process.env.REACT_APP_baseURL.length - 4)
  // 'http://localhost:3006/api' --> 'http://localhost:3006' without ".../api"
);

function TradingVolume() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();
  const [lightsParams, setLightsParams] = useState(false);
  const [PEITicker, setPEITicker] = useState("");
  const [microCap, setMicroCap] = useState("");
  const [microCapLabel, setMicroCapLabel] = useState("");
  const [classification, setClassification] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [labelText, setLabelText] = useState("Exchange Ticker");

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  useEffect(
    () => () => {
      socket.off("TRADINGVOLUME_UPDATED");
    },
    []
  );

  useEffect(() => {
    if (PEITicker !== "") {
      setLabelText("Exchange Ticker");
      setIsLoading(false);
      setMicroCap("");
      setMicroCapLabel("");
      setClassification("");
    }
    socket.on("TRADINGVOLUME_UPDATED", (msg) => {
      setIsLoading(false);
      const { peiticker, exchangeticker, classification: classificationValue } = msg;
      if (PEITicker === peiticker) {
        setMicroCapLabel(String(peiticker));
        setMicroCap(exchangeticker);
        setClassification(classificationValue);
        setLightsParams(false);
        if (classificationValue === "Trading Volume Not Found") {
          setMicroCapLabel("");
          setMicroCap("");
        }
      }
    });
  }, [PEITicker, socket]);

  const postTradingVolume = async (obj) => {
    const result = await httpPost({
      path: `/tradingvolume`,
      method: "POST",
      raw: JSON.stringify(obj),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleSetLightsParams = async () => {
    if (PEITicker !== "") {
      setLabelText("Exchange Ticker");
      // socket.emit("send_message_python_params", PEITicker);
      const newTradingVolume = {
        exchangeticker: 0,
        peiticker: PEITicker,
        status: "requested",
      };
      // eslint-disable-next-line no-unused-vars
      const result = await postTradingVolume(newTradingVolume);
      // setLightsParams(!lightsParams);
      setIsLoading(true);
      setMicroCap("");
      setMicroCapLabel("Calculating...");
      setClassification("");
    } else {
      setLabelText("Please type a Exchange Ticker");
      setIsLoading(false);
      setMicroCap("");
      setMicroCapLabel("");
      setClassification("");
    }
  };

  function microCapValueForLabel(inMicroCap) {
    if (inMicroCap === "") return "";
    if (parseFloat(inMicroCap).toFixed(2) === "NaN") return "Error calculating trading volume";
    return parseFloat(inMicroCap).toFixed(2);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} lg={6}>
              <MDBox mb={3}>
                <ControllerCardScriptParams
                  state={lightsParams}
                  icon={
                    <Icon className={lightsParams ? "text-white" : "text-dark"} fontSize="large">
                      {lightsParams ? "directions_run" : "directions_walk"}
                    </Icon>
                  }
                  title={lightsParams ? "script running..." : "run script"}
                  onChange={handleSetLightsParams}
                  PEITicker={PEITicker}
                  setPEITicker={setPEITicker}
                  microCap={microCapValueForLabel(microCap)}
                  microCapLabel={microCapLabel}
                  classification={classification}
                  isLoading={isLoading}
                  labelText={labelText}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TradingVolume;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { NumberRangeColumnFilter } from "components/TableFilters/selectByField";
import { formatDateInNewYorkNoSec, formatDateInNewYork } from "utils/dateUtil";

const fromTimeframeKeyToString = (timeframe) => {
  switch (timeframe) {
    case 1:
      return "Daily";
    case 2:
      return "Weekly";
    case 3:
      return "Monthly";
    case 4:
      return "Quarterly";
    case 5:
      return "Yearly";
    default:
      return "";
  }
};

export default function data() {
  return {
    columns: [
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 250,
        minWidth: 250,
        Filter: "",
      },
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      {
        Header: "TimeFrame",
        accessor: "TimeFrame",
        align: "left",
        maxWidth: 80,
        minWidth: 80,
        Filter: "",
        Cell: ({ row }) => fromTimeframeKeyToString(row.original.TimeFrame),
        // Filter: NumberRangeColumnFilter,
        // filter: "between",
        // isSorted: false,
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 75,
        minWidth: 75,
        Filter: "",
        // Filter: SelectColumnFilter,
        // filter: "includes",
        // isSorted: false,
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 75,
        minWidth: 75,
        Filter: "",
        // Filter: SelectColumnFilter,
        // filter: "includes",
        // isSorted: false,
      },
      {
        Header: "Count",
        accessor: "Count",
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        isSorted: false,
        Cell: ({ row }) => {
          let link = `/market-data-check/${row.original.PEITicker}`;

          if (row.original.FilterType) link += `/${row.original.FilterType}`;
          else link += `/0`;

          if (row.original.TimeFrame) link += `/${row.original.TimeFrame}`;

          return <MDLink to={link} text={row.values.Count ? row.values.Count.toString() : ""} />;
        },
      },
      {
        Header: "Reported Date",
        accessor: "InsertedDate",
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.InsertedDate),
      },
      // {
      //   Header: "Message",
      //   accessor: "Message",
      //   align: "left",
      //   maxWidth: 255,
      //   minWidth: 255,
      //   Filter: "",
      // },
      {
        Header: "Maximum Percentage Found",
        accessor: "Percentage",
        align: "left",
        maxWidth: 255,
        minWidth: 255,
        Filter: "",
      },
      {
        Header: "Date",
        accessor: "Date",
        align: "left",
        maxWidth: 255,
        minWidth: 255,
        Filter: "",
        Cell: ({ row }) => formatDateInNewYork(row.original.Date),
      },
      // {
      //   accessor: "increasingMoreThan500Count",
      //   Header: "%INC > 500",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
      // {
      //   accessor: "increasingBetween100And500Count",
      //   Header: "100 < %INC > 500",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
      // {
      //   accessor: "increasingBetween50And100Count",
      //   Header: "50 < %INC > 100",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
      // {
      //   accessor: "decreasingBetween50And100Count",
      //   Header: "-50 < %DEC > -100",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
      // {
      //   accessor: "decreasingBetween100And500Count",
      //   Header: "-100 < %DEC > -500",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
      // {
      //   accessor: "decreasingLessThan500Count",
      //   Header: "%DEC > -500",
      //   align: "left",
      //   maxWidth: 145,
      //   minWidth: 145,
      //   Filter: "",
      // },
    ],
  };
}

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const convertTimeFrameToString = (input) => {
    switch (input) {
      case 1:
        return "Daily";
      case 2:
        return "Weekly";
      case 3:
        return "Monthly";
      case 4:
        return "Quarterly";
      case 5:
        return "Yearly";
      default:
        return 1;
    }
  };

  return {
    columns3: [
      { Header: "PEI Ticker", accessor: "PEITicker", align: "left", maxWidth: 85, minWidth: 85 },
      {
        Header: "Market Name",
        accessor: "MarketName",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
      },
      { Header: "Sector", accessor: "Sector", align: "left" },
      { Header: "Region", accessor: "Region", align: "left" },
      {
        Header: "Market Overview Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.MarketOverviewDate),
        align: "left",
      },
      {
        Header: "Market Last Update Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.MarketLastUpdateDate),
        align: "left",
      },
      {
        Header: "Time Frame",
        accessor: (d) => convertTimeFrameToString(d.TimeFrame),
        align: "left",
      },
    ],

    rows3: [
      {
        marketName: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            AmerAgnico Eagle Mines Limited
          </MDTypography>
        ),
        Name: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            AmerAgnico Eagle Mines Limited
          </MDTypography>
        ),
        snapshots: <MDTypography variant="caption">20</MDTypography>,
        activeSubscriptions: <MDTypography variant="caption">10</MDTypography>,
        canceledSubscriptionsLast30Days: <MDTypography variant="caption">55</MDTypography>,
        subscriptionsAddedLast30Days: <MDTypography variant="caption">44</MDTypography>,
        failedMarketsLast30Days: <MDTypography variant="caption">33</MDTypography>,
        earliestMarketSnapshots: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        earliestMarketSubscription: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        netAddition: <MDTypography variant="caption">22</MDTypography>,
        PEITicker: <MDTypography variant="caption">AEFOOD</MDTypography>,
        sector: <MDTypography variant="caption">Foreign Exchange</MDTypography>,
        ReasonForFailure: (
          <MDTypography variant="caption">
            Foreign Exchange Lorem ipsum lorem ipsum loremmmm ipsum
          </MDTypography>
        ),
        region: <MDTypography variant="caption">AE Indices</MDTypography>,
        reportRunDate: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        lastUpdate: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        failedMarkets: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {0}
          </MDTypography>
        ),
        author: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
        function: <Job title="Manager" description="Organization" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Warning!" color="warning" variant="gradient" size="sm" />
          </MDBox>
        ),
        expRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        lastRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        earliestTrade: (
          <div
            style={{
              backgroundColor: "green",
              minWidth: "15px",
              width: "15px",
              minHeight: "15px",
              borderRadius: "30px",
              padding: "5px",
            }}
          />
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        marketName: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            EuAgnico Eagle Mines Limited
          </MDTypography>
        ),
        Name: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            EuAgnico Eagle Mines Limited
          </MDTypography>
        ),
        snapshots: <MDTypography variant="caption">20</MDTypography>,
        activeSubscriptions: <MDTypography variant="caption">10</MDTypography>,
        canceledSubscriptionsLast30Days: <MDTypography variant="caption">55</MDTypography>,
        subscriptionsAddedLast30Days: <MDTypography variant="caption">44</MDTypography>,
        failedMarketsLast30Days: <MDTypography variant="caption">33</MDTypography>,
        earliestMarketSnapshots: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        earliestMarketSubscription: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        netAddition: <MDTypography variant="caption">22</MDTypography>,
        PEITicker: <MDTypography variant="caption">AEFOOD</MDTypography>,
        sector: <MDTypography variant="caption">Foreign Exchange</MDTypography>,
        ReasonForFailure: (
          <MDTypography variant="caption">
            Foreign Exchange Lorem ipsum lorem ipsum loremmmm ipsum
          </MDTypography>
        ),
        region: <MDTypography variant="caption">AE Indices</MDTypography>,
        reportRunDate: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        lastUpdate: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/2022 08:32 AM
          </MDTypography>
        ),
        failedMarkets: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {0}
          </MDTypography>
        ),
        author: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Warning!" color="warning" variant="gradient" size="sm" />
          </MDBox>
        ),
        expRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            24/12/08
          </MDTypography>
        ),
        lastRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        earliestTrade: (
          <div
            style={{
              backgroundColor: "green",
              minWidth: "15px",
              width: "15px",
              minHeight: "15px",
              borderRadius: "30px",
              padding: "5px",
            }}
          />
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
    ],
  };
}

// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import httpPost from "services/httpPost";

import io from "socket.io-client";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import Checkbox from "components/FormsUI/Checkbox";

// NewUser page components
import FormField from "components/FormField";
import MDInput from "components/MDInput";

import DataTableComments from "components/Tables/DataTableComments";
import moment from "moment";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

function dateDiffInYearsAndMonths(date1, date2) {
  const monthDiff = Math.abs(date2.diff(date1, "months"));
  const years = (monthDiff / 12).toFixed(2);
  // const months = monthDiff;
  return years;
}

const socket = io.connect(
  process.env.REACT_APP_baseURL.substring(0, process.env.REACT_APP_baseURL.length - 4)
  // 'http://localhost:3006/api' --> 'http://localhost:3006' without ".../api"
);

function MarketTradingInformation({ formData }) {
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const isAdmin = userData?.role === "admin" ?? false;
  const { formField, values, errors, touched, setFieldValue, setIsOnHold } = formData;
  const {
    TradingVolume,
    /* TradingStartDate */
    Source,
    MarketHistoryYears,
    MarketCap,
  } = formField;
  const {
    TradingVolume: TradingVolumeV,
    TradingStartDate: TradingStartDateV,
    MarketHistoryYears: MarketHistoryYearsV,
    MarketCap: MarketCapV,
    Source: SourceV,
    CommentsData: CommentsDataV,
    OnHold: OnHoldV,
  } = values;

  const [commentsData, setCommentsData] = useState(CommentsDataV || []);

  const addComment = (comment) => {
    if (comment && comment.length > 0) {
      setCommentsData([
        {
          CommentText: comment,
          CommentUser: userData.name,
          CommentDate: new Date(),
        },
        ...commentsData,
      ]);
    }
  };

  useEffect(() => {
    socket.on("TRADINGVOLUME_UPDATED", (msg) => {
      // const { peiticker, exchangeticker } = msg;
      // setMicroCapLabel(String(peiticker));
      // setMicroCap(exchangeticker);
      const { exchangeticker, peiticker } = msg;
      if (peiticker === values.SourceTicker) {
        setFieldValue("TradingVolume", exchangeticker, false);
      }
    });
    return () => {
      socket.off("TRADINGVOLUME_UPDATED");
    };
  }, []);

  const postTradingVolume = async (obj) => {
    const result = await httpPost({
      path: `/tradingvolume`,
      method: "POST",
      raw: JSON.stringify(obj),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleCalculateTradingVolume = async (val) => {
    if (val && val.SourceTicker !== "" && val.SourceTicker !== undefined) {
      const newTradingVolume = {
        exchangeticker: 0,
        peiticker: val.SourceTicker,
        status: "requested",
      };
      // eslint-disable-next-line no-unused-vars
      const result = await postTradingVolume(newTradingVolume);
    }
  };

  useEffect(() => {
    setFieldValue("CommentsData", commentsData, false);
  }, [commentsData]);

  useEffect(() => {
    setIsOnHold(OnHoldV);
  }, [OnHoldV]);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Market Trading Information
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormField
              type={TradingVolume.type}
              label={TradingVolume.label}
              name={TradingVolume.name}
              value={Math.round(TradingVolumeV) || 0}
              placeholder={TradingVolume.placeholder}
              error={errors.TradingVolume && touched.TradingVolume}
              success={TradingVolumeV && TradingVolumeV.length > 0 && !errors.TradingVolume}
              disabled={userData && userData.role === "admin"}
            />
          </Grid>
          <Grid item xs={2}>
            <MDButton
              variant="gradient"
              size="small"
              disabled={userData && userData?.role === "admin"}
              color={userData && userData?.role === "admin" ? "secondary" : "success"}
              onClick={() => handleCalculateTradingVolume(values)}
            >
              calculate
            </MDButton>
          </Grid>
          <Grid item xs={6}>
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="caption" fontWeight="regular">
                Trading Start Date
              </MDTypography>
              <MDBox ml={1}>
                <MDDatePicker
                  input={{
                    placeholder: "Trading Start Date",
                    disabled: !!(userData && userData.role === "admin"),
                  }}
                  value={TradingStartDateV || ""}
                  onChange={(e, value) => {
                    setFieldValue("TradingStartDate", value, false);
                    const currentDate = moment();
                    const dateString = value;
                    const inputDate = moment(dateString);
                    const years = dateDiffInYearsAndMonths(currentDate, inputDate);
                    setFieldValue("MarketHistoryYears", years, false);
                    // setFieldValue("MarketHistoryMonths", months, false);
                  }}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormField
              type={MarketHistoryYears.type}
              label={MarketHistoryYears.label}
              name={MarketHistoryYears.name}
              value={MarketHistoryYearsV || ""}
              placeholder={MarketHistoryYears.placeholder}
              error={errors.MarketHistoryYears && touched.MarketHistoryYears}
              success={
                MarketHistoryYearsV && MarketHistoryYearsV.length > 0 && !errors.MarketHistoryYears
              }
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="source"
              name={Source.name}
              options={["Barchart", "Binance", "Coinbase", "CSI", "Internal", "REU", "SPX"]}
              value={SourceV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              disabled={userData && userData.role === "admin"}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("Source", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Source" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MDBox display="flex" alignItems="center">
              <MDBox>
                <FormField
                  type={MarketCap.type}
                  label={MarketCap.label}
                  name={MarketCap.name}
                  value={MarketCapV || 0}
                  placeholder={MarketCap.placeholder}
                  error={errors.MarketCap && touched.MarketCap}
                  success={MarketCapV && MarketCapV.length > 0 && !errors.MarketCap}
                  disabled={userData && userData.role === "admin"}
                />
              </MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                USD Billion
              </MDTypography>
            </MDBox>
          </Grid>
          {isAdmin && (
            <Grid item xs={12} sm={6}>
              <Checkbox name="OnHold" label="OnHold" checked={OnHoldV} disabled={!isAdmin} />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <DataTableComments
                table={{
                  columns: [
                    {
                      Header: "CommentDate",
                      accessor: "CommentDate",
                      width: "40%",
                      align: "left",
                      Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.CommentDate),
                    },
                    {
                      Header: "CommentUser",
                      accessor: "CommentUser",
                      align: "left",
                    },
                    {
                      Header: "CommentText",
                      accessor: "CommentText",
                      width: "65%",
                      align: "left",
                    },
                  ],
                  rows: CommentsDataV,
                }}
                isSorted
                showTotalEntries={false}
                entriesPerPage={false}
                noEndBorder={false}
                canAddComment
                addComment={addComment}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MarketTradingInformation
MarketTradingInformation.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MarketTradingInformation;

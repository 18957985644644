// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// react router
import { useParams } from "react-router-dom";

// @mui material components
import { Grid, Card } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCardHoliday from "examples/Cards/StatisticsCards/ComplexStatisticsCardHoliday";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// MarketSubscriptionByMarket components
import DataTableMarketSubscriptionByMarket from "components/Tables/DataTableMarketSubscriptionByMarket";

// Data
import marketDetailTableData from "layouts/tables/data/marketDetailTableData";
import { formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";

function Market() {
  const { marketId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [marketUpdateInfo, setMarketUpdateInfo] = useState([]);
  const [marketUpdateInfoSP, setMarketUpdateInfoSP] = useState({});
  const [marketUpdateInfoSPBusiness, setMarketUpdateInfoSPBusiness] = useState({});
  const [market, setMarket] = useState({});
  const [dataLength, setDataLength] = useState([]);
  const [preDataImportStatus, setPreDataImportStatus] = useState([]);
  const [timeframe, setTimeframe] = useState("1");

  const [isLoading, setisLoading] = useState(true);

  const handleChangeTimeframe = (event, newTimeframe) => {
    setTimeframe(newTimeframe);
  };

  const getMarketUpdateInfo = async () => {
    const result = await http({
      path: `/marketupdateinfo/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketUpdateInfoSP = async () => {
    const result = await http({
      path: `/markets/marketupdateinfo/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketUpdateInfoSPBusiness = async () => {
    const result = await http({
      path: `/markets/marketupdateinfobusiness/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDataLength = async () => {
    const result = await http({
      path: `/updatelogs/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getPreDataImportStatus = async () => {
    const result = await http({
      path: `/modelupdatelogs/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const [
            loadedMarketUpdateInfo,
            loadedMarketUpdateInfoSP,
            loadedMarketUpdateInfoSPBusiness,
            loadedDataLength,
            loadedPreDataImportStatus,
          ] = await Promise.all([
            getMarketUpdateInfo(),
            getMarketUpdateInfoSP(),
            getMarketUpdateInfoSPBusiness(),
            getDataLength(),
            getPreDataImportStatus(),
          ]);

          setMarketUpdateInfo([loadedMarketUpdateInfo]);
          setMarketUpdateInfoSP(loadedMarketUpdateInfoSP);
          setMarketUpdateInfoSPBusiness(loadedMarketUpdateInfoSPBusiness);
          setMarket(loadedMarketUpdateInfo["1"]);
          setDataLength(loadedDataLength);
          setPreDataImportStatus(loadedPreDataImportStatus);

          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [marketId]);

  const { columns1, columns4, columns5, columns6 } = marketDetailTableData();

  const marketPriceDataObj = marketUpdateInfoSP?.marketPriceData?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const gmwObj = marketUpdateInfoSP?.gmw;
  const reversalObj = marketUpdateInfoSP?.reversal?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const arrayObj = marketUpdateInfoSP?.dataArray?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const topTableObj = marketUpdateInfoSP?.topTable?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );

  const marketPriceDataBusinessObj = marketUpdateInfoSPBusiness?.marketPriceData?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const gmwBusinessObj = marketUpdateInfoSPBusiness?.gmw;
  const reversalBusinessObj = marketUpdateInfoSPBusiness?.reversal?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const arrayBusinessObj = marketUpdateInfoSPBusiness?.dataArray?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );
  const topTableBusinessObj = marketUpdateInfoSPBusiness?.topTable?.find(
    (item) => item.TimeFrame === Number(timeframe)
  );

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              {market && (
                <ComplexStatisticsCardHoliday
                  color="warning"
                  icon="api"
                  title=""
                  count={market.PEITicker}
                  details={market.Name}
                  moreDetails={
                    // eslint-disable-next-line no-underscore-dangle
                    market._updateLogInfo && market._updateLogInfo.length > 0
                      ? // eslint-disable-next-line no-underscore-dangle
                        `${formatDateInNewYorkMMDDYYYY(market._updateLogInfo[0].Errors)}`
                      : "No info available"
                  }
                  percentage={{
                    color: "success",
                    // amount: "+0%",
                    label: market.Name,
                  }}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h5">FILTERS</MDTypography>
                  <MDBox mt={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MDBox mb={2}>
                          <MDBox pt={0} px={1}>
                            <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                              Select timeframe
                            </MDTypography>
                          </MDBox>
                          <MDBox pt={1} px={2}>
                            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                              <ToggleButtonGroup
                                size="small"
                                color="info"
                                value={timeframe}
                                exclusive
                                onChange={handleChangeTimeframe}
                                aria-label="text alignment"
                              >
                                <ToggleButton value="1" aria-label="centered">
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    Daily
                                  </MDTypography>
                                </ToggleButton>
                                <ToggleButton value="2" aria-label="centered">
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    Weekly
                                  </MDTypography>
                                </ToggleButton>
                                {/* <ToggleButton value="justify" aria-label="justified" disabled> */}
                                <ToggleButton value="3" aria-label="centered">
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    Monthly
                                  </MDTypography>
                                </ToggleButton>
                                <ToggleButton value="4" aria-label="centered">
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    Quarterly
                                  </MDTypography>
                                </ToggleButton>
                                <ToggleButton value="5" aria-label="centered">
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    Yearly
                                  </MDTypography>
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={2} pb={2}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Pre Data Import Status
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <DataTableMarketSubscriptionByMarket
                  table={{
                    columns: columns1,
                    rows: preDataImportStatus,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Market Update Info
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                {marketUpdateInfo && (
                  <DataTableMarketSubscriptionByMarket
                    table={{
                      columns: columns5,
                      rows: [
                        {
                          MarketOverviewDate: marketPriceDataObj ? marketPriceDataObj.Date : null,
                          GMWAnalysisDate: gmwObj ? gmwObj.AnalysisDate : null,
                          ReversalsAnalysisDate: reversalObj ? reversalObj.AnalysisDate : null,
                          ArraysAnalysisDate: arrayObj ? arrayObj.AnalysisDate : null,
                          MarketLastUpdateDate: marketPriceDataObj
                            ? marketPriceDataObj.LastUpdateDate
                            : null,
                          TopTable: topTableObj ? topTableObj.AnalysisDate : null,
                        },
                      ],
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Market Update Info - Business
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                {marketUpdateInfo && (
                  <DataTableMarketSubscriptionByMarket
                    table={{
                      columns: columns6,
                      rows: [
                        {
                          MarketOverviewDate: marketPriceDataBusinessObj
                            ? marketPriceDataBusinessObj.Date
                            : null,
                          GMWAnalysisDate: gmwBusinessObj ? gmwBusinessObj.AnalysisDate : null,
                          ReversalsAnalysisDate: reversalBusinessObj
                            ? reversalBusinessObj.AnalysisDate
                            : null,
                          ArraysAnalysisDate: arrayBusinessObj
                            ? arrayBusinessObj.AnalysisDate
                            : null,
                          MarketLastUpdateDate: marketPriceDataBusinessObj
                            ? marketPriceDataBusinessObj.LastUpdateDate
                            : null,
                          TopTable: topTableBusinessObj ? topTableBusinessObj.AnalysisDate : null,
                        },
                      ],
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={1}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data Length
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <DataTableMarketSubscriptionByMarket
                  table={{
                    columns: columns4,
                    rows: dataLength,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Market;

// react
import { useEffect, useState } from "react";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";

import http from "services/http";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import DataTableTags from "examples/Tables/DataTableTags";

import MDLoader from "components/MDLoader";

import columns from "layouts/tables/data/marketTagsTableDataII";
import ViewTagsDetailII from "layouts/viewtagsdetailII";

function ViewAllTagsII() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();
  const [tagid, setTagid] = useState("");

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const handleCreateTag = async (e) => {
    e.preventDefault();
    navigate("/manage-tags", { state: { action: "New Tag Name" } });
  };

  const getMarketTags = async () => {
    const result = await http({
      path: `/markettag`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleSyncTags = async () => {
    // eslint-disable-next-line no-unused-vars
    const result = await http({
      path: `/markettag/synchronize`,
      accessToken: userData.token,
    });
  };

  const handleUpdateTagIds = async () => {
    // eslint-disable-next-line no-unused-vars
    const result = await http({
      path: `/markettag/updateTagIds`,
      accessToken: userData.token,
    });
  };

  const [marketTags] = useQueries({
    queries: [
      {
        queryKey: ["marketTags"],
        queryFn: getMarketTags,
      },
    ],
  });

  if (marketTags.isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <MDBox display="flex" justifyContent="left" alignItems="flex-start" mb={6}>
              <MDButton variant="gradient" color="info" onClick={handleCreateTag}>
                create tag
              </MDButton>
              {userData && userData.role === "admin" && (
                <MDBox ml={4}>
                  <MDButton variant="gradient" color="warning" onClick={handleSyncTags} pl={3}>
                    synchronize tags
                  </MDButton>
                </MDBox>
              )}
              {userData && userData.role === "admin" && (
                <MDBox ml={4}>
                  <MDButton variant="gradient" color="warning" onClick={handleUpdateTagIds} pl={3}>
                    update tagIds
                  </MDButton>
                </MDBox>
              )}
            </MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white">
                      Markets Tags
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    {marketTags && marketTags.data.length > 0 ? (
                      <DataTableTags
                        table={{
                          columns,
                          rows: marketTags.data,
                        }}
                        isSorted
                        canSearch
                        entriesPerPage
                        showTotalEntries
                        setTagid={setTagid}
                        // eslint-disable-next-line no-underscore-dangle
                        tagid={tagid || marketTags.data[0]._id}
                      />
                    ) : (
                      <MDTypography variant="h6" color="text" m={3}>
                        No data available
                      </MDTypography>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={7}>
            {marketTags && marketTags.data.length > 0 ? (
              <ViewTagsDetailII
                // eslint-disable-next-line no-underscore-dangle
                tagid={tagid || marketTags.data[0]._id}
                marketTags={marketTags}
                setTagid={setTagid}
              />
            ) : null}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewAllTagsII;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import MDTypography from "components/MDTypography";
import { formatDateHM } from "utils/dateUtil";

export default function dataGmwCommentsDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="medium"
          >
            {row.values.Name}
          </MDTypography>
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "Category", accessor: "Category", align: "center" },
      { Header: "run region", accessor: "RunRegion", align: "center" },
      { Header: "source", accessor: "Source", align: "center" },
      {
        Header: "ResearchItemId",
        accessor: "latestTopTable.ResearchItemId",
        align: "center",
      },
      {
        Header: "latest Analysis",
        accessor: (d) => formatDateHM(d.latestAnalysis.MyAnalysisDate),
        align: "center",
      },
      {
        Header: "latest TopTable",
        accessor: (d) => formatDateHM(d.latestTopTable.MyAnalysisDate),
        align: "center",
      },
    ],
  };
}

import React from "react";

// eslint-disable-next-line react/prop-types
function Highlight({ text = "", highlight = "" }) {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }

  // Create a regex to match the whole highlight text, case-insensitive
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);

  return (
    <span>
      {parts
        .filter((part) => part)
        // eslint-disable-next-line react/no-array-index-key
        .map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : part))}
    </span>
  );
}

export default Highlight;

// function Highlight({ text = "", highlight = "" }) {
//   if (!highlight.trim()) {
//     return <span>{text}</span>;
//   }
//   const regex = new RegExp(`(${highlight})`, "gi");
//   const parts = text.split(regex);
//   return (
//     <span>
//       {parts
//         .filter((part) => part)
//         // eslint-disable-next-line react/no-array-index-key
//         .map((part, i) => (regex.test(part) ? <mark key={i}>{part}</mark> : part))}
//     </span>
//   );
// }

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import HorizontalStepper from "layouts/dashboard/components/HorizontalStepper";
import HorizontalStepperRed from "layouts/dashboard/components/HorizontalStepperRed";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns1: [
      {
        Header: "Run Region",
        accessor: "RunRegion",
        align: "left",
      },
      {
        Header: "Progress Bar",
        accessor: "ProgressBar",
        align: "left",
        Cell: ({ row }) =>
          row.original.stepper < 5 ? (
            <HorizontalStepper
              activeStep={row.original.stepper == null ? null : row.original.stepper + 1}
            />
          ) : (
            <HorizontalStepperRed activeStep={row.original.stepper + 1 - 5} />
          ),
        // Cell: () => <HorizontalStepper activeStep={3} />,
      },
      {
        Header: "Expected Finish",
        accessor: "ExpectedFinish",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        // Cell: ({ row }) => moment(row.original.ExpectedFinish).format("MM/DD/YYYY hh:mm A"),
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.stepperInsertedDate),
      },
      {
        Header: "Failed Markets",
        accessor: "count",
        align: "center",
        Cell: ({ row }) => (
          <MDLink
            to={`/processes/${row.original.RunRegion}`}
            text={row.original.count.toString()}
          />
        ),
      },
    ],
  };
}

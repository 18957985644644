/**
=========================================================
* Material Dashboard React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// VerticalBarChart configurations
import configs from "examples/Charts/BarCharts/VerticalBarChartSearch/configs";

// Material Dashboard React base styles
import colors from "assets/theme/base/colors";

// import { DownloadExcel } from "react-excel-export";
import "./index.css";
import { formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";

function VerticalBarChartSearch({
  icon,
  title,
  tagName,
  rangeFrom,

  numberOfSearches,
  uniqueUsersWhoSearched,

  description,
  height,
  chart,
  // fullData,
}) {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        fill: false,
        maxBarThickness: 35,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      <MDBox
        mx={2}
        mt={-3}
        py={1.5}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {title}
        </MDTypography>
      </MDBox>
      {title || description ? (
        <MDBox
          display="flex"
          px={description ? 1 : 0}
          pt={1}
          style={{ justifyContent: "space-between" }}
        >
          <MDBox mt={1} ml={4} mb={2}>
            <MDTypography variant="subtitle2" color="text">
              Market Tag:
              <MDTypography variant="div" color="info" fontWeight="regular">
                {" "}
                {tagName}
              </MDTypography>
            </MDTypography>
            <MDTypography variant="subtitle2" color="text">
              Date Range:
              <MDTypography variant="div" color="text" fontWeight="regular">
                {" "}
                {formatDateInNewYorkMMDDYYYY(rangeFrom[0].startDate)} -{" "}
                {formatDateInNewYorkMMDDYYYY(rangeFrom[0].endDate)}
              </MDTypography>
            </MDTypography>
            <MDTypography variant="subtitle2" color="text">
              Number of Searches:
              <MDTypography variant="div" color="text" fontWeight="regular">
                {" "}
                {numberOfSearches}
              </MDTypography>
            </MDTypography>
            <MDTypography variant="subtitle2" color="text">
              Unique Users Who Searched:
              <MDTypography variant="div" color="text" fontWeight="regular">
                {" "}
                {uniqueUsersWhoSearched}
              </MDTypography>
            </MDTypography>
          </MDBox>
          {/* <MDBox mt={1} className="button-container">
            <DownloadExcel
              fileName="subscriptions per month"
              data={fullData}
              buttonLabel="EXPORT TO EXCEL"
              className="button-download"
            />
          </MDBox> */}
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Bar data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of VerticalBarChartSearch
VerticalBarChartSearch.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  tagName: "",
  rangeFrom: [],
  numberOfSearches: 0,
  uniqueUsersWhoSearched: 0,
  description: "",
  height: "19.125rem",
  // fullData: [],
};

// Typechecking props for the VerticalBarChartSearch
VerticalBarChartSearch.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  tagName: PropTypes.string,
  rangeFrom: PropTypes.arrayOf(PropTypes.object),
  numberOfSearches: PropTypes.number,
  uniqueUsersWhoSearched: PropTypes.number,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  // fullData: PropTypes.arrayOf(PropTypes.object),
};

export default VerticalBarChartSearch;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import { Grid, Card } from "@mui/material";

import MDLoader from "components/MDLoader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import bullishbearishTableData from "layouts/tables/data/bullishbearishTableData";
import DataTableBullishBearish from "examples/Tables/DataTableBullishBearish";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function BullishBearish() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();
  const [timeframe, setTimeframe] = useState("1");

  const getReversalPoints = async () => {
    const result = await http({
      path: `/reversal/reversalpoints`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [reversalpoints] = useQueries({
    queries: [
      {
        queryKey: ["reversalpoints"],
        queryFn: getReversalPoints,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const { columns1 } = bullishbearishTableData();

  if (reversalpoints.isLoading) return <MDLoader />;
  if (reversalpoints.isError) return <div>Error at loading reversalpoints</div>;
  const data = reversalpoints?.data[timeframe][0]?.results || [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  SELECT TIMEFRAME
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={timeframe}
                    exclusive
                    onChange={(e, value) => {
                      setTimeframe(value);
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="1" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Daily
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="2" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Weekly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="3" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Monthly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="4" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Quarterly
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="5" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Yearly
                      </MDTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {reversalpoints?.data && data.length > 0 ? (
        <DataTableBullishBearish
          table={{ columns: columns1, rows: data }}
          isSorted
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
        />
      ) : (
        <MDTypography variant="h6" fontWeight="light">
          No Data Available
        </MDTypography>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default BullishBearish;

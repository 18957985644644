import * as Yup from "yup";
import checkout from "layouts/newmarketrequest/schemas/form";

const {
  formField: { marketName, exchangeSymbol, exchange, externalEmail },
} = checkout;

const validations = [
  Yup.object().shape({
    [marketName.name]: Yup.string().required(marketName.errorMsg),
    [exchangeSymbol.name]: Yup.string()
      .required(exchangeSymbol.errorMsg)
      .max(10, exchangeSymbol.invalidMsg),
    [exchange.name]: Yup.string().required(exchange.errorMsg),
    [externalEmail.name]: Yup.string()
      .required(externalEmail.errorMsg)
      .email(externalEmail.invalidMsg),
  }),
];

export default validations;

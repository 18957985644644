// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import http from "services/http";
import httpPut from "services/httpPut";
import httpPost from "services/httpPost";

import MDLoader from "components/MDLoader";

// react router
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Card, Checkbox } from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// eslint-disable-next-line no-unused-vars

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// data
import selectData from "layouts/editmarket/data/selectData";
import countriesData from "layouts/data/countries";

const { currencies, regions } = countriesData();

// eslint-disable-next-line react/prop-types
function AutocompleteInput({ label, params }) {
  return (
    <MDInput
      {...params}
      label={label}
      InputLabelProps={{ shrink: true }}
      sx={{
        "& .MuiOutlinedInput-root": {
          padding: "5px!important",
        },
      }}
    />
  );
}

function EditMarket() {
  const navigate = useNavigate();
  const { marketId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [market, setMarket] = useState({});
  const [distinctMarketTags, setDistinctMarketTags] = useState([""]);
  const [inputValue, setInputValue] = useState("");
  const [distinctCategory, setDistinctCategory] = useState([]);
  const [distinctMarketType, setDistinctMarketType] = useState([]);
  const [distinctPrimarySubCategory, setDistinctPrimarySubCategory] = useState([]);
  const [distinctSecondarySubCategory, setDistinctSecondarySubCategory] = useState([]);
  const [countries, setCountries] = useState([]);
  const [errorCategory, setErrorCategory] = useState("");
  const [errorMarketType, setErrorMarketType] = useState("");

  const handleBlur = () => {
    if (inputValue === "") return;
    const newValue = [...market.MarketTags, inputValue];
    setMarket(() => ({
      ...market,
      MarketTags: newValue,
    }));
    setInputValue("");
  };

  const [isLoading, setisLoading] = useState(true);

  const getMarket = async () => {
    const result = await http({
      path: `/markets/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getCountries = async () => {
    const result = await http({
      path: `/country`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinct = async () => {
    const result = await http({
      path: `/markets/distinctmarketinfo`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const postDistinctMarketTags = async (loadedMarket) => {
    const result = await httpPost({
      path: `/markets/postdistinctmarkettags`,
      method: "POST",
      raw: JSON.stringify({
        sector: loadedMarket.market.Sector,
        exchange: loadedMarket.market.Exchange,
        countryCode: loadedMarket.market.CountryCode,
      }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedMarket = await getMarket(marketId);
          const loadedDistinctMarketTags = await postDistinctMarketTags(loadedMarket);
          const loadedDistinctmarketinfo = await getDistinct();
          const loadedCountries = await getCountries();
          const {
            distinctCategory: dC,
            distinctMarketType: dMT,
            distinctPrimarySubCategory: dPSC,
            distinctSecondarySubCategory: dSSC,
          } = loadedDistinctmarketinfo;
          setMarket(loadedMarket.market);
          setDistinctMarketTags(loadedDistinctMarketTags);
          setDistinctCategory(dC);
          setDistinctMarketType(dMT);
          setDistinctPrimarySubCategory(dPSC);
          setDistinctSecondarySubCategory(dSSC);
          setCountries(loadedCountries);
          setisLoading(false);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      };
      load();
    }
  }, []);

  const putMarket = async () => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/markets/${market._id}`,
      method: "PUT",
      raw: JSON.stringify(market),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!market.Category || !market.MarketType) {
      if (!market.Category) setErrorCategory("Category is required");
      if (!market.MarketType) setErrorMarketType("MarketType is required");
      return;
    }
    await putMarket();
    navigate("/markets");
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    navigate("/markets");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMarket(() => ({
      ...market,
      [name]: value,
    }));
  };

  const handleInputChange = (property, newValue) => {
    if (
      (property === "Category" || property === "MarketType") &&
      (!newValue || newValue.trim() === "")
    ) {
      if (property === "Category") setErrorCategory("Category is required");
      if (property === "MarketType") setErrorMarketType("MarketType is required");
    } else {
      if (property === "Category") setErrorCategory("");
      if (property === "MarketType") setErrorMarketType("");
    }
    if (property === "CountryName") {
      const country = countries.find((el) => el.DisplayName === newValue);
      setMarket((prevMarket) => ({
        ...prevMarket,
        CountryName: newValue,
        CountryCode: country?.CountryCode || "",
      }));
    }
    if (property === "SecondaryCountryName") {
      const country = countries.find((el) => el.DisplayName === newValue);
      setMarket((prevMarket) => ({
        ...prevMarket,
        SecondaryCountryName: newValue,
        SecondaryCountryCode: country?.CountryCode || "",
      }));
    }
    setMarket((prevMarket) => ({
      ...prevMarket,
      [property]: newValue,
    }));
  };

  if (isLoading) return <MDLoader />;

  const countryName = countries.find((el) => el.CountryCode === market.CountryCode)?.DisplayName;
  const secondaryCountryName = countries.find(
    (el) => el.CountryCode === market.SecondaryCountryCode
  )?.DisplayName;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edit Market
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <MDBox
                        mt={0.75}
                        sx={{
                          lineHeight: 0,
                          border: 1,
                          borderRadius: 2,
                          color: "rgb(230,230,230)",
                        }}
                      >
                        <Checkbox
                          name="GlobalMarketWatch"
                          checked={market.GlobalMarketWatch}
                          onChange={(e) => {
                            const { name, value, type, checked } = e.target;

                            setMarket(() => ({
                              ...market,
                              [name]: type === "checkbox" ? checked : value,
                            }));
                          }}
                        />
                        <MDTypography
                          component="span"
                          variant="h6"
                          color="text"
                          fontWeight="regular"
                          opacity={1.8}
                        >
                          Active
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={8} />
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Market Name"
                        name="Name"
                        id="Name"
                        value={market.Name}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="STC Type"
                        name="StcType"
                        id="StcType"
                        value={market.StcType || ""}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.GICSSector}
                        onChange={(e, newValue) => handleInputChange("GICSSector", newValue)}
                        options={selectData.GICSSector}
                        renderInput={(params) => AutocompleteInput({ params, label: "GICSSector" })}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Sector"
                        name="Sector"
                        id="Sector"
                        value={market.Sector}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="STC Folder Path"
                        name="STCFolderPath"
                        id="STCFolderPath"
                        value={market.STCFolderPath}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.GICSIndustryGrp}
                        // onChange={handleChangeGICSIndustryGrp}
                        onChange={(e, newValue) => handleInputChange("GICSIndustryGrp", newValue)}
                        options={selectData.GICSIndustryGrp}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "GICSIndustryGrp" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="PEI Ticker"
                        name="PEITicker"
                        id="PEITicker"
                        value={market.PEITicker}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="DBS Folder Path"
                        name="DBSFolderPath"
                        id="DBSFolderPath"
                        value={market.DBSFolderPath}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.GICSIndustry}
                        onChange={(e, newValue) => handleInputChange("GICSIndustry", newValue)}
                        options={selectData.GICSIndustry}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "GICSIndustry" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Source"
                        name="Source"
                        id="Source"
                        value={market.Source}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="CDF Folder Path"
                        name="CDFFolderPath"
                        id="CDFFolderPath"
                        value={market.CDFFolderPath}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Data Ticker"
                        name="DataTicker"
                        id="DataTicker"
                        value={market.DataTicker}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Reu Ticker"
                        name="ReuTicker"
                        id="ReuTicker"
                        value={market.ReuTicker}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Data Path"
                        name="DataPath"
                        id="DataPath"
                        value={market.DataPath}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Is Index"
                        name="IsIndex"
                        id="IsIndex"
                        value={market.IsIndex}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Reu Start Date"
                        name="ReuStartDate"
                        id="ReuStartDate"
                        value={market.ReuStartDate}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Type"
                        name="Type"
                        id="Type"
                        value={market.Type}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Evenly Weight"
                        name="EvenlyWeight"
                        id="EvenlyWeight"
                        value={market.EvenlyWeight}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Reu Code"
                        name="ReuCode"
                        id="ReuCode"
                        value={market.ReuCode}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Conversion"
                        name="Conversion"
                        id="Conversion"
                        value={market.Conversion}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Start Generating Anaylsis"
                        name="StartGeneratingAnaylsis"
                        id="StartGeneratingAnaylsis"
                        value={market.StartGeneratingAnaylsis}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Reu Refresh"
                        name="ReuRefresh"
                        id="ReuRefresh"
                        value={market.ReuRefresh}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Data Import Multiplier"
                        name="DataImportMultiplier"
                        id="DataImportMultiplier"
                        value={market.DataImportMultiplier}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Delivery Months"
                        name="DeliveryMonths"
                        id="DeliveryMonths"
                        value={market.DeliveryMonths}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Reu Relative"
                        name="ReuRelative"
                        id="ReuRelative"
                        value={market.ReuRelative}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={1}>
                      <MDInput
                        type="text"
                        label="CountryCode"
                        name="CountryCode"
                        id="CountryCode"
                        value={market.CountryCode}
                        disabled
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        value={market.CountryName || countryName}
                        onChange={(e, newValue) => handleInputChange("CountryName", newValue)}
                        options={["", ...countries.map((el) => el.DisplayName)]}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "CountryName" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={1}>
                      <MDInput
                        type="text"
                        label="SecondaryCountryCode"
                        name="SecondaryCountryCode"
                        id="SecondaryCountryCode"
                        value={market.SecondaryCountryCode}
                        disabled
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        value={market.SecondaryCountryName || secondaryCountryName}
                        onChange={(e, newValue) =>
                          handleInputChange("SecondaryCountryName", newValue)
                        }
                        options={["", ...countries.map((el) => el.DisplayName)]}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "SecondaryCountryName" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="Region"
                        name="Region"
                        // options={countries.map((s) => s.name)}
                        options={regions.map((r) => r.text)}
                        value={String(market.Region)}
                        // getOptionLabel={(option) => option}
                        getOptionLabel={(option) =>
                          regions.filter((e) => e.text === option)[0]?.text || ""
                        }
                        onChange={(e, value) => {
                          setMarket(() => ({
                            ...market,
                            Region: value,
                          }));
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Region"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "5px!important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="SecondaryRegion"
                        name="SecondaryRegion"
                        // options={countries.map((s) => s.name)}
                        options={regions.map((r) => r.text)}
                        value={String(market.SecondaryRegion)}
                        // getOptionLabel={(option) => option}
                        getOptionLabel={(option) =>
                          regions.filter((e) => e.text === option)[0]?.text || ""
                        }
                        onChange={(e, value) => {
                          setMarket(() => ({
                            ...market,
                            SecondaryRegion: value,
                          }));
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="SecondaryRegion"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "5px!important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Exchange Symbol"
                        name="ExchangeSymbol"
                        id="ExchangeSymbol"
                        value={market.ExchangeSymbol}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.Category}
                        onChange={(e, newValue) => handleInputChange("Category", newValue)}
                        options={distinctCategory}
                        renderInput={(params) => AutocompleteInput({ params, label: "Category" })}
                      />

                      {errorCategory !== "" && (
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            {errorCategory}
                          </MDTypography>
                        </MDBox>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.PrimarySubCategory}
                        onChange={(e, newValue) =>
                          handleInputChange("PrimarySubCategory", newValue)
                        }
                        options={distinctPrimarySubCategory}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "Primary Sub Category" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.SecondarySubCategory}
                        onChange={(e, newValue) =>
                          handleInputChange("SecondarySubCategory", newValue)
                        }
                        options={distinctSecondarySubCategory}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "Secondary Sub Category" })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={market.MarketType}
                        onChange={(e, newValue) => handleInputChange("MarketType", newValue)}
                        options={distinctMarketType}
                        renderInput={(params) =>
                          AutocompleteInput({ params, label: "Market Type" })
                        }
                      />

                      {errorMarketType !== "" && (
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            {errorMarketType}
                          </MDTypography>
                        </MDBox>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Capital Flow Map"
                        name="CapitalFlowMap"
                        id="CapitalFlowMap"
                        value={market.CapitalFlowMap}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Exchange"
                        name="Exchange"
                        id="Exchange"
                        value={market.Exchange}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="Currency"
                        name="Currency"
                        // options={countries.map((s) => s.name)}
                        options={currencies.map((s) => s.cc)}
                        value={String(market.Currency)}
                        // getOptionLabel={(option) => option}
                        getOptionLabel={(option) =>
                          currencies.filter((e) => e.cc === option)[0]?.name || ""
                        }
                        // style={{ width: 300 }}
                        onChange={(e, value) => {
                          setMarket(() => ({
                            ...market,
                            Currency: parseInt(value, 10) || 0,
                          }));
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Currency"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                padding: "5px!important",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} />
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Run Region"
                        name="RunRegion"
                        id="RunRegion"
                        value={market.RunRegion}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Trading Hours"
                        name="TradingHours"
                        id="TradingHours"
                        value={market.TradingHours}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Divider"
                        name="Divider"
                        id="Divider"
                        value={market.Divider}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="SpxTicker"
                        name="SpxTicker"
                        id="SpxTicker"
                        value={market.SpxTicker || ""}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="DecimalPlaces"
                        name="DecimalPlaces"
                        id="DecimalPlaces"
                        value={market.DecimalPlaces || ""}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="AddFactor"
                        name="AddFactor"
                        id="AddFactor"
                        value={market.AddFactor}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="ForwardFill"
                        name="ForwardFill"
                        id="ForwardFill"
                        value={market.ForwardFill}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Tick Value"
                        name="TickValue"
                        id="TickValue"
                        value={market.TickValue}
                        onChange={handleChange}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Stack spacing={3}>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={distinctMarketTags.map((el) => el.MarketTagName)}
                          defaultValue={[""]}
                          filterSelectedOptions
                          value={market.MarketTags || []}
                          onChange={(event, newValue) => {
                            setMarket(() => ({
                              ...market,
                              MarketTags: newValue,
                            }));
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          onBlur={handleBlur}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.MarketTagName}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <MDInput {...params} label="Market Tags" placeholder="Market Tags" />
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <MDBox mt={1} mb={1}>
                    <Grid container alignItems="stretch" justifyContent="center" space={2}>
                      <Grid item px={2}>
                        <MDButton variant="gradient" color="info" fullWidth onClick={handleCancel}>
                          cancel
                        </MDButton>
                      </Grid>
                      <Grid item px={2}>
                        <MDButton variant="gradient" color="info" fullWidth type="submit">
                          save
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditMarket;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
import MDLink from "components/MDLink";
// import MDTypography from "components/MDTypography";
import { SelectColumnFilterSubscriptionOverview } from "components/TableFilters/selectByFieldStatusId";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

// Images
export default function data() {
  const subscriptionColumns = [
    {
      Header: "Market Name",
      accessor: "Name",
      align: "left",
      sticky: "left",
      filter: "fuzzyText",
      width: 250,
      Cell: ({ row }) => {
        if (!row.original.GlobalMarketWatch === true)
          return (
            <div>
              <span>{row.values.Name}</span>
              <br />
              <MDBadge color="error" size="xs" badgeContent="Inactive" container />
            </div>
          );
        return row.values.Name; // <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />;
      },
    },
    {
      Header: "Exchange Symbol",
      accessor: "ExchangeSymbol",
      align: "left",
      filter: "fuzzyText",
      width: 200,
    },
    {
      Header: "Market Category",
      accessor: "Category",
      align: "left",
      Filter: SelectColumnFilterSubscriptionOverview,
      filter: "equals",
      width: 200,
    },
    {
      Header: "Market Primary SubCategory",
      accessor: "PrimarySubCategory",
      align: "left",

      Filter: SelectColumnFilterSubscriptionOverview,
      filter: "equals",
      width: 200,
    },
    {
      Header: "total Subs",
      accessor: "TotalTotal",
      align: "left",
      width: 140,
    },
    {
      Header: "pre-Existing Subs",
      accessor: "PreExisTotalSub",
      align: "left",
      width: 140,
    },
    {
      Header: "newly Purchased",
      accessor: "totalSubscriptions",
      align: "left",
      width: 140,
    },
    {
      Header: "Cancelled Subs",
      accessor: "totalCancellations",
      align: "left",
      width: 140,
    },
    {
      Header: "growth",
      accessor: (d) => d.totalSubscriptions - d.totalCancellations,
      sortType: "customSort",
      align: "center",
      width: 140,
    },
  ];
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      { Header: "Sector", accessor: "Sector", align: "left", maxWidth: 115, minWidth: 115 },
      { Header: "Region", accessor: "Region", align: "left", maxWidth: 70, minWidth: 70 },
      {
        Header: "DatePurchased",
        accessor: (d) => formatDateInNewYorkNoSec(d.DatePurchased),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Age, days",
        accessor: (d) => {
          const myDate = new Date();
          // return moment(myDate.getTime() - new Date(d.DatePurchased).getTime()).format(
          //   "MM/DD/YYYY hh:mm A"
          // );
          const difference = myDate.getTime() - new Date(d.MarketAdditionDate).getTime();
          const days = difference / (1000 * 3600 * 24);
          return Math.ceil(days);
        },
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
    ],
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "Product type",
        accessor: "ProductType",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "Product level",
        accessor: "ProductLevel",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "Membership level",
        accessor: "MembershipLevel",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      { Header: "Sector", accessor: "Sector", align: "left", maxWidth: 115, minWidth: 115 },
      { Header: "Region", accessor: "Region", align: "left", maxWidth: 70, minWidth: 70 },
      {
        Header: "Total number",
        accessor: "Total number",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "Newly added",
        accessor: "Newly added",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "Newly cancelled",
        accessor: "Newly cancelled",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "Net difference",
        accessor: "Net difference",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
    ],
    columns3: [...subscriptionColumns],
    columns4: [
      {
        Header: " ",
        sticky: "left",
        columns: [
          {
            Header: "Market Name",
            accessor: "Name",
            align: "left",
            sticky: "left",
            filter: "fuzzyText",
            width: 250,
            Cell: ({ row }) => {
              if (!row.original.GlobalMarketWatch === true)
                return (
                  <div>
                    <span>{row.values.Name}</span>
                    <br />
                    <MDBadge color="error" size="xs" badgeContent="Inactive" container />
                  </div>
                );
              return row.values.Name; // <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />;
            },
          },
        ],
      },
      {
        Header: "  ",
        columns: [
          {
            Header: "Exchange Symbol",
            accessor: "ExchangeSymbol",
            align: "left",
            filter: "fuzzyText",
            width: 200,
          },
          {
            Header: "Market Category",
            accessor: "Category",
            align: "left",
            Filter: SelectColumnFilterSubscriptionOverview,
            filter: "equals",
            width: 200,
          },
          {
            Header: "Market Primary SubCategory",
            accessor: "PrimarySubCategory",
            align: "left",
            Filter: SelectColumnFilterSubscriptionOverview,
            filter: "equals",
            width: 200,
          },
          {
            Header: "total Subs",
            accessor: "TotalTotal",
            align: "left",
            width: 140,
          },
          {
            Header: "pre-Existing Subs",
            accessor: "PreExisTotalSub",
            align: "left",
            width: 140,
          },
          {
            Header: "newly Purchased",
            accessor: "totalSubscriptions",
            align: "left",
            width: 140,
          },
          {
            Header: "Cancelled Subs",
            accessor: "totalCancellations",
            align: "left",
            width: 140,
          },
          {
            Header: "growth",
            accessor: (d) => d.totalSubscriptions - d.totalCancellations,
            sortType: "customSort",
            align: "center",
            width: 140,
          },
        ],
      },
      {
        Header: "Compared period data",
        columns: [
          {
            Header: "total Subs",
            accessor: "xTotalTotal",
            align: "left",
            width: 140,
          },
          {
            Header: "pre-Existing Subs",
            accessor: "xPreExisTotalSub",
            align: "left",
            width: 140,
          },
          {
            Header: "newly Purchased",
            accessor: "xtotalSubscriptions",
            align: "left",
            width: 140,
          },
          {
            Header: "Cancelled Subs",
            accessor: "xtotalCancellations",
            align: "left",
            width: 140,
          },
          {
            id: "xgrowth",
            Header: "growth",
            accessor: (d) => d.totalSubscriptions - d.totalCancellations,
            sortType: "customSort",
            align: "center",
            width: 140,
          },
        ],
      },
    ],
  };
}

// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import namedColor from "constants/constants";
import "chartjs-adapter-luxon";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PieChartDashboard from "examples/Charts/PieChartDashboard";

// Dashboard components
import DataTable from "examples/Tables/DataTable";
import ProgressMultiLineChart from "examples/Charts/LineCharts/ProgressMultiLineChart";
import MDLoader from "components/MDLoader";

// Data
import DataTableMarketSubscriptions from "components/Tables/DataTableMarketSubscriptions";
import regionsTableData from "layouts/tables/data/regionsTableData";
import regionsMarketsFailedMarketsTableData from "layouts/tables/data/regionsMarketsFailedMarketsTableData";
import LastUpdate from "./components/LastUpdate";

function Dashboard() {
  const navigate = useNavigate();
  // contextapi const [controller, dispatch] = useMaterialUIController();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [datasets, setDatasets] = useState([]);

  const getTotalOfMarkets = async () => {
    const result = await http({
      path: `/markets/count`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  const getNullTags = async () => {
    const result = await http({
      path: `/markettag/nulltags`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getNullCategories = async () => {
    const result = await http({
      path: `/markets/nullcategories`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getRegions = async () => {
    const result = await http({
      path: `/regions/markets`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getSystemRunTimeInfoSum = async () => {
    const result = await http({
      path: `/systemruntimeinfo/sumbyregion`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getRegionsMarketsFailedMarkets = async () => {
    const result = await http({
      path: `/regions/markets/failedmarkets`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const configureLineChart = async (ds) => {
    const objRegions = {};
    ds.forEach((el) => {
      const datasetByDay = el.data;
      datasetByDay.forEach((elem) => {
        const region = elem.RunRegion[0];
        if (!objRegions[region]) objRegions[region] = 1;
      });
    });

    const systemInfoDatasetsIII = [];
    Object.keys(objRegions).forEach((key, index) => {
      const dataFromRegion = ds.filter((el) => el.latestRunRegion[0] === key);
      // eslint-disable-next-line no-unused-vars
      const dataAboutCertainRegion = dataFromRegion.map((elem) => ({
        x: elem.latestDateTime,
        y: Math.floor(elem.totalTimeElapsed),
      }));

      systemInfoDatasetsIII.push({
        label: key,
        borderColor: namedColor(index * 2)[0],
        backgroundColor: namedColor(index * 2)[1],
        fill: false,
        data: dataAboutCertainRegion,
      });
    });

    const data = {
      labels: [],
      ds: systemInfoDatasetsIII.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      ),
    };
    setDatasets(data.ds);
  };

  const [
    totalOfMarkets,
    regionsMarketsFailedMarkets,
    systemRunTimeInfoSum,
    regions,
    nulltags,
    nullcategories,
  ] = useQueries({
    queries: [
      {
        queryKey: ["totalOfMarkets"],
        queryFn: getTotalOfMarkets,
      },
      {
        queryKey: ["regionsMarketsFailedMarkets"],
        queryFn: getRegionsMarketsFailedMarkets,
        select: (data) =>
          data
            .reduce((acumulador, elemento) => acumulador.concat(elemento.failedMarkets), [])
            .sort((a, b) => new Date(b.PostedOn).getTime() - new Date(a.PostedOn).getTime()),
      },
      {
        queryKey: ["systemRunTimeInfoSum"],
        queryFn: getSystemRunTimeInfoSum,
      },
      {
        queryKey: ["regions"],
        queryFn: getRegions,
      },
      {
        queryKey: ["nulltags"],
        queryFn: getNullTags,
      },
      {
        queryKey: ["nullcategories"],
        queryFn: getNullCategories,
      },
    ],
  });

  useEffect(() => {
    if (systemRunTimeInfoSum && systemRunTimeInfoSum.data !== undefined) {
      configureLineChart(systemRunTimeInfoSum.data);
    }
  }, [systemRunTimeInfoSum.data]);

  useEffect(() => {
    if (userData && userData.role === "marketing") navigate("/market-subscription-summary");
    if (userData === null) {
      navigate("/authentication/sign-in");
    }
  }, []);

  const { columns } = regionsTableData();
  const { columns2 } = regionsMarketsFailedMarketsTableData();

  if (totalOfMarkets.isLoading) return <MDLoader />;
  if (regionsMarketsFailedMarkets.isLoading) return <MDLoader />;
  if (systemRunTimeInfoSum.isLoading) return <MDLoader />;
  if (regions.isLoading) return <MDLoader />;
  if (nulltags.isLoading) return <MDLoader />;
  if (nullcategories.isLoading) return <MDLoader />;

  let T = 0;
  let F = 0;

  regions.data.forEach((el) => {
    T += el.TotalNoOfMarkets;
    F += el.flag ? el.failedMarketsCalculatedMinus : el.failedMarketsCalculated;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Region Status
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: regions.data }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox my={3}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <PieChartDashboard
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Recent Errors Summary"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: ["Updated", "Failed"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["info", "primary"],
                      data: [T, F],
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ProgressMultiLineChart
                  icon="insights"
                  title="System Update Performance"
                  count={480}
                  // progress={60}
                  height="13.375rem"
                  chart={{
                    labels: [],
                    datasets,
                  }}
                />
                <MDBox mb={3} mt={2}>
                  <LastUpdate
                    // totalOfMarkets={totalOfMarkets.data}
                    nulltags={nulltags.data}
                    nullcategories={nullcategories.data}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mb={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-2}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Failed Markets Update
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={4}>
                  <DataTableMarketSubscriptions
                    table={{
                      columns: columns2,
                      rows: regionsMarketsFailedMarkets.data,
                    }}
                    isSorted
                    entriesPerPage={{ defaultValue: 5 }}
                    showTotalEntries
                    noEndBorder
                    canSearch
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

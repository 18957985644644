/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { formatDate } from "utils/dateUtil";

// Material Dashboard 2 React components

export default function data({ setModal, setModalDelete, setModalUpdateRow, setRecordDetail }) {
  return {
    columns1: [
      {
        Header: "Date",
        accessor: (d) => formatDate(d.Date),
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
      },
      {
        Header: "Open",
        accessor: "Open",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "High",
        accessor: "High",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Low",
        accessor: "Low",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Close",
        accessor: "Close",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Action",
        accessor: "Action",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={0}
            mt={0}
          >
            <MDBox ml={0}>
              <MDButton
                size="small"
                variant="gradient"
                color="error"
                onClick={() => {
                  setModal((prev) => !prev);
                  setRecordDetail(row.original);
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        ),
        Filter: "",
        isSorted: false,
      },
    ],
    columns2: [
      {
        Header: "Id",
        accessor: "_id",
        align: "left",
        maxWidth: 40,
        minWidth: 40,
        Filter: "",
      },
      {
        Header: "Date",
        accessor: "Date",
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
        Cell: ({ row }) => formatDate(row.values.Date),
      },
      {
        Header: "Open",
        accessor: "Open",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "High",
        accessor: "High",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Low",
        accessor: "Low",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Close",
        accessor: "Close",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "actions",
        disableSortBy: true,
        accessor: "actions",
        Filter: "",
        isSorted: false,
        Cell: (info) => (
          <MDBox display="flex" alignItems="center">
            <Tooltip
              title="Delete Item"
              another={info}
              onClick={() => {
                setModalDelete((prev) => !prev);
                const {
                  cell: {
                    row: { original },
                  },
                } = info;
                setRecordDetail(original);
              }}
            >
              <IconButton color="error">
                <Icon>delete</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Edit Item"
              onClick={() => {
                setModalUpdateRow((prev) => !prev);
                const {
                  cell: {
                    row: { original },
                  },
                } = info;
                setRecordDetail(original);
              }}
            >
              <IconButton color="warning">
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
          </MDBox>
        ),
      },
    ],
  };
}

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import MDLink from "components/MDLink";
import { formatDateHM } from "utils/dateUtil";

export default function data() {
  return {
    columns1: [
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.PEITicker} />
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 320,
        minWidth: 320,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.original.Name} />
        ),
      },
      {
        Header: "Run Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },

      {
        Header: "Source",
        accessor: "Source",
        align: "center",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "Ticker",
        accessor: "Ticker",
        align: "center",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "DateTime",
        align: "center",
        accessor: (d) => formatDateHM(d.DateTime),
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Message",
        accessor: "Message",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
      },
    ],
  };
}

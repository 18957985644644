import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import io from "socket.io-client";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Dashboard React components
import MDBox from "components/MDBox";

// Material Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ControllerCardSteps from "examples/Cards/ControllerCardSteps";
import UpcomingEvents from "./components/UpcomingEvents";

const socket = io.connect(
  process.env.REACT_APP_baseURL.substring(0, process.env.REACT_APP_baseURL.length - 4)
  // 'http://localhost:3006/api' --> 'http://localhost:3006' without ".../api"
);

function DeactivateMarkets() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();
  const [lights, setLights] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  useEffect(() => {
    socket.on("receive_script", (msg) => {
      setItems((oldArray) => [...oldArray, msg]);
    });
    socket.on("receive_script_finished", (msg) => {
      setItems((oldArray) => [...oldArray, msg]);
      setLights(false);
    });
  }, [socket]);

  const handleSetLights = () => {
    socket.emit("send_message_python", "my_message");
    // console.log("sending the request script to the nodejs server");
    setLights(!lights);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={3} lg={3}>
              <MDBox mb={3}>
                <ControllerCardSteps
                  state={lights}
                  icon={
                    <Icon className={lights ? "text-white" : "text-dark"} fontSize="large">
                      {lights ? "directions_run" : "directions_walk"}
                    </Icon>
                  }
                  title={lights ? "script running..." : "run script"}
                  onChange={handleSetLights}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={7} display="flex" flexDirection={{ xs: "column", sm: "row" }}>
              <MDBox width="100%" mr={{ xs: 0, sm: 3 }} mb={{ xs: 3, sm: 0 }}>
                <UpcomingEvents messages={items} />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeactivateMarkets;

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import { Icon, IconButton, Tooltip } from "@mui/material";
import moment from "moment";

function ModalAddRow({ modal, handleCloseModal, rowData, recordDetail, handleUpdate }) {
  const [formValues, setFormValues] = useState({
    Date: moment(rowData.Date).format("YYYY-MM-DD"),
    Open: rowData.Open,
    High: rowData.High,
    Low: rowData.Low,
    Close: rowData.Close,
  });

  useEffect(() => {
    if (rowData) {
      setFormValues({
        Date: moment(rowData.Date).format("YYYY-MM-DD"),
        Open: rowData.Open,
        High: rowData.High,
        Low: rowData.Low,
        Close: rowData.Close,
      });
    }
  }, [rowData]);

  const copyInformationFromLastRecord = () => {
    setFormValues({
      Date: moment.utc(recordDetail.Date).format("YYYY-MM-DD"),
      Open: recordDetail.Open,
      High: recordDetail.High,
      Low: recordDetail.Low,
      Close: recordDetail.Close,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    handleUpdate(formValues);
    handleCloseModal();
  };

  return (
    <Modal
      open={modal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <MDBox mb={2}>
          <MDTypography variant="h5" component="h2" fontWeight="bold">
            Add Record Market Data Store
          </MDTypography>
        </MDBox>
        <MDBox mb={2} lineHeight={1.5}>
          <MDTypography variant="h6" fontWeight="light" color="text">
            <strong>Values:</strong>
            <br />
            PEITicker: &quot;{rowData.PEITicker}&quot;
            <br />
            TimeFrame: &quot;{rowData.TimeFrame}&quot;
            <br />
            {/* <MDButton variant="gradient" size="small" color="info" onClick={(e) => handleCopyRow(e)}> */}
            <Tooltip
              title="Copy Information From Last Record"
              another="Copy Information From Last Record"
            >
              <span>
                <IconButton color="info" onClick={copyInformationFromLastRecord}>
                  {/* {disabled={rowData}} */}
                  <Icon>copy</Icon>
                </IconButton>
              </span>
            </Tooltip>
            Copy
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <TextField
            label="Date"
            name="Date"
            type="date"
            value={formValues.Date}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ step: "any", placeholder: "" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Open"
            name="Open"
            type="number"
            value={formValues.Open}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ step: "any" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="High"
            name="High"
            type="number"
            value={formValues.High}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ step: "any" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Low"
            name="Low"
            type="number"
            value={formValues.Low}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ step: "any" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Close"
            name="Close"
            type="number"
            value={formValues.Close}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ step: "any" }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mt={3}>
          <MDButton variant="contained" color="info" size="medium" onClick={handleCloseModal}>
            Cancel
          </MDButton>
          <MDButton variant="contained" color="warning" size="medium" onClick={handleSubmit}>
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

export default ModalAddRow;

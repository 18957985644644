import { useState, useEffect } from "react";

import http from "services/http";

import MDLoader from "components/MDLoader";

// context
import {
  setTransparentNavbar,
  setMiniSidenav,
  useMaterialUIController,
  getContextSuggestions,
  logout,
  reset,
} from "context";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import ButtonGroup from "@mui/material/ButtonGroup";
import Modal from "@mui/material/Modal";

// search libraries
import Autosuggest from "react-autosuggest";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import "./index.css";
import { formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";

function DashboardNavbar({ absolute, light, isMini }) {
  const navigate = useNavigate();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { userData, miniSidenav, transparentNavbar, fixedNavbar, darkMode, contextSuggestions } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [marketSuggestions, setMarketSuggestions] = useState([]);
  // const [search, setSearch] = useState("");
  // suggestions component feature
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [marketModal, setMarketModal] = useState();
  const [logInfo, setLogInfo] = useState();
  const [modal, setModal] = useState(false);

  const getSuggestions = (value) =>
    marketSuggestions.filter(
      (market) =>
        market.Name.toUpperCase().includes(value.trim().toUpperCase()) ||
        market.PEITicker.toUpperCase().includes(value.trim().toUpperCase())
    );

  const [isLoading, setisLoading] = useState(true);

  const getMarketSuggestions = async () => {
    const result = await http({
      path: `/markets/marketsuggestions`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          if (contextSuggestions.length === 0) {
            const loadedMarketSuggestions = await getMarketSuggestions();
            setMarketSuggestions(loadedMarketSuggestions);
            getContextSuggestions(dispatch);
            // setContextMarketSuggestions(dispatch, loadedMarketSuggestions);
            setisLoading(false);
          } else {
            setMarketSuggestions(contextSuggestions);
            setisLoading(false);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = () => {
    logout(dispatch);
    reset(dispatch);
    navigate("/");
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  // const handleChange = (e) => {
  //   console.log(e.target.value, e.target.name);
  //   setSearch(e.target.value);
  // };

  const handleSearch = () => {
    const getMarketUpdateInfo = async (id) => {
      const result = await http({
        path: `/markets/${id}`,
        accessToken: userData.token,
      });
      if (result.ok && result.body) {
        return result.body;
      }
      return [];
    };

    const searchPEITicker = marketSuggestions.find(
      (market) => market.Name === searchTerm
    ).PEITicker;

    getMarketUpdateInfo(searchPEITicker).then((response) => {
      setMarketModal(response.market);
      setLogInfo(response.logInfo);
    });
  };

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (marketModal) toggle();
  }, [marketModal]);

  const handleCloseModal = () => {
    setModal(!modal);
  };

  const handleRedirect = () => {
    setModal(false);
    navigate(`/markets/${marketModal.PEITicker}`);
  };

  const handleCancel = () => {
    setModal(false);
  };

  if (isLoading) return <MDLoader />;

  return (
    <>
      {marketModal && (
        <Modal
          open={modal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <MDBox mt={1} mx={0.5}>
              <MDBox mb={1}>
                <MDTypography
                  component={Link}
                  to="{action.route}"
                  variant="h5"
                  textTransform="capitalize"
                  justifyContent="center"
                  alignItems="center"
                >
                  Market Information
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  Market Name:
                </MDTypography>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  {marketModal.Name}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  PEI Ticker:
                </MDTypography>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  {marketModal.PEITicker}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  Region:
                </MDTypography>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  {marketModal.Region}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  Sector:
                </MDTypography>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  {marketModal.Sector}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="h6" fontWeight="light" color="text">
                  Last Holiday Date:
                </MDTypography>
                <MDTypography id="modal-modal-title" variant="h6" component="h2">
                  {logInfo && logInfo.Errors
                    ? `${formatDateInNewYorkMMDDYYYY(logInfo.Errors)}`
                    : "No information available"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                <MDButton variant="gradient" color="info" size="small" onClick={handleCancel}>
                  <Icon sx={{ fontWeight: "bold" }}>cancel</Icon>
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" size="small" onClick={handleRedirect}>
                  <Icon sx={{ fontWeight: "bold" }}>search</Icon>
                  View Market
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Modal>
      )}
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox pr={1}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {/* <MDInput
                    label="Type a market"
                    value={search}
                    name="search"
                    onChange={handleChange}
                  /> */}
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    onSuggestionsFetchRequested={({ value }) => {
                      setSearchTerm(value);
                      setSuggestions(getSuggestions(value));
                    }}
                    // eslint-disable-next-line no-unused-vars
                    onSuggestionSelected={(_, { suggestionValue }) => {
                      // console.log("Selected: " + suggestionValue);
                    }}
                    getSuggestionValue={(suggestion) => suggestion.Name}
                    renderSuggestion={(suggestion, { query }) => {
                      const matches = AutosuggestHighlightMatch(
                        `${suggestion.PEITicker} - ${suggestion.Name}`,
                        query,
                        { insideWords: true }
                      );
                      const parts = AutosuggestHighlightParse(
                        `${suggestion.PEITicker} - ${suggestion.Name}`,
                        matches
                      );
                      return (
                        <span>
                          {parts.map((part, index) => {
                            const className = part.highlight
                              ? "react-autosuggest__suggestion-match"
                              : null;

                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <span className={className} key={index}>
                                {part.text}
                              </span>
                            );
                          })}
                        </span>
                      );
                    }}
                    inputProps={{
                      placeholder: "Type a market",
                      value: searchTerm,
                      // eslint-disable-next-line no-unused-vars
                      onChange: (_, { newValue, method }) => {
                        setSearchTerm(newValue);
                      },
                    }}
                    highlightFirstSuggestion
                  />
                  <MDButton variant="gradient" color="info" size="small" onClick={handleSearch}>
                    <Icon sx={{ fontWeight: "bold" }}>search</Icon>
                    search
                  </MDButton>
                </ButtonGroup>
              </MDBox>
              <MDBox color={light ? "white" : "inherit"}>
                <Link to="/profile">
                  <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton>
                </Link>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                {userData && userData.token ? (
                  <IconButton
                    size="small"
                    disableRipple
                    color="inherit"
                    sx={navbarMobileMenu}
                    onClick={handleLogout}
                  >
                    <Icon sx={iconsStyle}>logout</Icon>
                  </IconButton>
                ) : null}
                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import { useState } from "react";
import httpPost from "services/httpPost";

// react router
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

function Confirm() {
  const { userId } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");

  const putResetPassword = async () => {
    const result = await httpPost({
      // eslint-disable-next-line no-underscore-dangle
      path: `/users/reset-password`,
      method: "PUT",
      raw: JSON.stringify({ userId, newPassword, confirmNewPassword }),
      accessToken: "",
    });

    if (result.ok && result.body) {
      setMessageSuccess("Please go to sign in page");
      return result.body;
    }
    setMessageError("Something went wrong, please try again later");
    return [];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await putResetPassword();
    // navigate("/authentication/sign-in");
  };

  const handleChange = (e) => {
    setMessageSuccess("");
    setMessageError("");
    setNewPassword(e.target.value);
  };

  // if (isLoading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         textAlign: "center",
  //         minHeight: "100vh",
  //       }}
  //     >
  //       <Loader.ThreeDots color="#1A73E8" />
  //     </div>
  //   );

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Type New Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="New Password"
                variant="standard"
                fullWidth
                value={newPassword}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Confirm New Password"
                variant="standard"
                fullWidth
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </MDBox>
            {messageError !== "" && (
              <MDBox mt={2} pl={2} mb={1}>
                <MDTypography variant="h6" fontWeight="light" color="error" mt={1}>
                  {messageError}
                </MDTypography>
              </MDBox>
            )}
            {messageSuccess !== "" && (
              <MDBox mt={2} pl={2} mb={1}>
                <MDTypography variant="h6" fontWeight="light" color="success" mt={1}>
                  {messageSuccess}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                confirm
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Confirm;

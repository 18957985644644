/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import MDTypography from "components/MDTypography";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function dataGmwCommentsDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="medium"
          >
            {row.values.Name}
          </MDTypography>
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "Category", accessor: "Category", align: "center" },
      { Header: "run region", accessor: "RunRegion", align: "center" },
      {
        Header: "Analysis Date",
        accessor: "latestReversal.AnalysisDate",
        align: "center",
        Cell: ({ row }) => (
          <MDTypography component="div" variant="h7" color="text" fontWeight="light">
            {formatDateInNewYorkNoSec(row.original.latestReversal.AnalysisDate)}
          </MDTypography>
        ),
      },
      { Header: "ResearchItemId", accessor: "latestReversal.ResearchItemId", align: "center" },
    ],
  };
}

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";
import { formatDateInNewYork } from "utils/dateUtil";

export default function dataMissingMarketAnalysisGMWDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "MarketName",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="bold"
          >
            {row.values.MarketName}
          </MDTypography>
        ),
      },
      { Header: "Market Name", accessor: "AnalysisMarket[0].Name", align: "center" },
      { Header: "AnalysisId", accessor: "AnalysisId", align: "center" },
      { Header: "run region", accessor: "AnalysisMarket[0].RunRegion", align: "center" },
      // { Header: "MarketId", accessor: "ResearchItemId", align: "center" },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "sector", accessor: "AnalysisMarket[0].Sector.Name", align: "center" },

      {
        Header: "Analysis Date",
        accessor: (d) => formatDateInNewYork(d.firstAnalysisDate),
        align: "center",
      },
    ],
  };
}

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { formatDateInNewYork } from "utils/dateUtil";

const getColor = (inStatus) => {
  let color = "dark";
  switch (inStatus) {
    case "Success":
      color = "success";
      break;
    case "Failed":
      color = "error";
      break;
    default:
      color = "dark";
  }
  return color;
};

export default function data() {
  return {
    columns1: [
      { Header: "Source", accessor: "Source", align: "left" },
      {
        Header: "Source File Download Status",
        accessor: "SourceFileDownloadStatus",
        align: "left",
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={getColor(row.original.SourceFileDownloadStatus)}
          >
            {row.original.SourceFileDownloadStatus}
          </MDTypography>
        ),
      },
      {
        Header: ".HM5 File Download Status (GMW)",
        accessor: "GMWFileDownloadStatus",
        align: "left",
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={getColor(row.original.GMWFileDownloadStatus)}
          >
            {row.original.GMWFileDownloadStatus}
          </MDTypography>
        ),
      },
      {
        Header: ".GRD File Download Status (CGA)",
        accessor: "GridFileDownloadStatus",
        align: "left",
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={getColor(row.original.GridFileDownloadStatus)}
          >
            {row.original.GridFileDownloadStatus}
          </MDTypography>
        ),
      },
      {
        Header: ".TXT File Download Status (FORECAST)",
        accessor: "TextAnalysisFileDownloadStatus",
        align: "left",
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={getColor(row.original.TextAnalysisFileDownloadStatus)}
          >
            {row.original.TextAnalysisFileDownloadStatus}
          </MDTypography>
        ),
      },
      {
        Header: ".FOR Array File Download Status (FORECAST)",
        accessor: "ForArrayFileDownloadStatus",
        align: "left",
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={getColor(row.original.ForArrayFileDownloadStatus)}
          >
            {row.original.ForArrayFileDownloadStatus}
          </MDTypography>
        ),
      },
    ],

    columns4: [
      { Header: "Length of Daily", accessor: "Daily", align: "center" },
      { Header: "Length of Weekly", accessor: "Weekly", align: "center" },
      { Header: "Length of Monthly", accessor: "Monthly", align: "center" },
      { Header: "Length of Quarterly", accessor: "Quarterly", align: "center" },
      { Header: "Length of Yearly", accessor: "Yearly", align: "center" },
    ],

    columns5: [
      {
        Header: "Market Overview Date",
        accesor: "MarketOverviewDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.MarketOverviewDate
            ? formatDateInNewYork(row.original.MarketOverviewDate)
            : "",
      },
      {
        Header: "GMW Analysis Date",
        accesor: "GMWAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.GMWAnalysisDate ? formatDateInNewYork(row.original.GMWAnalysisDate) : "",
      },
      {
        Header: "Reversals Analysis Date",
        accesor: "ReversalsAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.ReversalsAnalysisDate
            ? formatDateInNewYork(row.original.ReversalsAnalysisDate)
            : "",
      },
      {
        Header: "Arrays Analysis Date",
        accesor: "ArraysAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.ArraysAnalysisDate
            ? formatDateInNewYork(row.original.ArraysAnalysisDate)
            : "",
      },
      {
        Header: "Top Table",
        accesor: "TopTable",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.TopTable ? formatDateInNewYork(row.original.TopTable) : "Not a Top mover",
      },
      {
        Header: "Market Last Update Date",
        accesor: "MarketLastUpdateDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.MarketLastUpdateDate
            ? formatDateInNewYork(row.original.MarketLastUpdateDate)
            : "",
      },
    ],

    columns6: [
      {
        Header: "Market Overview Date",
        accesor: "MarketOverviewDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.MarketOverviewDate
            ? formatDateInNewYork(row.original.MarketOverviewDate)
            : "",
      },
      {
        Header: "GMW Analysis Date",
        accesor: "GMWAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.GMWAnalysisDate ? formatDateInNewYork(row.original.GMWAnalysisDate) : "",
      },
      {
        Header: "Reversals Analysis Date",
        accesor: "ReversalsAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.ReversalsAnalysisDate
            ? formatDateInNewYork(row.original.ReversalsAnalysisDate)
            : "",
      },
      {
        Header: "Arrays Analysis Date",
        accesor: "ArraysAnalysisDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.ArraysAnalysisDate
            ? formatDateInNewYork(row.original.ArraysAnalysisDate)
            : "",
      },
      {
        Header: "Top Table",
        accesor: "TopTable",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.TopTable ? formatDateInNewYork(row.original.TopTable) : "Not a Top mover",
      },
      {
        Header: "Market Last Update Date",
        accesor: "MarketLastUpdateDate",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Cell: ({ row }) =>
          row.original.MarketLastUpdateDate
            ? formatDateInNewYork(row.original.MarketLastUpdateDate)
            : "",
      },
    ],

    rows1: [
      {
        marketName: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            AmerAgnico Eagle Mines Limited
          </MDTypography>
        ),
        PEITicker: <MDTypography variant="caption">AEFOOD</MDTypography>,
        sector: <MDTypography variant="caption">Foreign Exchange</MDTypography>,
        region: <MDTypography variant="caption">AE Indices</MDTypography>,
        failedMarkets: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {0}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Warning!" color="warning" variant="gradient" size="sm" />
          </MDBox>
        ),
        expRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        lastRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        earliestTrade: (
          <div
            style={{
              backgroundColor: "green",
              minWidth: "15px",
              width: "15px",
              minHeight: "15px",
              borderRadius: "30px",
              padding: "5px",
            }}
          />
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        marketName: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            EuAgnico Eagle Mines Limited
          </MDTypography>
        ),
        PEITicker: <MDTypography variant="caption">AEFOOD</MDTypography>,
        sector: <MDTypography variant="caption">Foreign Exchange</MDTypography>,
        region: <MDTypography variant="caption">AE Indices</MDTypography>,
        failedMarkets: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {0}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Warning!" color="warning" variant="gradient" size="sm" />
          </MDBox>
        ),
        expRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            24/12/08
          </MDTypography>
        ),
        lastRefreshTime: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        earliestTrade: (
          <div
            style={{
              backgroundColor: "green",
              minWidth: "15px",
              width: "15px",
              minHeight: "15px",
              borderRadius: "30px",
              padding: "5px",
            }}
          />
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
    ],
  };
}

/* eslint-disable no-underscore-dangle */
// react
import { useMaterialUIController } from "context";
import { useQuery } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// react router
import { useParams } from "react-router-dom";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// FailedMarketsByRegion components
import DataTableMarketSubscriptions from "components/Tables/DataTableMarketSubscriptions";

// Data
import failedMarketsByRegionTableData from "layouts/tables/data/failedMarketsByRegionTableData";

function FailedMarketsByRegion() {
  const { regionId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const getFailedMarketsByRegion = async () => {
    const result = await http({
      path: `/regions/markets/byregionbytimeframe/1`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const {
    isLoading,
    data: failedMarketsDetail,
    isError,
    error,
  } = useQuery({
    queryKey: ["failedMarketsByRegion"],
    queryFn: getFailedMarketsByRegion,
    select: (data) =>
      data
        .reduce(
          // eslint-disable-next-line no-underscore-dangle
          (acumulador, elemento) =>
            acumulador.concat(
              elemento.flag
                ? elemento.failedMarketsCalculatedMinus_
                : elemento.failedMarketsCalculated_
            ),
          []
        )
        .filter((el) => {
          switch (el.RunRegion) {
            case "US1":
            case "US5":
              return regionId === "Bonds";
            case "US2":
            case "US3":
              return regionId === "Americas";
            case "US4":
              return regionId === "Economics";
            default:
              return el.RunRegion === regionId;
          }
        }),
  });

  const { columns3 } = failedMarketsByRegionTableData();

  if (isLoading) return <MDLoader />;
  if (isError) return <div>Error {error.message}</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Failed Markets
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableMarketSubscriptions
                  table={{
                    columns: columns3,
                    rows: failedMarketsDetail,
                  }}
                  isSorted
                  entriesPerPage={{ defaultValue: 10 }}
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FailedMarketsByRegion;

// react
import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Checkbox from "components/FormsUI/Checkbox";

// NewMarketRequest page components
import FormField from "components/FormField";
import DataTableComments from "components/Tables/DataTableComments";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

function UserInfo({ formData }) {
  const { formField, categories, countries, setFieldValue, marketInfoRequest } = formData;
  const {
    marketName,
    exchangeSymbol,
    exchange,
    currency,
    sourceTicker,
    externalRequester,
    externalEmail,
    category,
    country,
  } = formField;

  const [visible, setVisible] = useState(marketInfoRequest.ExternalRequester);

  const handleSetVisible = (e) => {
    setVisible(!visible);
    setFieldValue("externalRequester", e.target.checked, false);
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={marketName.type}
              label={marketName.label}
              name={marketName.name}
              value={marketInfoRequest.MarketName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="category"
              name={category.name}
              options={categories}
              value={marketInfoRequest.Category}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("category", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Category" />}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={exchangeSymbol.type}
              label={exchangeSymbol.label}
              name={exchangeSymbol.name}
              value={marketInfoRequest.ExchangeSymbol}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={currency.type}
              label={currency.label}
              name={currency.name}
              value={marketInfoRequest.Currency}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={exchange.type}
              label={exchange.label}
              name={exchange.name}
              value={marketInfoRequest.Exchange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country"
              name={country.DisplayName}
              options={countries.map((s) => s.DisplayName)}
              value={marketInfoRequest.Country}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("country", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Country" />}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={sourceTicker.type}
              label={sourceTicker.label}
              name={sourceTicker.name}
              value={marketInfoRequest.SourceTicker}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Checkbox
              name="isBusinessUser"
              label="IsBusinessUser"
              checked={marketInfoRequest.isBusinessUser}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3.5} lg={3.5}>
            <MDBox
              display="flex"
              // justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDBox ml={1}>
                <Switch
                  checked={visible}
                  onChange={(e) => handleSetVisible(e)}
                  name={externalRequester.name}
                  value={marketInfoRequest.ExternalRequester}
                  disabled
                />
              </MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                External request
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            {visible && (
              <FormField
                type={externalEmail.type}
                label={externalEmail.label}
                name={externalEmail.name}
                value={marketInfoRequest.ExternalEmail}
                disabled
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <DataTableComments
              table={{
                columns: [
                  {
                    Header: "CommentDate",
                    accessor: "CommentDate",
                    width: "40%",
                    align: "left",
                    Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.CommentDate),
                  },
                  {
                    Header: "CommentUser",
                    accessor: "CommentUser",
                    align: "left",
                  },
                  {
                    Header: "CommentText",
                    accessor: "CommentText",
                    width: "65%",
                    align: "left",
                  },
                ],
                rows: marketInfoRequest.CommentsData,
              }}
              isSorted
              showTotalEntries={false}
              entriesPerPage={false}
              noEndBorder={false}
              canAddComment={false}
              addComment={() => {}}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;

/**
=========================================================
* Material Dashboard React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard React examples
import DefaultItem from "examples/Items/DefaultItem";
import Box from "@mui/material/Box";

function UpcomingEvents({ messages }) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  scrollToBottom();

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} lineHeight={1}>
        <MDTypography variant="h6" fontWeight="medium">
          Upcoming events
        </MDTypography>
        <MDTypography variant="button" color="text" fontWeight="regular">
          Joined
        </MDTypography>
      </MDBox>
      <Box
        p={2}
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          height: 300,
          overflow: "hidden",
          overflowY: "scroll",
          // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}
      >
        {messages.map((message) => (
          <MDBox mt={2.5} key={`${message.lenght}-${message}`}>
            <DefaultItem
              color="dark"
              icon="savings"
              title={message}
              description="27 March 2020, at 12:30 PM"
            />
          </MDBox>
        ))}
        <div ref={messagesEndRef} />
      </Box>
    </Card>
  );
}

// Setting default values for the props of UpcomingEvents
UpcomingEvents.defaultProps = {
  messages: [],
};

// Typechecking props of the UpcomingEvents
UpcomingEvents.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
};

export default UpcomingEvents;

/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from "react";
import { useQueries } from "@tanstack/react-query";
import { useMaterialUIController, setOpenMarketMessage } from "context";
// import { useNavigate, useParams, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";

import http from "services/http";
import httpPost from "services/httpPost";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { formatDateMDY } from "utils/dateUtil";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLoader from "components/MDLoader";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableRowSelectUMDSDetail from "examples/Tables/DataTableRowSelectUMDSDetail";
import DataTableMDS from "examples/Tables/DataTableMDS";

// Data
import updateMarketDataStoreDetail from "layouts/tables/data/updateMarketDataStoreTableDataDetail";
import { Divider, Icon, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MDDatePickerMDYRef from "components/MDDatePickerMDYRef";
import TimeFrame from "layouts/subscriptionsoverview/components/TimeFrame";
import ModalUpdateRow from "./components/ModalUpdateRow";
import ModalDelete from "./components/ModalDelete";
import ModalDeleteMany from "./components/ModalDeleteMany";
import ModalAddRow from "./components/ModalAddRow";

function UpdateMarketDataStoreDetail() {
  const { peiticker } = useParams();
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;

  const firstDatePickerRef = useRef(null);
  const secondDatePickerRef = useRef(null);

  // const navigate = useNavigate();
  const [purchasedDate, setPurchasedDate] = useState([
    moment().subtract(30, "days").format("MM-DD-YYYY"),
    moment().format("MM-DD-YYYY"),
  ]);
  // eslint-disable-next-line no-unused-vars
  const [purchasedDateGlobal, setPurchasedDateGlobal] = useState([undefined, undefined]);
  const [timeframe, setTimeframe] = useState("last30days");

  const [timeframeNoZero, setTimeframeNoZero] = useState("DNoZero");

  const [modalUpdateRow, setModalUpdateRow] = useState(false);
  const [modalAddRow, setModalAddRow] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteMany, setModalDeleteMany] = useState(false);
  const [recordDetail, setRecordDetail] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [ohlc, setOhlc] = useState(false);

  const handleCloseModalDelete = () => {
    setModalDelete(!modalDelete);
  };

  const handleCloseModalAddRow = () => {
    setModalAddRow(!modalAddRow);
  };

  const handleCloseModalDeleteMany = () => {
    setModalDeleteMany(!modalDeleteMany);
  };

  const handleCloseModalUpdateRow = () => {
    setModalUpdateRow(!modalUpdateRow);
  };

  const handleCancelDelete = () => {
    setModalDelete(false);
  };

  const handleCancelAddRow = () => {
    setModalAddRow(false);
  };

  const handleCancelUpdateRow = () => {
    setModalUpdateRow(false);
  };

  const handleCancelDeleteMany = () => {
    setModalDeleteMany(false);
  };

  const deleteUMDS = async () => {
    const result = await httpPost({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketdatastore/${recordDetail._id}`,
      method: "DELETE",
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const updateRow = async (formValues) => {
    const result = await httpPost({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketdatastore/${recordDetail._id}`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      accessToken: userData.token,
      // body: JSON.stringify({ ...formValues }),
      raw: JSON.stringify({ ...formValues }),
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const addRow = async (formValues) => {
    const result = await httpPost({
      path: `/marketdatastore`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      accessToken: userData.token,
      // body: JSON.stringify({ ...formValues }),
      // eslint-disable-next-line no-use-before-define
      raw: JSON.stringify({ ...recordToAdd, ...formValues }),
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const updateRows = async (rows) => {
    const result = await httpPost({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketdatastore/many`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      accessToken: userData.token,
      // body: JSON.stringify({ ...formValues }),
      raw: JSON.stringify(rows),
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  // eslint-disable-next-line no-unused-vars
  const getMarketDataStoreZeroValueDetail = async (purchasedDateParam) => {
    // if (
    //   !Array.isArray(purchasedDateParam) ||
    //   purchasedDateParam.length !== 2 ||
    //   purchasedDateParam[0] === undefined ||
    //   purchasedDateParam[1] === undefined
    // ) {
    //   return [];
    // }
    const result = await http({
      path: `/marketdatastore/all/${peiticker}?startDate=${purchasedDateParam[0]}&endDate=${purchasedDateParam[1]}&ohlc=${ohlc}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (
      purchasedDate[0] &&
      purchasedDate[1] &&
      (purchasedDate[0] === "" || purchasedDate[1] === "")
    ) {
      setPurchasedDateGlobal([undefined, undefined]);
    }
    if (purchasedDate[0] === "" || purchasedDate[1] === "") {
      setPurchasedDateGlobal([undefined, undefined]);
    }
    if (
      purchasedDate[0] &&
      purchasedDate[1] &&
      (purchasedDate[0] !== "" || purchasedDate[1] !== "")
    ) {
      setPurchasedDateGlobal(purchasedDate);
    }
  }, [purchasedDate]);

  const [data] = useQueries({
    queries: [
      {
        queryKey: ["updatemarketdatastoredetail", purchasedDateGlobal, peiticker, ohlc],
        queryFn: () => getMarketDataStoreZeroValueDetail(purchasedDateGlobal),
      },
    ],
  });

  const handleRedirectDelete = async () => {
    setModalDelete(false);
    await deleteUMDS();
    await setOpenMarketMessage(dispatch, {
      open: true,
      color: "success",
      icon: "check",
      title: "Success",
      // content: `${tag.data[0].MarketTagName} has been deleted`,
      content: `Market Data Store record has been deleted`,
    });
    // navigate(`/view-all-tags`);
    data.refetch();
  };

  const handleRedirectUpdateRow = async (formValues) => {
    setModalUpdateRow(false);
    await updateRow(formValues);
    await setOpenMarketMessage(dispatch, {
      open: true,
      color: "success",
      icon: "check",
      title: "Success",
      content: `Market Data Store record has been updated`,
    });
    data.refetch();
  };

  const handleRedirectAddRow = async (formValues) => {
    setModalAddRow(false);

    const result = await addRow(formValues);
    if (result.length === 0) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "error",
        icon: "check",
        title: "Error",
        content: `An internal server error occurred.`,
      });
    } else {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "success",
        icon: "check",
        title: "Success",
        content: `Market Data Store record has been added`,
      });
      data.refetch();
    }
  };

  const handleRedirectDeleteMany = async (rows) => {
    setModalDeleteMany(false);
    await updateRows(rows);
    await setOpenMarketMessage(dispatch, {
      open: true,
      color: "success",
      icon: "check",
      title: "Success",
      content: `Market Data Store recordS has been deleted`,
    });
    data.refetch();
  };

  const { columns2 } = updateMarketDataStoreDetail({
    setModalDelete,
    setModalUpdateRow,
    setRecordDetail,
  });

  if (data.isLoading) return <MDLoader />;

  if (data.error) {
    return <p>Error: {data.error.message}</p>;
  }

  const { DNoZero, WNoZero, MNoZero, QNoZero, YNoZero, marketInfoData } = data.data;

  const dataTimeframeNoZero = (inTimeframe) => {
    switch (inTimeframe) {
      case "DNoZero":
        return DNoZero;
      case "WNoZero":
        return WNoZero;
      case "MNoZero":
        return MNoZero;
      case "QNoZero":
        return QNoZero;
      case "YNoZero":
        return YNoZero;
      default:
        return DNoZero;
    }
  };

  const recordToAdd = {
    PEITicker: marketInfoData[0]?.PEITicker,
    Sector: marketInfoData[0]?.Sector,
    RunRegion: marketInfoData[0]?.RunRegion,
    TimeFrame: timeframeNoZero[0],
    Source: marketInfoData[0]?.Source,
    Date: moment().format("YYYY-MM-DD"),
    Open: 0,
    High: 0,
    Low: 0,
    Close: 0,
  };

  const lastRecord = dataTimeframeNoZero(timeframeNoZero).sort(
    (a, b) => moment(b.Date) - moment(a.Date)
  )[0];

  const findExtreme = (dataMDS, key, comparator) =>
    dataMDS.reduce((prev, current) => (comparator(prev[key], current[key]) ? prev : current), 0);

  const highestOpen = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Open", (a, b) => a > b);
  const lowestOpen = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Open", (a, b) => a < b);
  const highestHigh = findExtreme(dataTimeframeNoZero(timeframeNoZero), "High", (a, b) => a > b);
  const lowestHigh = findExtreme(dataTimeframeNoZero(timeframeNoZero), "High", (a, b) => a < b);
  const highestLow = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Low", (a, b) => a > b);
  const lowestLow = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Low", (a, b) => a < b);
  const highestClose = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Close", (a, b) => a > b);
  const lowestClose = findExtreme(dataTimeframeNoZero(timeframeNoZero), "Close", (a, b) => a < b);

  const tableData = [
    {
      metric: "Open",
      highestValue: highestOpen.Open,
      highestDate: new Date(highestOpen.Date),
      lowestValue: lowestOpen.Open,
      lowestDate: new Date(lowestOpen.Date),
    },
    {
      metric: "High",
      highestValue: highestHigh.High,
      highestDate: new Date(highestHigh.Date),
      lowestValue: lowestHigh.High,
      lowestDate: new Date(lowestHigh.Date),
    },
    {
      metric: "Low",
      highestValue: highestLow.Low,
      highestDate: new Date(highestLow.Date),
      lowestValue: lowestLow.Low,
      lowestDate: new Date(lowestLow.Date),
    },
    {
      metric: "Close",
      highestValue: highestClose.Close,
      highestDate: new Date(highestClose.Date),
      lowestValue: lowestClose.Close,
      lowestDate: new Date(lowestClose.Date),
    },
  ];

  const recordToAddLastRecord = {
    PEITicker: marketInfoData[0]?.PEITicker,
    Sector: marketInfoData[0]?.Sector,
    RunRegion: marketInfoData[0]?.RunRegion,
    TimeFrame: timeframeNoZero[0],
    Source: marketInfoData[0]?.Source,
    Date: lastRecord?.Date,
    Open: lastRecord?.Open,
    High: lastRecord?.High,
    Low: lastRecord?.Low,
    Close: lastRecord?.Close,
  };

  return (
    <DashboardLayout>
      <ModalDelete
        modal={modalDelete}
        handleCloseModal={handleCloseModalDelete}
        recordDetail={recordDetail}
        handleCancel={handleCancelDelete}
        handleRedirect={handleRedirectDelete}
        rowData={recordDetail}
      />
      <ModalUpdateRow
        modal={modalUpdateRow}
        handleCloseModal={handleCloseModalUpdateRow}
        recordDetail={recordDetail}
        handleCancel={handleCancelUpdateRow}
        handleUpdate={handleRedirectUpdateRow}
        rowData={recordDetail}
      />
      <ModalAddRow
        modal={modalAddRow}
        handleCloseModal={handleCloseModalAddRow}
        recordDetail={recordToAddLastRecord}
        handleCancel={handleCancelAddRow}
        handleUpdate={handleRedirectAddRow}
        rowData={recordToAdd}
      />
      <ModalDeleteMany
        modal={modalDeleteMany}
        handleCloseModal={handleCloseModalDeleteMany}
        handleCancel={handleCancelDeleteMany}
        handleRedirect={handleRedirectDeleteMany}
        recordDetail={recordDetail}
        rowData={recordDetail}
      />
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox>
                  <MDBox pt={2} px={2}>
                    <MDTypography
                      variant="h5"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      FILTERS
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={1} px={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="light"
                      color="text"
                      textTransform="uppercase"
                    >
                      Select timeframe
                    </MDTypography>
                  </MDBox>
                  <Grid item xs={9} m={3} mt={0} mb={0}>
                    <ToggleButtonGroup
                      size="small"
                      color="info"
                      value={timeframeNoZero}
                      exclusive
                      onChange={(e, newTimeframeNoZero) => setTimeframeNoZero(newTimeframeNoZero)}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="DNoZero" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          Daily
                          <MDTypography variant="caption" color="error" fontWeight="regular">
                            {DNoZero && DNoZero.length > 0 ? `(${DNoZero.length})` : ""}
                          </MDTypography>
                        </MDTypography>
                      </ToggleButton>
                      <ToggleButton value="WNoZero" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          Weekly
                          <MDTypography variant="caption" color="error" fontWeight="regular">
                            {WNoZero && WNoZero.length > 0 ? `(${WNoZero.length})` : ""}
                          </MDTypography>
                        </MDTypography>
                      </ToggleButton>
                      <ToggleButton value="MNoZero" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          Monthly
                          <MDTypography variant="caption" color="error" fontWeight="regular">
                            {MNoZero && MNoZero.length > 0 ? `(${MNoZero.length})` : ""}
                          </MDTypography>
                        </MDTypography>
                      </ToggleButton>
                      <ToggleButton value="QNoZero" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          Quarterly
                          <MDTypography variant="caption" color="error" fontWeight="regular">
                            {QNoZero && QNoZero.length > 0 ? `(${QNoZero.length})` : ""}
                          </MDTypography>
                        </MDTypography>
                      </ToggleButton>
                      <ToggleButton value="YNoZero" aria-label="centered">
                        <MDTypography variant="caption" color="text" fontWeight="regular">
                          Yearly
                          <MDTypography variant="caption" color="error" fontWeight="regular">
                            {YNoZero && YNoZero.length > 0 ? `(${YNoZero.length})` : ""}
                          </MDTypography>
                        </MDTypography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </MDBox>

                <MDBox>
                  <MDBox px={2} pb={3}>
                    <Grid container spacing={3} pt={1}>
                      <Grid item xs={12} sm={12}>
                        <MDBox display="inline-block">
                          <MDTypography
                            component="label"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            textTransform="capitalize"
                          >
                            Select Period
                          </MDTypography>
                        </MDBox>
                        <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
                          <MDDatePickerMDYRef
                            input={{
                              placeholder: "start date",
                              size: "small",
                              sx: { width: "5.1rem" },
                              maxdate: purchasedDate[1] || null,
                              disabled: timeframe !== null,
                            }}
                            onChange={(e, value) => {
                              setPurchasedDate((old = []) => [value || undefined, old[1]]);
                              if (value && purchasedDate[1] === "") {
                                secondDatePickerRef.current &&
                                  secondDatePickerRef.current.flatpickr.open();
                              }
                            }}
                            value={purchasedDate[0] || ""}
                            ref={firstDatePickerRef}
                            disabled
                          />

                          <MDTypography variant="caption"> to </MDTypography>
                          <MDDatePickerMDYRef
                            input={{
                              placeholder: "end date",
                              size: "small",
                              sx: { width: "5.1rem" },
                              mindate: purchasedDate[0] || null,
                              disabled: timeframe !== null,
                            }}
                            onChange={(e, value) => {
                              setPurchasedDate((old = []) => [
                                old[0],
                                value ? value.concat("T23:59:59.999Z").slice(0, 10) : undefined,
                              ]);
                              if (value && purchasedDate[0] === "") {
                                firstDatePickerRef.current &&
                                  firstDatePickerRef.current.flatpickr.open();
                              }
                            }}
                            value={purchasedDate[1]?.slice(0, 10) || ""}
                            ref={secondDatePickerRef}
                          />
                          {` `}
                          <MDButton
                            size="small"
                            color="dark"
                            iconOnly
                            disabled={
                              purchasedDate[0] === "" ||
                              purchasedDate[1] === "" ||
                              timeframe !== null
                            }
                            onClick={() => {
                              setPurchasedDate(["", ""]);
                            }}
                          >
                            <Icon>close</Icon>
                          </MDButton>
                        </MDBox>
                        <Grid item xs={12} sm={12}>
                          <MDBox pt={1} px={0}>
                            <TimeFrame
                              timeframe={timeframe}
                              setTimeframe={setTimeframe}
                              setPurchasedDate={setPurchasedDate}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <MDBox pt={1} px={0}>
                            <MDTypography
                              variant="caption"
                              color="text"
                              // display="flex"
                            >
                              <Checkbox
                                name="OHLC Zero Values"
                                label="OHLC Zero Values"
                                checked={ohlc}
                                onChange={() => setOhlc((prev) => !prev)}
                              />
                              OHLC Zero Values
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <MDBox mb={1.5}>
              {marketInfoData && (
                <>
                  <Card>
                    <MDBox display="flex" justifyContent="left" pt={2} px={3}>
                      <MDBox textAlign="right" lineHeight={1.25}>
                        <MDTypography variant="h5">{marketInfoData[0]?.PEITicker}</MDTypography>
                      </MDBox>
                    </MDBox>
                    <Divider />
                    <MDBox pb={2} px={2}>
                      <MDTypography component="p" variant="button" color="text" display="flex">
                        &nbsp;{marketInfoData[0]?.Name}
                      </MDTypography>
                    </MDBox>
                  </Card>
                  <MDBox pt={1}>
                    <Card>
                      <DataTableMDS
                        table={{
                          columns: [
                            {
                              Header: "Metric",
                              accessor: "metric",
                              align: "left",
                            },
                            {
                              Header: "Highest Value",
                              accessor: "highestValue",
                              align: "center",
                            },
                            {
                              Header: "Date (Highest)",
                              // accessor: "LastUpdateDate",
                              accessor: (d) => formatDateMDY(d.highestDate),
                              align: "center",
                            },
                            {
                              Header: "Lowest Value",
                              accessor: "lowestValue",
                              align: "center",
                            },
                            {
                              Header: "Date (Lowest)",
                              // accessor: "LastUpdateDate",
                              accessor: (d) => formatDateMDY(d.lowestDate),
                              align: "center",
                            },
                          ],
                          rows: tableData,
                        }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </Card>
                  </MDBox>
                </>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update Market Data Store
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableRowSelectUMDSDetail
                  table={{ columns: columns2, rows: dataTimeframeNoZero(timeframeNoZero) }}
                  isSorted
                  showTotalEntries
                  noEndBorder={false}
                  entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25, 50, 100, 200] }}
                  canSearch
                  pagination={{ variant: "gradient", color: "info" }}
                  setModalAddRow={setModalAddRow}
                  setModalDeleteMany={setModalDeleteMany}
                  setRecordDetail={setRecordDetail}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateMarketDataStoreDetail;

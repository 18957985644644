import * as Yup from "yup";
import checkout from "layouts/marketrequestmarkettradinginfo/schemas/form";

const {
  formField: {
    MarketName,
    ExchangeSymbol,
    Exchange,
    ExternalEmail,
    TradingVolume,
    TradingStartDate,
    MarketHistoryYears,
    Source,
    MarketCap,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [MarketName.name]: Yup.string().required(MarketName.errorMsg),
    [ExchangeSymbol.name]: Yup.string()
      .required(ExchangeSymbol.errorMsg)
      .max(10, ExchangeSymbol.invalidMsg),
    [Exchange.name]: Yup.string().required(Exchange.errorMsg),
    [ExternalEmail.name]: Yup.string().email(ExternalEmail.invalidMsg),
  }),
  Yup.object().shape({
    [TradingVolume.name]: Yup.string().required(TradingVolume.errorMsg),
    [TradingStartDate.name]: Yup.string().required(TradingStartDate.errorMsg),
    [MarketHistoryYears.name]: Yup.string().required(MarketHistoryYears.errorMsg),
    [Source.name]: Yup.string().required(Source.errorMsg),
    [MarketCap.name]: Yup.number().positive().min(0).required(MarketCap.errorMsg),
  }),
];

export default validations;

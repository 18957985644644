/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import MDLink from "components/MDLink";

const columns = [
  {
    Header: "Market Name",
    accessor: "Market",
    align: "left",
    maxWidth: 220,
    minWidth: 220,
    Cell: ({ row }) => (
      <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Market} />
    ),
  },
  {
    Header: "PEITicker",
    accessor: "PEITicker",
    align: "left",
    maxWidth: 110,
    minWidth: 110,
    Cell: ({ row }) => (
      <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.PEITicker} />
    ),
  },
  {
    Header: "Socrates Category",
    accessor: "Category",
    align: "center",
  },
  {
    Header: "GICS Sector",
    accessor: "GICS",
    align: "center",
  },
  {
    Header: "GICS Industry",
    accessor: "GICSIndustry",
    align: "center",
  },
];

export default columns;

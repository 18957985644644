// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import "./index.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import React, { forwardRef } from "react";

const MDDatePickerMDYRef = forwardRef(({ input, ...rest }, ref) => (
  <Flatpickr
    options={{
      dateFormat: "m-d-Y",
      minDate: input.mindate ? input.mindate : null,
      maxDate: input.maxdate ? input.maxdate : null,
    }}
    {...rest}
    ref={ref}
    render={({ defaultValue }, flatpickrRef) => (
      <MDInput {...input} defaultValue={defaultValue} inputRef={flatpickrRef} />
    )}
  />
));

// Setting default values for the props of MDDatePickerMDYRef
MDDatePickerMDYRef.defaultProps = {
  input: {},
};

// Typechecking props for the MDDatePickerMDYRef
MDDatePickerMDYRef.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePickerMDYRef;

const form = {
  formId: "new-user-form",
  formField: {
    Status: {
      name: "Status",
      label: "Status",
      type: "text",
    },
    PrimarySource: {
      name: "PrimarySource",
      label: "Primary Source",
      type: "text",
      errorMsg: "Primary Source is required.",
    },
    SecondarySource: {
      name: "SecondarySource",
      label: "SecondarySource",
      type: "text",
      errorMsg: "SecondarySource is required.",
    },
    MarketHistoryYears: {
      name: "MarketHistoryYears",
      label: "MarketHistoryYears",
      type: "text",
      errorMsg: "MarketHistoryYears is required.",
    },
    MarketHistoryMonths: {
      name: "MarketHistoryMonths",
      label: "MarketHistoryMonths",
      type: "text",
      errorMsg: "MarketHistoryMonths is required.",
    },
    SourceTicker: {
      name: "SourceTicker",
      label: "Source Ticker",
      type: "text",
      errorMsg: "SourceTicker is required.",
    },
    IsTradingVolumeEnough: {
      name: "IsTradingVolumeEnough",
      label: "Is Trading Volume Enough",
      type: "text",
      errorMsg: "IsTradingVolumeEnough is required.",
    },
    MarketCap: {
      name: "MarketCap",
      label: "Market Cap",
      type: "text",
      errorMsg: "Market Cap is required.",
    },
    NotifyMe: {
      name: "NotifyMe",
      label: "Notify Me",
      type: "text",
      errorMsg: "Notify Me is required.",
    },
    NAV: {
      name: "NAV",
      label: "NAV",
      type: "text",
      errorMsg: "NAV is required.",
    },
    MarketName: {
      name: "MarketName",
      label: "Market Name",
      type: "text",
      errorMsg: "Market Name is required.",
    },
    GeneralTicker: {
      name: "GeneralTicker",
      label: "General Ticker",
      type: "text",
      errorMsg: "General Ticker is required.",
    },
    StockExchange: {
      name: "StockExchange",
      label: "Stock Exchange",
      type: "text",
      errorMsg: "Stock Exchange is required.",
    },
    Currency: {
      name: "Currency",
      label: "Currency",
      type: "text",
    },
    Sector: {
      name: "Sector",
      label: "Sector",
    },
    Country: {
      name: "Country",
      label: "Country",
    },
    TotalRequests: {
      name: "TotalRequests",
      label: "TotalRequests",
      type: "text",
      errorMsg: "TotalRequests is required.",
    },
    Comments: {
      name: "Comments",
      label: "Comments",
      type: "text",
    },
    Requesters: {
      name: "Requesters",
      label: "Requesters",
      type: "text",
    },
  },
};

export default form;

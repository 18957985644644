/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import MDLink from "components/MDLink";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 110,
        minWidth: 110,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.PEITicker} />
        ),
      },
      {
        Header: "Failed Market Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.FailedMarketDate2),
        align: "center",
        maxWidth: 180,
        minWidth: 180,
      },
      {
        Header: "Last Action Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.PostedOn),
        align: "center",
        maxWidth: 180,
        minWidth: 180,
      },
      {
        Header: "Status",
        accessor: (d) => {
          switch (d.Status) {
            case "inprogress":
              return "In Progress";
            default:
              if (d.Status) return d.Status.charAt(0).toUpperCase() + d.Status.slice(1);
              return d.Status;
          }
        },
        align: "center",
      },
      {
        Header: "Reason For Failure",
        accessor: (d) => {
          if (d.ReasonForFailure)
            return d.ReasonForFailure.charAt(0).toUpperCase() + d.ReasonForFailure.slice(1);
          return d.ReasonForFailure;
        },
        align: "center",
      },
      {
        Header: "Action Taken By User",
        accessor: "ActionTakenByUser",
        align: "center",
      },
      {
        Header: "Comments",
        accessor: "latestSummary",
        align: "left",
        maxWidth: 250,
        minWidth: 250,
      },
    ],
  };
}

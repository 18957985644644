// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewMarketRequest page components

function MarketInfo({ formData }) {
  const { formField, values, errors, categories, setFieldValue, market } = formData;
  const { category } = formField;
  const { category: categoryV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDTypography variant="button" color="text">
              PEITicker: {market.PEITicker || ""}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDTypography variant="button" color="text">
              Name: {market.Name || ""}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="category"
              name={category}
              options={categories}
              value={categoryV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                setFieldValue("category", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Category" />}
            />
            {errors && errors.category && (
              <MDTypography variant="button" color="error">
                {errors.category}
              </MDTypography>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MarketInfo
MarketInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MarketInfo;

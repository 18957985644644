import checkout from "layouts/marketrequestmarkettradinginfo/schemas/form";

const {
  formField: {
    MarketName,
    ExchangeSymbol,
    Exchange,
    Currency,
    SourceTicker,
    ExternalRequester,
    ExternalEmail,
    Comments,
    TradingVolume,
    TradingStartDate,
    MarketHistoryYears,
    MarketHistoryMonths,
    Source,
    MarketCap,
  },
} = checkout;

const initialValues = {
  [MarketName.name]: "",
  [ExchangeSymbol.name]: "",
  [Exchange.name]: "",
  [Currency.name]: "",
  [SourceTicker.name]: "",
  [ExternalRequester.name]: false,
  [ExternalEmail.name]: "",
  [Comments.name]: "",
  [TradingVolume]: "",
  [TradingStartDate]: "",
  [MarketHistoryYears]: "",
  [MarketHistoryMonths]: "",
  [Source]: "",
  [MarketCap]: "",
};

export default initialValues;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCardMarketSubscription from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketSubscription";

// Dashboard components
import GmwCommentsChart from "examples/Charts/BarCharts/VerticalBarChartII/GmwCommentsChart";
import GmwCommentsDetails from "layouts/gmwcomments/components/GmwCommentsDetails";
import GmwCommentsErrorChart from "examples/Charts/BarCharts/VerticalBarChartII/GmwCommentsErrorChart";
import GmwCommentsErrorDetails from "layouts/gmwcomments/components/GmwCommentsErrorDetails";

// Data

function Gmwcomments() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [chartData, setChartData] = useState({});
  const [gmwComments, setGmwComments] = useState({});
  const [index, setIndex] = useState(0);
  const [indexDescription, setIndexDescription] = useState({});
  const [chartDataError, setChartDataError] = useState({});
  const [gmwCommentsError, setGmwCommentsError] = useState({});
  const [indexError, setIndexError] = useState(0);
  const [indexDescriptionError, setIndexDescriptionError] = useState({});

  const [isLoading, setisLoading] = useState(true);

  const chart = (_gmwcomments) => {
    // if (_gmwcomments === {}) return;
    // eslint-disable-next-line no-unused-vars
    const { marketsDaily, marketsWeekly, marketsMonthly, marketsQuarterly, marketsYearly } =
      _gmwcomments;
    setChartData({
      labels: ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"],
      datasets: [
        {
          label: "Timeframe < 13",
          color: "dark",
          data: [
            marketsDaily.length,
            marketsWeekly.length,
            marketsMonthly.length,
            marketsQuarterly.length,
            marketsYearly.length,
          ],
        },
      ],
    });
  };

  const chartError = (_gmwcommentsError) => {
    // if (_gmwcommentsError === {}) return;

    const { marketsDaily, marketsWeekly, marketsMonthly, marketsQuarterly, marketsYearly } =
      _gmwcommentsError;

    setChartDataError({
      labels: ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"],
      datasets: [
        {
          label: "Timeframe >= 13",
          color: "dark",
          data: [
            marketsDaily.length,
            marketsWeekly.length,
            marketsMonthly.length,
            marketsQuarterly.length,
            marketsYearly.length,
          ],
        },
      ],
    });
  };

  const getGmw = async () => {
    const result = await http({
      path: `/globalmarketwatch/latest/gmw`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const [loadedGmw] = await Promise.all([getGmw()]);
          const {
            marketsDaily,
            marketsWeekly,
            marketsMonthly,
            marketsQuarterly,
            marketsYearly,
            marketsDailyGTE,
            marketsWeeklyGTE,
            marketsMonthlyGTE,
            marketsQuarterlyGTE,
            marketsYearlyGTE,
          } = loadedGmw;
          setGmwComments({
            marketsDaily,
            marketsWeekly,
            marketsMonthly,
            marketsQuarterly,
            marketsYearly,
          });
          setGmwCommentsError({
            marketsDaily: marketsDailyGTE,
            marketsWeekly: marketsWeeklyGTE,
            marketsMonthly: marketsMonthlyGTE,
            marketsQuarterly: marketsQuarterlyGTE,
            marketsYearly: marketsYearlyGTE,
          });
          // const { marketsDaily: totalMarketsDaily } = marketsDaily;
          // const { marketsDaily } = loadedGmwcommentsError;
          setIndexDescription({
            description: "Daily",
            value: marketsDaily ? marketsDaily.length : 0,
          });
          setIndexDescriptionError({
            description: "Daily",
            value: marketsDailyGTE ? marketsDailyGTE.length : 0,
          });
          chart({
            marketsDaily,
            marketsWeekly,
            marketsMonthly,
            marketsQuarterly,
            marketsYearly,
          });
          chartError({
            marketsDaily: marketsDailyGTE,
            marketsWeekly: marketsWeeklyGTE,
            marketsMonthly: marketsMonthlyGTE,
            marketsQuarterly: marketsQuarterlyGTE,
            marketsYearly: marketsYearlyGTE,
          });
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  const handleChangeIndex = (event, elements) => {
    if (elements[0]) {
      setIndex(elements[0].index);
      let description = "";
      switch (elements[0].index) {
        case 0:
          description = "Daily";
          break;
        case 1:
          description = "Weekly";
          break;
        case 2:
          description = "Monthly";
          break;
        case 3:
          description = "Quarterly";
          break;
        case 4:
          description = "Yearly";
          break;
        default:
          break;
      }
      setIndexDescription({ description, value: elements[0].element.$context.raw });
      // console.log(elements, elements[0].index, elements[0].element.$context.raw);
    }
  };

  const handleChangeIndexError = (event, elements) => {
    if (elements[0]) {
      setIndexError(elements[0].index);
      let description = "";
      switch (elements[0].index) {
        case 0:
          description = "Daily";
          break;
        case 1:
          description = "Weekly";
          break;
        case 2:
          description = "Monthly";
          break;
        case 3:
          description = "Quarterly";
          break;
        case 4:
          description = "Yearly";
          break;
        default:
          break;
      }
      setIndexDescriptionError({ description, value: elements[0].element.$context.raw });
      // console.log(elements, elements[0].index, elements[0].element.$context.raw);
    }
  };

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={5}>
            <MDBox>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title={`Total Markets With No GMW Comments (${indexDescription.description})`}
                count={indexDescription.value}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={1}>
              <GmwCommentsChart
                icon={{ color: "dark", component: "leaderboard" }}
                title="Markets Without GMW Comments History"
                description=""
                chart={chartData}
                handleChangeIndex={handleChangeIndex}
              />
            </MDBox>
            <MDBox>
              <GmwCommentsDetails
                dataTable1={gmwComments}
                index={index}
                setIndexDescription={setIndexDescription}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={5}>
            <MDBox>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title={`Markets With GMW Comments Error (${indexDescriptionError.description})`}
                count={indexDescriptionError.value}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={1}>
              <GmwCommentsErrorChart
                icon={{ color: "dark", component: "leaderboard" }}
                title="Markets With GMW Comments Error"
                description=""
                chart={chartDataError}
                handleChangeIndex={handleChangeIndexError}
              />
            </MDBox>
            <MDBox>
              <GmwCommentsErrorDetails
                dataTable1={gmwCommentsError}
                index={indexError}
                setIndexDescriptionError={setIndexDescriptionError}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Gmwcomments;

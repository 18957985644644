// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import marketsTableData from "layouts/tables/data/marketsTableData";
import DataTableRowSelectUMDS from "examples/Tables/DataTableRowSelectUMDS";

function UpdateMarketDataStore() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const getMarkets = async () => {
    const result = await http({
      path: `/markets`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [markets] = useQueries({
    queries: [
      {
        queryKey: ["markets"],
        queryFn: getMarkets,
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const { columns2 } = marketsTableData();

  if (markets.isLoading) return <MDLoader />;
  if (markets.isError) return <div>Error at loading markets</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTableRowSelectUMDS
        table={{ columns: columns2, rows: markets?.data || [] }}
        isSorted
        entriesPerPage
        showTotalEntries
        noEndBorder
        canSearch
      />
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateMarketDataStore;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns3: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink
            to={`/failed-markets/${row.original.PEITicker}/timeframe/1`}
            text={row.original.Name}
          />
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "left", maxWidth: 65, minWidth: 65 },
      {
        Header: "Sector",
        accessor: "Sector.DisplayName",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      { Header: "Region", accessor: "RunRegion", align: "left", maxWidth: 60, minWidth: 60 },
      {
        Header: "Overview Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Market_MarketPriceData.Date),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Last Update Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Market_MarketPriceData.LastUpdateDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Reason for Failure",
        // accessor: "MessageOrReasonForFailure",
        accessor: (d) => (d.Comments ? d.Comments.replace(" 00:00:00", "") : ""),
        align: "left",
        minWidth: 220,
      },
      {
        Header: "",
        accessor: "Edit",
        align: "left",
        minWidth: 50,
        Cell: ({ row }) => (
          <MDLink to={`/failed-markets/edit/${row.original.PEITicker}/timeframe/1`} text="Edit" />
        ),
      },
    ],
  };
}

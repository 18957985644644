/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import { Icon } from "@mui/material";
import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  const customFilterFunctionRegion = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      if (filterValue === "Americas")
        return rows.filter((row) =>
          ["US1", "US2", "US3", "US4", "US5"].includes(row.original.RunRegion)
        );
      return rows.filter((row) => filterValue.includes(row.original.RunRegion));
    }
    return rows;
  };

  const customFilterFunctionSource = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      return rows.filter((row) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!row.original.Source2) return false;
        // eslint-disable-next-line no-underscore-dangle
        return filterValue.includes(row.original.Source2);
      });
    }
    return rows;
  };

  const customFilterFunctionTimeFrame = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      return rows.filter((row) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!row.original.TimeFrame) return false;
        // eslint-disable-next-line no-underscore-dangle
        return filterValue.includes(row.original.TimeFrame);
      });
    }
    return rows;
  };

  // const handleChangeSelect = (e) => {
  //   console.log(e);
  // };

  return {
    columns: [
      {
        Header: "Market Name",
        accessor: "Name",
        Filter: "",
        align: "left",
        maxWidth: 310,
        minWidth: 310,
        Cell: ({ row }) => (
          <MDLink
            to={`/failed-markets/${row.original.PEITicker}/timeframe/${row.original.TimeFrame}`}
            text={row.original.Name}
          />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Sector",
        accessor: (d) => d.Sector.Name || d.Sector || "",
        Filter: "",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "Ticker",
        accessor: "DataTicker",
        Filter: "",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Cell: ({ row }) => {
          if (row.original.Source === "REU") {
            return row.original.ReuTicker || "";
          }
          if (row.original.Source === "SPX") {
            return row.original.SpxTicker || "";
          }
          return row.original.DataTicker || "";
        },
      },
      {
        Header: "Source",
        accessor: "Source",
        Filter: "",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        filter: customFilterFunctionSource,
      },
      {
        Header: "Region",
        accessor: "RunRegion",
        Filter: "",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        filter: customFilterFunctionRegion,
      },
      {
        Header: "Category",
        accessor: "Category",
        Filter: "",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "PrimarySubCategory",
        accessor: "PrimarySubCategory",
        Filter: "",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "Overview Date",
        accessor: "Date",
        Filter: "",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.Date),
      },
      {
        Header: "Last Update Date",
        accessor: "LastUpdateDate",
        Filter: "",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.LastUpdateDate),
      },
      {
        Header: "TimeFrame",
        accessor: "TimeFrame",
        Filter: "",
        align: "left",
        maxWidth: 65,
        minWidth: 65,
        filter: customFilterFunctionTimeFrame,
        Cell: ({ row }) => {
          let timeFrameDisplay = "";
          switch (row.original.TimeFrame) {
            case 1:
              timeFrameDisplay = "Daily";
              break;
            case 2:
              timeFrameDisplay = "Weekly";
              break;
            case 3:
              timeFrameDisplay = "Monthly";
              break;
            case 4:
              timeFrameDisplay = "Quarterly";
              break;
            case 5:
              timeFrameDisplay = "Yearly";
              break;
            default:
              timeFrameDisplay = "";
              break;
          }
          return timeFrameDisplay;
        },
      },
      // {
      //   Header: "Open",
      //   accessor: "Open",
      //   Filter: "",
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      // },
      // {
      //   Header: "High",
      //   accessor: "High",
      //   Filter: "",
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      // },
      // {
      //   Header: "Low",
      //   accessor: "Low",
      //   Filter: "",
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      // },
      // {
      //   Header: "Close",
      //   accessor: "Close",
      //   Filter: "",
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      // },
      {
        Header: "OHLC == 0",
        accessor: "zeroValueCount",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Details",
        accessor: "Details",
        Filter: "",
        align: "center",
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ row }) => (
          <MDTypography variant="h4" color="text">
            <Link to={`/failed-markets-zero-value/${row.original.PEITicker}`}>
              <Icon>rule</Icon>
            </Link>
          </MDTypography>
        ),
      },
      {
        Header: "Actions",
        accessor: "Edit",
        Filter: "",
        align: "center",
        maxWidth: 50,
        minWidth: 50,
        Cell: ({ row }) => (
          <MDLink
            to={`/failed-markets/edit-zero-value/${row.original.PEITicker}/timeframe/${row.original.TimeFrame}`}
            text="Edit"
          />
        ),
      },
    ],
  };
}

const form = {
  formId: "new-user-form",
  formField: {
    marketName: {
      name: "marketName",
      label: "Market Name",
      type: "text",
      errorMsg: "Market Name is required.",
    },
    exchangeSymbol: {
      name: "exchangeSymbol",
      label: "Exchange Symbol",
      type: "text",
      errorMsg: "Exchange Symbol is required.",
      invalidMsg: "Exchange Symbol should be less than 10 characters.",
    },
    exchange: {
      name: "exchange",
      label: "Exchange",
      type: "text",
      errorMsg: "Exchange is required.",
    },
    currency: {
      name: "currency",
      label: "Currency",
      type: "text",
    },
    sourceTicker: {
      name: "sourceTicker",
      label: "Source Ticker",
      type: "text",
      // errorMsg: "Source Ticker should not be less th",
      // invalidMsg: "Your email address is invalid",
    },
    externalRequester: {
      name: "externalRequester",
      label: "External Request",
      type: "text",
    },
    externalEmail: {
      name: "externalEmail",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    comments: {
      name: "comments",
      label: "Comments",
    },
    commentsArrayData: {
      name: "commentsArrayData",
      label: "commentsArrayData",
    },
    sector: {
      name: "sector",
      label: "Sector",
    },
    category: {
      name: "category",
      label: "Category",
    },
    country: {
      name: "country",
      label: "Country",
    },
  },
};

export default form;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  const customFilterFunctionRegion = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      const value = filterValue[0];
      switch (value) {
        case "Asia":
          return rows.filter((row) => ["Asia"].includes(row.original.RunRegion));
        case "Europe":
          return rows.filter((row) => ["Europe"].includes(row.original.RunRegion));
        case "Americas":
          return rows.filter((row) => ["US2", "US3"].includes(row.original.RunRegion));
        case "Economics":
          return rows.filter((row) => ["US4"].includes(row.original.RunRegion));
        case "Bonds":
          return rows.filter((row) => ["US1", "US5"].includes(row.original.RunRegion));
        default:
          return rows;
      }
    }
    return rows;
  };

  const customFilterFunctionSource = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      return rows.filter((row) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!row.original.Source) return false;
        // eslint-disable-next-line no-underscore-dangle
        return filterValue.includes(row.original.Source);
      });
    }
    return rows;
  };

  const customFilterFunctionTimeFrame = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      return rows.filter((row) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!row.original.Market_MarketPriceData.TimeFrame) return false;
        // eslint-disable-next-line no-underscore-dangle
        return filterValue.includes(row.original.Market_MarketPriceData.TimeFrame);
      });
    }
    return rows;
  };

  return {
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink
            to={`/failed-markets/${row.original.PEITicker}/timeframe/${row.original.Market_MarketPriceData.TimeFrame}`}
            text={row.original.Name}
          />
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "left", maxWidth: 85, minWidth: 85 },
      {
        Header: "Sector",
        accessor: (d) => d?.Sector?.DisplayName || d?.Sector || "",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "Ticker",
        accessor: "DataTicker",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Cell: ({ row }) => {
          if (row.original.Source === "REU") {
            return row.original.ReuTicker || "";
          }
          if (row.original.Source === "SPX") {
            return row.original.SpxTicker || "";
          }
          return row.original.DataTicker || "";
        },
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        filter: customFilterFunctionSource,
      },
      // TODO, filter for Source instead of Source2
      // {
      //   Header: "Source",
      //   accessor: "Source2",
      //   align: "left",
      //   maxWidth: 70,
      //   minWidth: 70,
      //   filter: customFilterFunctionSource,
      // },
      {
        Header: "Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        filter: customFilterFunctionRegion,
      },
      {
        Header: "Overview Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Market_MarketPriceData.Date),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Last Update Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.Market_MarketPriceData.LastUpdateDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "TimeFrame",
        accessor: "TimeFrame",
        align: "left",
        maxWidth: 55,
        minWidth: 55,
        filter: customFilterFunctionTimeFrame,
        Cell: ({ row }) => {
          let timeFrameDisplay = "";
          switch (row.original.Market_MarketPriceData.TimeFrame) {
            case 1:
              timeFrameDisplay = "Daily";
              break;
            case 2:
              timeFrameDisplay = "Weekly";
              break;
            case 3:
              timeFrameDisplay = "Monthly";
              break;
            case 4:
              timeFrameDisplay = "Quarterly";
              break;
            case 5:
              timeFrameDisplay = "Yearly";
              break;
            default:
              timeFrameDisplay = "";
              break;
          }
          return timeFrameDisplay;
        },
      },
      {
        Header: "Reason for Failure",
        accessor: (d) => (d.Comments ? d.Comments.replace(" 00:00:00", "") : ""),
        align: "left",
        minWidth: 220,
      },
      {
        Header: "",
        accessor: "Edit",
        align: "center",
        maxWidth: 30,
        minWidth: 30,
        Cell: ({ row }) => (
          <MDLink
            to={`/failed-markets/edit/${row.original.PEITicker}/timeframe/${row.original.Market_MarketPriceData.TimeFrame}`}
            text="Edit"
          />
        ),
      },
    ],
  };
}

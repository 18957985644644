import checkout from "layouts/marketrequestmanager/schemas/form";

const {
  formField: {
    Status,
    PrimarySource,
    SecondarySource,
    MarketHistoryYears,
    MarketHistoryMonths,
    SourceTicker,
    IsTradingVolumeEnough,
    MarketCap,
    NotifyMe,
    NAV,
    MarketName,
    GeneralTicker,
    StockExchange,
    Currency,
    Sector,
    Country,
    TotalRequests,
    Comments,
    Requesters,
  },
} = checkout;

const initialValues = {
  [Status.name]: "",
  [PrimarySource.name]: "",
  [SecondarySource.name]: "",
  [MarketHistoryYears.name]: "",
  [MarketHistoryMonths.name]: "",
  [SourceTicker.name]: "",
  [IsTradingVolumeEnough.name]: "",
  [MarketCap.name]: "",
  [NotifyMe.name]: "",
  [NAV.name]: "",
  [MarketName.name]: "",
  [GeneralTicker.name]: "",
  [StockExchange.name]: "",
  [Currency.name]: "",
  [Sector.name]: "",
  [Country.name]: "",
  [TotalRequests.name]: "",
  [Comments.name]: "",
  [Requesters.name]: "",
};

export default initialValues;

export const getDescription = (inStatusId) => {
  let description = "";
  switch (inStatusId) {
    case 1:
      description = "Submitted";
      break;
    case 2:
      description = "Pending for Approval";
      break;
    case 3:
      description = "Approved";
      break;
    case 4:
      description = "Completed";
      break;
    case 5:
      description = "Rejected";
      break;
    case 6:
      description = "On Hold";
      break;
    default:
      description = "";
  }
  return description;
};

export const getColor = (inStatusId) => {
  let color = "dark";
  switch (inStatusId) {
    // case "Approved":
    case 3:
      color = "success";
      break;
    // case "Completed":
    case 4:
      color = "success";
      break;
    // case "Rejected":
    case 5:
      color = "error";
      break;
    default:
      color = "dark";
  }
  return color;
};

export const calculateNextStatus = (values, type) => {
  let nextStatus = { ...values };
  switch (type) {
    // no need to change status in this case
    case "UpdateMTI_1":
      if (!values.OnHold) {
        nextStatus = {
          ...nextStatus,
          // Status: "Pending for Approval",
          StatusId: 2,
          ReviewDate: new Date(),
        };
      }
      break;
    case "UpdateMTI_2":
      if (!values.OnHold) {
        if (values.StatusId === 6) {
          switch (values.NextStatusId) {
            case 2:
              nextStatus = {
                ApprovalDate: new Date(),
                StatusId: 3,
              };
              break;
            case 3:
              nextStatus = {
                CompletionDate: new Date(),
                StatusId: 4,
              };
              break;
            default:
              break;
          }
        } else {
          switch (values.StatusId) {
            case 2:
              nextStatus = { ApprovalDate: new Date(), StatusId: 3 };
              break;
            case 3:
              nextStatus = { CompletionDate: new Date(), StatusId: 4 };
              break;
            default:
              break;
          }
        }
      } else if (values.StatusId !== 6) {
        switch (values.StatusId) {
          case 2:
            nextStatus = { NextStatusId: 3, StatusId: 6 };
            break;
          case 3:
            nextStatus = { NextStatusId: 4, StatusId: 6 };
            break;
          default:
            break;
        }
      }
      break;
    case "UpdateMTI_3":
      if (!values.OnHold) {
        if (values.StatusId === 1) {
          nextStatus = {
            ...nextStatus,
            // Status: "Pending for Approval",
            StatusId: 2,
            ReviewDate: new Date(),
          };
        }
        if (values.StatusId === 6) {
          nextStatus = {
            ...nextStatus,
            StatusId: 2,
            ReviewDate: new Date(),
          };
        }
      } else if (values.StatusId !== 6) {
        nextStatus = {
          ...nextStatus,
          NextStatusId: 2,
          StatusId: 6,
        };
      }
      break;
    default:
      break;
  }
  return nextStatus;
};

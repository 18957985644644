// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// NewMarketRequest page components
import FormField from "components/FormField";

function TicketForm({ formData }) {
  const { formFieldTicket, values, errors, touched } = formData;
  const { title, description } = formFieldTicket;
  const { title: titleV, description: descriptionV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={title.type}
              label={title.label}
              name={title.name}
              value={titleV}
              placeholder={title.placeholder}
              error={errors.title && touched.title}
              success={titleV.length > 0 && !errors.title}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for TicketForm
TicketForm.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TicketForm;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// This is a custom filter UI for selecting
// a unique option from a list

// react
import MDDatePickerMDY from "components/MDDatePickerMDY";
import { useMemo, useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { MultiSelect } from "react-multi-select-component";
import { getDescription } from "utils/marketRequestUtil";
import MDButton from "components/MDButton";

const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsS = new Set();
    preFilteredRows.forEach((row) => {
      optionsS.add(row.values[id]);
    });
    return [...optionsS.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Autocomplete
      options={options.filter(Boolean)}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select an option"
          variant="standard"
          style={{
            color: "#a0a4b6",
            borderRadius: "4px",
            borderColor: "#a0a4b6",
          }}
        />
      )}
      value={filterValue}
      onChange={(_, value) => {
        setFilter(value || undefined);
      }}
    />
  );
};

const SelectColumnFilterSubscriptionOverview = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsS = new Set();
    preFilteredRows.forEach((row) => {
      optionsS.add(row.values[id]);
    });
    return [...optionsS.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Autocomplete
      options={["Select All", ...options.filter(Boolean).sort()]}
      noOptionsText="No Results"
      // freeSolo
      getOptionLabel={(option) => option}
      // disableClearable
      // forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select"
          variant="outlined"
          size="small"
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: "12px", // Adjust font size
              color: "#b0b4c6", // Adjust input text color
              textTransform: "none",
              paddingRight: "38px",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: "12px", // Adjust label font size
              color: "#b0b4c6", // Adjust label color
              textTransform: "none",
            },
          }}
          sx={{
            "& .MuiAutocomplete-clearIndicator": {
              fontSize: "12px", // Adjust icon size
              color: "#b0b4c6",
              padding: "1px", // Adjust padding to save space
              // margin: "0 4px", // Adjust margin to save space
            },
            "& .MuiAutocomplete-popupIndicator": {
              fontSize: "12px", // Adjust icon size
              color: "#b0b4c6",
              padding: "1px", // Adjust padding to save space
              // margin: "0 4px", // Adjust margin to save space
            },
            // width: "140px", // Adjust width to fit the text
            color: "#a0a4b6",
            borderRadius: "8px",
            borderColor: "#a0a4b6",
          }}
          style={{
            color: "#a0a4b6",
            borderRadius: "8px",
            borderColor: "#a0a4b6",
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ fontSize: "10px" }}>
          {option}
        </li>
      )}
      value={filterValue}
      onChange={(_, value) => {
        if (value === "Select All") setFilter(undefined);
        else setFilter(value || undefined);
      }}
    />
  );
};

const SelectColumnFilterMembershipType = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const MEMBERSHIP_TYPE = "MembershipType";
  const ALL_MEMBERSHIPS = "All Memberships";

  const [selectedValue, setSelectedValue] = useState(filterValue || ALL_MEMBERSHIPS);

  useEffect(() => {
    setSelectedValue(filterValue || ALL_MEMBERSHIPS);
  }, [filterValue]);

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsS = new Set();
    preFilteredRows.forEach((row) => {
      optionsS.add(row.values[id]);
    });
    if (id === MEMBERSHIP_TYPE) return [ALL_MEMBERSHIPS, ...optionsS.values()];
    return [...optionsS.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Autocomplete
      options={options.filter(Boolean)}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select an option"
          variant="standard"
          style={{
            color: "#a0a4b6",
            borderRadius: "4px",
            borderColor: "#a0a4b6",
          }}
        />
      )}
      value={selectedValue}
      onChange={(_, value) => {
        if (id === MEMBERSHIP_TYPE && value === ALL_MEMBERSHIPS) {
          setFilter(undefined);
        } else setFilter(value);
      }}
    />
  );
};

// eslint-disable-next-line no-unused-vars
const MultiSelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsS = new Set();
    preFilteredRows.forEach((row) => {
      optionsS.add(row.values[id]);
    });
    return [...optionsS.values()];
  }, [id, preFilteredRows, filterValue]);

  // Render a multi-select box
  return (
    <MDBox mt={2}>
      <MultiSelect
        options={options.filter(Boolean).map((el) => ({
          label: el,
          value: el,
        }))}
        value={filterValue || []}
        onChange={(values) => {
          setFilter(values.length ? values : []);
        }}
        labelledBy="Select"
        label=""
      />
    </MDBox>
  );
};

const SelectColumnFilterDescription = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const optionsS = new Set();
    preFilteredRows.forEach((row) => {
      optionsS.add(row.values[id]);
    });
    return [...optionsS.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Autocomplete
      // options={options.filter(Boolean).sort((a, b) => a - b)}
      options={options.sort((a, b) => a - b)}
      // getOptionLabel={(option) => option}
      getOptionLabel={(option) => `${option} - ${getDescription(option)}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select an option"
          variant="standard"
          style={{
            color: "#a0a4b6",
            borderRadius: "4px",
            borderColor: "#a0a4b6",
          }}
        />
      )}
      value={filterValue}
      onChange={(_, value) => {
        setFilter(value || undefined);
      }}
    />
  );
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
const NumberRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = useMemo(() => {
    // eslint-disable-next-line no-shadow
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    // eslint-disable-next-line no-shadow
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  const myStyle = {
    width: "5rem",
  };

  return (
    // <MDBox sx={{ width: "100%" }}>
    <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
      <MDInput
        value={filterValue[0] || 0}
        size="small"
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]]);
        }}
        placeholder={`Min (${min})`}
        style={{
          marginRight: "0.2rem",
          ...myStyle,
        }}
      />
      <MDTypography variant="caption">{" to "}</MDTypography>
      <MDInput
        value={filterValue[1] || 0}
        size="small"
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined]);
        }}
        placeholder={`Max (${max})`}
        style={{
          marginLeft: "0.2rem",
          ...myStyle,
        }}
      />
    </MDBox>
  );
};

const multiSelectFilterFn = (rows, id, filterValues) => {
  const arrayFilterValues = filterValues.map((el) => el.value);
  if (arrayFilterValues.length > 0) {
    return rows.filter((row) => arrayFilterValues.includes(row.original.Exchange));
  }
  return rows;
};

const multiSelectFilterFnGeneric = (rows, id, filterValues, propertyName) => {
  const arrayFilterValues = filterValues.map((el) => el.value);
  if (arrayFilterValues.length > 0) {
    return rows.filter((row) => arrayFilterValues.includes(row.original[propertyName]));
  }
  return rows;
};

const multiSelectFilterFnRegion = (rows, id, filterValues) =>
  multiSelectFilterFnGeneric(rows, id, filterValues, "Region");

const multiSelectFilterFnMembershipType = (rows, id, filterValues) =>
  multiSelectFilterFnGeneric(rows, id, filterValues, "MembershipType");

const dateBetweenFilterFn = (rows, id, filterValues) => {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  // add a day
  if (ed) {
    ed.setDate(ed.getDate() + 1);
  }
  if (ed || sd) {
    return rows.filter((r) => {
      // format data
      const dateAndHour = r.values[id].split(" ");
      const [year, month, day] = dateAndHour[0].split("-");
      const date = [month, day, year].join("/");
      const hour = dateAndHour[1];
      const formattedData = `${date} ${hour}`;

      const cellDate = new Date(formattedData);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      }
      if (sd) {
        return cellDate >= sd;
      }
      return cellDate <= ed;
    });
  }
  return rows;
};

const DateRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  // eslint-disable-next-line no-unused-vars
  const [min, max] = useMemo(() => {
    // eslint-disable-next-line no-shadow
    let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);
    // eslint-disable-next-line no-shadow
    let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);

    preFilteredRows.forEach((row) => {
      const rowDate = new Date(row.values[id]);

      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
      <MDDatePickerMDY
        input={{ placeholder: "start date", size: "small", sx: { width: "5.1rem" } }}
        onChange={(e, value) => {
          setFilter((old = []) => [value || undefined, old[1]]);
        }}
        value={filterValue[0] || ""}
      />
      <MDTypography variant="caption"> to </MDTypography>
      <MDDatePickerMDY
        input={{ placeholder: "end date", size: "small", sx: { width: "5.1rem" } }}
        onChange={(e, value) => {
          setFilter((old = []) => [
            old[0],
            value ? value.concat("T23:59:59.999Z").slice(0, 10) : undefined,
          ]);
        }}
        value={filterValue[1]?.slice(0, 10) || ""}
      />
      {` `}
      <MDButton
        size="small"
        color="dark"
        iconOnly
        disabled={filterValue[0] === undefined || filterValue[1] === undefined}
        onClick={() => {
          setFilter([undefined, undefined]);
        }}
      >
        <Icon>close</Icon>
      </MDButton>
    </MDBox>
  );
};

export {
  SelectColumnFilter,
  SelectColumnFilterMembershipType,
  SelectColumnFilterSubscriptionOverview,
  MultiSelectColumnFilter,
  NumberRangeColumnFilter,
  SelectColumnFilterDescription,
  dateBetweenFilterFn,
  multiSelectFilterFn,
  multiSelectFilterFnRegion,
  multiSelectFilterFnMembershipType,
  DateRangeColumnFilter,
};

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// MarketSubscriptionByMarket components
import DataTableMarketSubscriptionByMarket from "components/Tables/DataTableMarketSubscriptionByMarket";

// Data
import marketTableData from "layouts/tables/data/marketTableData";

function MarketSubscriptionByMarket() {
  const navigate = useNavigate();
  const { id, timeframeId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [market, setMarket] = useState([]);

  const [isLoading, setisLoading] = useState(true);

  const getMarket = async () => {
    const result = await http({
      path: `/failedmarkets/${id}/timeframe/${timeframeId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const loadedMarket = await getMarket();
          setMarket([loadedMarket]);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  const { columns3 } = marketTableData();

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="api"
                title=""
                count={market[0].PEITicker}
                percentage={{
                  color: "success",
                  // amount: "+0%",
                  label: market[0].MarketName,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Failed Market
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableMarketSubscriptionByMarket
                  table={{
                    columns: columns3,
                    rows: market,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketSubscriptionByMarket;

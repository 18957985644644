export default function data() {
  return {
    currencies: [
      { cc: "0", symbol: "Unknown", name: "Unknown" },
      { cc: "1", symbol: "USDollar", name: "US Dollar" },
      { cc: "2", symbol: "MalaysianRinggit", name: "Malaysian Ringgit" },
      { cc: "3", symbol: "Yen", name: "Yen" },
      { cc: "4", symbol: "NewZealandDollar", name: "New Zealand Dollar" },
      { cc: "5", symbol: "AustralianDollar", name: "Australian Dollar" },
      { cc: "6", symbol: "BritishPound", name: "British Pound" },
      { cc: "7", symbol: "SwissFranc", name: "Swiss Franc" },
      { cc: "8", symbol: "Euro", name: "Euro" },
      { cc: "9", symbol: "CanadianDollar", name: "Canadian Dollar" },
      { cc: "10", symbol: "Rubles", name: "Rubles" },
      { cc: "11", symbol: "BrazilianReal", name: "Brazilian Real" },
      { cc: "12", symbol: "PhilippinePeso", name: "Philippine Peso" },
      { cc: "13", symbol: "QatariRiyal", name: "Qatari Riyal" },
      { cc: "14", symbol: "ChineseYuan", name: "Chinese Yuan" },
      { cc: "15", symbol: "SingaporeDollar", name: "Singapore Dollar" },
      { cc: "16", symbol: "NewTaiwanDollar", name: "New Taiwan Dollar" },
      { cc: "17", symbol: "ArgentinePeso", name: "Argentine Peso" },
      { cc: "18", symbol: "ChileanPeso", name: "Chilean Peso" },
      { cc: "19", symbol: "CzechKoruna", name: "Czech Koruna" },
      { cc: "20", symbol: "EgyptPound", name: "Egypt Pound" },
      { cc: "21", symbol: "HongKongDollar", name: "Hong Kong Dollar" },
      { cc: "22", symbol: "IndianRupee", name: "Indian Rupee" },
      { cc: "23", symbol: "IndonesianRupiah", name: "Indonesian Rupiah" },
      { cc: "24", symbol: "IsraeliShekel", name: "Israeli Shekel" },
      { cc: "25", symbol: "KoreanWon", name: "Korean Won" },
      { cc: "26", symbol: "MexicoPeso", name: "Mexico Peso" },
      { cc: "27", symbol: "PeruNuevoSol", name: "Peru Nuevo Sol" },
      { cc: "28", symbol: "PolishZloty", name: "Polish Zloty" },
      { cc: "29", symbol: "SaudiArabianRiyal", name: "Saudi Arabian Riyal" },
      { cc: "30", symbol: "TurkishLira", name: "Turkish Lira" },
      { cc: "31", symbol: "DenmarkKrone", name: "Denmark Krone" },
      { cc: "32", symbol: "NorwayKrone", name: "Norway Krone" },
      { cc: "33", symbol: "SwedishKrona", name: "Swedish Krone" },
      { cc: "34", symbol: "ThailandBaht", name: "Thailand Baht" },
      { cc: "35", symbol: "ColumbiaPeso", name: "Columbia Peso" },
      { cc: "36", symbol: "HungaryForint", name: "Hungary Forint" },
      { cc: "37", symbol: "KuwaitDinar", name: "Kuwait Dinar" },
      { cc: "38", symbol: "SouthAfricanRand", name: "South African Rand" },
      { cc: "39", symbol: "UkrainianHryvnia", name: "Ukrainian Hryvnia" },
      { cc: "40", symbol: "PakistaniRupee", name: "Pakistani Rupee" },
      { cc: "41", symbol: "TanzanianShilling", name: "Tanzanian Shilling" },
      { cc: "42", symbol: "KenyanShilling", name: "Kenyan Shilling" },
      { cc: "43", symbol: "TunisianDinar", name: "Tunisian Dinar" },
      { cc: "44", symbol: "BulgarianLev", name: "Bulgarian Lev" },
      { cc: "45", symbol: "VietnameseDong", name: "Vietnamese Dong" },
      { cc: "46", symbol: "RomanianLeu", name: "Romanian Leu" },
      { cc: "47", symbol: "LebanesePound", name: "Lebanese Pound" },
      { cc: "48", symbol: "IcelandicKrona", name: "Icelandic Krona" },
      { cc: "49", symbol: "BhutanNgultrum", name: "Bhutan Ngultrum" },
      { cc: "50", symbol: "LaosKip", name: "Laos Kip" },
      { cc: "51", symbol: "BangladeshTaka", name: "Bangladesh Taka" },
      { cc: "52", symbol: "SerbianDinar", name: "Serbian Dinar" },
      { cc: "53", symbol: "MoldovanLeu", name: "Moldovan Leu" },
      { cc: "54", symbol: "CostaRicanColon", name: "Costa Rican Colon" },
      { cc: "55", symbol: "Percentage", name: "Percentage (%)" },
      { cc: "56", symbol: "SriLankanRupee", name: "Sri Lankan Rupee" },
      { cc: "57", symbol: "MaldiveIslandRufiy", name: "Maldive Island Rufiy" },
      { cc: "58", symbol: "MauritaniaOuguiyaNew", name: "Mauritania Ouguiya - New" },
      { cc: "59", symbol: "GreekDrachma", name: "Greek Drachma" },
      { cc: "60", symbol: "KazakhstanTenge", name: "Kazakhstan Tenge" },
      { cc: "61", symbol: "NewBelarusRouble", name: "New Belarus Rouble" },
      { cc: "62", symbol: "IraqiDinar", name: "Iraqi Dinar" },
      { cc: "63", symbol: "IranianRial", name: "Iranian Rial" },
      { cc: "64", symbol: "EgyptianPound", name: "Egyptian Pound" },
      { cc: "65", symbol: "JordanianDinar", name: "Jordanian Dinar" },
      { cc: "66", symbol: "AlbanianLEK", name: "Albanian LEK" },
      { cc: "67", symbol: "ArmenianDRAM", name: "Armenian DRAM" },
      { cc: "68", symbol: "BahrainiDinar", name: "Bahraini Dinar" },
      { cc: "69", symbol: "GeorgianLari", name: "Georgian Lari" },
      { cc: "70", symbol: "OmaniRial", name: "Omani Rial" },
      { cc: "71", symbol: "QatariRial", name: "Qatari Rial" },
      { cc: "72", symbol: "VenezuelanBolivarSoberano", name: "Venezuelan Bolivar Soberano" },
      { cc: "73", symbol: "GuyaneseDollar", name: "Guyanese Dollar" },
      { cc: "74", symbol: "SurinameseDollar", name: "Surinamese Dollar" },
      { cc: "75", symbol: "UruguayanPeso", name: "Uruguayan Peso" },
      { cc: "76", symbol: "ParaguayanGuarani", name: "Paraguayan Guarani" },
      { cc: "77", symbol: "MoroccanDirham", name: "Moroccan Dirham" },
      { cc: "78", symbol: "UnitedArabEmiratesDirham", name: "United Arab Emirates Dirham (AED)" },
    ],
    regions: [
      { text: "", value: "" },
      { text: "Americas", value: "Americas" },
      { text: "Europe", value: "Europe" },
      { text: "Asia", value: "Asia" },
      { text: "Global", value: "Global" },
      { text: "Middle East", value: "Middle East" },
      { text: "Africa", value: "Africa" },
      { text: "Australia/Oceania", value: "Australia/Oceania" },
      { text: "ECB", value: "ECB" },
      { text: "AE Index", value: "AE Index" },
      { text: "North America", value: "North America" },
      { text: "South America", value: "South America" },
    ],
  };
}

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDProgress from "components/MDProgress";

// ProgressLineChart configurations
import configs from "examples/Charts/LineCharts/ProgressMultiLineChart/config";

function ProgressMultiLineChart({ color, icon, title, height, chart }) {
  const { data, options, datasets } = configs(
    color,
    chart.labels || [],
    title,
    chart.data || [],
    chart.datasets || []
  );

  return (
    <Card>
      <MDBox display="flex" alignItems="center" pt={2} px={2}>
        <MDBox
          width="3rem"
          height="3rem"
          display="grid"
          justifyContent="center"
          alignItems="center"
          borderRadius="md"
          shadow="md"
          color="white"
          bgColor={color}
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </MDBox>
        <MDBox ml={2} lineHeight={1}>
          {/* <MDTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color="text"
          >
            {title}
          </MDTypography> */}
          {/* {count ? (
            <MDTypography variant="h5" fontWeight="bold">
              {count}
            </MDTypography>
          ) : null} */}
          <MDTypography variant="h6" fontWeight="bold">
            {title}
          </MDTypography>
        </MDBox>
        {/* <MDBox width="25%" ml="auto">
          <MDTypography display="block" variant="caption" fontWeight="medium" color="text">
            {progress}%
          </MDTypography>
          <MDBox mt={0.25}>
            <MDProgress variant="gradient" color={color} value={progress} />
          </MDBox>
        </MDBox> */}
      </MDBox>
      {useMemo(
        () => (
          <MDBox mt={2}>
            <Line
              data={{
                labels: data.labels,
                datasets,
              }}
              options={options}
              style={{ height }}
            />
          </MDBox>
        ),
        [chart, height, color]
      )}
    </Card>
  );
}
// Setting default values for the props of ProgressMultiLineChart
ProgressMultiLineChart.defaultProps = {
  color: "info",
  // count: 0,
  height: "6.25rem",
};

// Typechecking props for the ProgressMultiLineChart
ProgressMultiLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  // count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // progress: PropTypes.number.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default ProgressMultiLineChart;

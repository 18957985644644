/* eslint-disable react/prop-types */
import { useState } from "react";
import { useQueries } from "@tanstack/react-query";
import { useMaterialUIController } from "context";

import http from "services/http";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLoader from "components/MDLoader";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableSpecificSort from "examples/Tables/DataTableSpecificSort";

// Data
import updateMarketPriceData from "layouts/tables/data/updateMarketPriceDataTableData";

function TimeframeButton({ value, label, data, onChange }) {
  return (
    <ToggleButton value={value} aria-label="centered" onChange={onChange}>
      <MDTypography variant="caption" color="text" fontWeight="regular">
        {label}{" "}
        <MDTypography variant="caption" color="error" fontWeight="regular">
          {data && data.length > 0 ? `(${data.length})` : ""}
        </MDTypography>
      </MDTypography>
    </ToggleButton>
  );
}

function UpdateMarketPriceData() {
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const [timeframe, setTimeframe] = useState("D");

  const getMarketPriceDataZeroValue = async () => {
    const result = await http({
      path: `/marketpricedata`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [data] = useQueries({
    queries: [
      {
        queryKey: ["updatemarketpricedata"],
        queryFn: getMarketPriceDataZeroValue,
      },
    ],
  });

  const { columns } = updateMarketPriceData();

  if (data.isLoading) return <MDLoader />;

  if (data.error) {
    return <p>Error: {data.error.message}</p>;
  }

  const { 1: D, 2: W, 3: M, 4: Q, 5: Y } = data.data;

  const dataTimeframe = (inTimeframe) => {
    switch (inTimeframe) {
      case "D":
        return D;
      case "W":
        return W;
      case "M":
        return M;
      case "Q":
        return Q;
      case "Y":
        return Y;
      default:
        return D;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <Card>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h5" fontWeight="bold" color="text" textTransform="uppercase">
                  FILTERS
                </MDTypography>
              </MDBox>
              <MDBox pt={1} px={2}>
                <MDTypography
                  variant="h6"
                  fontWeight="light"
                  color="text"
                  textTransform="uppercase"
                >
                  Select timeframe
                </MDTypography>
              </MDBox>
              <Grid item xs={9} m={3} mt={0} mb={3}>
                <ToggleButtonGroup
                  size="small"
                  color="info"
                  value={timeframe}
                  exclusive
                  onChange={(e, newTimeframe) => setTimeframe(newTimeframe)}
                  aria-label="text alignment"
                >
                  <TimeframeButton value="D" label="Daily" data={D} />
                  <TimeframeButton value="W" label="Weekly" data={W} />
                  <TimeframeButton value="M" label="Monthly" data={M} />
                  <TimeframeButton value="Q" label="Quarterly" data={Q} />
                  <TimeframeButton value="Y" label="Yearly" data={Y} />
                </ToggleButtonGroup>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data With OHLC Values As Zero
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableSpecificSort
                  table={{ columns, rows: dataTimeframe(timeframe) }}
                  isSorted
                  showTotalEntries
                  noEndBorder={false}
                  entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
                  canSearch
                  pagination={{ variant: "gradient", color: "info" }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateMarketPriceData;

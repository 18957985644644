/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { matchSorter } from "match-sorter";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
// import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import { Card, Grid, Box } from "@mui/material";
import PieChartSearch from "examples/Charts/PieChartSearch";
import { themeColors } from "utils/productUtil";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import MDTableFooter from "components/MDTableFooter";
import FilterGridItem from "./components/FilterGridItem";

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <MDInput
      size="small"
      sx={{ width: "10rem" }}
      value={filterValue || undefined}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

function DataTableRowSelectMarkets({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  marketInfoCharts,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  const handleChangePagination = (event, value) => {
    handleInputPagination({ target: { value } });
    handleInputPaginationValue({ target: { value } });
  };

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <>
      <MDBox my={0}>
        <Grid container spacing={3}>
          <Grid item xl={8} xxl={5}>
            <MDBox mb={3}>
              {marketInfoCharts ? (
                <PieChartSearch
                  ml={20}
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Primary Source Profile"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: marketInfoCharts.map((r) => r._id),
                    datasets: {
                      label: "Projects",
                      backgroundColors: themeColors,
                      data: marketInfoCharts.map((r) => r.count),
                    },
                  }}
                  categoryLabel="markets"
                />
              ) : (
                <div>No records found</div>
              )}
            </MDBox>
          </Grid>
          <Grid item xl={4} xxl={3}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">Filters</MDTypography>
                <MDBox>
                  <Grid container spacing={3} pt={2}>
                    <FilterGridItem
                      label="Region"
                      header={headerGroups}
                      columnKey="Region"
                      length={8}
                    />
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3} pt={2}>
                    <FilterGridItem
                      label="Category"
                      header={headerGroups}
                      columnKey="Category"
                      length={8}
                    />
                  </Grid>
                </MDBox>
                <MDBox>
                  <Grid container spacing={3} pt={2}>
                    <FilterGridItem
                      label="Source"
                      header={headerGroups}
                      columnKey="Source"
                      length={8}
                    />
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Markets
                  </MDTypography>
                </MDBox>
                <Box sx={{ width: "100%" }}>
                  <MDBox pt={2}>
                    <TableContainer sx={{ boxShadow: "none" }}>
                      {entriesPerPage || canSearch ? (
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={3}
                        >
                          {entriesPerPage && (
                            <MDBox display="flex" alignItems="center">
                              <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                  setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                              />
                              <MDTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                              </MDTypography>
                            </MDBox>
                          )}
                          {canSearch && (
                            <MDBox width="12rem" ml="auto">
                              <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                  setSearch(search);
                                  onSearchChange(currentTarget.value);
                                }}
                              />
                            </MDBox>
                          )}
                        </MDBox>
                      ) : null}
                      <Table {...getTableProps()}>
                        <MDBox component="thead">
                          {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <DataTableHeadCell
                                  {...column.getHeaderProps(
                                    isSorted && column.getSortByToggleProps()
                                  )}
                                  width={column.width ? column.width : "auto"}
                                  align={column.align ? column.align : "left"}
                                  sorted={setSortedValue(column)}
                                >
                                  {column.render("Header")}
                                </DataTableHeadCell>
                              ))}
                            </TableRow>
                          ))}
                        </MDBox>
                        <TableBody {...getTableBodyProps()}>
                          {page.map((row, key) => {
                            prepareRow(row);
                            return (
                              <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <DataTableBodyCell
                                    noBorder={noEndBorder && rows.length - 1 === key}
                                    align={cell.column.align ? cell.column.align : "left"}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </DataTableBodyCell>
                                ))}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>

                      <MDTableFooter
                        showTotalEntries={showTotalEntries}
                        pageOptions={pageOptions}
                        entriesStart={entriesStart}
                        entriesEnd={entriesEnd}
                        pageIndex={pageIndex}
                        rows={rows}
                        pagination={pagination}
                        renderPagination={renderPagination}
                        customizedPageOptions={customizedPageOptions}
                        handleInputPagination={handleInputPagination}
                        handleInputPaginationValue={handleInputPaginationValue}
                        handleChangePagination={handleChangePagination}
                      />
                    </TableContainer>
                  </MDBox>
                </Box>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTableRowSelectMarkets
DataTableRowSelectMarkets.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  marketInfoCharts: [],
};

// Typechecking props for the DataTableRowSelectMarkets
DataTableRowSelectMarkets.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  marketInfoCharts: PropTypes.arrayOf(PropTypes.object),
};

export default DataTableRowSelectMarkets;

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
//
function MyMDTypo(label, value, piColor, darkMode) {
  let color = "";
  let valueText = "";
  const myValue = parseInt(value, 10);

  if (myValue < 0) {
    color = "error";
    valueText = `${value}`;
  }
  if (myValue > 0) {
    color = "success";
    if (piColor === "text") valueText = `${value}`;
    else valueText = `+${value}`;
  }
  if (myValue === 0) {
    color = "text";
    valueText = `${value}`;
  }
  return (
    <MDBox mt={2} mb={3}>
      <MDBox>
        <MDTypography
          variant="h6"
          fontWeight="bold"
          color={piColor || color}
          verticalAlign="middle"
        >
          {valueText}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="h7" fontWeight="light" color={darkMode ? "text" : "secondary"}>
          {label}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

function DefaultStatisticsCardSubscriptionsOverview({ title, data }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <MDBox p={1}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <MDBox mb={0.5} lineHeight={1}>
              <MDTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            {MyMDTypo(data.purchased.label, data.purchased.value, "text", darkMode)}
          </Grid>
          <Grid item xs={1.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            {MyMDTypo(data.cancelled.label, data.cancelled.value, "text", darkMode)}
          </Grid>
          <Grid item xs={1.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center" }}>
            {MyMDTypo(data.growth.label, data.growth.value, null, darkMode)}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCardSubscriptionsOverview
DefaultStatisticsCardSubscriptionsOverview.defaultProps = {
  data: {
    purchased: { color: "success", value: "", label: "" },
    cancelled: { color: "success", value: "", label: "" },
    growth: { color: "success", value: "", label: "" },
  },
};

// Typechecking props for the DefaultStatisticsCardSubscriptionsOverview
DefaultStatisticsCardSubscriptionsOverview.propTypes = {
  title: PropTypes.string.isRequired,

  data: PropTypes.shape({
    purchased: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    cancelled: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
    growth: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  }),
};

export default DefaultStatisticsCardSubscriptionsOverview;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import httpPost from "services/httpPost";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import VerticalBarChartIII from "examples/Charts/BarCharts/VerticalBarChartIII";
import DateRangePickerCompAnalysis from "examples/DateRangePickerAnalysis";

// import { addMonths } from "date-fns";
import defineds from "examples/DateRangePicker/datesConfiguration";

import { monthNames } from "utils/productUtil";

function MonthlyAnalysis() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [isLoading, setisLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [subscriptions, setSubscriptions] = useState([]);
  const [labels, setLabels] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [rangeFrom, setRangeFrom] = useState([
    {
      // startDate: addMonths(new Date(), -3),
      startDate: defineds.startOfLast3Months,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const sideBarCustomObject = [
    {
      label: "3 Months",
      range: () => ({
        startDate: defineds.startOfLast3Months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "6 Months",
      range: () => ({
        startDate: defineds.startOfLast6Months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 year",
      range: () => ({
        startDate: defineds.startOfLast12Months,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "2 years",
      range: () => ({
        startDate: defineds.startOfLast24Months,
        endDate: defineds.endOfToday,
      }),
    },
  ];

  const postSubscriptions = async () => {
    const { startDate } = rangeFrom[0];

    const myDate = {
      myDay: startDate.getDate(),
      myMonth: startDate.getMonth(),
      myYear: startDate.getFullYear(),
    };

    const result = await httpPost({
      path: `/subscriptions/permonth`,
      method: "POST",
      raw: JSON.stringify({ rangeFrom, myDate }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const loadedSubscriptions = await postSubscriptions();
          setSubscriptions(loadedSubscriptions);
          setLabels(
            loadedSubscriptions.map((group) => `${monthNames[group.month - 1]}-${group.year}`)
          );
          setDataChart(loadedSubscriptions.map((group) => group.subscriptions));
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [rangeFrom]);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={3} pb={1}>
                <MDBox pb={2}>
                  <MDTypography variant="h5">FILTERS</MDTypography>
                </MDBox>
                <MDBox px={1}>
                  <MDBox
                    py={1}
                    display="flex"
                    justifyContent="left"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      PICK THE TIME PERIOD
                    </MDTypography>
                    <MDBox
                      display="flex"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          <DateRangePickerCompAnalysis
                            range={rangeFrom}
                            setRange={setRangeFrom}
                            sideBarCustomObject={sideBarCustomObject}
                            // setFilterByRange={setFilterByRange}
                          />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <VerticalBarChartIII
                icon={{ color: "dark", component: "leaderboard" }}
                title="Monthly Subscription Analysis"
                description=""
                chart={{
                  labels,
                  datasets: [
                    {
                      label: "subscriptions",
                      color: "dark",
                      data: dataChart,
                    },
                  ],
                }}
                fullData={subscriptions.map((el) => ({
                  month: el.month,
                  monthName: monthNames[el.month - 1],
                  year: el.year,
                  subscriptions: el.subscriptions,
                }))}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MonthlyAnalysis;

/* eslint-disable react/prop-types */
import React from "react";

import MDBox from "components/MDBox";
import { Icon, IconButton } from "@mui/material";
import MDTypography from "components/MDTypography";

// Custom styles for DashboardNavbar
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";

function FiltersHeader({ icon, setIsFilterCollapse, iconsStyle }) {
  return (
    <MDBox display="flex" justifyContent="space-between">
      <MDBox textAlign="right" lineHeight={1.25}>
        <MDTypography variant="h5" mt={0}>
          Filters
        </MDTypography>
      </MDBox>
      <IconButton
        sx={navbarIconButton}
        size="small"
        disableRipple
        // display="flex"
        // justifyContent="center"
        // alignItems="center"
        onClick={() => {
          setIsFilterCollapse((prev) => setIsFilterCollapse(!prev));
        }}
      >
        <Icon sx={iconsStyle}>{icon}</Icon>
      </IconButton>
    </MDBox>
  );
}

export default FiltersHeader;

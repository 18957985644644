/* eslint-disable react/prop-types */
import { Box, Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PieChartSubscriptionOverview from "examples/Charts/PieChartSubscriptionOverview";
import React from "react";

function SubscriptionCharts({
  purchasedDate,
  showCompare,
  purchasedCompareDate,
  newCategory,
  themeColors,
  newPrimarySubCategory,
}) {
  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox py={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Subscriptions By Market Categories And Primary Subcategories
                </MDTypography>
              </MDBox>
              <Box sx={{ width: "100%" }}>
                <MDBox px={3}>
                  <MDBox mb={3}>
                    <MDBox mb={3}>
                      <Grid container spacing={3} my={3}>
                        <Grid item xs={12} md={6}>
                          <MDTypography variant="h7" color="text">
                            Report period {`${purchasedDate[0]} - ${purchasedDate[1]}`}
                          </MDTypography>
                        </Grid>
                        {showCompare && (
                          <Grid item xs={12} md={6}>
                            <MDTypography variant="h7" color="text">
                              Compared period{" "}
                              {`${purchasedCompareDate[0]} - ${purchasedCompareDate[1]}`}
                            </MDTypography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={showCompare ? 2.8 : 4}>
                          <PieChartSubscriptionOverview
                            icon={{ color: "info", component: "leaderboard" }}
                            title="Market Category"
                            chart={{
                              // eslint-disable-next-line no-underscore-dangle
                              labels: newCategory.map((r) => r._id),
                              datasets: {
                                label: "Projects",
                                backgroundColors: themeColors,
                                data: newCategory.map((r) => r.count),
                              },
                            }}
                            showCompare={showCompare}
                          />
                        </Grid>
                        <Grid item xs={12} md={showCompare ? 2.8 : 4}>
                          <PieChartSubscriptionOverview
                            icon={{ color: "info", component: "leaderboard" }}
                            title="Market Primary Subcategory"
                            chart={{
                              // eslint-disable-next-line no-underscore-dangle
                              labels: newPrimarySubCategory.map((r) => r._id),
                              datasets: {
                                label: "Projects",
                                backgroundColors: themeColors,
                                data: newPrimarySubCategory.map((r) => r.count),
                              },
                            }}
                            showCompare={showCompare}
                          />
                        </Grid>
                        {/* Compared */}
                        {showCompare && (
                          <>
                            <Grid xs={12} md={0.5} m={0} p={0}>
                              <Divider orientation="vertical" />
                            </Grid>
                            <Grid item xs={12} md={2.8}>
                              <PieChartSubscriptionOverview
                                icon={{ color: "info", component: "leaderboard" }}
                                title="Market Category"
                                chart={{
                                  // eslint-disable-next-line no-underscore-dangle
                                  labels: newCategory.map((r) => r._id),
                                  datasets: {
                                    label: "Projects",
                                    backgroundColors: themeColors,
                                    data: newCategory.map((r) => r.count),
                                  },
                                }}
                                showCompare={showCompare}
                              />
                            </Grid>
                            <Grid item xs={12} md={2.8}>
                              <PieChartSubscriptionOverview
                                icon={{ color: "info", component: "leaderboard" }}
                                title="Market Primary Subcategory"
                                chart={{
                                  // eslint-disable-next-line no-underscore-dangle
                                  labels: newPrimarySubCategory.map((r) => r._id),
                                  datasets: {
                                    label: "Projects",
                                    backgroundColors: themeColors,
                                    data: newPrimarySubCategory.map((r) => r.count),
                                  },
                                }}
                                showCompare={showCompare}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Box>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default SubscriptionCharts;

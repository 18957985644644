import {
  addDays,
  // subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
} from "date-fns";

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  startOfLastTwoWeek: startOfWeek(addDays(new Date(), -14)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastThreeDay: startOfDay(addDays(new Date(), -2)),
  startOfLastFiveDay: startOfDay(addDays(new Date(), -4)),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -6)),
  startOfLastFourteenDay: startOfDay(addDays(new Date(), -14)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  startOfLast3Months: startOfMonth(addMonths(new Date(), -2)),
  startOfLast6Months: startOfMonth(addMonths(new Date(), -5)),
  startOfLast12Months: startOfMonth(addMonths(new Date(), -11)),
  startOfLast24Months: startOfMonth(addMonths(new Date(), -23)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  // startOflastYear: startOfYear(addYears(new Date(), -1)),
  startOflastYear: startOfDay(addYears(new Date(), -1)),
  startOflast2Years: startOfDay(addYears(new Date(), -2)),
  endOflastYear: endOfYear(addYears(new Date(), -1)),
};

export default defineds;

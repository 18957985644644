// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Checkbox from "components/FormsUI/Checkbox";

// NewMarketRequest page components
import FormField from "components/FormField";
import DataTableComments from "components/Tables/DataTableComments";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

function UserInfo({ formData }) {
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const { formField, values, errors, touched, categories, countries, currencies, setFieldValue } =
    formData;
  const {
    marketName,
    exchangeSymbol,
    exchange,
    // currency,
    sourceTicker,
    externalRequester,
    isBusinessUser,
    externalEmail,
    category,
    country,
  } = formField;
  const {
    marketName: marketNameV,
    exchangeSymbol: exchangeSymbolV,
    exchange: exchangeV,
    currency: currencyV,
    sourceTicker: sourceTickerV,
    commentsArrayData: commentsArrayDataV,
    externalRequester: externalRequesterV,
    externalEmail: externalEmailV,
    category: categoryV,
    country: countryV,
  } = values;

  const [visible, setVisible] = useState(false);
  const [commentsData, setCommentsData] = useState(commentsArrayDataV || []);

  const handleSetVisible = (e) => {
    setVisible(!visible);
    setFieldValue("externalRequester", e.target.checked, false);
  };

  const addComment = (comment) => {
    setCommentsData([
      {
        CommentText: comment,
        CommentUser: userData.name,
        CommentDate: new Date(),
      },
      ...commentsData,
    ]);
  };

  useEffect(() => {
    setFieldValue("CommentsData", commentsData, false);
  }, [commentsData]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="button" color="text">
          To submit a new market request please fill the form below. In case you have some extra
          info that should be considered while reviewing the request please leave it in the comment
          section.
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={marketName.type}
              label={marketName.label}
              name={marketName.name}
              value={marketNameV}
              placeholder={marketName.placeholder}
              error={errors.marketName && touched.marketName}
              success={marketNameV.length > 0 && !errors.marketName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="category"
              name={category}
              // options={categories.map((s) => s.Name)}
              options={categories}
              value={categoryV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("category", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Category" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={exchangeSymbol.type}
              label={exchangeSymbol.label}
              name={exchangeSymbol.name}
              value={exchangeSymbolV}
              placeholder={exchangeSymbol.placeholder}
              error={errors.exchangeSymbol && touched.exchangeSymbol}
              success={exchangeSymbolV.length > 0 && !errors.exchangeSymbol}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="currency"
              name="currency"
              // options={countries.map((s) => s.name)}
              options={currencies.map((s) => s.cc)}
              value={currencyV || null}
              // getOptionLabel={(option) => option}
              getOptionLabel={(option) => currencies.filter((e) => e.cc === option)[0].name}
              // style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("currency", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Currency" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={exchange.type}
              label={exchange.label}
              name={exchange.name}
              value={exchangeV}
              placeholder={exchange.placeholder}
              error={errors.exchange && touched.exchange}
              success={exchangeV.length > 0 && !errors.exchange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country"
              name={country.DisplayName}
              options={countries.map((s) => s.DisplayName)}
              value={countryV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("country", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Country" />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={sourceTicker.type}
              label={sourceTicker.label}
              name={sourceTicker.name}
              value={sourceTickerV}
              placeholder={sourceTicker.placeholder}
              error={errors.sourceTicker && touched.sourceTicker}
              success={sourceTickerV.length > 0 && !errors.sourceTicker}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Checkbox name="isBusinessUser" label="IsBusinessUser" checked={isBusinessUser} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3.5} lg={3.5}>
            <MDBox
              display="flex"
              // justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDBox ml={0}>
                <Switch
                  checked={visible}
                  onChange={(e) => handleSetVisible(e)}
                  name={externalRequester.name}
                  value={externalRequesterV}
                />
              </MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                External request
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            {visible && (
              <FormField
                type={externalEmail.type}
                label={externalEmail.label}
                name={externalEmail.name}
                value={externalEmailV}
                placeholder={externalEmail.placeholder}
                error={errors.externalEmail && touched.externalEmail}
                success={externalEmailV.length > 0 && !errors.externalEmail}
                disabled={!visible}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <DataTableComments
              table={{
                columns: [
                  {
                    Header: "CommentDate",
                    accessor: "CommentDate",
                    width: "40%",
                    align: "left",
                    Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.CommentDate),
                  },
                  {
                    Header: "CommentUser",
                    accessor: "CommentUser",
                    align: "left",
                  },
                  {
                    Header: "CommentText",
                    accessor: "CommentText",
                    width: "65%",
                    align: "left",
                  },
                ],
                rows: commentsData,
              }}
              isSorted
              showTotalEntries={false}
              entriesPerPage={false}
              noEndBorder={false}
              canAddComment
              addComment={addComment}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;

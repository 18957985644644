import { useMemo, useState, useEffect } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChartSnapshots configurations
import configs from "examples/Charts/PieChartSubscriptionOverview/configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

function PieChartSubscriptionOverview({ icon, title, height, chart, showCompare }) {
  // eslint-disable-next-line no-unused-vars
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(
    () => {
      setChartData({
        labels: chart.labels,
        datasets: [
          {
            data: data.datasets[0].data,
            // backgroundColor: ["#1A73E8", "#D81B60", "#fb8c00", "#66BB6A", "#EF5350", "#42424A"],
            backgroundColor: [
              "#EF5350",
              "#AB47BC",
              "#5C6BC0",
              "#29B6F6",
              "#26A69A",
              "#9CCC65",
              "#FFEE58",
              "#FFA726",
              "#8D6E63",
              "#78909C",
            ],
            hoverOffset: 11,
            weight: 9,
            cutout: 0,
            tension: 0.9,
            pointRadius: 2,
            borderWidth: 2,
            fill: false,
            // datalabels: {
            //   color: "#FFCE56",
            // },
          },
        ],
      });
      let layout;
      let offset = 40;
      let fontsize = 9;

      if (window.innerWidth < 1440) {
        fontsize = 8;
        if (showCompare) {
          layout = { padding: { bottom: 80, left: 100, right: 60, top: 80 } };
          offset = 20;
        } else {
          layout = { padding: { bottom: 80, left: 100, right: 50, top: 80 } };
          offset = 35;
        }
      } else if (showCompare) {
        layout = { padding: { bottom: 90, left: 90, right: 70, top: 90 } };
        offset = 35;
      } else {
        layout = { padding: { bottom: 50, left: 50, right: 50, top: 50 } };
        offset = 55;
        fontsize = 10;
      }
      setChartOptions({
        // animation to false because an issue with the library is not fixed yet
        animation: true,
        responsive: true,
        maintainAspectRatio: false,
        layout,
        type: "pie",
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: "#42424A",
            labels: {
              index: {
                align: "end",
                // anchor: "end",
                offset,
                // clamp: true,
                display: "auto", // to hide labels which overlap
                // clip: true,
                font: { size: fontsize },
                formatter(value, ctx) {
                  let total = 0;
                  ctx.dataset.data.forEach((item) => {
                    total += item;
                  });
                  return ctx.active
                    ? `${ctx.chart.legend.legendItems[ctx.dataIndex].text} \n  [${value}] markets`
                    : `${ctx.chart.legend.legendItems[ctx.dataIndex].text} \n ${(
                        value / total
                      ).toLocaleString(undefined, {
                        style: "percent",
                        maximumFractionDigits: 2,
                      })}`;
                },
                opacity(ctx) {
                  return ctx.active ? 1 : 0.4;
                },
              },
            },
          },
          tooltip: {
            callbacks: {
              label: (item) => {
                let total = 0;
                item.dataset.data.forEach((el) => {
                  total += el;
                });
                return `${item.chart.legend.legendItems[item.dataIndex].text} ${(
                  item.dataset.data[item.dataIndex] / total
                ).toLocaleString(undefined, {
                  style: "percent",
                  maximumFractionDigits: 2,
                })}`;
                // return data['datasets'][0]['data'][tooltipItem['index']];
              },
            },
          },
        },
      });
    },
    [data.datasets[0].data],
    window.innerWidth
  );

  const renderChart = (
    <MDBox py={1} pr={1} pl={icon.component ? 1 : 2}>
      {title ? (
        <MDBox display="flex" px={1} pt={1}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h7">{title}</MDTypography>}
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            {/* <Pie data={data} options={options} /> */}
            <Pie options={chartOptions} data={chartData} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  if (data.datasets[0].data.length === 0)
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "5vh",
          }}
        >
          <MDTypography
            variant="h7"
            fontWeight="light"
            // color={darkMode ? "text" : "secondary"}
          >
            No Data Available
          </MDTypography>
        </div>
      </Card>
    );

  return title ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChartSubscriptionOverview
PieChartSubscriptionOverview.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  height: "19.125rem",
};

// Typechecking props for the PieChartSubscriptionOverview
PieChartSubscriptionOverview.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  showCompare: PropTypes.bool.isRequired,
};

export default PieChartSubscriptionOverview;

import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCardFailedMarket from "examples/Cards/StatisticsCards/ComplexStatisticsCardFailedMarket";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import failedMarketsZeroValueTableDataDetail from "layouts/tables/data/failedMarketsZeroValueTableDataDetail";

function FailedMarketsZeroValueDetail() {
  const { peiticker } = useParams();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [zeroValueData, setZeroValueData] = useState([]);
  const [timeframeValue, setTimeframeValue] = useState("daily");

  const { columns } = failedMarketsZeroValueTableDataDetail();

  const getZeroValue = async () => {
    const result = await http({
      path: `/failedmarkets/zeroval/${peiticker}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleChangeTimeframe = (event, newTimeframe) => {
    setTimeframeValue(newTimeframe);
  };

  const [fetchedZeroValueDataDetail] = useQueries({
    queries: [
      {
        queryKey: ["fetchedZeroValueDataDetailMain", peiticker],
        queryFn: getZeroValue,
      },
    ],
  });

  useEffect(() => {
    let filteredData = fetchedZeroValueDataDetail.data;
    if (!filteredData) return;
    switch (timeframeValue) {
      case "daily":
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 1).results;
        break;
      case "weekly":
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 2).results;
        break;
      case "monthly":
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 3).results;
        break;
      case "quarterly":
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 4).results;
        break;
      case "yearly":
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 5).results;

        break;
      default:
        filteredData = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 1).results;
        break;
    }
    setZeroValueData(filteredData.concat(filteredData[0]?.zeroValueDetails || []));
  }, [timeframeValue, fetchedZeroValueDataDetail.data]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (fetchedZeroValueDataDetail.isLoading) return <MDLoader />;
  if (fetchedZeroValueDataDetail.isError) return <div>Error at loading analysis market</div>;

  const firstOccurrence = fetchedZeroValueDataDetail.data.find((item) => item.results.length > 0);

  const timeFrame1Object = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 1);
  const timeFrame2Object = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 2);
  const timeFrame3Object = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 3);
  const timeFrame4Object = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 4);
  const timeFrame5Object = fetchedZeroValueDataDetail.data.find((item) => item.timeFrame === 5);

  const resultsLength1 = timeFrame1Object
    ? timeFrame1Object.results.length + (timeFrame1Object.results[0]?.zeroValueDetails?.length || 0)
    : 0;
  const resultsLength2 = timeFrame2Object
    ? timeFrame2Object.results.length + (timeFrame2Object.results[0]?.zeroValueDetails?.length || 0)
    : 0;
  const resultsLength3 = timeFrame3Object
    ? timeFrame3Object.results.length + (timeFrame3Object.results[0]?.zeroValueDetails?.length || 0)
    : 0;
  const resultsLength4 = timeFrame4Object
    ? timeFrame4Object.results.length + (timeFrame4Object.results[0]?.zeroValueDetails?.length || 0)
    : 0;
  const resultsLength5 = timeFrame5Object
    ? timeFrame5Object.results.length + (timeFrame5Object.results[0]?.zeroValueDetails?.length || 0)
    : 0;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              {firstOccurrence?.results?.length > 0 && (
                <ComplexStatisticsCardFailedMarket
                  color="warning"
                  icon="api"
                  title={firstOccurrence?.results[0]?.Name}
                  count={firstOccurrence?.results[0]?.PEITicker}
                  percentage={{
                    color: "success",
                    label: firstOccurrence?.results[0]?.Name,
                  }}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox pt={2} px={3}>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    color="error"
                    textTransform="uppercase"
                  >
                    Total failed markets timeframe : {zeroValueData.length}
                  </MDTypography>
                </MDBox>
                <Grid item m={3} mt={1} mb={3}>
                  <MDBox pt={1} px={1}>
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      Select the timeframe
                    </MDTypography>
                  </MDBox>
                  <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={timeframeValue}
                    exclusive
                    onChange={handleChangeTimeframe}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="daily" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Daily {`${resultsLength1}`}
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="weekly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Weekly {`${resultsLength2}`}
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="monthly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Monthly {`${resultsLength3}`}
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="quarterly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Quarterly {`${resultsLength4}`}
                      </MDTypography>
                    </ToggleButton>
                    <ToggleButton value="yearly" aria-label="centered">
                      <MDTypography variant="caption" color="text" fontWeight="regular">
                        Yearly {`${resultsLength5}`}
                      </MDTypography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {zeroValueData && zeroValueData.length > 0 ? (
        <MDBox my={3} mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Region Status
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableWithColumnFilters
                    table={{ columns, rows: zeroValueData }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Details"]}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default FailedMarketsZeroValueDetail;

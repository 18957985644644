import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Steps({ title, description }) {
  return (
    <Card>
      <MDBox p={3} sx={{ textAlign: "center" }}>
        <MDBox mt={1} mb={1} lineHeight={0} sx={{ textAlign: "center" }}>
          <MDTypography variant="h3" fontWeight="bold">
            {title}
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {description}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Steps
Steps.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the Steps
Steps.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string,
};

export default Steps;

/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { formatDate } from "utils/dateUtil";

// Material Dashboard 2 React components

export default function data() {
  return {
    columns: [
      {
        Header: "PEITicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 80,
        minWidth: 80,
        Filter: "",
      },
      {
        Header: "Date",
        accessor: (d) => formatDate(d.Date),
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
      },
      {
        Header: "Name",
        accessor: "Name",
        align: "left",
        maxWidth: 80,
        minWidth: 80,
        Filter: "",
      },
      {
        Header: "Open",
        accessor: "Open",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "High",
        accessor: "High",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Low",
        accessor: "Low",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Close",
        accessor: "Close",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Action",
        accessor: "Action",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => {
          const link = `/update-market-price-data/detail/${row.original.PEITicker}`;
          return (
            <MDTypography variant="h4" color="text">
              <Link to={link}>
                <Icon>edit</Icon>
              </Link>
            </MDTypography>
          );
        },
        Filter: "",
        isSorted: false,
      },
    ],
  };
}

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useMaterialUIController, setOpenMarketMessage } from "context";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

import MDSnackbar from "components/MDSnackbar";

function Footer() {
  const { size } = typography;
  const [controller, dispatch] = useMaterialUIController();
  const { openMarketMessage, openMarketMessageSettings } = controller;

  const closeSuccessSB = () => setOpenMarketMessage(dispatch, { open: false });

  const renderSuccessSB = (
    <MDSnackbar
      color={openMarketMessageSettings.color}
      icon={openMarketMessageSettings.icon}
      title={openMarketMessageSettings.title}
      content={openMarketMessageSettings.content}
      dateTime=""
      open={openMarketMessage}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      {renderSuccessSB}
      <MDBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          {/* {new Date().getFullYear()}, version 1.0 */}
        </MDBox>
      </MDBox>
    </>
  );
}

export default Footer;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function dataGmwCommentsErrorDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="medium"
          >
            {row.original.Name}
          </MDTypography>
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "Research Item ID", accessor: "latestReversal.ResearchItemId", align: "center" },
      {
        Header: "Category",
        accessor: "Category",
        align: "center",
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "center",
      },
      // {
      //   Header: "Length",
      //   accessor: "Length",
      //   align: "center",
      //   Cell: ({ row }) => {
      //     let value;
      //     switch (row.original.TimeFrame) {
      //       case "Daily":
      //         value = row.original["Length of Daily"];
      //         break;
      //       case "Weekly":
      //         value = row.original["Length of Weekly"];
      //         break;
      //       case "Monthly":
      //         value = row.original["Length of Monthly"];
      //         break;
      //       case "Quarterly":
      //         value = row.original["Length of Quarterly"];
      //         break;
      //       case "Yearly":
      //         value = row.original["Length of Yearly"];
      //         break;
      //       default:
      //         break;
      //     }
      //     return value || 0;
      //   },
      // },
      {
        Header: "Analysis Date",
        accessor: "latestReversal.AnalysisDate",
        align: "center",
        Cell: ({ row }) => (
          <MDTypography component="div" variant="h7" color="error" fontWeight="light">
            {formatDateInNewYorkNoSec(row.original.latestReversal.AnalysisDate)}
          </MDTypography>
        ),
      },
      { Header: "AnalysisId", accessor: "latestReversal.AnalysisId", align: "center" },
    ],
  };
}

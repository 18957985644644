/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCardMarketSubscription from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketSubscription";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Market Subscription components
import DataTableMarketSubscriptions from "components/Tables/DataTableMarketSubscriptions";

// Data
import marketSubscriptionsTableData from "layouts/tables/data/marketSubscriptionsTableData";

function MarketSubscription() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [marketSubscriptionSummary, setMarketSubscriptionSummary] = useState([]);
  const [totalSnapshots, setTotalSnapshots] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalSubscriptionsAddedLast30Days, setTotalSubscriptionsAddedLast30Days] = useState(0);
  const [totalSubscriptionsCancelledLast30Days, setTotalSubscriptionsCancelledLast30Days] =
    useState(0);

  const [isLoading, setisLoading] = useState(true);

  const totalCardValues = (data) => {
    setTotalActive(
      data.reduce((current, next) => current + next.TotalCountOfActiveSubscriptions || 0, 0)
    );
    setTotalSnapshots(data.reduce((current, next) => current + next.TotalCountofSnapshots || 0, 0));
    setTotalSubscriptionsAddedLast30Days(
      data.reduce(
        (current, next) => current + next.TotalCountOfSubscriptionAddedInLast30Days || 0,
        0
      )
    );
    setTotalSubscriptionsCancelledLast30Days(
      data.reduce(
        (current, next) => current + next.TotalCountOfCancelledSubscriptionInLast30Days || 0,
        0
      )
    );
  };

  const getMarketSubscriptionSummary = async () => {
    const result = await http({
      path: `/marketSubscription`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const loadedMarketSubscriptionSummary = await getMarketSubscriptionSummary();
          setMarketSubscriptionSummary(loadedMarketSubscriptionSummary);
          totalCardValues(loadedMarketSubscriptionSummary);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  const { columns3 } = marketSubscriptionsTableData();

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title="Total Snapshots"
                count={totalSnapshots}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardMarketSubscription
                color="dark"
                icon="weekend"
                title="Total Active Subscriptions"
                count={totalActive}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardMarketSubscription
                icon="leaderboard"
                title="Total Subscriptions Added Last 30 Days"
                count={totalSubscriptionsAddedLast30Days}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardMarketSubscription
                color="success"
                icon="store"
                title="Total Subscriptions Cancelled Last 30 Days"
                count={totalSubscriptionsCancelledLast30Days}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCardMarketSubscription
                color="primary"
                icon="person_add"
                title="Net Addition Last 30 Days"
                count={totalSubscriptionsAddedLast30Days - totalSubscriptionsCancelledLast30Days}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Market Subscription
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableMarketSubscriptions
                  table={{ columns: columns3, rows: marketSubscriptionSummary }}
                  isSorted
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketSubscription;

import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import httpPost from "services/httpPost";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card, Divider } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest layout schemas for form and form fields
import { useLocation } from "react-router-dom";

import DataTableRowSelectMPDDetail from "examples/Tables/DataTableRowSelectMPDDetail";
import { formatDateMDY } from "utils/dateUtil";

function MarketPriceDataDetail() {
  const location = useLocation();
  const { percentageChangeInfo } = location.state;

  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const [isLoading, setisLoading] = useState(true);
  const [marketPriceData, setMarketPriceData] = useState([]);

  const postMarketPriceData = async () => {
    const dataArray = percentageChangeInfo?.closeEqualsPrevClose;
    const result = await httpPost({
      path: `/marketpricedata/${percentageChangeInfo.PEITicker}`,
      method: "POST",
      raw: JSON.stringify({ dataArray }),
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedMarketPriceData = await postMarketPriceData();
          setMarketPriceData(loadedMarketPriceData);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MDBox mb={1.5}>
              {percentageChangeInfo && (
                <Card>
                  <MDBox display="flex" justifyContent="left" pt={2} px={3}>
                    <MDBox textAlign="right" lineHeight={1.25}>
                      <MDTypography variant="h5">{percentageChangeInfo?.PEITicker}</MDTypography>
                    </MDBox>
                  </MDBox>
                  <Divider />
                  <MDBox pb={2} px={2}>
                    <MDTypography component="p" variant="button" color="text" display="flex">
                      &nbsp;{percentageChangeInfo?.Name}
                    </MDTypography>
                  </MDBox>
                </Card>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Market Price Data
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableRowSelectMPDDetail
                  table={{
                    columns: [
                      {
                        Header: "_id",
                        accessor: "_id",
                        align: "left",
                        maxWidth: 160,
                        minWidth: 160,
                        Filter: "",
                      },
                      {
                        Header: "Date",
                        accessor: (d) => formatDateMDY(d.Date),
                        align: "left",
                        maxWidth: 160,
                        minWidth: 160,
                        Filter: "",
                      },
                      {
                        Header: "Open",
                        accessor: "Open",
                        align: "left",
                        maxWidth: 150,
                        minWidth: 150,
                        Filter: "",
                        isSorted: false,
                      },
                      {
                        Header: "High",
                        accessor: "High",
                        align: "left",
                        maxWidth: 150,
                        minWidth: 150,
                        Filter: "",
                        isSorted: false,
                      },
                      {
                        Header: "Low",
                        accessor: "Low",
                        align: "left",
                        maxWidth: 150,
                        minWidth: 150,
                        Filter: "",
                        isSorted: false,
                      },
                      {
                        Header: "Close",
                        accessor: "Close",
                        align: "left",
                        maxWidth: 150,
                        minWidth: 150,
                        Filter: "",
                        isSorted: false,
                      },
                      {
                        Header: "Prev. Close",
                        accessor: "previousMarketPriceData.Close",
                        align: "left",
                        maxWidth: 150,
                        minWidth: 150,
                        Filter: "",
                        isSorted: false,
                        Cell: ({ row }) => row.original.previousMarketPriceData?.Close || "",
                      },
                      {
                        Header: "Prev. Date",
                        accessor: (d) => d.previousMarketPriceData.Date,
                        align: "left",
                        maxWidth: 160,
                        minWidth: 160,
                        Filter: "",
                        Cell: ({ row }) =>
                          formatDateMDY(row.original.previousMarketPriceData?.Date) || "",
                      },
                    ],
                    rows: marketPriceData,
                  }}
                  isSorted
                  showTotalEntries
                  noEndBorder={false}
                  entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25, 50, 100, 200] }}
                  canSearch
                  pagination={{ variant: "gradient", color: "info" }}
                  setModalAddRow={() => {}}
                  setModalDeleteMany={() => {}}
                  setRecordDetail={() => {}}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketPriceDataDetail;

import * as Yup from "yup";
import checkout from "layouts/nulltagsedit/schemas/form";

const {
  formField: { marketTags },
} = checkout;

const validations = [
  Yup.object().shape({
    [marketTags.name]: Yup.array()
      .of(Yup.string())
      .required(marketTags.errorMsg)
      .test("not-empty", "Market tags cannot be empty", (value) => value && value.length > 0)
      .typeError("Market tags cannot be null"),
  }),
];

export default validations;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableFailedMarkets from "components/Tables/DataTableFailedMarkets";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// Data
import failedMarketsTableData from "layouts/tables/data/failedMarketsTableData";

function FailedMarkets() {
  const navigate = useNavigate();
  const { timeframeId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  // eslint-disable-next-line react/prop-types
  const [timeframe, setTimeframe] = useState(timeframeId);
  const [failedMarkets, setFailedMarkets] = useState([]);
  const [uniqueSourceValues, setUniqueSourceValues] = useState([]);

  const [selectRegion, setSelectRegion] = useState([]);
  const [selectSource, setSelectSource] = useState([]);
  const [timeF, setTimeF] = useState([1]);

  const [totalCount, setTotalCount] = useState(0);

  const [isLoading, setisLoading] = useState(true);

  const handleChangeTimeframe = (event, newTimeframe) => {
    setTimeframe(newTimeframe);
    switch (newTimeframe) {
      case "daily":
        setTimeF([1]);
        break;
      case "weekly":
        setTimeF([2]);
        break;
      case "monthly":
        setTimeF([3]);
        break;
      case "quarterly":
        setTimeF([4]);
        break;
      case "yearly":
        setTimeF([5]);
        break;
      default:
        setTimeF([]);
        break;
    }
  };

  const handleChangeSelectRegion = (event, newSelectRegion) => {
    if (newSelectRegion === "All") setSelectRegion([]);
    else setSelectRegion([newSelectRegion]);
  };

  const handleChangeSelectSource = (event, newSelectSource) => {
    if (newSelectSource === "all") setSelectSource([]);
    else setSelectSource([newSelectSource]);
  };

  const getTimeFrameIdFromParam = (inputTimeframe) => {
    switch (inputTimeframe) {
      case "daily":
        return 1;
      case "weekly":
        return 2;
      case "monthly":
        return 3;
      case "quarterly":
        return 4;
      case "yearly":
        return 5;
      default:
        return -1;
    }
  };

  const getFailedMarkets = async () => {
    const result = await http({
      path: `/regions/markets/byregionbytimeframe/all/${getTimeFrameIdFromParam(timeframe)}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return {
      data: [],
      arrayUniqueSourceValues: [],
    };
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          setisLoading(true);
          const { data, arrayUniqueSourceValues } = await getFailedMarkets();
          setFailedMarkets(data);
          setUniqueSourceValues(arrayUniqueSourceValues);
          setTotalCount(data.length);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
    // eslint-disable-next-line react/prop-types
  }, [userData, timeframe]);

  useEffect(() => {
    setTimeframe(timeframeId);
  }, [timeframeId]);

  const { columns2 } = failedMarketsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={2}>
        <MDBox mb={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Card id="filter">
                <MDBox pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                    Filters
                  </MDTypography>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    color="error"
                    textTransform="uppercase"
                  >
                    Total failed markets {timeframeId} : {!isLoading ? totalCount : ""}
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <ToggleButtonGroup
                      value={timeframe}
                      exclusive
                      onChange={handleChangeTimeframe}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="daily" aria-label="centered">
                        <Link to="/failed-markets/timeframe/daily">
                          <MDTypography
                            component="span"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            opacity={0.9}
                            sx={{ lineHeight: 0 }}
                          >
                            Daily
                          </MDTypography>
                        </Link>
                      </ToggleButton>
                      <ToggleButton value="weekly" aria-label="centered">
                        <Link to="/failed-markets/timeframe/weekly">
                          <MDTypography
                            component="span"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            opacity={0.9}
                            sx={{ lineHeight: 0 }}
                          >
                            Weekly
                          </MDTypography>
                        </Link>
                      </ToggleButton>
                      <ToggleButton value="monthly" aria-label="centered">
                        <Link to="/failed-markets/timeframe/monthly">
                          <MDTypography
                            component="span"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            opacity={0.9}
                            sx={{ lineHeight: 0 }}
                          >
                            Monthly
                          </MDTypography>
                        </Link>
                      </ToggleButton>
                      <ToggleButton value="quarterly" aria-label="centered">
                        <Link to="/failed-markets/timeframe/quarterly">
                          <MDTypography
                            component="span"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            opacity={0.9}
                            sx={{ lineHeight: 0 }}
                          >
                            Quarterly
                          </MDTypography>
                        </Link>
                      </ToggleButton>
                      <ToggleButton value="yearly" aria-label="centered">
                        <Link to="/failed-markets/timeframe/yearly">
                          <MDTypography
                            component="span"
                            variant="button"
                            color="text"
                            fontWeight="regular"
                            opacity={0.9}
                            sx={{ lineHeight: 0 }}
                          >
                            Yearly
                          </MDTypography>
                        </Link>
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <MDBox>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={2}>
                            <MDBox pt={1} px={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="light"
                                textTransform="uppercase"
                              >
                                Select the region
                              </MDTypography>
                            </MDBox>
                            <ToggleButtonGroup
                              size="small"
                              color="info"
                              value={selectRegion}
                              exclusive
                              onChange={handleChangeSelectRegion}
                              aria-label="text alignment"
                            >
                              <ToggleButton value="All" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  All
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="Americas" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Americas
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="Asia" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Asia
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="Europe" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Europe
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="Economics" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Economics
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="Bonds" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Bonds
                                </MDTypography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={2}>
                            <MDBox pt={1} px={1}>
                              <MDTypography
                                variant="h6"
                                fontWeight="light"
                                textTransform="uppercase"
                              >
                                Select the source
                              </MDTypography>
                            </MDBox>
                            <ToggleButtonGroup
                              size="small"
                              color="info"
                              value={selectSource}
                              exclusive
                              onChange={handleChangeSelectSource}
                              aria-label="text alignment"
                            >
                              <ToggleButton value="all" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  All
                                </MDTypography>
                              </ToggleButton>
                              {uniqueSourceValues.map((source) => (
                                <ToggleButton value={source} aria-label="centered" key={source}>
                                  <MDTypography variant="caption" color="text" fontWeight="regular">
                                    {source}
                                  </MDTypography>
                                </ToggleButton>
                              ))}
                            </ToggleButtonGroup>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Failed Markets
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {isLoading ? (
                  <MDLoader minHeight="10vh" />
                ) : (
                  <DataTableFailedMarkets
                    table={{ columns: columns2, rows: failedMarkets }}
                    isSorted
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                    canSearch
                    regionOutside={selectRegion}
                    sourceOutside={selectSource}
                    timeframeOutside={timeF}
                    setTotalCountOutside={setTotalCount}
                    exportToExcel
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default FailedMarkets;

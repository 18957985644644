/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";
// import MDTypography from "components/MDTypography";
import { SelectColumnFilter } from "components/TableFilters/selectByFieldStatusId";
import { Link } from "react-router-dom";

export default function data() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Bullish",
        accessor: "latestReversal.Direction1Count",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Bearish",
        accessor: "latestReversal.Direction0Count",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "AnalysisId",
        accessor: "latestAnalysis._id",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Bullish Missing Points",
        accessor: (d) =>
          d.DailyReversalTable?.Minor?.Bullish.filter(
            (value) => !d.latestReversal?.Direction1Details?.includes(value)
          )
            .concat(
              d.DailyReversalTable?.Major?.Bullish.filter(
                (value) => !d.latestReversal?.Direction1Details?.includes(value)
              )
            )
            .filter((value) => value !== 0).length,
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Cell: ({ row }) => {
          const val = row.original.DailyReversalTable?.Minor?.Bullish.filter(
            (value) => !row.original.latestReversal?.Direction1Details?.includes(value)
          )
            .concat(
              row.original.DailyReversalTable?.Major?.Bullish.filter(
                (value) => !row.original.latestReversal?.Direction1Details?.includes(value)
              )
            )
            .filter((value) => value !== 0).length;

          if (val === 0) return <MDTypography variant="div">{0}</MDTypography>;
          // eslint-disable-next-line no-underscore-dangle
          const link = `/reversal-mapping/${row.original.PEITicker}`;
          // state={{ data: "hola" }}
          return (
            <Link to={link} state={{ data: row.original }} text={val.toString()}>
              {val.toString()}{" "}
            </Link>
          );
        },
      },

      {
        Header: "Bearish Missing Points",
        accessor: (d) =>
          d.DailyReversalTable?.Minor?.Bearish.filter(
            (value) => !d.latestReversal?.Direction0Details?.includes(value)
          )
            .concat(
              d.DailyReversalTable?.Major?.Bearish.filter(
                (value) => !d.latestReversal?.Direction0Details?.includes(value)
              )
            )
            .filter((value) => value !== 0).length,
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Cell: ({ row }) => {
          const val = row.original.DailyReversalTable?.Minor?.Bearish.filter(
            (value) => !row.original.latestReversal?.Direction0Details?.includes(value)
          )
            .concat(
              row.original.DailyReversalTable?.Major?.Bearish.filter(
                (value) => !row.original.latestReversal?.Direction0Details?.includes(value)
              )
            )
            .filter((value) => value !== 0).length;
          if (val === 0) return <MDTypography variant="div">{0}</MDTypography>;
          // eslint-disable-next-line no-underscore-dangle
          const link = `/reversal-mapping/${row.original.PEITicker}`;
          return (
            <Link to={link} state={{ data: row.original }} text={val.toString()}>
              {val.toString()}
            </Link>
          );
        },
      },
    ],
  };
}

// {
//   Header: "Bullish",
//   accessor: "latestReversal.Direction1Details",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction1Details?.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Bearish",
//   accessor: "latestReversal.Direction0Details",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction0Details?.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )),
// },
// {
//   Header: "Minor Bullish",
//   accessor: "DailyReversalTable.Minor.Bullish",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Minor.Bullish.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )),
// },
// {
//   Header: "Major Bullish",
//   accessor: "DailyReversalTable.Major.Bullish",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Major.Bullish.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )),
// },
// {
//   Header: "Minor Bearish",
//   accessor: "DailyReversalTable.Minor.Bearish",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Minor.Bearish.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )),
// },
// {
//   Header: "Major Bearish",
//   accessor: "DailyReversalTable.Major.Bearish",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Major.Bearish.map((value) => (
//       <MDTypography variant="caption" color="text">
//         {value}{" "}
//       </MDTypography>
//     )),
// },
// {
//   Header: "Minor Bullish Matches",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction0Details?.filter((value) =>
//       row.original.DailyReversalTable.Minor.Bullish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bullish Matches",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction0Details?.filter((value) =>
//       row.original.DailyReversalTable.Major.Bullish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// {
//   Header: "Minor Bearish Matches",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction1Details?.filter((value) =>
//       row.original.DailyReversalTable.Minor.Bearish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bearish Matches",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction1Details?.filter((value) =>
//       row.original.DailyReversalTable.Major.Bearish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// {
//   Header: "Minor Bullish Missing First",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction0Details?.filter(
//       (value) => !row.original.DailyReversalTable.Minor.Bullish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="primary">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bullish Missing First",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction0Details?.filter(
//       (value) => !row.original.DailyReversalTable.Major.Bullish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="primary">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// {
//   Header: "Minor Bearish Missing First",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction1Details?.filter(
//       (value) => !row.original.DailyReversalTable.Minor.Bearish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="primary">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bearish Missing First",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.latestReversal?.Direction1Details?.filter(
//       (value) => !row.original.DailyReversalTable.Major.Bearish.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="primary">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// {
//   Header: "Minor Bullish Missing Second",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Minor.Bullish.filter(
//       (value) => !row.original.latestReversal?.Direction0Details?.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="warning">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bullish Missing Second",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Major.Bullish.filter(
//       (value) => !row.original.latestReversal?.Direction0Details?.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="warning">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// {
//   Header: "Minor Bearish Missing Second",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Minor.Bearish.filter(
//       (value) => !row.original.latestReversal?.Direction1Details?.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },
// {
//   Header: "Major Bearish Missing Second",
//   align: "left",
//   maxWidth: 200,
//   minWidth: 200,
//   Cell: ({ row }) =>
//     row.original.DailyReversalTable.Major.Bearish.filter(
//       (value) => !row.original.latestReversal?.Direction1Details?.includes(value)
//     ).map((value) => (
//       <MDTypography variant="caption" color="info">
//         {value}{" "}
//       </MDTypography>
//     )) || "",
// },

// .map((value) => (
//   <MDTypography variant="caption" color="info">
//     {value}{" "}
//   </MDTypography>
// )) || ""

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { SelectColumnFilter } from "components/TableFilters/selectByField";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,

        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Sector",
        accessor: "Sector",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
      },
      {
        Header: "Region",
        accessor: "Region",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
      },
      {
        Header: "DatePurchased",
        accessor: (d) => formatDateInNewYorkNoSec(d.DatePurchased),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Age, days",
        accessor: (d) => {
          const myDate = new Date();
          // return moment(myDate.getTime() - new Date(d.DatePurchased).getTime()).format(
          //   "MM/DD/YYYY hh:mm A"
          // );
          const difference = myDate.getTime() - new Date(d.MarketAdditionDate).getTime();
          const days = difference / (1000 * 3600 * 24);
          return Math.ceil(days);
        },
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
    ],
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 65,
        minWidth: 65,
        Filter: "",
      },
      {
        Header: "ProductType",
        accessor: "SubscriptionType",
        align: "left",
        maxWidth: 145,
        minWidth: 145,
        Filter: "",
      },
      {
        Header: "ProductLevel",
        accessor: "SubsAnalysisTypeDESC",
        align: "left",
        maxWidth: 145,
        minWidth: 145,
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "MembershipLevel",
        accessor: "SubscriptionLevel",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: "",
      },
      {
        Header: "Sector",
        accessor: "Sector",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: "",
      },
      {
        Header: "Region",
        accessor: "Region",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Filter: "",
      },
      {
        Header: "Total number",
        accessor: "calculatedCount",
        align: "left",
        maxWidth: 105,
        minWidth: 105,
        Filter: "",
      },
      {
        Header: "Newly added",
        accessor: "calculatedSubscriptionsCount",
        align: "left",
        maxWidth: 105,
        minWidth: 105,
        Filter: "",
      },
      {
        Header: "Newly cancelled",
        accessor: "calculatedCancellationsCount",
        align: "left",
        maxWidth: 130,
        minWidth: 130,
        Filter: "",
      },
      {
        Header: "Net difference",
        accessor: "netDifference",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: "",
        Cell: ({ row }) => {
          const balance =
            row.original.calculatedSubscriptionsCount - row.original.calculatedCancellationsCount;
          let color = "";
          if (balance > 0) color = "green";
          if (balance < 0) color = "red";
          return <div style={{ color }}>{balance}</div>;
        },
      },

      {
        Header: "Total number",
        accessor: "calculatedCountTo",
        align: "left",
        maxWidth: 105,
        minWidth: 105,
        backgroundGray: true,
        Filter: "",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => (
          <div style={{ backgroundColor: "rgb(230,230,230)" }}>
            {row.original.calculatedCountTo || "0"}
          </div>
        ),
      },
      {
        Header: "Newly added",
        accessor: "calculatedSubscriptionsCountTo",
        align: "left",
        maxWidth: 105,
        minWidth: 105,
        backgroundGray: true,
        Filter: "",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => (
          <div style={{ backgroundColor: "rgb(230,230,230)" }}>
            {row.original.calculatedSubscriptionsCountTo || "0"}
          </div>
        ),
      },
      {
        Header: "Newly cancelled",
        accessor: "calculatedCancellationsCountTo",
        align: "left",
        maxWidth: 130,
        minWidth: 130,
        backgroundGray: true,
        Filter: "",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => (
          <div style={{ backgroundColor: "rgb(230,230,230)" }}>
            {row.original.calculatedCancellationsCountTo || "0"}
          </div>
        ),
      },
      {
        Header: "Net difference",
        accessor: "netDifferenceTo",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        backgroundGray: true,
        Filter: "",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => {
          const balance =
            row.original.calculatedSubscriptionsCountTo -
            row.original.calculatedCancellationsCountTo;
          let color = "";
          if (balance > 0) color = "green";
          if (balance < 0) color = "red";
          return <div style={{ color, backgroundColor: "rgb(230,230,230)" }}>{balance || "0"}</div>;
        },
      },
    ],
  };
}

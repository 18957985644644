/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  const customFilterFunctionTimeFrame = (rows, id, filterValue) => {
    if (filterValue.length > 0) {
      return rows.filter((row) => {
        // eslint-disable-next-line no-underscore-dangle
        if (!row.original.TimeFrame) return false;
        // eslint-disable-next-line no-underscore-dangle
        return filterValue.includes(row.original.TimeFrame);
      });
    }
    return rows;
  };

  // const handleChangeSelect = (e) => {
  //   console.log(e);
  // };

  return {
    columns: [
      {
        Header: "Overview Date",
        accessor: "Date",
        Filter: "",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.Date),
      },
      {
        Header: "Last Update Date",
        accessor: "LastUpdateDate",
        Filter: "",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.LastUpdateDate),
      },
      {
        Header: "TimeFrame",
        accessor: "TimeFrame",
        Filter: "",
        align: "left",
        maxWidth: 65,
        minWidth: 65,
        filter: customFilterFunctionTimeFrame,
        Cell: ({ row }) => {
          let timeFrameDisplay = "";
          switch (row.original.TimeFrame) {
            case 1:
              timeFrameDisplay = "Daily";
              break;
            case 2:
              timeFrameDisplay = "Weekly";
              break;
            case 3:
              timeFrameDisplay = "Monthly";
              break;
            case 4:
              timeFrameDisplay = "Quarterly";
              break;
            case 5:
              timeFrameDisplay = "Yearly";
              break;
            default:
              timeFrameDisplay = "";
              break;
          }
          return timeFrameDisplay;
        },
      },
      {
        Header: "Open",
        accessor: "Open",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "High",
        accessor: "High",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Low",
        accessor: "Low",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Close",
        accessor: "Close",
        Filter: "",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
    ],
  };
}

// eslint-disable-next-line import/prefer-default-export
export const themeColors = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

import React from "react";
import * as Loader from "react-loader-spinner";
import PropTypes from "prop-types";

function MDLoader({ minHeight }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight,
      }}
    >
      <Loader.ThreeDots color="#1A73E8" />
    </div>
  );
}

MDLoader.defaultProps = {
  minHeight: "100vh",
};

MDLoader.propTypes = {
  minHeight: PropTypes.string,
};

export default MDLoader;

/* eslint-disable react/prop-types */
import { useState } from "react";
import { useQueries } from "@tanstack/react-query";
import { useMaterialUIController, setOpenMarketMessage } from "context";
// import { useNavigate, useParams, Link } from "react-router-dom";
import { useParams, Link } from "react-router-dom";

import http from "services/http";
import httpPost from "services/httpPost";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDLoader from "components/MDLoader";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableSpecificSort from "examples/Tables/DataTableSpecificSort";
import ComplexStatisticsCardHoliday from "examples/Cards/StatisticsCards/ComplexStatisticsCardHoliday";

// Data
import updateMarketPriceDataDetail from "layouts/tables/data/updateMarketPriceDataTableDataDetail";
import { Icon, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { formatDateInNewYorkMMDDYYYY } from "utils/dateUtil";

function TimeframeButton({ value, label, data, onChange }) {
  return (
    <ToggleButton value={value} aria-label="centered" onChange={onChange}>
      <MDTypography variant="caption" color="text" fontWeight="regular">
        {label}{" "}
        <MDTypography variant="caption" color="error" fontWeight="regular">
          {data && data.length > 0 ? `(${data.length})` : ""}
        </MDTypography>
      </MDTypography>
    </ToggleButton>
  );
}

function UpdateMarketPriceData() {
  const { peiticker } = useParams();
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  // const navigate = useNavigate();

  const [timeframe, setTimeframe] = useState("D");
  const [timeframeNoZero, setTimeframeNoZero] = useState("DNoZero");

  const [modal, setModal] = useState(false);
  const [recordDetail, setRecordDetail] = useState({});

  const handleCloseModal = () => {
    setModal(!modal);
  };

  const handleCancel = () => {
    setModal(false);
  };

  const deleteTag = async () => {
    const result = await httpPost({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketpricedata/${recordDetail._id}`,
      method: "DELETE",
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketPriceDataZeroValueDetail = async () => {
    const result = await http({
      path: `/marketpricedata/zero/${peiticker}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [data] = useQueries({
    queries: [
      {
        queryKey: ["updatemarketpricedatadetail"],
        queryFn: getMarketPriceDataZeroValueDetail,
      },
    ],
  });

  const handleRedirect = async () => {
    setModal(false);
    await deleteTag();
    await setOpenMarketMessage(dispatch, {
      open: true,
      color: "success",
      icon: "check",
      title: "Success",
      // content: `${tag.data[0].MarketTagName} has been deleted`,
      content: `Market Price Data record has been deleted`,
    });
    // navigate(`/view-all-tags`);
    data.refetch();
  };

  const { columns1 } = updateMarketPriceDataDetail({ setModal, setRecordDetail });
  const { columns2 } = updateMarketPriceDataDetail({ setModal, setRecordDetail });

  if (data.isLoading) return <MDLoader />;

  if (data.error) {
    return <p>Error: {data.error.message}</p>;
  }

  const {
    1: D,
    2: W,
    3: M,
    4: Q,
    5: Y,
    NoZero1: DNoZero,
    NoZero2: WNoZero,
    NoZero3: MNoZero,
    NoZero4: QNoZero,
    NoZero5: YNoZero,
    marketInfoData,
  } = data.data;

  const dataTimeframe = (inTimeframe) => {
    switch (inTimeframe) {
      case "D":
        return D;
      case "W":
        return W;
      case "M":
        return M;
      case "Q":
        return Q;
      case "Y":
        return Y;
      default:
        return D;
    }
  };
  const dataTimeframeNoZero = (inTimeframe) => {
    switch (inTimeframe) {
      case "DNoZero":
        return DNoZero;
      case "WNoZero":
        return WNoZero;
      case "MNoZero":
        return MNoZero;
      case "QNoZero":
        return QNoZero;
      case "YNoZero":
        return YNoZero;
      default:
        return DNoZero;
    }
  };

  return (
    <DashboardLayout>
      <Modal
        open={modal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <MDBox mt={1} mx={0.5}>
            <MDBox mb={1}>
              <MDTypography
                component={Link}
                to="{action.route}"
                variant="h5"
                textTransform="capitalize"
                justifyContent="center"
                alignItems="center"
              >
                Delete Market Price Data
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="h6" fontWeight="light" color="text">
                Are you sure you want to
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="h6" fontWeight="light" color="text">
                delete the document ?
                <br />
                <br />
                values:
                <br />
                <br />
                PEITikcer: &quot;{recordDetail.PEITicker}&quot;
                <br />
                TimeFrame: &quot;{recordDetail.TimeFrame}&quot;
                <br />
                Date: &quot;{formatDateInNewYorkMMDDYYYY(recordDetail.Date)}&quot;
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
              <MDButton variant="gradient" color="info" size="small" onClick={handleCancel}>
                No
              </MDButton>
              <MDButton variant="gradient" color="error" size="small" onClick={handleRedirect}>
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                Yes, delete it
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Modal>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              {marketInfoData && (
                <ComplexStatisticsCardHoliday
                  color="warning"
                  icon="api"
                  title=""
                  count={marketInfoData[0]?.PEITicker}
                  details={marketInfoData[0]?.Name}
                  moreDetails=""
                  percentage={{
                    color: "success",
                    label: marketInfoData[0]?.Name,
                  }}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Card>
                <MDBox pt={2} px={2}>
                  <MDTypography
                    variant="h5"
                    fontWeight="bold"
                    color="text"
                    textTransform="uppercase"
                  >
                    FILTERS
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} px={2}>
                  <MDTypography
                    variant="h6"
                    fontWeight="light"
                    color="text"
                    textTransform="uppercase"
                  >
                    Select timeframe
                  </MDTypography>
                </MDBox>
                <Grid item xs={9} m={3} mt={0} mb={3}>
                  <ToggleButtonGroup
                    size="small"
                    color="info"
                    value={timeframe}
                    exclusive
                    onChange={(e, newTimeframe) => setTimeframe(newTimeframe)}
                    aria-label="text alignment"
                  >
                    <TimeframeButton value="D" label="Daily" data={D} />
                    <TimeframeButton value="W" label="Weekly" data={W} />
                    <TimeframeButton value="M" label="Monthly" data={M} />
                    <TimeframeButton value="Q" label="Quarterly" data={Q} />
                    <TimeframeButton value="Y" label="Yearly" data={Y} />
                  </ToggleButtonGroup>
                </Grid>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data With OHLC Values As Zero
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableSpecificSort
                  table={{ columns: columns1, rows: dataTimeframe(timeframe) }}
                  isSorted
                  showTotalEntries
                  noEndBorder={false}
                  entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
                  canSearch
                  pagination={{ variant: "gradient", color: "info" }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox pt={1} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={8}>
            <Card>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h5" fontWeight="bold" color="text" textTransform="uppercase">
                  FILTERS
                </MDTypography>
              </MDBox>
              <MDBox pt={1} px={2}>
                <MDTypography
                  variant="h6"
                  fontWeight="light"
                  color="text"
                  textTransform="uppercase"
                >
                  Select timeframe
                </MDTypography>
              </MDBox>
              <Grid item xs={9} m={3} mt={0} mb={3}>
                <ToggleButtonGroup
                  size="small"
                  color="info"
                  value={timeframeNoZero}
                  exclusive
                  onChange={(e, newTimeframeNoZero) => setTimeframeNoZero(newTimeframeNoZero)}
                  aria-label="text alignment"
                >
                  <TimeframeButton value="DNoZero" label="Daily" data={DNoZero} />
                  <TimeframeButton value="WNoZero" label="Weekly" data={WNoZero} />
                  <TimeframeButton value="MNoZero" label="Monthly" data={MNoZero} />
                  <TimeframeButton value="QNoZero" label="Quarterly" data={QNoZero} />
                  <TimeframeButton value="YNoZero" label="Yearly" data={YNoZero} />
                </ToggleButtonGroup>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data OHLC Values Last 20 Records
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableSpecificSort
                  table={{ columns: columns2, rows: dataTimeframeNoZero(timeframeNoZero) }}
                  isSorted
                  showTotalEntries
                  noEndBorder={false}
                  entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15, 20, 25] }}
                  canSearch
                  pagination={{ variant: "gradient", color: "info" }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateMarketPriceData;

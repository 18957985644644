/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { useMaterialUIController } from "context";
import {
  SelectColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterDescription,
  NumberRangeColumnFilter,
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  multiSelectFilterFn,
} from "components/TableFilters/selectByFieldStatusId";
// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";
import { getDescription, getColor } from "utils/marketRequestUtil";

export default function data() {
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "MarketName",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        filter: "fuzzyText",
      },
      {
        Header: "Requester",
        accessor: "Name",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Date of Submission",
        accessor: (d) => formatDateInNewYorkNoSec(d.InsertedDate),
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
      },
      {
        Header: "Number of request",
        accessor: "TotalRequests",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      },

      {
        Header: "Market History (Years)",
        accessor: "MarketHistoryYears",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      },
      {
        Header: "TradingVolume",
        // accessor: "TradingVolume",
        accessor: (d) => Math.round(d.TradingVolume) || "",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      },
      {
        Header: "Market Cap",
        accessor: "MarketCap",
        align: "left",
        maxWidth: 170,
        minWidth: 170,
        Filter: NumberRangeColumnFilter,
        filter: "between",
      },
      {
        Header: "Exchange Symbol",
        accessor: "ExchangeSymbol",
        // TODO
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        // filter: "fuzzyText",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Exchange Name",
        accessor: "Exchange",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        // filter: "fuzzyText",
        Filter: MultiSelectColumnFilter,
        filter: multiSelectFilterFn,
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "StatusId",
        accessor: "StatusId",
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row }) => (
          <MDTypography
            component="span"
            variant="button"
            fontWeight="regular"
            color={getColor(row.original.StatusId)}
          >
            {getDescription(row.original.StatusId)}
          </MDTypography>
        ),
        Filter: SelectColumnFilterDescription,
        filter: "equals",
        isSorted: false,
      },
      {
        Header: "Trading Volume Enough",
        accessor: "IsTradingVolumeEnough",
        align: "center",
        // code of button is in \DataTableRowSelectMarketRequest.jsx
        Filter: "",
      },
      {
        Header: "",
        accessor: "Action",
        align: "left",
        maxWidth: 140,
        minWidth: 140,
        Cell: ({ row }) => (
          <MDTypography variant="h4" color="text">
            {userData && ["admin", "cs", "data"].includes(userData.role) && (
              <Link
                to="/Market-Management/View-Market-Request"
                state={{ marketInfoRequest: row.original }}
              >
                <Icon>visibility</Icon>
              </Link>
            )}
            {(row.original.StatusId === 1 ||
              row.original.StatusId === 2 ||
              row.original.StatusId === 6) &&
              userData &&
              ["data"].includes(userData.role) && (
                <Link
                  to="/Market-Management/Edit-Market-Request-Market-Trading-Information"
                  state={{ marketInfoRequest: row.original }}
                >
                  <Icon>edit</Icon>
                </Link>
              )}
            {(row.original.StatusId === 2 ||
              row.original.StatusId === 3 ||
              row.original.StatusId === 6) &&
              userData &&
              ["admin"].includes(userData.role) && (
                <Link
                  to="/Market-Management/Edit-Market-Request-Market-Trading-Information"
                  state={{ marketInfoRequest: row.original }}
                >
                  <Icon>edit</Icon>
                </Link>
              )}
            {row.original.StatusId === 1 && userData && ["cs", "admin"].includes(userData.role) && (
              <Link
                to="/Market-Management/Edit-Market-Request"
                state={{ marketInfoRequest: row.original }}
              >
                <Icon>edit</Icon>
              </Link>
            )}
          </MDTypography>
        ),
        Filter: "",
      },
    ],
  };
}

// react

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import * as React from "react";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import dataMissingMarketAnalysisGMWDetails from "../Data/dataMissingMarketAnalysisGMWDetails";

function MissingMarketAnalysisGMWDetails({ dataTable1 }) {
  const { columns1 } = dataMissingMarketAnalysisGMWDetails();

  const { marketsDaily } = dataTable1;

  return (
    <Card>
      <Box sx={{ width: "100%" }}>
        <MDBox>
          {dataTable1 ? (
            <DataTable
              table={{ columns: columns1, rows: marketsDaily }}
              showTotalEntries={false}
              isSorted
              noEndBorder
              entriesPerPage={false}
            />
          ) : null}
        </MDBox>
      </Box>
    </Card>
  );
}

// Setting default props for the Footer
MissingMarketAnalysisGMWDetails.defaultProps = {
  dataTable1: {},
};

// Typechecking props for the Footer
MissingMarketAnalysisGMWDetails.propTypes = {
  dataTable1: PropTypes.shape({
    // message: PropTypes.string.isRequired,
    // myDate: PropTypes.string.isRequired,
    marketsDaily: PropTypes.arrayOf(PropTypes.object),
    // marketsWeekly: PropTypes.arrayOf(PropTypes.object),
    // marketsMonthly: PropTypes.arrayOf(PropTypes.object),
    // marketsQuarterly: PropTypes.arrayOf(PropTypes.object),
    // marketsYearly: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default MissingMarketAnalysisGMWDetails;

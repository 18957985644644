const baseURL = process.env.REACT_APP_baseURL;

const logError = async (request, response) => {
  const contentType = response.headers.get("content-type");
  let body;
  if (contentType && contentType.indexOf("application/json") !== -1) {
    body = await response.json();
  } else {
    body = await response.text();
  }
  // eslint-disable-next-line no-console
  console.error(`Error requesting ${request.method} ${request.url}`, body);
};

const http = async (config) => {
  const request = new Request(`${baseURL}${config.path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    body: config.body ? JSON.stringify(config.body) : undefined,
  });

  const myHeaders = new Headers();
  if (config.accessToken) {
    myHeaders.append("Authorization", `Bearer ${config.accessToken}`);
  }

  const requestOptions = {
    method: config.method || "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(request, requestOptions);
  if (response.ok) {
    const body = await response.json();
    return { ok: response.ok, body };
  }
  logError(request, response);
  return { ok: response.ok };
};

export default http;

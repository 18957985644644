const form = {
  formId: "new-user-form",
  formField: {
    MarketName: {
      name: "MarketName",
      label: "Market Name",
      type: "text",
      errorMsg: "Market Name is required.",
    },
    ExchangeSymbol: {
      name: "ExchangeSymbol",
      label: "Exchange Symbol",
      type: "text",
      errorMsg: "Exchange Symbol is required.",
      invalidMsg: "Exchange Symbol should be less than 10 characters.",
    },
    Exchange: {
      name: "Exchange",
      label: "Exchange",
      type: "text",
      errorMsg: "Exchange is required.",
    },
    Currency: {
      name: "Currency",
      label: "Currency",
      type: "text",
      errorMsg: "Currency is required.",
      invalidMsg: "Currency value is invalid",
    },
    SourceTicker: {
      name: "SourceTicker",
      label: "Source Ticker",
      type: "text",
    },
    ExternalRequester: {
      name: "ExternalRequester",
      label: "External Request",
      type: "text",
    },
    ExternalEmail: {
      name: "ExternalEmail",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    Comments: {
      name: "Comments",
      label: "Comments",
    },
    Sector: {
      name: "Sector",
      label: "Sector",
    },
    Category: {
      name: "Category",
      label: "Category",
    },
    Country: {
      name: "Country",
      label: "Country",
    },

    TradingVolume: {
      name: "TradingVolume",
      label: "TradingVolume",
      type: "number",
      errorMsg: "TradingVolume is required.",
    },
    TradingStartDate: {
      name: "TradingStartDate",
      label: "TradingStartDate",
      type: "text",
      errorMsg: "TradingStartDate is required.",
    },
    MarketHistoryYears: {
      name: "MarketHistoryYears",
      label: "MarketHistoryYears",
      type: "number",
      errorMsg: "MarketHistoryYears is required.",
    },
    Source: {
      name: "Source",
      label: "Source",
      type: "text",
      errorMsg: "Source is required.",
      invalidMsg: "Your source is invalid",
    },
    MarketHistoryMonths: {
      name: "MarketHistoryMonths",
      label: "MarketHistoryMonths",
      type: "number",
      errorMsg: "MarketHistoryMonths is required.",
    },
    MarketCap: {
      name: "MarketCap",
      label: "MarketCap",
      type: "number",
      errorMsg: "MarketCap is required.",
    },
  },
};

export default form;

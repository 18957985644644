/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

const { gradients } = colors;

function configs(color, labels, label, data, datasets) {
  return {
    data: {
      labels,
    },
    options: {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
            pointStyle: "rectRounded",
          },
        },
        datalabels: {
          formatter: (val) => val.y,
          // backgroundColor: "rgba(52, 71, 103, .1)",
          color: "rgba(52, 71, 103, .95)",
          align: "end",
          anchor: "start",
        },
      },
      pointHoverRadius: 10,
      intersect: false,
      mode: "index",
      scales: {
        y: {
          title: {
            display: true,
            text: "Elapsed Time",
          },
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 25,
            color: "#9ca2b7",
            font: {
              size: 12,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          type: "time",
          time: {
            // Luxon format string
            tooltipFormat: "DD T",
            unit: "day",
            unitStepSize: 1,
          },
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
            color: "#c1c4ce5c",
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 12,
              weight: 300,
              family: "Roboto",
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
    },
    datasets: datasets.map((el, index) => ({
      label: el.label,
      // data: el.data.map((x) => x.y),
      data: el.data,
      tension: 0,
      pointRadius: 4,
      pointBackgroundColor: Object.values(gradients)[index]
        ? Object.values(gradients)[index].state
        : gradients.info.state,
      borderColor: Object.values(gradients)[index].state,
      borderWidth: 4,
      backgroundColor: "transparent",
      maxBarThickness: 6,
      fill: true,
    })),
  };
}

export default configs;

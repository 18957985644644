/* eslint-disable react/prop-types */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Pagination from "@mui/material/Pagination";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";

import MDInput from "components/MDInput";

// eslint-disable-next-line react/prop-types
function MDTableFooter({
  showTotalEntries,
  pageOptions,
  entriesStart,
  entriesEnd,
  rows,
  pagination,
  renderPagination,
  customizedPageOptions,
  handleInputPagination,
  handleInputPaginationValue,
  pageIndex,
  handleChangePagination,
}) {
  return (
    <MDBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "flex-start", sm: "center" }}
      // eslint-disable-next-line react/prop-types
      p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
    >
      {showTotalEntries && (
        <MDBox mb={{ xs: 3, sm: 0 }}>
          <MDTypography variant="button" color="secondary" fontWeight="regular">
            Showing {entriesStart} to {entriesEnd} of {rows.length} entries
          </MDTypography>
        </MDBox>
      )}
      {pageOptions.length > 1 && (
        <MDPagination
          variant={pagination.variant ? pagination.variant : "gradient"}
          color={pagination.color ? pagination.color : "info"}
        >
          {renderPagination.length > 6 ? (
            <MDBox
              mx={1}
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
            >
              <Pagination
                count={renderPagination.length}
                page={customizedPageOptions[pageIndex]}
                onChange={handleChangePagination}
                color="info"
                showFirstButton
                showLastButton
              />
              <MDInput
                inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                value={customizedPageOptions[pageIndex]}
                onChange={(handleInputPagination, handleInputPaginationValue)}
              />
            </MDBox>
          ) : (
            renderPagination
          )}
        </MDPagination>
      )}
    </MDBox>
  );
}

// Setting default values for the props of MDTableFooter
MDTableFooter.defaultProps = {
  showTotalEntries: true,
  pageOptions: [],
  entriesStart: 0,
  entriesEnd: 0,
  pageIndex: 0,
  pagination: { variant: "gradient", color: "info" },
};

// Typechecking props for the MDTableFooter
MDTableFooter.propTypes = {
  showTotalEntries: PropTypes.bool,
  pageOptions: PropTypes.arrayOf(PropTypes.number),
  entriesStart: PropTypes.number,
  entriesEnd: PropTypes.number,
  pageIndex: PropTypes.number,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  handleInputPagination: PropTypes.func.isRequired,
  handleInputPaginationValue: PropTypes.func.isRequired,
  handleChangePagination: PropTypes.func.isRequired,
};

export default MDTableFooter;

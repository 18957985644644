// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

/* eslint-disable react/function-component-definition */
import { useEffect, useRef, useState } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import format from "date-fns/format";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./index.css";

import { addDays, startOfDay, endOfDay, isSameDay, differenceInCalendarDays } from "date-fns";

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
};

const DateRangePickerCompAnalysis = ({
  range,
  setRange,
  sideBarCustomObject,
  // setFilterByRange,
}) => {
  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBarCustomObject),
  ];

  return (
    <div className="calendarWrap">
      <input
        value={`${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
          range[0].endDate,
          "MM/dd/yyyy"
        )}`}
        readOnly
        className="inputBox"
        // eslint-disable-next-line no-shadow
        onClick={() => setOpen((open) => !open)}
      />

      <div ref={refOne}>
        {open && (
          <div>
            <DateRangePicker
              onChange={(item) => {
                // console.log("item", item);
                // setFilterByRange(true);
                setRange([item.selection]);
              }}
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={range}
              direction="horizontal"
              staticRanges={staticRanges}
              maxDate={new Date()}
              inputRanges={[
                {
                  label: "days up to today",
                  range(value) {
                    // console.log("VALUE", value);
                    if (value) {
                      return {
                        startDate: addDays(
                          defineds.startOfToday,
                          (Math.max(Number(value), 1) - 1) * -1
                        ),
                        endDate: defineds.endOfToday,
                        clearField: false,
                      };
                    }
                    return { startDate: new Date(), endDate: new Date(), clearField: true };
                  },
                  getCurrentValue(inputRange) {
                    // console.log("inputRange", inputRange);
                    if (inputRange.clearField) return "";
                    if (!isSameDay(inputRange.endDate, defineds.endOfToday)) return "-";
                    if (!inputRange.startDate) return "∞";
                    return differenceInCalendarDays(defineds.endOfToday, inputRange.startDate) + 1;
                  },
                },
              ]}
            />
            <MDBox mt={1} mb={1}>
              <Grid container alignItems="stretch" justifyContent="center" space={2}>
                <Grid item px={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={() => setOpen(false)}
                  >
                    Select Range
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </div>
        )}
      </div>
    </div>
  );
};

// Typechecking props for the DateRangePickerComp
DateRangePickerCompAnalysis.propTypes = {
  // range: PropTypes.shape({
  //   startDate: PropTypes.instanceOf(Date),
  //   endDate: PropTypes.instanceOf(Date),
  //   key: PropTypes.string,
  // }).isRequired,
  range: PropTypes.arrayOf(PropTypes.object).isRequired,
  setRange: PropTypes.func.isRequired,
  sideBarCustomObject: PropTypes.arrayOf(PropTypes.object).isRequired,
  // setFilterByRange: PropTypes.func.isRequired,
};

export default DateRangePickerCompAnalysis;

import * as Yup from "yup";
import checkout from "layouts/widgets/schemas/formTicket";

const {
  formFieldTicket: { title },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string().required(title.errorMsg),
    // description is not mandatory for this form at the moment
  }),
];

export default validations;

const CHART_COLORS = {
  red: ["rgb(255, 99, 132, 1)", "rgb(255, 99, 132, 0.2)"],
  orange: ["rgb(255, 159, 64, 1)", "rgb(255, 159, 64, 0.2)"],
  yellow: ["rgb(255, 205, 86, 1)", "rgb(255, 205, 86, 0.2)"],
  green: ["rgb(75, 192, 192, 1)", "rgb(75, 192, 192, 0.2)"],
  blue: ["rgb(54, 162, 235, 1)", "rgb(54, 162, 235, 0.2)"],
  purple: ["rgb(153, 102, 255, 1)", "rgb(153, 102, 255, 0.2)"],
  grey: ["rgb(201, 203, 207, 1)", "rgb(201, 203, 207, 0.2)"],
};

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.grey,
];

const namedColor = (index) => NAMED_COLORS[index % NAMED_COLORS.length];

export default namedColor;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import RecentUpdateWarnings from "layouts/datadownloadcheck/components/RecentUpdateWarnings";

// Dashboard components
import DataTableMarkets from "components/Tables/DataTableMarkets";

// Data
import marketFailedLedgerTableData from "layouts/tables/data/marketFailedLedgerTableData";

function DataDownloadCheck() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [marketFailedLedger, setMarketFailedLedger] = useState([]);

  const [isLoading, setisLoading] = useState(true);

  const getMarketFailedLedger = async () => {
    const result = await http({
      path: `/marketfailedledger/latest`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getMarketFailedLedgerStepperInfo = async () => {
    const result = await http({
      path: `/updatelogs/stepper`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDataCheck = async () => {
    const result = await http({
      path: `/updatelogs/processes/datacheck`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getLatestMarketFailedLedger = async () => {
    const result = await http({
      path: `/marketfailedledger/latest/oneregion`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getLogs = async (concern) => {
    const result = await http({
      path: `/updatelogs/concern/${concern}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [dataCheck, marketFailedLedgerDashboard, holidaysLogs] = useQueries({
    queries: [
      {
        queryKey: ["dataCheck"],
        queryFn: getDataCheck,
      },
      {
        queryKey: ["marketFailedLedger"],
        queryFn: getLatestMarketFailedLedger,
      },
      {
        queryKey: ["holidaysLogs"],
        queryFn: () => getLogs("Holidays"),
      },
    ],
  });

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const [loadedMarketFailedLedger, loadedMarketFailedLedgerStepperInfo] = await Promise.all(
            [getMarketFailedLedger(), getMarketFailedLedgerStepperInfo()]
          );

          const mfl = loadedMarketFailedLedger.map((mflel) => {
            let stepperValue = null;
            let stepperInsertedDate = null;

            const stepOneSuccess = loadedMarketFailedLedgerStepperInfo.filter(
              (el) =>
                el.ProcessType === 1 &&
                el.RunRegion[0] === mflel.RunRegion &&
                el.Status === "Success"
            );
            if (stepOneSuccess.length > 0) {
              stepperValue = 0;
              stepperInsertedDate = stepOneSuccess[0].InsertedDate;
              const stepTwoSuccess = loadedMarketFailedLedgerStepperInfo.filter(
                (el) =>
                  el.ProcessType === 2 &&
                  el.RunRegion[0] === mflel.RunRegion &&
                  el.Status === "Success"
              );
              if (stepTwoSuccess.length > 0) {
                stepperValue = 2;
              }
            } else {
              const stepOneFailed = loadedMarketFailedLedgerStepperInfo.filter(
                (el) =>
                  el.ProcessType === 1 &&
                  el.RunRegion[0] === mflel.RunRegion &&
                  el.Status === "Failed"
              );
              if (stepOneFailed.length > 0) {
                stepperValue = 5;
                stepperInsertedDate = stepOneFailed[0].InsertedDate;
                const stepTwoFailed = loadedMarketFailedLedgerStepperInfo.filter(
                  (el) =>
                    el.ProcessType === 2 &&
                    el.RunRegion[0] === mflel.RunRegion &&
                    el.Status === "Failed"
                );
                if (stepTwoFailed.length > 0) {
                  stepperValue = 6;
                }
              }
            }

            return { ...mflel, stepper: stepperValue, stepperInsertedDate };
          });

          setMarketFailedLedger(mfl);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  const { columns1 } = marketFailedLedgerTableData();

  if (isLoading) return <MDLoader />;
  if (dataCheck.isLoading) return <MDLoader />;
  if (marketFailedLedger.isLoading) return <MDLoader />;
  if (holidaysLogs.isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data Download and Upload Status
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTableMarkets
                  table={{ columns: columns1, rows: marketFailedLedger }}
                  noEndBorder
                  entriesPerPage={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RecentUpdateWarnings
                dataTable1={marketFailedLedgerDashboard.data}
                dataTable2={dataCheck.data}
                dataTable3={holidaysLogs.data}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataDownloadCheck;

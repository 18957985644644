// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCardMarketSubscription from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketSubscription";

// Dashboard components
import TopMoversChart from "examples/Charts/BarCharts/VerticalBarChartII/TopMoversChart";
import GmwCommentsDetails from "layouts/topmoverscheck/components/GmwCommentsDetails";

// Data

function TopMoversCheck() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [chartData, setChartData] = useState({});
  const [gmwComments, setGmwComments] = useState({});
  const [index, setIndex] = useState(0);
  const [indexDescription, setIndexDescription] = useState({});

  const [isLoading, setisLoading] = useState(true);

  const chart = (_gmwcomments) => {
    // if (_gmwcomments === {}) return;
    // eslint-disable-next-line no-unused-vars
    const { marketsDaily, marketsWeekly, marketsMonthly, marketsQuarterly, marketsYearly } =
      _gmwcomments;
    setChartData({
      labels: ["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"],
      datasets: [
        {
          label: "Top Movers Check By Timeframe",
          color: "dark",
          data: [
            marketsDaily.length,
            marketsWeekly.length,
            marketsMonthly.length,
            marketsQuarterly.length,
            marketsYearly.length,
          ],
        },
      ],
    });
  };

  const getTopMovers = async () => {
    const result = await http({
      path: `/toptable`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const [loadedTopMovers] = await Promise.all([getTopMovers()]);
          const marketsDaily = loadedTopMovers["1"][0]?.results || [];
          const marketsWeekly = loadedTopMovers["2"][0]?.results || [];
          const marketsMonthly = loadedTopMovers["3"][0]?.results || [];
          const marketsQuarterly = loadedTopMovers["4"][0]?.results || [];
          const marketsYearly = loadedTopMovers["5"][0]?.results || [];
          setGmwComments({
            marketsDaily,
            marketsWeekly,
            marketsMonthly,
            marketsQuarterly,
            marketsYearly,
          });

          setIndexDescription({
            description: "Daily",
            value: marketsDaily ? marketsDaily.length : 0,
          });

          chart({
            marketsDaily,
            marketsWeekly,
            marketsMonthly,
            marketsQuarterly,
            marketsYearly,
          });
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  const handleChangeIndex = (event, elements) => {
    if (elements[0]) {
      setIndex(elements[0].index);
      let description = "";
      switch (elements[0].index) {
        case 0:
          description = "Daily";
          break;
        case 1:
          description = "Weekly";
          break;
        case 2:
          description = "Monthly";
          break;
        case 3:
          description = "Quarterly";
          break;
        case 4:
          description = "Yearly";
          break;
        default:
          break;
      }
      setIndexDescription({ description, value: elements[0].element.$context.raw });
      // console.log(elements, elements[0].index, elements[0].element.$context.raw);
    }
  };

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={5}>
            <MDBox>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title={`Total Markets (${indexDescription.description})`}
                count={indexDescription.value}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={1}>
              <TopMoversChart
                icon={{ color: "dark", component: "leaderboard" }}
                title="Top Movers Check By Timeframe"
                description=""
                chart={chartData}
                handleChangeIndex={handleChangeIndex}
              />
            </MDBox>
            <MDBox>
              <GmwCommentsDetails
                dataTable1={gmwComments}
                index={index}
                setIndexDescription={setIndexDescription}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TopMoversCheck;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCardMarketSubscription from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketSubscription";

import GmwCommentsChart from "examples/Charts/BarCharts/HorizontalBarChartII/GmwCommentsChart";
import MissingMarketAnalysisGMWDetails from "layouts/missingmarketanalysisdata/components/MissingMarketsAnalysisGMWDetails";
import { formatDateInNewYork } from "utils/dateUtil";

function MissingMarketAnalysisData() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [runRegionV, setRunRegionV] = useState("");
  const [timeframeV, setTimeframeV] = useState("");
  const [analysisMarkets, setAnalysisMarkets] = useState([]);
  const [analysisDate, setAnalysisDate] = useState("");

  const getGmwcomments = async () => {
    const result = await http({
      path: `/analysis/data`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getLatestAnalysisDateByRegion = async () => {
    const result = await http({
      path: `/analysis/data/lad`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [gmwComments, latestAnalisysDate] = useQueries({
    queries: [
      {
        queryKey: ["gmwCommentsData"],
        queryFn: getGmwcomments,
      },
      {
        queryKey: ["latestAnalysisDateByRegion"],
        queryFn: getLatestAnalysisDateByRegion,
      },
    ],
  });

  useEffect(() => {
    setAnalysisDate("");
    if (timeframeV !== "" && runRegionV !== "") {
      let timeframe;
      let runregion;

      switch (timeframeV) {
        case "Daily":
          timeframe = 1;
          break;
        case "Weekly":
          timeframe = 2;
          break;
        case "Monthly":
          timeframe = 3;
          break;
        case "Quarterly":
          timeframe = 4;
          break;
        case "Yearly":
          timeframe = 5;
          break;
        default:
          timeframe = 0;
      }

      switch (runRegionV) {
        case "Americas":
          runregion = ["US2", "US3"];
          break;
        case "Asia":
          runregion = ["Asia"];
          break;
        case "Europe":
          runregion = ["Europe"];
          break;
        case "Economics":
          runregion = ["US4"];
          break;
        case "Bonds":
          runregion = ["US1", "US5"];
          break;

        default:
          runregion = [""];
      }

      const markets = gmwComments.data.filter(
        // eslint-disable-next-line no-underscore-dangle
        (m) =>
          runregion.includes(m.AnalysisMarket[0].RunRegion) &&
          // eslint-disable-next-line no-underscore-dangle
          m._id.TimeFrame === timeframe
      );

      const lad = latestAnalisysDate.data
        // .filter((l) => l.RunRegion === runRegionV && l.TimeFrame === timeframe)
        .filter((l) => l.TimeFrame === timeframe)
        .reduce((max, current) => (current.TotalMarkets > max.TotalMarkets ? current : max), {
          TotalMarkets: 0,
        });
      // const latestDate = latestAnalysisDate.AnalysisDate;

      setAnalysisDate(
        formatDateInNewYork(
          // latestAnalisysDate.data.filter(
          //   (l) => l.RunRegion === runRegionV && l.TimeFrame === timeframe
          // )[0].AnalysisDate
          lad.AnalysisDate
        )
      );

      setAnalysisMarkets(markets);
    }
  }, [runRegionV, timeframeV]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (gmwComments.isLoading) return <MDLoader />;
  if (gmwComments.isError) return <div>Error at loading analysis market</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Card>
                <Grid item xs={6} m={3} mb={2}>
                  <Autocomplete
                    id="RunRegion"
                    name="RunRegion"
                    options={["Americas", "Asia", "Europe", "Economics", "Bonds"]}
                    value={runRegionV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    onChange={(e, value) => {
                      setRunRegionV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Run Region" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={4}>
                  <Autocomplete
                    id="TimeFrame"
                    name="TimeFrame"
                    options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
                    value={timeframeV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 300 }}
                    onChange={(e, value) => {
                      // console.log(value);
                      setTimeframeV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Timeframe" />
                    )}
                  />
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <MDBox mb={3}>
                <Card sx={{ height: "25%" }}>
                  <MDBox pt={1} px={3}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Latest Analysis Date
                    </MDTypography>
                    <MDBox mt={0} mb={2}>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {analysisDate}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <GmwCommentsChart
                icon={{ color: "dark", component: "leaderboard" }}
                title="Missing Market Analysis"
                description=""
                height={150}
                chart={{
                  labels: [timeframeV],
                  datasets: [
                    {
                      label: "Missing Market Analysis",
                      color: "dark",
                      data: [analysisMarkets.length],
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title={`Missing Market Analysis ${timeframeV}`}
                count={analysisMarkets.length}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {analysisMarkets && analysisMarkets.length > 0 ? (
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox>
                <MissingMarketAnalysisGMWDetails
                  dataTable1={{ InsertedDate: [], marketsDaily: analysisMarkets }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      ) : null}
      <Footer />
    </DashboardLayout>
  );
}

export default MissingMarketAnalysisData;

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// NewMarketRequest page components
import FormField from "layouts/adduser/components/FormField";

function UserInfo({ formData }) {
  const { formField, values, errors, touched, roles, setFieldValue } = formData;
  const { username, password, role, name } = formField;
  const { username: usernameV, password: passwordV, role: roleV, name: nameV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={username.type}
              label={username.label}
              name={username.name}
              value={usernameV}
              placeholder={username.placeholder}
              error={errors.username && touched.username}
              success={usernameV.length > 0 && !errors.username}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordV}
              placeholder={password.placeholder}
              error={errors.password && touched.password}
              success={passwordV.length > 0 && !errors.password}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="role"
              name={role.name}
              options={roles.map((s) => s.name)}
              value={roleV || null}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              onChange={(e, value) => {
                // console.log(value);
                setFieldValue("role", value !== null ? value : "");
              }}
              renderInput={(params) => <MDInput {...params} variant="standard" label="Role" />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;

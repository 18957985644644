/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

// Append '4d' to the colors (alpha channel), except for the hovered index
function handleHover(evt, item, legend) {
  const { datasets } = legend.chart.data;

  datasets.forEach((dataset, datasetIndex) => {
    // Modify backgroundColor for each dataset in the array
    dataset.backgroundColor =
      dataset.backgroundColor.length === 9
        ? dataset.backgroundColor // If the color already has an alpha, do nothing
        : datasetIndex === item.datasetIndex // If it's the hovered dataset, don't apply alpha
        ? dataset.backgroundColor
        : dataset.backgroundColor + "4D"; // Apply '4D' alpha channel to other datasets
  });

  legend.chart.update();
}

// Removes the alpha channel from background colors
function handleLeave(evt, item, legend) {
  const { datasets } = legend.chart.data;

  datasets.forEach((dataset) => {
    // Check if the background color has an alpha channel (length 9) and remove it
    dataset.backgroundColor =
      dataset.backgroundColor.length === 9
        ? dataset.backgroundColor.slice(0, -2) // Remove the last two characters (the alpha channel)
        : dataset.backgroundColor; // Leave the color unchanged if no alpha channel
  });

  legend.chart.update();
}

function multicolorConfigs(labels, datasets, handleClick) {
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          // display: false,
          position: "top",
          onHover: handleHover,
          onLeave: handleLeave,
        },
        // title: {
        //   display: true,
        //   text: "Chart.js Bar Chart",
        // },
        datalabels: {
          color: "white",
          display(context) {
            // return false;
            return true;
            // conditional display based on value
            // return context.dataset.data[context.dataIndex] > 15;
          },
          font: {
            weight: "light",
          },
          formatter: Math.round,
        },
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 11,
              family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
      onClick: handleClick,
      onHover: (event, elements) => {
        // Change cursor style if hovering over a bar
        event.native.target.style.cursor = elements.length > 0 ? "pointer" : "default";
      },
    },
  };
}

export default multicolorConfigs;

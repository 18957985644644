/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import MDLink from "components/MDLink";
import MDTypography from "components/MDTypography";

const columns = [
  {
    Header: "Tags",
    accessor: "MarketTagName",
    align: "left",
  },
  {
    Header: "Number of Markets",
    accessor: (d) => d.Markets.length,
    align: "center",
  },
  {
    Header: "Volume (TBD)",
    accessor: "Count",
    align: "center",
    Cell: ({ row }) => {
      if (row.original.Count === 0) {
        return (
          <MDTypography variant="div" color="info">
            {row.original.Count}
          </MDTypography>
        );
      }
      // eslint-disable-next-line no-underscore-dangle
      const link = `/tag-volume-report/${row.original._id}`;
      return <MDLink to={link} text={row.original.Count.toString()} />;
    },
  },
];

export default columns;

/* eslint-disable react/prop-types */
import React from "react";
import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
// import { Link } from "react-router-dom";

function ModalDeleteMany({ modal, handleCloseModal, recordDetail, handleCancel, handleRedirect }) {
  return (
    <Modal
      open={modal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: "center",
        }}
      >
        <MDBox mb={2}>
          <MDTypography variant="h5" component="h2" fontWeight="bold">
            Delete Market Data Store
          </MDTypography>
        </MDBox>
        <MDBox mb={2}>
          <MDTypography variant="h6" fontWeight="light" color="text">
            Are you sure you want to delete <strong>{recordDetail.length}</strong> documents?
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between" mt={3}>
          <MDButton
            variant="contained"
            color="info"
            size="medium"
            onClick={handleCancel}
            sx={{ minWidth: 100 }}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="error"
            size="medium"
            onClick={() => handleRedirect(recordDetail)}
            sx={{ minWidth: 100, display: "flex", alignItems: "center" }}
          >
            <Icon sx={{ mr: 1 }}>delete</Icon>
            Yes, delete records
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

export default ModalDeleteMany;

import checkout from "layouts/adduser/schemas/form";

const {
  formField: { username, password, role, name },
} = checkout;

const initialValues = {
  [username.name]: "",
  [password.name]: "",
  [role.name]: "",
  [name.name]: "",
};

export default initialValues;

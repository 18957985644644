/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/newtag/schemas/form";

const {
  formField: { TagName },
} = checkout;

const validations = [
  Yup.object().shape({
    [TagName.name]: Yup.string().required(TagName.errorMsg).max(30, TagName.invalidMsg),
  }),
  Yup.object().shape({
    [TagName.name]: Yup.string().max(30, TagName.invalidMsg),
  }),
];

export default validations;

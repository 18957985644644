import * as Yup from "yup";
import checkout from "layouts/adduser/schemas/form";

const {
  formField: { username, password, role, name },
} = checkout;

const validations = [
  Yup.object().shape({
    [username.name]: Yup.string().email(username.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg),
    [role.name]: Yup.string().required(role.errorMsg),
    [name.name]: Yup.string().required(name.invalidMsg),
  }),
];

export default validations;

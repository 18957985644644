/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexStatisticsCardFailedMarket({
  color,
  title,
  count,
  icon,
  details1,
  details2,
  details3,
  details4,
}) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          <MDTypography variant="h4">{count}</MDTypography>
        </MDBox>
      </MDBox>
      <>
        <Divider />
        <MDBox pb={2} px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            {details1}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text" display="flex">
            {details2}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text" display="flex">
            {details3}
          </MDTypography>
          <MDTypography component="p" variant="button" color="text" display="flex">
            {details4}
          </MDTypography>
        </MDBox>
      </>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCardFailedMarket
ComplexStatisticsCardFailedMarket.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },

  details1: "",
  details2: "",
  details3: "",
  details4: "",
};

// Typechecking props for the ComplexStatisticsCardFailedMarket
ComplexStatisticsCardFailedMarket.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,

  details1: PropTypes.string,
  details2: PropTypes.string,
  details3: PropTypes.string,
  details4: PropTypes.string,
};

export default ComplexStatisticsCardFailedMarket;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// import MDTypography from "components/MDTypography";
// import { formatDateInNewYorkNoSec } from "utils/dateUtil";

// Material Dashboard 2 React components
export default function modelsColumns() {
  return {
    columns: [
      { Header: "Market Name", accessor: "DisplayName", align: "left" },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },

      { Header: "Sector", accessor: "Sector.DisplayName", align: "center" },
      { Header: "Region", accessor: "RunRegion", align: "center" },
      { Header: "Category", accessor: "Category", align: "center" },

      { Header: "Resistance", accessor: "latestTechnical.Resistance", align: "center" },
      { Header: "Support", accessor: "latestTechnical.Support", align: "center" },
      // {
      //   Header: "Market Name",
      //   accessor: "ResearchItem.DisplayName",
      //   align: "left",
      //   maxWidth: 220,
      //   minWidth: 220,
      //   Cell: ({ row }) => (
      //     <MDTypography
      //       component="a"
      //       href={`/markets/${row.original.ResearchItem.PEITicker}`}
      //       variant="h7"
      //       color="info"
      //       fontWeight="medium"
      //     >
      //       {row.original.ResearchItem.DisplayName}
      //     </MDTypography>
      //   ),
      // },
      // { Header: "PEI Ticker", accessor: "ResearchItem.PEITicker", align: "center" },
      // { Header: "Research Item ID", accessor: "ResearchItem._id", align: "center" },
      // {
      //   Header: "Sector",
      //   accessor: "ResearchItem.Sector.DisplayName",
      //   align: "center",
      // },
      // {
      //   Header: "RunRegion",
      //   accessor: "RunRegion",
      //   align: "center",
      // },
      // {
      //   Header: "Source",
      //   accessor: "Source",
      //   align: "center",
      // },
      // {
      //   Header: "Length",
      //   accessor: "Length",
      //   align: "center",
      // },
      // {
      //   Header: "Analysis Date",
      //   accessor: (d) => formatDateInNewYorkNoSec(d.AnalysisDate),
      //   align: "center",
      // },
      // { Header: "AnalysisId", accessor: "AnalysisId", align: "center" },
      // {
      //   Header: "GMW Comment",
      //   accessor: "Comment",
      //   align: "center",
      // },
    ],
  };
}

/* eslint-disable react/prop-types */
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DefaultStatisticsCardSubscriptionsOverview from "examples/Cards/StatisticsCards/DefaultStatisticsCardSubscriptionsOverview";
import DefaultStatisticsCardSubscriptionsOverviewExt from "examples/Cards/StatisticsCards/DefaultStatisticsCardSubscriptionsOverviewExt";
import React from "react";

const relativeValue = (reported, compared) => {
  if (reported.isNaN || compared.isNaN) {
    return "";
  }

  if (compared === 0) {
    return "";
  }

  const result = (((reported - compared) / compared) * 100).toFixed(0);
  return `${result}%`;
};

const absoluteDifferenceValue = (reported, compared) => {
  if (reported.isNaN || compared.isNaN) {
    return "";
  }

  return `${Math.abs((reported - compared).toFixed(0))}`;
};

function PerformanceOverviewCards({ totals, purchasedDate, showCompare, purchasedCompareDate }) {
  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox pt={3}>
            <Card>
              <MDBox py={1}>
                <MDBox px={1} m={3}>
                  <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                    Performance Overview
                  </MDTypography>
                </MDBox>
                <MDBox mb={1} mx={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <DefaultStatisticsCardSubscriptionsOverview
                        title={`All total subs for ${purchasedDate[0]} - ${purchasedDate[1]}`}
                        data={{
                          purchased: {
                            value: totals.tSub,
                            label: "Purchased",
                          },
                          cancelled: {
                            value: totals.tCan,
                            label: "Cancelled",
                          },
                          growth: {
                            value: totals.tNet,
                            label: "Growth",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <DefaultStatisticsCardSubscriptionsOverview
                        title={`All summary subs for ${purchasedDate[0]} - ${purchasedDate[1]}`}
                        data={{
                          purchased: {
                            value: totals.tSubSu,
                            label: "Purchased",
                          },
                          cancelled: {
                            value: totals.tCanSu,
                            label: "Cancelled",
                          },
                          growth: {
                            value: totals.tNetSu,
                            label: "Growth",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <DefaultStatisticsCardSubscriptionsOverview
                        title={`All detailed subs for ${purchasedDate[0]} - ${purchasedDate[1]}`}
                        data={{
                          purchased: {
                            value: totals.tSubDe,
                            label: "Purchased",
                          },
                          cancelled: {
                            value: totals.tCanDe,
                            label: "Cancelled",
                          },
                          growth: {
                            value: totals.tNetDe,
                            label: "Growth",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <DefaultStatisticsCardSubscriptionsOverview
                        title={`All premium subs for ${purchasedDate[0]} - ${purchasedDate[1]}`}
                        data={{
                          purchased: {
                            value: totals.tSubPr,
                            label: "Purchased",
                          },
                          cancelled: {
                            value: totals.tCanPr,
                            label: "Cancelled",
                          },
                          growth: {
                            value: totals.tNetPr,
                            label: "Growth",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                {/* Compare */}
                {showCompare && (
                  <MDBox mb={1} mx={3} mt={-8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <DefaultStatisticsCardSubscriptionsOverviewExt
                          title={`Compared to ${purchasedCompareDate[0]} - ${purchasedCompareDate[1]} period`}
                          data={{
                            purchased: {
                              value: totals.xtSub,
                              label: "Purchased",
                            },
                            cancelled: {
                              value: totals.xtCan,
                              label: "Cancelled",
                            },
                            growth: {
                              value: totals.xtNet,
                              label: "Growth",
                            },
                            relativePurchased: {
                              value: relativeValue(totals.tSub, totals.xtSub),
                              label: "Purchased",
                            },
                            relativeCancelled: {
                              value: relativeValue(totals.tCan, totals.xtCan),
                              label: "Cancelled",
                            },
                            relativeGrowth: {
                              value: relativeValue(totals.tNet, totals.xtNet),
                              label: "Growth",
                            },
                            absolutePurchased: {
                              value: absoluteDifferenceValue(totals.tSub, totals.xtSub),
                              label: "Purchased",
                            },
                            absoluteCancelled: {
                              value: absoluteDifferenceValue(totals.tCan, totals.xtCan),
                              label: "Cancelled",
                            },
                            absoluteGrowth: {
                              value: absoluteDifferenceValue(totals.tNet, totals.xtNet),
                              label: "Growth",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DefaultStatisticsCardSubscriptionsOverviewExt
                          title={`Compared to ${purchasedCompareDate[0]} - ${purchasedCompareDate[1]} period`}
                          data={{
                            purchased: {
                              value: totals.xtSubSu,
                              label: "Purchased",
                            },
                            cancelled: {
                              value: totals.xtCanSu,
                              label: "Cancelled",
                            },
                            growth: {
                              value: totals.xtNetSu,
                              label: "Growth",
                            },
                            relativePurchased: {
                              value: relativeValue(totals.tSubSu, totals.xtSubSu),
                              label: "Purchased",
                            },
                            relativeCancelled: {
                              value: relativeValue(totals.tCanSu, totals.xtCanSu),
                              label: "Cancelled",
                            },
                            relativeGrowth: {
                              value: relativeValue(totals.tNetSu, totals.xtNetSu),
                              label: "Growth",
                            },
                            absolutePurchased: {
                              value: absoluteDifferenceValue(totals.tSubSu, totals.xtSubSu),
                              label: "Purchased",
                            },
                            absoluteCancelled: {
                              value: absoluteDifferenceValue(totals.tCanSu, totals.xtCanSu),
                              label: "Cancelled",
                            },
                            absoluteGrowth: {
                              value: absoluteDifferenceValue(totals.tNetSu, totals.xtNetSu),
                              label: "Growth",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DefaultStatisticsCardSubscriptionsOverviewExt
                          title={`Compared to ${purchasedCompareDate[0]} - ${purchasedCompareDate[1]} period`}
                          data={{
                            purchased: {
                              value: totals.xtSubDe,
                              label: "Purchased",
                            },
                            cancelled: {
                              value: totals.xtCanDe,
                              label: "Cancelled",
                            },
                            growth: {
                              value: totals.xtNetDe,
                              label: "Growth",
                            },
                            relativePurchased: {
                              value: relativeValue(totals.tSubDe, totals.xtSubDe),
                              label: "Purchased",
                            },
                            relativeCancelled: {
                              value: relativeValue(totals.tCanDe, totals.xtCanDe),
                              label: "Cancelled",
                            },
                            relativeGrowth: {
                              value: relativeValue(totals.tNetDe, totals.xtNetDe),
                              label: "Growth",
                            },
                            absolutePurchased: {
                              value: absoluteDifferenceValue(totals.tSubDe, totals.xtSubDe),
                              label: "Purchased",
                            },
                            absoluteCancelled: {
                              value: absoluteDifferenceValue(totals.tCanDe, totals.xtCanDe),
                              label: "Cancelled",
                            },
                            absoluteGrowth: {
                              value: absoluteDifferenceValue(totals.tNetDe, totals.xtNetDe),
                              label: "Growth",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <DefaultStatisticsCardSubscriptionsOverviewExt
                          title={`Compared to ${purchasedCompareDate[0]} - ${purchasedCompareDate[1]} period`}
                          data={{
                            purchased: {
                              value: totals.xtSubPr,
                              label: "Purchased",
                            },
                            cancelled: {
                              value: totals.xtCanPr,
                              label: "Cancelled",
                            },
                            growth: {
                              value: totals.xtNetPr,
                              label: "Growth",
                            },
                            relativePurchased: {
                              value: relativeValue(totals.tSubPr, totals.xtSubPr),
                              label: "Purchased",
                            },
                            relativeCancelled: {
                              value: relativeValue(totals.tCanPr, totals.xtCanPr),
                              label: "Cancelled",
                            },
                            relativeGrowth: {
                              value: relativeValue(totals.tNetPr, totals.xtNetPr),
                              label: "Growth",
                            },
                            absolutePurchased: {
                              value: absoluteDifferenceValue(totals.tSubPr, totals.xtSubPr),
                              label: "Purchased",
                            },
                            absoluteCancelled: {
                              value: absoluteDifferenceValue(totals.tCanPr, totals.xtCanPr),
                              label: "Cancelled",
                            },
                            absoluteGrowth: {
                              value: absoluteDifferenceValue(totals.tNetPr, totals.xtNetPr),
                              label: "Growth",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default PerformanceOverviewCards;

/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState, useRef } from "react";

// context
import { useMaterialUIController } from "context";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useExpanded,
  useBlockLayout,
} from "react-table";
import { useSticky } from "react-table-sticky";

// @mui material components
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Card,
  Grid,
  Box,
  Icon,
  AppBar,
  TextField,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { themeColors } from "utils/productUtil";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import MDDatePickerMDYRef from "components/MDDatePickerMDYRef";

// Material Dashboard 2 React example components
import MDTableFooter from "components/MDTableFooter";
import MDBadge from "components/MDBadge";
import TimeFrame from "layouts/subscriptionsoverview/components/TimeFrame";
import customSort from "./components/sortFunctions";
import Highlight from "./components/Highlight";
import PerformanceOverviewCards from "./components/PerformanceOverviewCards";
import SubscriptionCharts from "./components/SubscriptionCharts";
import FiltersHeader from "./components/FiltersHeader";
import { Styles } from "./TableStyles";

function DefaultCell({ fontWeight = "regular", text }) {
  return (
    <MDBox display="flex" flexDirection="column" px={1}>
      <MDTypography
        variant="button"
        fontWeight={fontWeight}
        color="secondary"
        sx={{ fontSize: "0.775rem" }}
      >
        {text}
      </MDTypography>
    </MDBox>
  );
}

function TotalCollapsedCell({ total }) {
  return (
    <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
      {DefaultCell({ text: "All Subs" })}
      {DefaultCell({ text: total })}
    </MDBox>
  );
}

function TotalSubsExpandedCell({ total, summary, detailed, premium }) {
  return (
    <>
      <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
        {DefaultCell({ fontWeight: "medium", text: "All Subs" })}
        {DefaultCell({ fontWeight: "medium", text: total })}
      </MDBox>
      <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
        {DefaultCell({ text: "Summary" })}
        {DefaultCell({ text: summary })}
      </MDBox>
      <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
        {DefaultCell({ text: "Detailed" })}
        {DefaultCell({ text: detailed })}
      </MDBox>
      <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
        {DefaultCell({ text: "Premium" })}
        {DefaultCell({ text: premium })}
      </MDBox>
    </>
  );
}

function MarketNameExpandedCell({ row, filters }) {
  const nameFilter = filters.find((filter) => filter.id === "Name")?.value || "";

  return (
    <MDBox display="flex" alignItems="flex-start" justifyContent="space-between">
      <MDBox display="flex" flexDirection="column" ml={2}>
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="secondary"
          // sx={{ width: "160px", fontSize: "0.775rem" }}
          sx={{ fontSize: "0.775rem" }}
        >
          {/* <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} /> */}
          {/* {row.values.Name} */}
          <Highlight text={row.values.Name} highlight={nameFilter} />
        </MDTypography>
        {!row.original.GlobalMarketWatch && (
          <MDBadge color="error" size="xs" badgeContent="Inactive" container />
        )}
      </MDBox>
      <MDBox {...row.getToggleRowExpandedProps({})}>
        {row.isExpanded ? (
          <Icon fontSize="medium">arrow_drop_down</Icon>
        ) : (
          <Icon fontSize="medium">arrow_right</Icon>
        )}
      </MDBox>
    </MDBox>
  );
}

function ExchangeSymbolCell({ row, filters }) {
  const exchangeSymbolFilter =
    filters.find((filter) => filter.id === "ExchangeSymbol")?.value || "";

  return (
    <MDBox display="flex" flexDirection="column" ml={2}>
      <MDTypography
        variant="button"
        fontWeight="regular"
        color="secondary"
        // sx={{ width: "160px", fontSize: "0.775rem" }}
        sx={{ fontSize: "0.775rem" }}
      >
        {/* <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} /> */}
        {/* {row.values.Name} */}
        <Highlight text={row.values.ExchangeSymbol} highlight={exchangeSymbolFilter} />
      </MDTypography>
    </MDBox>
  );
}

function formatPlusMinus(subscriptions, cancellations) {
  if (subscriptions > cancellations) return `+${Math.abs(subscriptions - cancellations)}`;
  if (subscriptions === cancellations) return `0`;
  return `-${Math.abs(subscriptions - cancellations)}`;
}

function countProperty(rows, propertyName) {
  const result = {};
  rows.forEach((item) => {
    const propertyValue = item.original[propertyName];
    // Skip null or undefined values
    if (propertyValue != null) {
      result[propertyValue] = (result[propertyValue] || 0) + 1;
    }
  });

  // Convert the result to the desired format
  const formattedResult = Object.keys(result)
    .map((propertyValue) => ({
      _id: propertyValue,
      count: result[propertyValue],
    }))
    .sort((a, b) => b.count - a.count);

  return formattedResult;
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  // const count = preFilteredRows.length;

  return (
    <MDInput
      size="small"
      sx={{ width: "auto" }}
      value={filterValue || undefined}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Search"
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  const regex = new RegExp(filterValue, "i"); // 'i' makes it case-insensitive
  return rows.filter((row) => regex.test(row.values[id]));
}

function DataTableRowSelectSubscriptionsOverview({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  purchasedDate,
  setPurchasedDate,
  purchasedCompareDate,
  setPurchasedCompareDate,
  timeframe,
  setTimeframe,
  fixedNavbar,
  membershipType,
  setMembershipType,
  userRegistrationDate,
  setUserRegistrationDate,
  showCompare,
  radio,
  setRadio,
  calculatePurchasedCompareDate,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const [controller] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const light = false;
  const [isFilterCollapse, setIsFilterCollapse] = useState(false);

  const [totals, setTotals] = useState({
    tSub: 0,
    tSubSu: 0,
    tSubPr: 0,
    tSubDe: 0,
    tCan: 0,
    tCanSu: 0,
    tCanPr: 0,
    tCanDe: 0,
    tNet: 0,
    tNetSu: 0,
    tNetPr: 0,
    tNetDe: 0,
    xtSub: 0,
    xtSubSu: 0,
    xtSubPr: 0,
    xtSubDe: 0,
    xtCan: 0,
    xtCanSu: 0,
    xtCanPr: 0,
    xtCanDe: 0,
    xtNet: 0,
    xtNetSu: 0,
    xtNetPr: 0,
    xtNetDe: 0,
  });

  const firstDatePickerRef = useRef(null);
  const secondDatePickerRef = useRef(null);
  const firstDateCompareRef = useRef(null);
  const secondDateCompareRef = useRef(null);
  const firstUserRegDateCompareRef = useRef(null);
  const secondUserRegDateCompareRef = useRef(null);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      sortTypes: {
        customSort,
      },
      initialState: { pageIndex: 0 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useSticky
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = tableInstance;

  // recalculate the total calculated count after filters are applied

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  const handleChangePagination = (event, value) => {
    handleInputPagination({ target: { value } });
    handleInputPaginationValue({ target: { value } });
  };

  const handleRadioChange = (value) => {
    setRadio(value);
    calculatePurchasedCompareDate(value);
  };

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const [newCategory, setNewCategory] = useState([]);
  const [newPrimarySubCategory, setNewPrimarySubCategory] = useState([]);

  useEffect(() => {
    const tSubC = rows.reduce((acc, curr) => acc + curr.original.totalSubscriptions, 0);
    const tSubSuC = rows.reduce((acc, curr) => acc + curr.original.summarySubCalculated, 0);
    const tSubPrC = rows.reduce((acc, curr) => acc + curr.original.premiumSubCalculated, 0);
    const tSubDeC = rows.reduce((acc, curr) => acc + curr.original.detailedSubCalculated, 0);

    const tCanC = rows.reduce((acc, curr) => acc + curr.original.totalCancellations, 0);
    const tCanSuC = rows.reduce((acc, curr) => acc + curr.original.summaryCanCalculated, 0);
    const tCanPrC = rows.reduce((acc, curr) => acc + curr.original.premiumCanCalculated, 0);
    const tCanDeC = rows.reduce((acc, curr) => acc + curr.original.detailedCanCalculated, 0);

    // COMPARED
    const xtSubC = rows.reduce((acc, curr) => acc + curr.original.xtotalSubscriptions, 0);
    const xtSubSuC = rows.reduce((acc, curr) => acc + curr.original.xsummarySubCalculated, 0);
    const xtSubPrC = rows.reduce((acc, curr) => acc + curr.original.xpremiumSubCalculated, 0);
    const xtSubDeC = rows.reduce((acc, curr) => acc + curr.original.xdetailedSubCalculated, 0);

    const xtCanC = rows.reduce((acc, curr) => acc + curr.original.xtotalCancellations, 0);
    const xtCanSuC = rows.reduce((acc, curr) => acc + curr.original.xsummaryCanCalculated, 0);
    const xtCanPrC = rows.reduce((acc, curr) => acc + curr.original.xpremiumCanCalculated, 0);
    const xtCanDeC = rows.reduce((acc, curr) => acc + curr.original.xdetailedCanCalculated, 0);

    setTotals((prevTotals) => ({
      ...prevTotals,
      tSub: tSubC,
      tSubSu: tSubSuC,
      tSubPr: tSubPrC,
      tSubDe: tSubDeC,
      tCan: tCanC,
      tCanSu: tCanSuC,
      tCanPr: tCanPrC,
      tCanDe: tCanDeC,
      tNet: tSubC - tCanC,
      tNetSu: tSubSuC - tCanSuC,
      tNetPr: tSubPrC - tCanPrC,
      tNetDe: tSubDeC - tCanDeC,
      xtSub: xtSubC,
      xtSubSu: xtSubSuC,
      xtSubPr: xtSubPrC,
      xtSubDe: xtSubDeC,
      xtCan: xtCanC,
      xtCanSu: xtCanSuC,
      xtCanPr: xtCanPrC,
      xtCanDe: xtCanDeC,
      xtNet: xtSubC - xtCanC,
      xtNetSu: xtSubSuC - xtCanSuC,
      xtNetPr: xtSubPrC - xtCanPrC,
      xtNetDe: xtSubDeC - xtCanDeC,
    }));

    const categoryCounts = countProperty(rows, "Category");

    setNewCategory(categoryCounts);
    const primarySubCategoryCounts = countProperty(rows, "PrimarySubCategory");
    const totalSlices = 9;
    const top10PrimarySubCategory = primarySubCategoryCounts.slice(0, totalSlices);
    const otherPrimarySubCategoryCount = primarySubCategoryCounts
      .slice(totalSlices)
      .reduce((acc, item) => acc + item.count, 0);

    if (primarySubCategoryCounts.length > totalSlices) {
      top10PrimarySubCategory.push({
        _id: "Others",
        count: otherPrimarySubCategoryCount,
      });
    }

    setNewPrimarySubCategory(top10PrimarySubCategory);
  }, [data, filters]);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const [isAppBarVisible, setIsAppBarVisible] = useState(false);
  const scrollRefAppBar = useRef(null);
  const scrollRefBox = useRef(null);

  const syncScroll = (source) => {
    if (source === "AppBar" && scrollRefBox.current && scrollRefAppBar.current) {
      scrollRefBox.current.scrollLeft = scrollRefAppBar.current.scrollLeft;
    } else if (source === "Box" && scrollRefAppBar.current && scrollRefBox.current) {
      scrollRefAppBar.current.scrollLeft = scrollRefBox.current.scrollLeft;
    }
  };

  useEffect(() => {
    const handleScrollAppBar = () => {
      syncScroll("AppBar");
    };

    const handleScrollBox = () => {
      syncScroll("Box");
    };

    const appBarElement = scrollRefAppBar.current;
    const boxElement = scrollRefBox.current;

    if (isAppBarVisible && appBarElement && boxElement) {
      appBarElement.addEventListener("scroll", handleScrollAppBar);
      boxElement.addEventListener("scroll", handleScrollBox);
    }

    return () => {
      if (appBarElement) appBarElement.removeEventListener("scroll", handleScrollAppBar);
      if (boxElement) boxElement.removeEventListener("scroll", handleScrollBox);
    };
  }, [isAppBarVisible]);

  useEffect(() => {
    const handleScroll = () => {
      if (showCompare) {
        setIsAppBarVisible(window.scrollY >= 1400);
      } else {
        setIsAppBarVisible(window.scrollY >= 1070);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderHeader = (column, idx) => {
    const baseProps = column.getHeaderProps();
    let headerStyle = {};
    if (idx >= 9 || (idx > 1 && column.accessor === undefined))
      headerStyle = { background: "rgb(230,230,230)" };

    return (
      <div {...baseProps} className="th" style={{ ...baseProps.style, ...headerStyle }} key={idx}>
        <MDBox
          py={0.5}
          px={1.5}
          sx={() => ({
            verticalAlign: "top",
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.2) !important",
          })}
          {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
        >
          <MDBox
            position="relative"
            textAlign="left"
            color={darkMode ? "white" : "secondary"}
            opacity={0.7}
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
              cursor:
                setSortedValue(column) && column.accessor !== undefined ? "pointer" : undefined,
              userSelect:
                setSortedValue(column) && column.accessor !== undefined ? "none" : undefined,
            })}
            // width="180px"
          >
            <MDBox
              mr={1}
              color={darkMode ? "white" : "secondary"}
              opacity={0.7}
              sx={({ typography: { size, fontWeightBold } }) => ({
                fontSize: size.xxs,
                fontWeight: fontWeightBold,
                textTransform: "uppercase",
                textAlign: column.accessor !== undefined ? "" : "center",
                cursor:
                  setSortedValue(column) && column.accessor !== undefined ? "pointer" : undefined,
                userSelect:
                  setSortedValue(column) && column.accessor !== undefined ? "none" : undefined,
              })}
            >
              {column.render("Header")}
            </MDBox>
            {setSortedValue(column) && column.accessor !== undefined && (
              <MDBox
                position="absolute"
                top={0}
                right={(column.align ? column.align : "left") !== "right" ? "16px" : 0}
                left={(column.align ? column.align : "left") === "right" ? "-5px" : "unset"}
                sx={({ typography: { size } }) => ({
                  fontSize: size.lg,
                })}
              >
                <MDBox
                  position="absolute"
                  top={-6}
                  color={setSortedValue(column) === "asce" ? "text" : "secondary"}
                  opacity={setSortedValue(column) === "asce" ? 1 : 0.5}
                >
                  <Icon>arrow_drop_up</Icon>
                </MDBox>
                <MDBox
                  position="absolute"
                  top={0}
                  color={setSortedValue(column) === "desc" ? "text" : "secondary"}
                  opacity={setSortedValue(column) === "desc" ? 1 : 0.5}
                >
                  <Icon>arrow_drop_down</Icon>
                </MDBox>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox
          py={0.5}
          px={0.5}
          sx={() => ({
            verticalAlign: "top",
          })}
        >
          <MDBox
            position="relative"
            textAlign="center"
            color={darkMode ? "white" : "secondary"}
            opacity={0.7}
            sx={({ typography: { size, fontWeightBold } }) => ({
              fontSize: size.xxs,
              fontWeight: fontWeightBold,
              textTransform: "uppercase",
              cursor:
                setSortedValue(column) && column.accessor !== undefined ? "pointer" : undefined,
              userSelect:
                setSortedValue(column) && column.accessor !== undefined ? "none" : undefined,
            })}
          >
            {column.canFilter && column.filter ? column.render("Filter") : null}
          </MDBox>
        </MDBox>
      </div>
    );
  };

  let posFilter;
  if (fixedNavbar) posFilter = "100px";
  else posFilter = "16px";

  return (
    <>
      <AppBar position="sticky" style={{ top: posFilter }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mb={0}>
              <Card>
                {isFilterCollapse ? (
                  <MDBox p={3}>
                    <FiltersHeader
                      icon="expand_more"
                      setIsFilterCollapse={setIsFilterCollapse}
                      iconsStyle={iconsStyle}
                    />
                  </MDBox>
                ) : (
                  <MDBox p={3}>
                    <FiltersHeader
                      icon="expand_less"
                      setIsFilterCollapse={setIsFilterCollapse}
                      iconsStyle={iconsStyle}
                    />
                    <MDBox>
                      <Grid container spacing={3} pt={1}>
                        <Grid item xs={6} sm={6}>
                          <MDBox display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize"
                            >
                              Select Period
                            </MDTypography>
                          </MDBox>
                          <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "start date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                maxdate: purchasedDate[1] || null,
                                disabled: timeframe !== null,
                              }}
                              onChange={(e, value) => {
                                setPurchasedDate((old = []) => [value || undefined, old[1]]);
                                if (value && purchasedDate[1] === "") {
                                  secondDatePickerRef.current &&
                                    secondDatePickerRef.current.node.focus();
                                }
                              }}
                              value={purchasedDate[0] || ""}
                              ref={firstDatePickerRef}
                              disabled
                            />

                            <MDTypography variant="caption"> to </MDTypography>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "end date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                mindate: purchasedDate[0] || null,
                                disabled: timeframe !== null,
                              }}
                              onChange={(e, value) => {
                                setPurchasedDate((old = []) => [
                                  old[0],
                                  value ? value.concat("T23:59:59.999Z").slice(0, 10) : undefined,
                                ]);
                                if (value && purchasedDate[0] === "") {
                                  firstDatePickerRef.current &&
                                    firstDatePickerRef.current.node.focus();
                                }
                              }}
                              value={purchasedDate[1]?.slice(0, 10) || ""}
                              ref={secondDatePickerRef}
                            />
                            {` `}
                            <MDButton
                              size="small"
                              color="dark"
                              iconOnly
                              disabled={
                                purchasedDate[0] === "" ||
                                purchasedDate[1] === "" ||
                                timeframe !== null
                              }
                              onClick={() => {
                                setPurchasedDate(["", ""]);
                              }}
                            >
                              <Icon>close</Icon>
                            </MDButton>
                          </MDBox>
                          <Grid item xs={12} sm={12}>
                            <MDBox pt={1} px={0}>
                              <TimeFrame
                                timeframe={timeframe}
                                setTimeframe={setTimeframe}
                                setPurchasedDate={setPurchasedDate}
                              />
                            </MDBox>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <MDBox display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              Period to Compare
                            </MDTypography>
                          </MDBox>
                          <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "start date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                maxdate: purchasedCompareDate[1] || null,
                                disabled: radio !== null,
                              }}
                              onChange={(e, value) => {
                                setPurchasedCompareDate((old = []) => [value || undefined, old[1]]);
                                if (value && purchasedCompareDate[1] === "") {
                                  secondDateCompareRef.current &&
                                    secondDateCompareRef.current.node.focus();
                                }
                              }}
                              value={purchasedCompareDate[0] || ""}
                              ref={firstDateCompareRef}
                              disabled
                            />

                            <MDTypography variant="caption"> to </MDTypography>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "end date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                mindate: purchasedCompareDate[0] || null,
                                disabled: radio !== null,
                              }}
                              onChange={(e, value) => {
                                setPurchasedCompareDate((old = []) => [
                                  old[0],
                                  value ? value.concat("T23:59:59.999Z").slice(0, 10) : undefined,
                                ]);
                                if (value && purchasedCompareDate[0] === "") {
                                  firstDateCompareRef.current &&
                                    firstDateCompareRef.current.node.focus();
                                }
                              }}
                              value={purchasedCompareDate[1]?.slice(0, 10) || ""}
                              ref={secondDateCompareRef}
                            />
                            {` `}
                            <MDButton
                              size="small"
                              color="dark"
                              iconOnly
                              disabled={
                                purchasedCompareDate[0] === "" || purchasedCompareDate[1] === ""
                              }
                              onClick={() => {
                                setPurchasedCompareDate(["", ""]);
                                setRadio(null);
                              }}
                            >
                              <Icon>close</Icon>
                            </MDButton>
                          </MDBox>
                          <MDBox display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              or select one of the following options to compare
                            </MDTypography>
                          </MDBox>

                          <Grid item xs={12} sm={12}>
                            <RadioGroup
                              value={radio}
                              onChange={(e) => handleRadioChange(e.target.value)}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={radio === "preceding"}
                                    onChange={() =>
                                      handleRadioChange(radio === "preceding" ? null : "preceding")
                                    }
                                  />
                                }
                                label={
                                  <MDTypography
                                    component="label"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    textTransform="capitalize"
                                  >
                                    Preceding Period
                                  </MDTypography>
                                }
                              />
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                color="text"
                                ml={4}
                                mt={-1.5}
                              >
                                Same amount of time immediately prior to the selected one
                              </MDTypography>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={radio === "year-over-year"}
                                    onChange={() =>
                                      handleRadioChange(
                                        radio === "year-over-year" ? null : "year-over-year"
                                      )
                                    }
                                  />
                                }
                                label={
                                  <MDTypography
                                    component="label"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                  >
                                    Year-over-year
                                  </MDTypography>
                                }
                              />
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                color="text"
                                ml={4}
                                mt={-1.5}
                              >
                                Same dates a year back
                              </MDTypography>
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <Grid item xs={4} py={0} mt={1}>
                      <MDBox display="flex" justifyContent="space-between">
                        <MDTypography variant="h5" mt={0}>
                          Sub Filters
                        </MDTypography>
                        {(membershipType !== "" ||
                          (userRegistrationDate[0] &&
                            userRegistrationDate[1] &&
                            userRegistrationDate[0] !== undefined &&
                            userRegistrationDate[1] !== undefined)) && (
                          <MDButton
                            size="small"
                            disabled={
                              purchasedDate[0] === undefined || purchasedDate[1] === undefined
                            }
                            onClick={() => {
                              setMembershipType("");
                              setUserRegistrationDate(["", ""]);
                            }}
                          >
                            <MDTypography
                              variant="button"
                              fontWeight="light"
                              color="info"
                              textTransform="capitalize"
                            >
                              Clear All Sub Filters
                            </MDTypography>
                          </MDButton>
                        )}
                      </MDBox>
                    </Grid>
                    <MDBox>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={3}>
                          <MDBox display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize"
                            >
                              Membership Start Date
                            </MDTypography>
                          </MDBox>
                          <MDBox p={0} justifyContent="flex-start" alignItems="center" mt={1}>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "start date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                maxdate: userRegistrationDate[1] || null,
                              }}
                              onChange={(e, value) => {
                                setUserRegistrationDate((old = []) => [value || undefined, old[1]]);
                                if (value && userRegistrationDate[1] === "") {
                                  secondUserRegDateCompareRef.current &&
                                    secondUserRegDateCompareRef.current.node.focus();
                                }
                              }}
                              value={userRegistrationDate[0] || ""}
                              ref={firstUserRegDateCompareRef}
                            />
                            <MDTypography variant="caption"> to </MDTypography>
                            <MDDatePickerMDYRef
                              input={{
                                placeholder: "end date",
                                size: "small",
                                sx: { width: "5.1rem" },
                                mindate: userRegistrationDate[0] || null,
                              }}
                              onChange={(e, value) => {
                                setUserRegistrationDate((old = []) => [
                                  old[0],
                                  value ? value.concat("T23:59:59.999Z").slice(0, 10) : undefined,
                                ]);
                                if (value && userRegistrationDate[0] === "") {
                                  firstUserRegDateCompareRef.current &&
                                    firstUserRegDateCompareRef.current.node.focus();
                                }
                              }}
                              value={userRegistrationDate[1]?.slice(0, 10) || ""}
                              ref={secondUserRegDateCompareRef}
                            />
                            {` `}
                            <MDButton
                              size="small"
                              color="dark"
                              iconOnly
                              disabled={
                                userRegistrationDate[0] === "" || userRegistrationDate[1] === ""
                              }
                              onClick={() => {
                                setUserRegistrationDate(["", ""]);
                              }}
                            >
                              <Icon>close</Icon>
                            </MDButton>
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <MDBox display="inline-block">
                            <MDTypography
                              component="label"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              textTransform="capitalize"
                            >
                              Membership Type
                            </MDTypography>
                          </MDBox>
                          <MDBox mt={1}>
                            <Autocomplete
                              options={[
                                "All Memberships",
                                "Basic Membership",
                                "Plus Membership",
                                "Pro Membership",
                              ].filter(Boolean)}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label=""
                                  placeholder="Select"
                                  variant="standard"
                                  style={{
                                    color: "#a0a4b6",
                                    borderRadius: "4px",
                                    borderColor: "#a0a4b6",
                                  }}
                                />
                              )}
                              value={membershipType}
                              onChange={(_, value) => {
                                setMembershipType(value || "");
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                )}
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </AppBar>

      {/* Performance Overview, Cards */}
      <PerformanceOverviewCards
        totals={totals}
        purchasedDate={purchasedDate}
        showCompare={showCompare}
        purchasedCompareDate={purchasedCompareDate}
      />

      {/* Charts */}
      <SubscriptionCharts
        purchasedDate={purchasedDate}
        showCompare={showCompare}
        purchasedCompareDate={purchasedCompareDate}
        newCategory={newCategory}
        themeColors={themeColors}
        newPrimarySubCategory={newPrimarySubCategory}
      />

      {/* Table */}
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Subscription Overview
                  </MDTypography>
                </MDBox>
                <Box sx={{ width: "100%" }}>
                  <MDBox pt={2}>
                    <TableContainer sx={{ boxShadow: "none" }} ref={scrollRefBox}>
                      {entriesPerPage || canSearch ? (
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={3}
                        >
                          {entriesPerPage && (
                            <MDBox display="flex" alignItems="center">
                              <Autocomplete
                                disableClearable
                                value={pageSize.toString()}
                                options={entries}
                                onChange={(event, newValue) => {
                                  setEntriesPerPage(parseInt(newValue, 10));
                                }}
                                size="small"
                                sx={{ width: "5rem" }}
                                renderInput={(params) => <MDInput {...params} />}
                              />
                              <MDTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                              </MDTypography>
                            </MDBox>
                          )}
                          {canSearch && (
                            <MDBox width="12rem" ml="auto">
                              <MDInput
                                placeholder="Search..."
                                value={search}
                                size="small"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                  setSearch(search);
                                  onSearchChange(currentTarget.value);
                                }}
                              />
                            </MDBox>
                          )}
                        </MDBox>
                      ) : null}
                      <Styles>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={3}
                        >
                          <div
                            {...getTableProps()}
                            className="table sticky"
                            style={{ width: "auto", height: 605 }}
                          >
                            <div className="header">
                              {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()} className="tr">
                                  {headerGroup.headers.map(renderHeader)}
                                </TableRow>
                              ))}
                            </div>

                            <div {...getTableBodyProps()} className="body">
                              {page.map((row) => {
                                prepareRow(row);
                                return (
                                  <div {...row.getRowProps()} className="tr">
                                    {row.cells.map((cell, idx) => {
                                      const baseProps = cell.getCellProps();
                                      const headerStyle =
                                        idx >= 9 ? { background: "rgb(230, 230, 230)" } : {};
                                      return (
                                        <div
                                          {...cell.getCellProps()}
                                          className="td"
                                          style={{ ...baseProps.style, ...headerStyle }}
                                        >
                                          {idx === 0 ? ( // Market Name
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>{MarketNameExpandedCell({ row, filters })}</>
                                          ) : idx === 1 ? ( // Exchange Symbol
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>{ExchangeSymbolCell({ row, filters })}</>
                                          ) : idx === 2 ? ( // Market Category
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>{DefaultCell({ text: row.original.Category })}</>
                                          ) : idx === 3 ? ( // Market Primary Sub Category
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {DefaultCell({
                                                text: row.original.PrimarySubCategory,
                                              })}
                                            </>
                                          ) : idx === 4 ? ( // Total Subs
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total:
                                                      row.original.PreExisTotalSub +
                                                      row.original.totalSubscriptions -
                                                      row.original.totalCancellations,
                                                    summary:
                                                      row.original.PreExisSummary +
                                                      row.original.summarySubCalculated -
                                                      row.original.summaryCanCalculated,
                                                    detailed:
                                                      row.original.PreExisDetailed +
                                                      row.original.detailedSubCalculated -
                                                      row.original.detailedCanCalculated,
                                                    premium:
                                                      row.original.PreExisPremium +
                                                      row.original.premiumSubCalculated -
                                                      row.original.premiumCanCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total:
                                                      row.original.PreExisTotalSub +
                                                      row.original.totalSubscriptions -
                                                      row.original.totalCancellations,
                                                  })}
                                            </>
                                          ) : idx === 5 ? ( // Pre Existing
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.PreExisTotalSub,
                                                    summary: row.original.PreExisSummary,
                                                    detailed: row.original.PreExisDetailed,
                                                    premium: row.original.PreExisPremium,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.PreExisTotalSub,
                                                  })}
                                            </>
                                          ) : idx === 6 ? ( // Newly Purchased
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.totalSubscriptions,
                                                    summary: row.original.summarySubCalculated,
                                                    detailed: row.original.detailedSubCalculated,
                                                    premium: row.original.premiumSubCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.totalSubscriptions,
                                                  })}
                                            </>
                                          ) : idx === 7 ? ( // Cancelled Subs
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.totalCancellations,
                                                    summary: row.original.summaryCanCalculated,
                                                    detailed: row.original.detailedCanCalculated,
                                                    premium: row.original.premiumCanCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.totalCancellations,
                                                  })}
                                            </>
                                          ) : idx === 8 ? ( // Growth
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: formatPlusMinus(
                                                      row.original.totalSubscriptions,
                                                      row.original.totalCancellations
                                                    ),
                                                    summary: formatPlusMinus(
                                                      row.original.summarySubCalculated,
                                                      row.original.summaryCanCalculated
                                                    ),
                                                    detailed: formatPlusMinus(
                                                      row.original.detailedSubCalculated,
                                                      row.original.detailedCanCalculated
                                                    ),
                                                    premium: formatPlusMinus(
                                                      row.original.premiumSubCalculated,
                                                      row.original.premiumCanCalculated
                                                    ),
                                                  })
                                                : TotalCollapsedCell({
                                                    total: formatPlusMinus(
                                                      row.original.totalSubscriptions,
                                                      row.original.totalCancellations
                                                    ),
                                                  })}
                                            </>
                                          ) : idx === 9 ? ( // Comparsion Total Subs
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total:
                                                      row.original.xPreExisTotalSub +
                                                      row.original.xtotalSubscriptions -
                                                      row.original.xtotalCancellations,
                                                    summary:
                                                      row.original.xPreExisSummary +
                                                      row.original.xsummarySubCalculated -
                                                      row.original.xsummaryCanCalculated,
                                                    detailed:
                                                      row.original.xPreExisDetailed +
                                                      row.original.xdetailedSubCalculated -
                                                      row.original.xdetailedCanCalculated,
                                                    premium:
                                                      row.original.xPreExisPremium +
                                                      row.original.xpremiumSubCalculated -
                                                      row.original.xpremiumCanCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total:
                                                      row.original.xPreExisTotalSub +
                                                      row.original.xtotalSubscriptions -
                                                      row.original.xtotalCancellations,
                                                  })}
                                            </>
                                          ) : idx === 10 ? ( // Comparsion Pre Existing
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.xPreExisTotalSub,
                                                    summary: row.original.xPreExisSummary,
                                                    detailed: row.original.xPreExisDetailed,
                                                    premium: row.original.xPreExisPremium,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.xPreExisTotalSub,
                                                  })}
                                            </>
                                          ) : idx === 11 ? ( // Comparsion Newly Purchased
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.xtotalSubscriptions,
                                                    summary: row.original.xsummarySubCalculated,
                                                    detailed: row.original.xdetailedSubCalculated,
                                                    premium: row.original.xpremiumSubCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.xtotalSubscriptions,
                                                  })}
                                            </>
                                          ) : idx === 12 ? ( // Comparsion Cancelled Subs
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: row.original.xtotalCancellations,
                                                    summary: row.original.xsummaryCanCalculated,
                                                    detailed: row.original.xdetailedCanCalculated,
                                                    premium: row.original.xpremiumCanCalculated,
                                                  })
                                                : TotalCollapsedCell({
                                                    total: row.original.xtotalCancellations,
                                                  })}
                                            </>
                                          ) : idx === 13 ? ( // Comparsion Growth
                                            // eslint-disable-next-line react/jsx-no-useless-fragment
                                            <>
                                              {row.isExpanded
                                                ? TotalSubsExpandedCell({
                                                    total: formatPlusMinus(
                                                      row.original.xtotalSubscriptions,
                                                      row.original.xtotalCancellations
                                                    ),
                                                    summary: formatPlusMinus(
                                                      row.original.xsummarySubCalculated,
                                                      row.original.xsummaryCanCalculated
                                                    ),
                                                    detailed: formatPlusMinus(
                                                      row.original.xdetailedSubCalculated,
                                                      row.original.xdetailedCanCalculated
                                                    ),
                                                    premium: formatPlusMinus(
                                                      row.original.xpremiumSubCalculated,
                                                      row.original.xpremiumCanCalculated
                                                    ),
                                                  })
                                                : TotalCollapsedCell({
                                                    total: formatPlusMinus(
                                                      row.original.xtotalSubscriptions,
                                                      row.original.xtotalCancellations
                                                    ),
                                                  })}
                                            </>
                                          ) : (
                                            cell.render("Cell")
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </MDBox>
                      </Styles>

                      <MDTableFooter
                        showTotalEntries={showTotalEntries}
                        pageOptions={pageOptions}
                        entriesStart={entriesStart}
                        entriesEnd={entriesEnd}
                        pageIndex={pageIndex}
                        rows={rows}
                        pagination={pagination}
                        renderPagination={renderPagination}
                        customizedPageOptions={customizedPageOptions}
                        handleInputPagination={handleInputPagination}
                        handleInputPaginationValue={handleInputPaginationValue}
                        handleChangePagination={handleChangePagination}
                      />
                    </TableContainer>
                  </MDBox>
                </Box>
              </Card>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

// Setting default values for the props of DataTableRowSelectSubscriptionsOverview
DataTableRowSelectSubscriptionsOverview.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  category: [],
  primarysubcategory: [],
  timeframe: null,
  radio: null,
};

// Typechecking props for the DataTableRowSelectSubscriptionsOverview
DataTableRowSelectSubscriptionsOverview.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  category: PropTypes.arrayOf(PropTypes.object),
  primarysubcategory: PropTypes.arrayOf(PropTypes.object),
  purchasedDate: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPurchasedDate: PropTypes.func.isRequired,
  purchasedCompareDate: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPurchasedCompareDate: PropTypes.func.isRequired,
  timeframe: PropTypes.string,
  setTimeframe: PropTypes.func.isRequired,
  membershipType: PropTypes.string.isRequired,
  setMembershipType: PropTypes.func.isRequired,
  userRegistrationDate: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUserRegistrationDate: PropTypes.func.isRequired,
  showCompare: PropTypes.bool.isRequired,
  radio: PropTypes.bool,
  setRadio: PropTypes.func.isRequired,
  calculatePurchasedCompareDate: PropTypes.func.isRequired,
};

export default DataTableRowSelectSubscriptionsOverview;

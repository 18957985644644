import { useState, useEffect } from "react";
import io from "socket.io-client";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ControllerCardSteps from "examples/Cards/ControllerCardSteps";

// form page components
import TicketForm from "layouts/widgets/components/TicketForm";

// Widgets page components
import Chart from "layouts/widgets/components/Chart";

import validationsTicket from "layouts/widgets/schemas/validationsTicket";
import formTicket from "layouts/widgets/schemas/formTicket";
import initialValuesTicket from "layouts/widgets/schemas/initialValuesTicket";

// Data
import progressLineChartData from "layouts/widgets/data/progressLineChartData";
import caloriesChartData from "layouts/widgets/data/caloriesChartData";

const socket = io.connect(
  process.env.REACT_APP_baseURL.substring(0, process.env.REACT_APP_baseURL.length - 4)
  // 'http://localhost:3006/api' --> 'http://localhost:3006' without ".../api"
);

function getStepContentTicket(formData) {
  return <TicketForm formData={formData} />;
}

function Widgets() {
  // eslint-disable-next-line no-unused-vars
  const [tickets, setTickets] = useState([]);
  const [activeStepTicket, setActiveStepTicket] = useState(0);
  const [lights, setLights] = useState(false);

  const { formIdTicket, formFieldTicket } = formTicket;
  const currentValidationTicket = validationsTicket[activeStepTicket];

  useEffect(() => {
    socket.on("receive_tickets", (socketTickets) => {
      console.log("receive_tickets");
      setTickets(socketTickets);
    });
    socket.on("receive_ticket", (socketTicket) => {
      console.log("receive_ticket");
      setTickets([...tickets, socketTicket]);
    });
  }, [socket]);

  const handleSetLights = () => setLights(!lights);

  const submitFormTicket = async (values, actions) => {
    socket.emit("send_ticket", values);

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStepTicket(0);
  };

  const handleSubmitTicket = (values, actions) => {
    submitFormTicket(values, actions);
    // navigate("/markets-management");
  };

  const handleCancelTicket = (e) => {
    e.preventDefault();
    // console.log("Clearing the form ticket");
    // navigate("/markets-management");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox py={0} mb={20} height="28vh">
                <Grid
                  container
                  justifyContent="left"
                  alignItems="left"
                  sx={{ height: "100%", mt: 2 }}
                >
                  <Grid item xs={12} lg={6}>
                    <Formik
                      initialValues={initialValuesTicket}
                      validationSchema={currentValidationTicket}
                      onSubmit={handleSubmitTicket}
                    >
                      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                        <Form id={formIdTicket} autoComplete="off">
                          <Card sx={{ height: "100%" }}>
                            <MDBox
                              mx={2}
                              mt={-3}
                              py={3}
                              px={2}
                              variant="gradient"
                              bgColor="info"
                              borderRadius="lg"
                              coloredShadow="info"
                            >
                              <MDTypography variant="h6" color="white">
                                Submit New Ticket
                              </MDTypography>
                            </MDBox>
                            <MDBox p={3}>
                              <MDBox>
                                {getStepContentTicket({
                                  values,
                                  touched,
                                  formFieldTicket,
                                  errors,
                                  setFieldValue,
                                })}
                                <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                                  <MDButton
                                    disabled={isSubmitting}
                                    type="button"
                                    variant="gradient"
                                    color="info"
                                    sx={{ marginLeft: "40px" }}
                                    onClick={handleCancelTicket}
                                  >
                                    cancel
                                  </MDButton>
                                  <MDButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    sx={{ marginLeft: "40px" }}
                                  >
                                    submit
                                  </MDButton>
                                </MDBox>
                              </MDBox>
                            </MDBox>
                          </Card>
                        </Form>
                      )}
                    </Formik>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ProgressLineChart
                icon="date_range"
                title="Tickets"
                count={480}
                progress={60}
                height="13.375rem"
                chart={progressLineChartData}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Chart
                title="today tickets"
                count={480}
                percentage={{ color: "success", label: "+5%" }}
                chart={caloriesChartData}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <ControllerCardSteps
                state={lights}
                icon={
                  <Icon className={lights ? "text-white" : "text-dark"} fontSize="large">
                    {lights ? "directions_run" : "directions_walk"}
                  </Icon>
                }
                title={lights ? "script running..." : "run script"}
                onChange={handleSetLights}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Widgets;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { SelectColumnFilter, NumberRangeColumnFilter } from "components/TableFilters/selectByField";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function dataRecentUpdateWarnings() {
  return {
    columns1: [
      { Header: "name", accessor: "Name", align: "left" },
      { Header: "ticker", accessor: "PEITicker", align: "left" },
      { Header: "reason", accessor: (d) => d.Message.replace(" 00:00:00", ""), align: "left" },
      {
        Header: "last. refresh time",
        accessor: (d) => formatDateInNewYorkNoSec(d.DateTime),
        align: "left",
      },
    ],
    columns2: [
      // {
      //   Header: "TimeFrame",
      //   accessor: "TimeFrame",
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      //   Filter: "",
      // },
      {
        Header: "PeiTicker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      { Header: "name", accessor: "Name", align: "left", maxWidth: 310, minWidth: 310, Filter: "" },
      {
        Header: "sector",
        accessor: "Sector",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: SelectColumnFilter,
        filter: "includes",
        isSorted: false,
      },
      {
        Header: "region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: SelectColumnFilter,
        filter: "includes",
        isSorted: false,
      },
      {
        Header: "source",
        accessor: "Source",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: SelectColumnFilter,
        filter: "includes",
        isSorted: false,
      },
      {
        Header: "ticker",
        accessor: "Ticker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
        Filter: "",
      },
      // {
      //   Header: "percentage",
      //   accessor: (d) => {
      //     let percentage = "";
      //     if (d.Errors) {
      //       const arraySorted = d.Errors.sort((a, b) => b.Percentage - a.Percentage);
      //       percentage = `${arraySorted[0].Percentage.toFixed(2)}`;
      //     } else {
      //       percentage = "";
      //     }
      //     return percentage;
      //   },
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      //   Filter: "",
      // },

      {
        Header: "percentage",
        accessor: (d) => d.maxAbsoluteValue.Percentage.toFixed(2),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        isSorted: false,
      },

      // {
      //   Header: "percentage2",
      //   accessor: (d) => {
      //     let percentage = "";
      //     if (d.Sector === "Bonds" && Math.abs(d.maxAbsoluteValue.Percentage) >= 500) {
      //       percentage = d.maxAbsoluteValue.Percentage.toFixed(2);
      //     } else if (Math.abs(d.maxAbsoluteValue.Percentage) >= 100) {
      //       percentage = d.maxAbsoluteValue.Percentage.toFixed(2);
      //     }
      //     return percentage;
      //   },
      //   align: "left",
      //   maxWidth: 85,
      //   minWidth: 85,
      //   Filter: "",
      // },

      // {
      //   Header: "Date",
      //   accessor: (d) => {
      //     let percentage = "";
      //     if (d.Errors) {
      //       const arraySorted = d.Errors.sort((a, b) => b.Percentage - a.Percentage);
      //       percentage = `${moment(arraySorted[0].Date).format("MM/DD/YYYY hh:mm A")}`;
      //     } else {
      //       percentage = "";
      //     }
      //     return percentage;
      //   },
      //   align: "left",
      //   maxWidth: 220,
      //   minWidth: 220,
      //   Filter: "",
      // },

      // {
      //   Header: "Reported Date",
      //   accessor: (d) => moment(d.maxAbsoluteValue.Date).format("MM/DD/YYYY hh:mm A"),
      //   align: "left",
      //   maxWidth: 220,
      //   minWidth: 220,
      //   Filter: "",
      //   sortType: "datetime",
      // },
      {
        Header: "Reported Date",
        accessor: "maxAbsoluteValueDate",
        align: "left",
        maxWidth: 190,
        minWidth: 190,
        Filter: "",
        Cell: ({ row }) => formatDateInNewYorkNoSec(row.original.maxAbsoluteValueDate),
      },

      // {
      //   Header: "DateTime",
      //   accessor: (d) => moment(d.DateTime).format("MM/DD/YYYY hh:mm A"),
      //   align: "left",
      //   maxWidth: 220,
      //   minWidth: 220,
      //   Filter: "",
      // },
    ],
  };
}

const form = {
  formId: "new-user-form",
  formField: {
    username: {
      name: "username",
      label: "Email",
      type: "email",
      errorMsg: "Email is required.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
    },
    role: {
      name: "role",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
    },
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
  },
};

export default form;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react
import { useEffect, useState } from "react";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import namedColor from "constants/constants";
import { Chart } from "chart.js";

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import dataRecentUpdateWarnings from "../../../dashboard/components/Projects/dataRecentUpdateWarnings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Projects({ dataTable1, dataTable2, dataTable3 }) {
  const { columns1, columns2 } = dataRecentUpdateWarnings();
  const [timeframe, setTimeframe] = useState("daily");
  const [totalCount, setTotalCount] = useState(
    dataTable2.filter((el) => el.TimeFrame === "D").length
  );
  const [dataTableFiltered2, setDataTableFiltered2] = useState(
    dataTable2.filter((el) => el.TimeFrame === "D")
  );

  useEffect(() => {
    setTimeframe(null, "daily");
  }, [dataTable2]);

  const handleChangeTimeframe = (event, newTimeframe) => {
    setTimeframe(newTimeframe);
    let timeFrameCode = "";
    switch (newTimeframe) {
      case "yearly":
        timeFrameCode = "Y";
        break;
      case "quarterly":
        timeFrameCode = "Q";
        break;
      case "monthly":
        timeFrameCode = "M";
        break;
      case "weekly":
        timeFrameCode = "W";
        break;
      case "daily":
        timeFrameCode = "D";
        break;
      default:
        break;
    }
    const newDataTable2 = dataTable2.filter((el) => el.TimeFrame === timeFrameCode);
    setDataTableFiltered2(newDataTable2);
    setTotalCount(newDataTable2.length);
  };

  let myChart2 = null;
  let numberOfBars = 0;
  const objMessages = {};
  dataTable1.forEach((el) => {
    if (!objMessages[el.Message]) objMessages[el.Message] = 1;
  });
  numberOfBars = Object.keys(objMessages).length * 6.6;

  const [value, setValue] = React.useState(0);
  const [isDetailsShown, setIsDetailsShown] = useState(true);

  const configureChartLogs = async (dataLogs, titleLogs) => {
    if (dataLogs.length === 0) return;
    // eslint-disable-next-line prefer-template
    const ctx = document.getElementById("recentErrorsSummaryChart" + titleLogs.replace(/ /g, ""));
    if (ctx === null) return;

    const messagesObj = {};

    dataLogs.forEach((element) => {
      if (!messagesObj[element.Message]) {
        messagesObj[element.Message] = 1;
      } else messagesObj[element.Message] += 1;
    });

    const labelsMessages = [];
    const dataMessages = [];
    const bgColor = [];
    const bColor = [];
    let maxValue = 0;

    Object.keys(messagesObj).forEach((key, index) => {
      if (messagesObj[key] > maxValue) maxValue = messagesObj[key]; // maxValue for calculate the width
      const dsColor = namedColor(index);
      labelsMessages.push(key);
      dataMessages.push(messagesObj[key]);
      bColor.push(dsColor[0]);
      bgColor.push(dsColor[1]);
    });

    if (myChart2) myChart2.destroy();

    myChart2 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labelsMessages,
        datasets: [
          {
            label: "Data Download by Reason",
            data: dataMessages,
            backgroundColor: [
              "rgb(255, 99, 132, 1)",
              "rgb(255, 205, 86, 1)",
              "rgb(54, 162, 235, 1)",
              "rgb(255, 159, 64, 1)",
              "rgb(153, 102, 255, 1)",
              "rgb(255, 99, 132, 1)",
              "rgb(255, 205, 86, 1)",
              "rgb(54, 162, 235, 1)",
              "rgb(255, 159, 64, 1)",
              "rgb(153, 102, 255, 1)",
            ],
            borderColor: [
              "rgb(255, 99, 132, 1)",
              "rgb(255, 205, 86, 1)",
              "rgb(54, 162, 235, 1)",
              "rgb(255, 159, 64, 1)",
              "rgb(153, 102, 255, 1)",
              "rgb(255, 99, 132, 1)",
              "rgb(255, 205, 86, 1)",
              "rgb(54, 162, 235, 1)",
              "rgb(255, 159, 64, 1)",
              "rgb(153, 102, 255, 1)",
            ],
            borderWidth: 0,
            // barPercentage: 0.95,
            barThickness: 10,
          },
        ],
      },
      options: {
        layout: { padding: { left: 0, right: 0 } },
        indexAxis: "y",
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          datalabels: {
            formatter: (val) => val.y,
            // backgroundColor: "rgba(52, 71, 103, .1)",
            // color: "rgba(52, 71, 103, .95)",
            color: "rgba(12, 12, 12, 1)",
            align: "center",
            anchor: "center",
          },
        },
        scale: {
          ticks: {
            precision: 0,
          },
        },
        scales: {
          y: {
            display: true, // Hide Y axis labels
          },
          x: {
            display: true, // Hide X axis labels
            max: maxValue * 1.5,
          },
        },
      },
    });
  };

  useEffect(() => {
    configureChartLogs(dataTable1, "Data Download Failed");
  }, [dataTable1, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Recent Update Warnings
        </MDTypography>
      </MDBox>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Data Download" {...a11yProps(0)} />
            <Tab label="Data Check" {...a11yProps(1)} />
            <Tab label="Holidays" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <MDBox>
            {dataTable1.length > 0 ? (
              <>
                <canvas
                  id="recentErrorsSummaryChartDataDownloadFailed"
                  style={{ maxHeight: `${numberOfBars}vh` }}
                />
                <br />
                <MDButton
                  style={{ position: "absolute", left: "50%" }}
                  color="info"
                  size="small"
                  onClick={() => setIsDetailsShown(!isDetailsShown)}
                >
                  {isDetailsShown ? "Hide" : "Show"} Details
                </MDButton>
                <br />
              </>
            ) : null}
          </MDBox>
          <MDBox>
            {dataTable1 && isDetailsShown ? (
              <DataTable
                table={{ columns: columns1, rows: dataTable1 }}
                showTotalEntries={false}
                isSorted={false}
                noEndBorder
                entriesPerPage={false}
              />
            ) : null}
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MDBox>
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Card id="filter">
                    <MDBox pt={3} px={2}>
                      <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                        Filters
                      </MDTypography>
                      <MDTypography
                        variant="h5"
                        fontWeight="bold"
                        color="error"
                        textTransform="uppercase"
                      >
                        List of markets with high jump in data ({timeframe || "daily"}):{" "}
                        {totalCount}
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={1} pb={2} px={2}>
                      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        <ToggleButtonGroup
                          value={timeframe}
                          exclusive
                          onChange={handleChangeTimeframe}
                          aria-label="text alignment"
                        >
                          <ToggleButton value="daily" aria-label="centered">
                            <MDTypography
                              component="span"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              opacity={0.9}
                              sx={{ lineHeight: 0 }}
                            >
                              Daily
                            </MDTypography>
                          </ToggleButton>
                          <ToggleButton value="weekly" aria-label="centered">
                            <MDTypography
                              component="span"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              opacity={0.9}
                              sx={{ lineHeight: 0 }}
                            >
                              Weekly
                            </MDTypography>
                          </ToggleButton>
                          <ToggleButton value="monthly" aria-label="centered">
                            <MDTypography
                              component="span"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              opacity={0.9}
                              sx={{ lineHeight: 0 }}
                            >
                              Monthly
                            </MDTypography>
                          </ToggleButton>
                          <ToggleButton value="quarterly" aria-label="centered">
                            <MDTypography
                              component="span"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              opacity={0.9}
                              sx={{ lineHeight: 0 }}
                            >
                              Quarterly
                            </MDTypography>
                          </ToggleButton>
                          <ToggleButton value="yearly" aria-label="centered">
                            <MDTypography
                              component="span"
                              variant="button"
                              color="text"
                              fontWeight="regular"
                              opacity={0.9}
                              sx={{ lineHeight: 0 }}
                            >
                              Yearly
                            </MDTypography>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <MDBox>
            <DataTableWithColumnFilters
              table={{ columns: columns2, rows: dataTableFiltered2 }}
              showTotalEntries
              isSorted
              noEndBorder
              entriesPerPage
              canSearch
              arrayColumnsToSkipSorted={["region", "source", "sector", "percentage"]}
            />
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MDBox>
            <DataTable
              table={{ columns: columns1, rows: dataTable3 }}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
          </MDBox>
        </TabPanel>
      </Box>
    </Card>
  );
}

// Setting default props for the Footer
Projects.defaultProps = {
  dataTable1: [],
  dataTable2: [],
  dataTable3: [],
};

// Typechecking props for the Footer
Projects.propTypes = {
  dataTable1: PropTypes.arrayOf(PropTypes.object),
  dataTable2: PropTypes.arrayOf(PropTypes.object),
  dataTable3: PropTypes.arrayOf(PropTypes.object),
};

export default Projects;

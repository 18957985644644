// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewMarketRequest page components

function MarketInfo({ formData }) {
  const { formField, values, errors, distinctMarketTags, setFieldValue, market } = formData;
  const { marketTags } = formField;
  const { marketTags: marketTagsV } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <MDTypography variant="button" color="text">
              PEITicker: {market.PEITicker || ""}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <MDTypography variant="button" color="text">
              Name: {market.Name || ""}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="marketTags"
                name={marketTags.name}
                options={distinctMarketTags.map((el) => el.MarketTagName)}
                filterSelectedOptions
                value={marketTagsV || []}
                getOptionLabel={(option) => option}
                // style={{ width: 400 }}
                onChange={(e, value) => {
                  setFieldValue("marketTags", value !== null ? value : "");
                }}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <MDInput {...params} label="Market Tags" placeholder="Market Tags" />
                )}
              />
            </Stack>
            {errors && errors.marketTags && (
              <MDTypography variant="button" color="error">
                {errors.marketTags}
              </MDTypography>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MarketInfo
MarketInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MarketInfo;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDLink from "components/MDLink";
import { formatDateInNewYork, formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function data() {
  // const calculatePercentage = (total, number) => (number * 100) / total;

  return {
    columns: [
      {
        Header: "Region",
        accessor: "Region",
        align: "left",
      },
      { Header: "Number of Markets", accessor: "TotalNoOfMarkets", align: "center" },
      {
        Header: "Failed Markets",
        accessor: "failedMarketsCalculated",
        align: "center",
        Cell: ({ row }) => {
          if (!row.original.flag)
            return (
              <MDLink
                to={`/failed-markets/region/${row.original.Region}`}
                text={row.original.failedMarketsCalculated.toString()}
              />
            );
          return (
            <MDLink
              to={`/failed-markets/region/${row.original.Region}`}
              text={row.original.failedMarketsCalculatedMinus.toString()}
            />
          );
        },
      },
      {
        Header: "Exp. Refresh Time",
        // accessor: (d) => moment(new Date("2019/06/01 14:04:03")).format("MM/DD/YYYY hh:mm A"),
        // eslint-disable-next-line no-unused-vars
        // accessor: (d) => moment(d.CreationDate).format("MM/DD/YYYY hh:mm A"),
        align: "center",
        Cell: ({ row }) => {
          const myDateTime = new Date();
          switch (row.original.Region) {
            case "Asia":
              myDateTime.setHours(9);
              myDateTime.setMinutes(30);
              myDateTime.setSeconds(0);
              break;
            case "Europe":
              myDateTime.setHours(17);
              myDateTime.setMinutes(30);
              myDateTime.setSeconds(0);
              break;
            case "Americas":
              myDateTime.setHours(21);
              myDateTime.setMinutes(45);
              myDateTime.setSeconds(0);
              break;
            default:
              break;
          }
          return `${formatDateInNewYorkNoSec(myDateTime)} EST`;
        },
      },
      {
        Header: "Last Refresh Time",
        // accessor: "LastUpdateDate",
        accessor: (d) => formatDateInNewYork(d.LastUpdateDate),
        align: "center",
      },
      {
        Header: "Earliest Trade",
        accessor: "earliestTrade",
        align: "center",
        // eslint-disable-next-line no-unused-vars
        Cell: ({ row }) => {
          const day = new Date().getDay();
          let color = "green";
          if (day === 0 || day === 6) {
            color = "red";
          }
          return (
            <div
              style={{
                backgroundColor: color,
                borderRadius: "30px",
                padding: "10px",
              }}
            />
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        align: "center",
        Cell: ({ row }) => {
          let badge = (
            <MDBox ml={-1}>
              <MDBadge badgeContent="Attention" color="error" variant="gradient" size="sm" />
            </MDBox>
          );
          if (row.original.failedMarketsCalculated === 0) {
            badge = (
              <MDBox ml={-1}>
                <MDBadge badgeContent="Success" color="success" variant="gradient" size="sm" />
              </MDBox>
            );
          } else if (row.original.failedMarketsCalculated <= 10) {
            badge = (
              <MDBox ml={-1}>
                <MDBadge badgeContent="Warning" color="warning" variant="gradient" size="sm" />
              </MDBox>
            );
          } else if (row.original.failedMarketsCalculated > 10) {
            badge = (
              <MDBox ml={-1}>
                <MDBadge badgeContent="Attention" color="error" variant="gradient" size="sm" />
              </MDBox>
            );
          }
          return badge;
        },
      },
    ],
  };
}

// react
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableMarketFailedLedger from "components/Tables/DataTableMarketFailedLedger";

// Data
import marketFailedLedgerDetailTableData from "layouts/tables/data/marketFailedLedgerDetailTableData";

import { useParams } from "react-router-dom";

const { columns1 } = marketFailedLedgerDetailTableData();

function MarketFailedLedger() {
  const { region } = useParams();

  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const [isLoading, setisLoading] = useState(true);
  const [marketFailedLedger, setMarketFailedLedger] = useState([]);

  const getMarketFailedLedger = async () => {
    const result = await http({
      path: `/marketfailedledger/latest/${region}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedMarketFailedLedger = await getMarketFailedLedger();
          setMarketFailedLedger(loadedMarketFailedLedger);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Data Processes Detail
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTableMarketFailedLedger
              table={{
                columns: columns1,
                rows: marketFailedLedger,
              }}
              isSorted
              entriesPerPage
              showTotalEntries
              noEndBorder
              canSearch
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketFailedLedger;

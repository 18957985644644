import checkout from "layouts/newmarketrequest/schemas/form";

const {
  formField: {
    marketName,
    exchangeSymbol,
    exchange,
    currency,
    sourceTicker,
    externalRequester,
    externalEmail,
    comments,
  },
} = checkout;

const initialValues = {
  [marketName.name]: "",
  [exchangeSymbol.name]: "",
  [exchange.name]: "",
  [currency.name]: "",
  [sourceTicker.name]: "",
  [externalRequester.name]: false,
  [externalEmail.name]: "",
  [comments.name]: "",
};

export default initialValues;

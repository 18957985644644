/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDTypography from "components/MDTypography";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";

export default function dataGmwCommentsErrorDetails() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDTypography
            component="a"
            href={`/markets/${row.original.PEITicker}`}
            variant="h7"
            color="info"
            fontWeight="medium"
          >
            {row.original.Name}
          </MDTypography>
        ),
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "center" },
      { Header: "Research Item ID", accessor: "_id", align: "center" },
      {
        Header: "Sector",
        accessor: "Sector",
        align: "center",
      },
      {
        Header: "RunRegion",
        accessor: "RunRegion",
        align: "center",
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "center",
      },
      {
        Header: "Length",
        accessor: "Length",
        align: "center",
        Cell: ({ row }) => {
          let value;
          switch (row.original.TimeFrame) {
            case "Daily":
              value = row.original["Length of Daily"];
              break;
            case "Weekly":
              value = row.original["Length of Weekly"];
              break;
            case "Monthly":
              value = row.original["Length of Monthly"];
              break;
            case "Quarterly":
              value = row.original["Length of Quarterly"];
              break;
            case "Yearly":
              value = row.original["Length of Yearly"];
              break;
            default:
              break;
          }
          return value || 0;
        },
      },
      {
        Header: "Analysis Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.AnalysisDate),
        align: "center",
      },
      { Header: "AnalysisId", accessor: "AnalysisId", align: "center" },
      {
        Header: "GMW Comment",
        accessor: "DailyTrendComment",
        align: "center",
        Cell: ({ row }) => {
          let comment;
          switch (row.original.TimeFrame) {
            case "Daily":
              comment = row.original.DailyTrendComment;

              break;
            case "Weekly":
              comment = row.original.WeeklyTrendComment;
              break;
            case "Monthly":
              comment = row.original.MothlyTrendComment;
              break;
            case "Quarterly":
              comment = row.original.QuarterlyTrendComment;
              break;
            case "Yearly":
              comment = row.original.YearlyTrendComment;
              break;
            default:
              break;
          }
          return comment || "";
        },
      },
    ],
  };
}

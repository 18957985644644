// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import percentageChangeTableData from "layouts/tables/data/percentageChangeTableData";

function PercentageChange() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [runRegionV, setRunRegionV] = useState("");
  const [timeframeV, setTimeframeV] = useState("");
  const [checkSocratesData, setCheckSocratesData] = useState([]);

  const { columns } = percentageChangeTableData();

  const getPercentageChange = async () => {
    const result = await http({
      path: `/marketpricedata/history`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [fetchedCheckSocratesData] = useQueries({
    queries: [
      {
        queryKey: ["fetchedPercentageChange"],
        queryFn: getPercentageChange,
      },
    ],
  });

  useEffect(() => {
    if (timeframeV !== "" && runRegionV !== "") {
      let timeframe;
      let runregion;

      switch (timeframeV) {
        case "Daily":
          timeframe = 1;
          break;
        case "Weekly":
          timeframe = 2;
          break;
        case "Monthly":
          timeframe = 3;
          break;
        case "Quarterly":
          timeframe = 4;
          break;
        case "Yearly":
          timeframe = 5;
          break;
        default:
          timeframe = 0;
      }

      switch (runRegionV) {
        case "Americas":
          runregion = ["US2", "US3"];
          break;
        case "Asia":
          runregion = ["Asia"];
          break;
        case "Europe":
          runregion = ["Europe"];
          break;
        case "Economics":
          runregion = ["US4"];
          break;
        case "Bonds":
          runregion = ["US1", "US5"];
          break;
        default:
          runregion = [""];
      }

      // filters by Timeframe
      const checksocratesdatafiltered = fetchedCheckSocratesData.data[timeframe];

      // filters RunRegion
      const xchecksocratesdatafiltered = checksocratesdatafiltered[0].result.filter(
        (percentageChangeData) => runregion.includes(percentageChangeData.RunRegion)
      );

      setCheckSocratesData(xchecksocratesdatafiltered);
    }
  }, [runRegionV, timeframeV]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (fetchedCheckSocratesData.isLoading) return <MDLoader />;
  if (fetchedCheckSocratesData.isError) return <div>Error at loading analysis market</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3.5}>
              <Card>
                <Grid item xs={6} m={3} mb={2}>
                  <Autocomplete
                    id="RunRegion"
                    name="RunRegion"
                    options={["Americas", "Asia", "Europe", "Economics", "Bonds"]}
                    value={runRegionV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      setRunRegionV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Run Region" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={4}>
                  <Autocomplete
                    id="TimeFrame"
                    name="TimeFrame"
                    options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
                    value={timeframeV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      // console.log(value);
                      setTimeframeV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Timeframe" />
                    )}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {checkSocratesData && checkSocratesData.length > 0 ? (
        <MDBox my={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Percentage Change
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableWithColumnFilters
                    table={{ columns, rows: checkSocratesData }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Count"]}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default PercentageChange;

import { useMaterialUIController, adduser } from "context";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// AddUser page components
import NewUser from "layouts/adduser/components/NewUser";

// AddUser layout schemas for form and form feilds
import validations from "layouts/adduser/schemas/validations";
import form from "layouts/adduser/schemas/form";
import initialValues from "layouts/adduser/schemas/initialValues";
import { useNavigate } from "react-router-dom";

import rolesData from "layouts/data/roles";

const { roles } = rolesData();

function getStepContent(formData) {
  return <NewUser formData={formData} />;
}

function AddUser() {
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();

  const navigate = useNavigate();

  const { formId, formField } = form;
  const currentValidation = validations[0];

  const submitForm = async (values) => {
    let roleCode;
    switch (values.role) {
      case "Marketing":
        roleCode = "marketing";
        break;
      case "Data and Models Team":
        roleCode = "data";
        break;
      case "Customer Service":
        roleCode = "cs";
        break;
      default:
        roleCode = "";
        break;
    }
    const newUser = {
      ...values,
      role: roleCode,
      email: values.username,
    };
    adduser(dispatch, newUser);
  };

  const handleSubmit = (values) => {
    submitForm(values);
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={4}>
            <Formik
              initialValues={{
                ...initialValues,
                role: "Data and Models Team",
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Add User
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          roles,
                          setFieldValue,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                          <MDButton
                            disabled={isSubmitting}
                            type="button"
                            variant="gradient"
                            color="info"
                            sx={{ marginLeft: "40px" }}
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            cancel
                          </MDButton>
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="info"
                            sx={{ marginLeft: "40px" }}
                          >
                            create
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddUser;

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import { useEffect } from "react";

// context
import { useMaterialUIController, logout, reset } from "context";

// react-router-dom components
import { useNavigate } from "react-router-dom";

function SignOut() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();

  const handleLogout = () => {
    reset(dispatch);
    logout(dispatch);
    navigate("/");
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return null;
}

export default SignOut;

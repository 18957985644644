import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";

import http from "services/http";

import MDLoader from "components/MDLoader";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewMarketRequest page components
import UserInfo from "layouts/marketrequest/components/UserInfo";

// NewMarketRequest layout schemas for form and form feilds
import validations from "layouts/marketrequest/schemas/validations";
import form from "layouts/marketrequest/schemas/form";
import initialValues from "layouts/marketrequest/schemas/initialValues";
import { useNavigate, useLocation } from "react-router-dom";

import countriesData from "layouts/data/countries";

const { currencies } = countriesData();

function getStepContent(formData) {
  return <UserInfo formData={formData} />;
}

function MarketRequest() {
  const location = useLocation();
  const { marketInfoRequest } = location.state;
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getDistinctCategories = async () => {
    const result = await http({
      path: `/marketimportinfo/categories`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getCountries = async () => {
    const result = await http({
      path: `/country`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const submitForm = async (values, actions) => {
    // eslint-disable-next-line no-unused-vars
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    navigate("/view-all-request");
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/view-all-request");
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedCategories = await getDistinctCategories();
          const loadedCountries = await getCountries();
          setCategories(loadedCategories);
          setCountries(loadedCountries);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={0} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        View Market Request
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent({
                          values,
                          touched,
                          formField,
                          errors,
                          categories,
                          countries,
                          currencies,
                          setFieldValue,
                          marketInfoRequest,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton variant="gradient" color="info" onClick={handleBack}>
                            back
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketRequest;

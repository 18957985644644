/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// eslint-disable-next-line react/prop-types
function FilterGridItem({ label, header, columnKey, width, align, length }) {
  return (
    <Grid item xs={12} sm={length || 4}>
      <MDBox display="inline-block">
        <MDTypography
          component="label"
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
        >
          {label === "StatusId" ? "Status" : label}
        </MDTypography>
      </MDBox>
      {header.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps()} key={columnKey}>
          {headerGroup.headers
            .filter((column) => column.Header === label)
            .map((column) => (
              <div
                {...column.getHeaderProps()}
                key={column.id}
                width={width || "auto"}
                align={align || "left"}
              >
                {column.render("Filter")}
              </div>
            ))}
        </div>
      ))}
    </Grid>
  );
}

export default FilterGridItem;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import { useQueries } from "@tanstack/react-query";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableWithColumnFilters from "components/Tables/DataTableWithColumnFilters";

import dataCheckSocratesData from "layouts/tables/data/checkSocratesDataTableData";
import { formatDateInNewYork } from "utils/dateUtil";
// import MDDatePicker from "components/MDDatePicker";

function MarketDataCheck() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [runRegionV, setRunRegionV] = useState("");
  const [timeframeV, setTimeframeV] = useState("");
  const [checkSocratesData, setCheckSocratesData] = useState([]);
  const [analysisDate, setAnalysisDate] = useState("");
  const [percentageV, setPercentageV] = useState("");
  const [categoryV, setCategoryV] = useState("");

  const { columns } = dataCheckSocratesData();

  const getCheckSocratesData = async () => {
    const result = await http({
      path: `/checksocratesdata`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const [fetchedCheckSocratesData] = useQueries({
    queries: [
      {
        queryKey: ["fetchedCheckSocratesData"],
        queryFn: getCheckSocratesData,
      },
    ],
  });

  const checkPercentageFilter = (data) => {
    let tempData;
    switch (percentageV) {
      case "":
        tempData = data;
        break;
      case "All":
        tempData = data;
        break;
      case "% Increase > 500":
        tempData = data.filter((el) => el.increasingMoreThan500Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.increasingMoreThan500Count,
          Message: `Maximum value found was ${el.increasingMoreThan500Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.increasingMoreThan500MaxDate)}`,
          Percentage: el.increasingMoreThan500Max,
          Date: el.increasingMoreThan500MaxDate,
          FilterType: 1,
        }));
        break;
      case "% Increase Range (100,500)":
        tempData = data.filter((el) => el.increasingBetween100And500Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.increasingBetween100And500Count,
          Message: `Maximum value found was ${el.increasingBetween100And500Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.increasingBetween100And500MaxDate)}`,
          Percentage: el.increasingBetween100And500Max,
          Date: el.increasingBetween100And500MaxDate,
          FilterType: 2,
        }));
        break;
      case "% Increase Range (50,100)":
        tempData = data.filter((el) => el.increasingBetween50And100Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.increasingBetween50And100Count,
          Message: `Maximum value found was ${el.increasingBetween50And100Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.increasingBetween50And100MaxDate)}`,
          Percentage: el.increasingBetween50And100Max,
          Date: el.increasingBetween50And100MaxDate,
          FilterType: 3,
        }));
        break;
      case "% Decrease Range (-50,-100)":
        tempData = data.filter((el) => el.decreasingBetween50And100Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.decreasingBetween50And100Count,
          Message: `Minimum value found was ${el.decreasingBetween50And100Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.decreasingBetween50And100MaxDate)}`,
          Percentage: el.decreasingBetween50And100Max,
          Date: el.decreasingBetween50And100MaxDate,
          FilterType: 4,
        }));
        break;
      case "% Decrease Range (-100,-500)":
        tempData = data.filter((el) => el.decreasingBetween100And500Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.decreasingBetween100And500Count,
          Message: `Minimum value found was ${el.decreasingBetween100And500Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.decreasingBetween100And500MaxDate)}`,
          Percentage: el.decreasingBetween100And500Max,
          Date: el.decreasingBetween100And500MaxDate,
          FilterType: 5,
        }));
        break;
      case "% Decrease < -500":
        tempData = data.filter((el) => el.decreasingLessThan500Count > 0);
        tempData = tempData.map((el) => ({
          ...el,
          Count: el.decreasingLessThan500Count,
          Message: `Minimum value found was: ${el.decreasingLessThan500Max.toFixed(
            2
          )}% , Date: ${formatDateInNewYork(el.decreasingLessThan500Max.Date)}`,
          Percentage: el.decreasingLessThan500Max,
          Date: el.decreasingLessThan500MaxDate,
          FilterType: 6,
        }));
        break;
      default:
        tempData = data;
        break;
    }
    // tempData = tempData.sort((a, b) => Math.abs(b.Count) - Math.abs(a.Count));
    return tempData;
  };

  useEffect(() => {
    setAnalysisDate("");
    if (timeframeV !== "" && runRegionV !== "") {
      let timeframe;
      let runregion;

      switch (timeframeV) {
        case "Daily":
          timeframe = 1;
          break;
        case "Weekly":
          timeframe = 2;
          break;
        case "Monthly":
          timeframe = 3;
          break;
        case "Quarterly":
          timeframe = 4;
          break;
        case "Yearly":
          timeframe = 5;
          break;
        default:
          timeframe = 0;
      }

      switch (runRegionV) {
        case "Americas":
          runregion = ["US2", "US3"];
          break;
        case "Asia":
          runregion = ["Asia"];
          break;
        case "Europe":
          runregion = ["Europe"];
          break;
        case "Economics":
          runregion = ["US4"];
          break;
        case "Bonds":
          runregion = ["US1", "US5"];
          break;
        default:
          runregion = [""];
      }

      // filters by Timeframe by RunRegion
      let checksocratesdatafiltered = fetchedCheckSocratesData.data.filter(
        (csd) => runregion.includes(csd.RunRegion) && csd.TimeFrame === timeframe
      );

      if (percentageV !== "") {
        checksocratesdatafiltered = checkPercentageFilter(checksocratesdatafiltered);
      }

      if (categoryV !== "") {
        checksocratesdatafiltered = checksocratesdatafiltered.filter(
          (el) => el.Category === categoryV
        );
      }

      setCheckSocratesData(checksocratesdatafiltered);

      if (checksocratesdatafiltered && checksocratesdatafiltered.length > 0) {
        setAnalysisDate(formatDateInNewYork(checksocratesdatafiltered[0].InsertedDate));
      } else {
        setAnalysisDate("");
      }
    }
  }, [runRegionV, timeframeV, percentageV, categoryV]);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  if (fetchedCheckSocratesData.isLoading) return <MDLoader />;
  if (fetchedCheckSocratesData.isError) return <div>Error at loading analysis market</div>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3.5}>
              <Card>
                <Grid item xs={6} m={3} mb={2}>
                  <Autocomplete
                    id="RunRegion"
                    name="RunRegion"
                    options={["Americas", "Asia", "Europe", "Economics", "Bonds"]}
                    value={runRegionV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      setRunRegionV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Run Region" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} m={3} mt={0} mb={4}>
                  <Autocomplete
                    id="TimeFrame"
                    name="TimeFrame"
                    options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
                    value={timeframeV || null}
                    // getOptionLabel={(option) => option}
                    style={{ width: 200 }}
                    onChange={(e, value) => {
                      // console.log(value);
                      setTimeframeV(value || "");
                    }}
                    renderInput={(params) => (
                      <MDInput {...params} variant="standard" label="Timeframe" />
                    )}
                  />
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="h6">FILTERS</MDTypography>
                    <MDBox mt={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <MDBox mb={2}>
                            <MDBox pt={0} px={0}>
                              <Grid item xs={6}>
                                <Autocomplete
                                  id="Percentage Change"
                                  name="Percentage Change"
                                  options={[
                                    "% Increase > 500",
                                    "% Increase Range (100,500)",
                                    "% Increase Range (50,100)",
                                    "% Decrease Range (-50,-100)",
                                    "% Decrease Range (-100,-500)",
                                    "% Decrease < -500",
                                  ]}
                                  value={percentageV || null}
                                  style={{ width: 250 }}
                                  onChange={(e, value) => {
                                    setPercentageV(value || "");
                                    // console.log(value);
                                  }}
                                  renderInput={(params) => (
                                    <MDInput {...params} variant="standard" label="Percentage" />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Autocomplete
                                  id="Category"
                                  name="Category"
                                  options={checkSocratesData
                                    .map((el) => el.Category)
                                    .filter((v, i, a) => a.indexOf(v) === i)}
                                  value={categoryV || null}
                                  style={{ width: 250 }}
                                  onChange={(e, value) => {
                                    setCategoryV(value || "");
                                    // console.log(value);
                                  }}
                                  renderInput={(params) => (
                                    <MDInput {...params} variant="standard" label="Category" />
                                  )}
                                />
                              </Grid>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <MDBox mb={3}>
                <Card sx={{ height: "25%" }}>
                  <MDBox pt={1} px={3}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Latest Update Date
                    </MDTypography>
                    <MDBox mt={0} mb={2}>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        {analysisDate}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {checkSocratesData && checkSocratesData.length > 0 ? (
        <MDBox my={3} mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Market Data Check
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTableWithColumnFilters
                    table={{ columns, rows: checkSocratesData }}
                    showTotalEntries
                    isSorted
                    noEndBorder
                    entriesPerPage
                    canSearch
                    arrayColumnsToSkipSorted={["Count"]}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <p> No data available</p>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default MarketDataCheck;

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dataGmwCommentsErrorDetails from "../Data/dataGmwCommentsErrorDetails";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GmwCommentsErrorDetails({
  dataTable1,
  index,
  setIndexDescriptionError,
  maxAnalysisDates,
}) {
  const { columns1 } = dataGmwCommentsErrorDetails();

  const { marketsDaily, marketsWeekly, marketsMonthly, marketsQuarterly, marketsYearly } =
    dataTable1;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    let des = "";
    let val = 0;
    const dat = maxAnalysisDates[newValue];
    switch (newValue) {
      case 0:
        des = "Daily";
        val = marketsDaily ? marketsDaily.length : 0;
        break;
      case 1:
        des = "Weekly";
        val = marketsWeekly ? marketsWeekly.length : 0;
        break;
      case 2:
        des = "Monthly";
        val = marketsMonthly ? marketsMonthly.length : 0;
        break;
      case 3:
        des = "Quarterly";
        val = marketsQuarterly ? marketsQuarterly.length : 0;
        break;
      case 4:
        des = "Yearly";
        val = marketsYearly ? marketsYearly.length : 0;
        break;
      default:
        des = "";
        val = 0;
        break;
    }
    setIndexDescriptionError({ description: des, value: val, date: dat });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(index);
  }, [index]);

  return (
    <Card>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Daily" {...a11yProps(0)} />
            <Tab label="Weekly" {...a11yProps(1)} />
            <Tab label="Monthly" {...a11yProps(2)} />
            <Tab label="Quarterly" {...a11yProps(3)} />
            <Tab label="Yearly" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <MDBox>
            {dataTable1 ? (
              <DataTable
                table={{ columns: columns1, rows: marketsDaily }}
                showTotalEntries={false}
                isSorted
                noEndBorder
                entriesPerPage
                canSearch
              />
            ) : null}
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MDBox>
            <DataTable
              table={{ columns: columns1, rows: marketsWeekly }}
              showTotalEntries={false}
              isSorted
              noEndBorder
              entriesPerPage
              canSearch
            />
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MDBox>
            <DataTable
              table={{ columns: columns1, rows: marketsMonthly }}
              showTotalEntries={false}
              isSorted
              noEndBorder
              entriesPerPage
              canSearch
            />
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MDBox>
            <DataTable
              table={{ columns: columns1, rows: marketsQuarterly }}
              showTotalEntries={false}
              isSorted
              noEndBorder
              entriesPerPage
              canSearch
            />
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <MDBox>
            <DataTable
              table={{ columns: columns1, rows: marketsYearly }}
              showTotalEntries={false}
              isSorted
              noEndBorder
              entriesPerPage
              canSearch
            />
          </MDBox>
        </TabPanel>
      </Box>
    </Card>
  );
}

// Setting default props for the Footer
GmwCommentsErrorDetails.defaultProps = {
  dataTable1: {},
  index: 0,
  maxAnalysisDates: {},
};

// Typechecking props for the Footer
GmwCommentsErrorDetails.propTypes = {
  dataTable1: PropTypes.shape({
    marketsDaily: PropTypes.arrayOf(PropTypes.object),
    marketsWeekly: PropTypes.arrayOf(PropTypes.object),
    marketsMonthly: PropTypes.arrayOf(PropTypes.object),
    marketsQuarterly: PropTypes.arrayOf(PropTypes.object),
    marketsYearly: PropTypes.arrayOf(PropTypes.object),
  }),
  index: PropTypes.number,
  setIndexDescriptionError: PropTypes.func.isRequired,
  maxAnalysisDates: PropTypes.arrayOf(PropTypes.object),
};

export default GmwCommentsErrorDetails;

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
//
function MyMDTypo(label, value, piColor, darkMode, mt) {
  let color = "";
  let valueText = "";
  const myValue = parseInt(value, 10);

  if (myValue < 0) {
    color = "error";
    valueText = `${value}`;
  }
  if (myValue > 0) {
    color = "success";
    if (piColor === "text") valueText = `${value}`;
    else valueText = `+${value}`;
  }
  if (myValue === 0) {
    color = "text";
    valueText = `${value}`;
  }
  return (
    <MDBox mt={mt} mb={2}>
      <MDBox>
        <MDTypography
          variant="h6"
          fontWeight="bold"
          color={piColor || color}
          verticalAlign="middle"
        >
          {valueText}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="h7" fontWeight="light" color={darkMode ? "text" : "secondary"}>
          {label}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

function MyMDTypoPercentage(label, value, piColor, darkMode, mt) {
  return (
    <MDBox mt={mt} mb={2}>
      <MDBox>
        <MDTypography
          variant="h6"
          fontWeight="bold"
          color={piColor || "text"}
          verticalAlign="middle"
        >
          {value}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="h7" fontWeight="light" color={darkMode ? "text" : "secondary"}>
          {value === "" ? "" : label}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

function CardTitle({ title }) {
  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <MDBox mb={0.5} lineHeight={1} mt={2}>
          <MDTypography variant="button" fontWeight="medium" color="text">
            {title}
          </MDTypography>
        </MDBox>
      </Grid>
    </Grid>
  );
}

function DefaultStatisticsCardSubscriptionsOverviewExt({ title, data }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Card>
      <MDBox p={1} style={{ backgroundColor: "rgb(230,230,230)", borderRadius: "inherit" }}>
        <MDBox>
          <CardTitle title={title} />
          <Grid container>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypo(data.purchased.label, data.purchased.value, "text", darkMode, 2)}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypo(data.cancelled.label, data.cancelled.value, "text", darkMode, 2)}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypo(data.growth.label, data.growth.value, null, darkMode, 2)}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <CardTitle title="Relative Change" />
          <Grid container>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.relativePurchased.label,
                data.relativePurchased.value,
                "text",
                darkMode
              )}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.relativeCancelled.label,
                data.relativeCancelled.value,
                "text",
                darkMode
              )}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.relativeGrowth.label,
                data.relativeGrowth.value,
                null,
                darkMode
              )}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <CardTitle title="Absolute Difference" />
          <Grid container>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.absolutePurchased.label,
                data.absolutePurchased.value,
                "text",
                darkMode
              )}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.absoluteCancelled.label,
                data.absoluteCancelled.value,
                "text",
                darkMode
              )}
            </Grid>
            <Grid item xs={1.5}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              {MyMDTypoPercentage(
                data.absoluteGrowth.label,
                data.absoluteGrowth.value,
                null,
                darkMode
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

const valueLabelColorDefaultValues = { color: "success", value: "", label: "" };
const valueLabelStructure = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};
// Setting default values for the props of DefaultStatisticsCardSubscriptionsOverviewExt
DefaultStatisticsCardSubscriptionsOverviewExt.defaultProps = {
  data: {
    purchased: valueLabelColorDefaultValues,
    cancelled: valueLabelColorDefaultValues,
    growth: valueLabelColorDefaultValues,
    relativePurchased: valueLabelColorDefaultValues,
    relativeCancelled: valueLabelColorDefaultValues,
    relativeGrowth: valueLabelColorDefaultValues,
    absolutePurchased: valueLabelColorDefaultValues,
    absoluteCancelled: valueLabelColorDefaultValues,
    absoluteGrowth: valueLabelColorDefaultValues,
  },
};

// Typechecking props for the DefaultStatisticsCardSubscriptionsOverviewExt
DefaultStatisticsCardSubscriptionsOverviewExt.propTypes = {
  title: PropTypes.string.isRequired,

  data: PropTypes.shape({
    purchased: PropTypes.shape(valueLabelStructure),
    cancelled: PropTypes.shape(valueLabelStructure),
    growth: PropTypes.shape(valueLabelStructure),
    relativePurchased: PropTypes.shape(valueLabelStructure),
    relativeCancelled: PropTypes.shape(valueLabelStructure),
    relativeGrowth: PropTypes.shape(valueLabelStructure),
    absolutePurchased: PropTypes.shape(valueLabelStructure),
    absoluteCancelled: PropTypes.shape(valueLabelStructure),
    absoluteGrowth: PropTypes.shape(valueLabelStructure),
  }),
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DefaultStatisticsCardSubscriptionsOverviewExt;

import * as Yup from "yup";
import checkout from "layouts/marketrequestmanager/schemas/form";

const {
  formField: {
    MarketName,
    MarketHistoryYears,
    MarketHistoryMonths,
    SourceTicker,
    Currency,
    SecondarySource,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [SecondarySource.name]: Yup.string().required(SecondarySource.errorMsg),
    [MarketHistoryYears.name]: Yup.number(),
    [MarketHistoryMonths.name]: Yup.number(),
    [SourceTicker.name]: Yup.string().required(SourceTicker.errorMsg),
    [MarketName.name]: Yup.string().required(MarketName.errorMsg),
    [Currency.name]: Yup.string().required(Currency.errorMsg),
  }),
];

export default validations;

import { useEffect, useState } from "react";
import { useMaterialUIController, setOpenMarketMessage, setLoadMarketManagement } from "context";
import { useLocation, useNavigate } from "react-router-dom";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card } from "@mui/material";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
// import MarketRequestManagerInfo from "layouts/marketrequestmanager/components/MarketRequestManagerInfo";
import Part2 from "layouts/marketrequestmanager/components/Part2";
// import Part3 from "layouts/marketrequestmanager/components/Part3";

// NewUser layout schemas for form and form feilds
import validations from "layouts/marketrequestmanager/schemas/validations";
import form from "layouts/marketrequestmanager/schemas/form";
import initialValues from "layouts/marketrequestmanager/schemas/initialValues";

// data
import selectData from "layouts/editmarket/data/selectData";
import countriesData from "layouts/data/countries";

const { currencies } = countriesData();

function getSteps() {
  return ["Reviewer Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <Part2 formData={formData} />;
    default:
      return null;
  }
}

function MarketRequestManager() {
  const location = useLocation();
  const { marketInfoRequest } = location.state;
  const [controller, dispatch] = useMaterialUIController();
  const { userData, loadMarketManagement } = controller;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [sectors, setSectors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [distinctMarketInfo, setDistinctMarketInfo] = useState({});

  const getDistinctSectors = async () => {
    const result = await http({
      path: `/marketimportinfo/sectors`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctMarketInfo = async () => {
    const result = await http({
      path: `/markets/distinctmarketinfo`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return {};
  };

  const patchMarketInfoRequest = async (values) => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketinforequest/${marketInfoRequest._id}`,
      method: "PUT",
      raw: JSON.stringify(values),
      accessToken: userData.token,
    });

    return result;
  };

  const getCountries = async () => {
    const result = await http({
      path: `/country`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    // eslint-disable-next-line no-unused-vars
    const result = await patchMarketInfoRequest({
      ...values,
      // Status: "Approved",
      StatusId: 3,
    });
    if (result.ok && result.body) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        color: "success",
        icon: "check",
        title: "Success",
        content: "Request has been approved",
      });
    }
    await setLoadMarketManagement(dispatch, loadMarketManagement + 1);

    // console.log(result);
    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
      navigate("/view-all-request");
    } else {
      // eslint-disable-next-line no-unused-vars
      const result = await patchMarketInfoRequest({
        ...values,
        Status: "Reviewed",
      });
      await setLoadMarketManagement(dispatch, loadMarketManagement + 1);
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const [loadedSectors, loadedDistinctMarketInfo, loadedCountries] = await Promise.all([
            getDistinctSectors(),
            getDistinctMarketInfo(),
            getCountries(),
          ]);

          setSectors(loadedSectors);
          setDistinctMarketInfo(loadedDistinctMarketInfo);
          setCountries(loadedCountries);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                ...initialValues,
                ...marketInfoRequest,
                IsTradingVolumeEnough: marketInfoRequest.IsTradingVolumeEnough || false,
                NotifyMe: marketInfoRequest.NotifyMe || false,
                MarketHistoryYears: marketInfoRequest.MarketHistoryYears || 0,
                MarketHistoryMonths: marketInfoRequest.MarketHistoryMonths || 0,
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <MDTypography variant="h6" color="white">
                        Review Market Request
                      </MDTypography>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          sectors,
                          countries,
                          currencies,
                          setFieldValue,
                          marketInfoRequest,
                          distinctMarketInfo,
                          selectData,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "approve" : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketRequestManager;

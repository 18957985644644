const form = {
  formIdTicket: "new-user-form-ticket",
  formFieldTicket: {
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
      invalidMsg: "Your title is invalid",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Description is required.",
      invalidMsg: "Your description is invalid",
    },
  },
};

export default form;

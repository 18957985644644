/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDLink from "components/MDLink";
import { SelectColumnFilter } from "components/TableFilters/selectByFieldStatusId";

export default function data() {
  return {
    columns1: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "",
        accessor: "edit",
        align: "left",
        maxWidth: 30,
        minWidth: 30,
        Cell: ({ row }) => <MDLink to={`/edit/${row.original.PEITicker}`} text="Edit" />,
      },
    ],
    columns2: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Ticker",
        accessor: "DataTicker",
        Filter: "",
        align: "left",
        maxWidth: 70,
        minWidth: 70,
        Cell: ({ row }) => {
          if (row.original.Source === "REU") {
            return row.original.ReuTicker || "";
          }
          if (row.original.Source === "SPX") {
            return row.original.SpxTicker || "";
          }
          if (row.original.Source === "CSI") {
            return row.original.DataTicker || "";
          }
          if (row.original.Source === "Refactor") {
            return "RefactorQ";
          }
          if (row.original.Source === "Internal") {
            return "Internal";
          }
          return row.original.DataTicker || "";
        },
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "",
        accessor: "edit",
        align: "left",
        maxWidth: 30,
        minWidth: 30,
        Cell: ({ row }) => (
          <MDLink to={`/update-market-data-store/detail/${row.original.PEITicker}`} text="Edit" />
        ),
      },
    ],
    columns3: [
      {
        Header: "Market Name",
        accessor: "Name",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
        Cell: ({ row }) => (
          <MDLink to={`/markets/${row.original.PEITicker}`} text={row.values.Name} />
        ),
      },
      {
        Header: "PEI Ticker",
        accessor: "PEITicker",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Source",
        accessor: "Source",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Category",
        accessor: "Category",
        align: "left",
        maxWidth: 115,
        minWidth: 115,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Region",
        accessor: "RunRegion",
        align: "left",
        maxWidth: 200,
        minWidth: 200,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      // {
      //   Header: "",
      //   accessor: "edit",
      //   align: "left",
      //   maxWidth: 30,
      //   minWidth: 30,
      //   Cell: ({ row }) => <MDLink to={`/edit/${row.original.PEITicker}`} text="Edit" />,
      // },
    ],
  };
}

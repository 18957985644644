/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

const columns = [
  {
    Header: "Market Name",
    accessor: "Market",
    align: "left",
    width: "40%",
    // Cell: ({ row }) => {
    //   // const balance =
    //   //   row.original.calculatedSubscriptionsCount - row.original.calculatedCancellationsCount;
    //   const color = "green";
    //   // if (balance > 0) color = "green";
    //   // if (balance < 0) color = "red";
    //   console.log(row.original);
    //   return <div style={{ color }}>{row.original.Market}</div>;
    // },
    // Cell: ({ row }) => (
    //   <div style={{ backgroundColor: "rgb(3,172,19)", color: "white" }}>
    //     {row.original.Market || ""}
    //   </div>
    // ),
  },
  {
    Header: "PEITicker",
    accessor: "PEITicker",
    align: "left",
    width: "10%",
  },
  {
    Header: "Socrates Category",
    accessor: "Category",
    align: "center",
    width: "25%",
  },
  {
    Header: "GICS Sector",
    accessor: "GICS",
    align: "center",
    width: "25%",
  },
  {
    Header: "GICS Industry",
    accessor: "GICSIndustry",
    align: "center",
    width: "25%",
  },
];

export default columns;

/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React components
// import MDLink from "components/MDLink";

// Images
import upArrow from "assets/images/icons/arrows/up-arrow.png";
import downArrow from "assets/images/icons/arrows/down-arrow.png";
import doubleSidedArrow from "assets/images/icons/arrows/double-sided-arrow.png";
import { formatDateInNewYorkNoSec } from "utils/dateUtil";
import moment from "moment";

export default function data() {
  return {
    columns3: [
      {
        Header: "Market Name",
        accessor: "MarketName",
        align: "left",
        maxWidth: 220,
        minWidth: 220,
      },
      {
        Header: "Report Run Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.RptRunDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      { Header: "PEI Ticker", accessor: "PEITicker", align: "left", maxWidth: 85, minWidth: 85 },
      {
        Header: "Snapshots",
        accessor: "TotalCountofSnapshots",
        align: "left",
        maxWidth: 85,
        minWidth: 85,
      },
      {
        Header: "Active Subscriptions",
        accessor: "TotalCountOfActiveSubscriptions",
        align: "left",
        maxWidth: 90,
        minWidth: 90,
      },
      {
        Header: "Canceled Subscriptions Last 30 Days",
        accessor: "TotalCountOfCancelledSubscriptionInLast30Days",
        align: "left",
        maxWidth: 90,
        minWidth: 90,
      },
      {
        Header: "Subscriptions Added Last 30 Days",
        accessor: "TotalCountOfSubscriptionAddedInLast30Days",
        align: "left",
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: "Failed Markets Last 30 Days",
        accessor: "CoountOfFailedMarketsLast30Days",
        align: "left",
        maxWidth: 100,
        minWidth: 100,
      },
      {
        Header: "Net Addition Last 30 Days",
        accessor: "NetAdditionLast30Days",
        align: "left",
        maxWidth: 90,
        minWidth: 90,
        Cell: ({ row }) => (
          <>
            <span> {row.values.NetAdditionLast30Days} &nbsp;&nbsp;</span>
            <span hidden={row.values.NetAdditionLast30Days <= 0}>
              <img src={upArrow} alt="upArrow" style={{ maxWidth: "50%", maxHeight: "50%" }} />
            </span>
            <span hidden={row.values.NetAdditionLast30Days >= 0}>
              <img src={downArrow} alt="downArrow" style={{ maxWidth: "50%", maxHeight: "50%" }} />
            </span>
            <span hidden={row.values.NetAdditionLast30Days !== 0}>
              <img
                src={doubleSidedArrow}
                alt="doubleSidedArrow"
                style={{ maxWidth: "50%", maxHeight: "50%" }}
              />
            </span>
          </>
        ),
      },
      {
        Header: "Market Addition Date",
        accessor: (d) => formatDateInNewYorkNoSec(d.MarketAdditionDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Earliest Market Snapshots Purchase Date",
        accessor: (d) =>
          !moment(d.EarliestMarketSnapshotsPurchaseDate).isValid()
            ? "Never Subscribed"
            : formatDateInNewYorkNoSec(d.EarliestMarketSnapshotsPurchaseDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
      {
        Header: "Earliest Market Subscription Date",
        accessor: (d) =>
          !moment(d.EarliestMarketSubscriptionDate).isValid()
            ? "Never Subscribed"
            : formatDateInNewYorkNoSec(d.EarliestMarketSubscriptionDate),
        align: "left",
        maxWidth: 150,
        minWidth: 150,
      },
    ],
  };
}

/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";
import PropTypes from "prop-types";

import http from "services/http";

import MDLoader from "components/MDLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Dashboard components
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChartMulticolorII";

import DataTable from "examples/Tables/DataTable";
import modelsColumns from "layouts/tables/data/dataModels";
import ComplexStatisticsCardMarketSubscription from "examples/Cards/StatisticsCards/ComplexStatisticsCardMarketSubscription";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MissingModels() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [fullDataModels, setFullDataModels] = useState([]);
  const [dataModels, setDataModels] = useState([]);
  const [filteredDataModels, setFilteredDataModels] = useState([]); // Filtered data
  const [dataChartModels, setDataChartModels] = useState({
    labels: ["Daily", "Weekly", "Mothly", "Quarterly", "Yearly"],
    datasets: [
      // {
      //   label: "Reversals",
      //   data: [10, 10, 21, 30, 40],
      // },
      // {
      //   label: "Arrays",
      //   data: [5, 15, 6, 15, 10],
      // },
      {
        label: "Support",
        data: [20, 5, 25, 45, 30],
      },
      {
        label: "Resistance",
        data: [25, 30, 7, 40, 15],
      },
      {
        label: "Auto Drawn Technical Lines",
        data: [35, 10, 27, 20, 10],
      },
      {
        label: "Stochastic",
        data: [45, 40, 25, 32, 20],
      },
      {
        label: "Energy Model",
        data: [30, 40, 11, 10, 30],
      },
      {
        label: "Indicating Ranges",
        data: [10, 22, 5, 15, 40],
      },
    ],
  });
  const [descriptionModels, setDescriptionModels] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    let filtered;
    let label;
    let value;

    switch (newValue) {
      case 0:
        filtered = fullDataModels["1"];
        label = "Daily";
        value = fullDataModels["1"].length;
        break;
      case 1:
        filtered = fullDataModels["2"];
        label = "Weekly";
        value = fullDataModels["2"].length;
        break;
      case 2:
        filtered = fullDataModels["3"];
        label = "Monthly";
        value = fullDataModels["3"].length;
        break;
      case 3:
        filtered = fullDataModels["4"];
        label = "Quarterly";
        value = fullDataModels["4"].length;
        break;
      case 4:
        filtered = fullDataModels["5"];
        label = "Yearly";
        value = fullDataModels["5"].length;
        break;
      default:
        filtered = [
          ...fullDataModels["1"],
          ...fullDataModels["2"],
          ...fullDataModels["3"],
          ...fullDataModels["4"],
          ...fullDataModels["5"],
        ];
        label = "All";
        value = filtered.length;
        break;
    }
    setDescriptionModels({ label, value });
    setFilteredDataModels(filtered);
    setTabValue(newValue);
  };

  // eslint-disable-next-line no-unused-vars
  const handleClickMissingModels = (evt, elements, legend) => {
    // Check if a bar was clicked
    if (elements.length > 0) {
      const chartElement = elements[0];
      // const { datasetIndex } = chartElement;
      const { index } = chartElement;
      // const { data } = evt.chart.data.datasets[datasetIndex];
      const { datasets } = evt.chart.data;
      // Retrieve the data from the clicked bar
      const label = evt.chart.data.labels[index];

      let totalIndex = 0;
      for (let i = 0; i < datasets.length; i++) {
        totalIndex += datasets[i].data[index];
      }

      // const value = data[index]; the value of the individual column
      // console.log(`You clicked on the bar for label: ${label}, value: ${value}`);
      setDescriptionModels({ label, value: totalIndex });
      // Filter dataModels by TimeFrame
      // let filtered = dataModels.filter((model) => model.TimeFrame === label);
      let filtered;
      switch (label) {
        case "Daily":
          filtered = fullDataModels["1"];
          setTabValue(0);
          break;
        case "Weekly":
          filtered = fullDataModels["2"];
          setTabValue(1);
          break;
        case "Monthly":
          filtered = fullDataModels["3"];
          setTabValue(2);
          break;
        case "Quarterly":
          filtered = fullDataModels["4"];
          setTabValue(3);
          break;
        case "Yearly":
          filtered = fullDataModels["5"];
          setTabValue(4);
          break;
        default:
          filtered = [
            ...fullDataModels["1"],
            ...fullDataModels["2"],
            ...fullDataModels["3"],
            ...fullDataModels["4"],
            ...fullDataModels["5"],
          ];
          setTabValue(5);
          break;
      }
      setFilteredDataModels(filtered);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const resetFilterMissingModels = () => {
    setFilteredDataModels(fullDataModels); // Reset to original data
  };

  const [isLoading, setisLoading] = useState(true);
  const { columns: columns1 } = modelsColumns();

  const getMissingModels = async () => {
    const result = await http({
      path: `/models`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return 0;
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
      const load = async () => {
        try {
          const [loadedModels] = await Promise.all([getMissingModels()]);
          const { technicalResults } = loadedModels;

          setFullDataModels(technicalResults);

          setDescriptionModels({
            label: "All",
            value:
              technicalResults["1"].length +
              technicalResults["2"].length +
              technicalResults["3"].length +
              technicalResults["4"].length +
              technicalResults["5"].length +
              0,
          });

          const filteredResistance = {};
          const filteredSupport = {};
          // eslint-disable-next-line no-unused-vars
          let noTechnicalCount = 0;

          // Iterate over the keys (timeframes) in the technicalResults object
          for (const timeFrame in technicalResults) {
            if (technicalResults.hasOwnProperty(timeFrame)) {
              const timeFrameData = technicalResults[timeFrame];

              // Initialize the array for this timeframe in the filteredResistance object
              filteredResistance[timeFrame] = [];

              // Iterate over each element in the current timeframe array
              timeFrameData.forEach((entry) => {
                if (!entry.latestTechnical) {
                  // Count elements without latestTechnical
                  noTechnicalCount++;
                } else if (entry.latestTechnical.Resistance === 0) {
                  // Add elements with Resistance: 0 to the filteredResistance array
                  filteredResistance[timeFrame].push(entry);
                }
              });

              // If no elements are added for this timeframe, remove the empty array
              if (filteredResistance[timeFrame].length === 0) {
                delete filteredResistance[timeFrame];
              }
            }
          }

          for (const timeFrame in technicalResults) {
            if (technicalResults.hasOwnProperty(timeFrame)) {
              const timeFrameData = technicalResults[timeFrame];

              // Initialize the array for this timeframe in the filteredSupport object
              filteredSupport[timeFrame] = [];

              // Iterate over each element in the current timeframe array
              timeFrameData.forEach((entry) => {
                if (!entry.latestTechnical) {
                  // Count elements without latestTechnical
                  noTechnicalCount++;
                } else if (entry.latestTechnical.Support === 0) {
                  // Add elements with Support: 0 to the filteredSupport array
                  filteredSupport[timeFrame].push(entry);
                }
              });

              // If no elements are added for this timeframe, remove the empty array
              if (filteredSupport[timeFrame].length === 0) {
                delete filteredSupport[timeFrame];
              }
            }
          }

          // console.log("Filtered Results with same structure:", filteredResistance);
          // console.log("Number of entries without latestTechnical:", noTechnicalCount);

          setDataChartModels({
            labels: ["Daily", "Weekly", "Mothly", "Quarterly", "Yearly"],
            datasets: [
              {
                label: "Support",
                data: [
                  filteredSupport["1"].length,
                  filteredSupport["2"].length,
                  filteredSupport["3"].length,
                  filteredSupport["4"].length,
                  filteredSupport["5"].length,
                ],
              },
              {
                label: "Resistance",
                data: [
                  filteredResistance["1"].length,
                  filteredResistance["2"].length,
                  filteredResistance["3"].length,
                  filteredResistance["4"].length,
                  filteredResistance["5"].length,
                ],
              },
              // {
              //   label: "Auto Drawn Technical Lines",
              //   data: [100, 100, 100, 100, 100],
              // },
              // {
              //   label: "Stochastic",
              //   data: [100, 100, 100, 100, 100],
              // },
              // {
              //   label: "Energy Model",
              //   data: [100, 100, 100, 100, 100],
              // },
              // {
              //   label: "Indicating Ranges",
              //   data: [100, 100, 100, 100, 100],
              // },
              // {
              //   label: "Reversals",
              //   data: [100, 100, 100, 100, 100],
              // },
              // {
              //   label: "Arrays",
              //   data: [100, 100, 100, 100, 100],
              // },
            ],
          });
          setDataModels(technicalResults["1"]);

          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [userData]);

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1}>
              <VerticalBarChart
                icon={{ color: "dark", component: "leaderboard" }}
                title="Markets With Missing Models"
                height="15rem"
                description=" "
                chart={dataChartModels}
                multicolors
                handleClick={handleClickMissingModels}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={5} lg={3}>
            <MDBox>
              <ComplexStatisticsCardMarketSubscription
                color="warning"
                icon="api"
                title={`Timeframe (${descriptionModels.label})`}
                count={descriptionModels.value}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Daily" {...a11yProps(0)} />
                  <Tab label="Weekly" {...a11yProps(1)} />
                  <Tab label="Monthly" {...a11yProps(2)} />
                  <Tab label="Quarterly" {...a11yProps(3)} />
                  <Tab label="Yearly" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <MDBox>
                <DataTable
                  table={{
                    columns: columns1,
                    rows: filteredDataModels.length > 0 ? filteredDataModels : dataModels || [],
                  }}
                  isSorted
                  entriesPerPage
                  showTotalEntries
                  noEndBorder
                  canSearch
                />
              </MDBox>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MissingModels;

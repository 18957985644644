/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { useMaterialUIController, setOpenMarketMessage } from "context";
import { useLocation, useNavigate } from "react-router-dom";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import { Grid, Card, Stepper, Step, StepLabel } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import MarketRequestDataTeamInfo from "layouts/marketrequestmarkettradinginfo/components/MarketRequestDataTeamInfo";
import MarketTradingInformation from "layouts/marketrequestmarkettradinginfo/components/MarketTradingInformation";

// NewUser layout schemas for form and form feilds
import validations from "layouts/marketrequestmarkettradinginfo/schemas/validations";
import form from "layouts/marketrequestmarkettradinginfo/schemas/form";
import initialValues from "layouts/marketrequestmarkettradinginfo/schemas/initialValues";

// data
import selectData from "layouts/editmarket/data/selectData";
import countriesData from "layouts/data/countries";
import { calculateNextStatus } from "utils/marketRequestUtil";

const { currencies } = countriesData();

function getSteps() {
  return ["Market Info", "Market Trading Information"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <MarketRequestDataTeamInfo formData={formData} />;
    case 1:
      return <MarketTradingInformation formData={formData} />;
    default:
      return null;
  }
}

function MarketRequestMarketTrading() {
  const location = useLocation();
  const { marketInfoRequest } = location.state;
  const [controller, dispatch] = useMaterialUIController();
  const { userData } = controller;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [distinctMarketInfo, setDistinctMarketInfo] = useState({});
  const [isOnHold, setIsOnHold] = useState(false);

  const getDistinctCategories = async () => {
    const result = await http({
      path: `/marketimportinfo/categories`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getDistinctMarketInfo = async () => {
    const result = await http({
      path: `/markets/distinctmarketinfo`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return {};
  };

  const patchMarketInfoRequest = async (values) => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/marketinforequest/${marketInfoRequest._id}`,
      method: "PUT",
      raw: JSON.stringify(values),
      accessToken: userData.token,
    });
    return result;
  };

  const getCountries = async () => {
    const result = await http({
      path: `/country`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleReject = async () => {
    const result = await patchMarketInfoRequest({
      // eslint-disable-next-line no-underscore-dangle
      id: marketInfoRequest._id,
      // Status: "Rejected",
      StatusId: 5,
      RejectionDate: new Date(),
    });
    if (result.ok && result.body) {
      await setOpenMarketMessage(dispatch, {
        open: true,
        content: "Request has been updated",
      });
    }
    navigate("/view-all-request");
  };

  // eslint-disable-next-line no-unused-vars
  const handleApprove = async (values) => {
    const nextStatus = calculateNextStatus(values, "UpdateMTI_2");

    const result = await patchMarketInfoRequest({
      // eslint-disable-next-line no-underscore-dangle
      id: marketInfoRequest._id,
      ...nextStatus,
    });
    if (result.ok && result.body) {
      await setOpenMarketMessage(dispatch, { open: true, content: "Request has been updated" });
    }
    navigate("/view-all-request");
  };

  const submitForm = async (values, actions) => {
    const nextStatus = calculateNextStatus(values, "UpdateMTI_3");

    const result = await patchMarketInfoRequest({
      ...values,
      ...nextStatus,
    });
    if (result.ok && result.body) {
      await setOpenMarketMessage(dispatch, { open: true, content: "Request has been updated" });
    }

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      await submitForm(values, actions);
      navigate("/view-all-request");
    } else {
      const nextStatus = calculateNextStatus(values, "UpdateMTI_1");
      // eslint-disable-next-line no-unused-vars
      const result = await patchMarketInfoRequest({
        ...values,
        ...nextStatus,
      });
      if (result.ok && result.body) {
        await setOpenMarketMessage(dispatch, {
          open: true,
          content: "Request has been updated",
        });
      } else {
        await setOpenMarketMessage(dispatch, {
          open: true,
          color: "error",
          icon: "error",
          title: "Database Error",
          content: JSON.stringify(`${result.message}---${result.error}`),
        });
      }
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const [loadedCategories, loadedDistinctMarketInfo, loadedCountries] = await Promise.all([
            getDistinctCategories(),
            getDistinctMarketInfo(),
            getCountries(),
          ]);

          setCategories(loadedCategories);
          setDistinctMarketInfo(loadedDistinctMarketInfo);
          setCountries(loadedCountries);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/view-all-request");
  };

  const calculateTextLabel = () => {
    if (marketInfoRequest.StatusId === 2) {
      return "approve";
    }
    if (marketInfoRequest.StatusId === 3) {
      return "complete";
    }
    if (marketInfoRequest.StatusId === 6) {
      if (marketInfoRequest.NextStatusId === 2) {
        return "approve";
      }
      if (marketInfoRequest.NextStatusId === 3) {
        return "complete";
      }
    }
    return "";
  };

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 3 }}>
          <Grid item xs={12} lg={9}>
            <Formik
              initialValues={{
                ...initialValues,
                ...marketInfoRequest,
                IsBusinessUser: marketInfoRequest.IsBusinessUser || false,
              }}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                          categories,
                          countries,
                          currencies,
                          setFieldValue,
                          marketInfoRequest,
                          distinctMarketInfo,
                          selectData,
                          setIsOnHold,
                        })}
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDBox>
                            <MDButton
                              disabled={isSubmitting}
                              type="button"
                              variant="gradient"
                              color="error"
                              sx={{ marginLeft: "40px" }}
                              onClick={handleCancel}
                            >
                              cancel
                            </MDButton>
                          </MDBox>
                          <MDBox>
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton variant="gradient" color="info" onClick={handleBack}>
                                back
                              </MDButton>
                            )}
                            <MDButton
                              disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="info"
                              sx={{ marginLeft: "40px", marginRight: "40px" }}
                            >
                              {isLastStep ? "update" : "next"}
                            </MDButton>
                            {isLastStep &&
                            userData &&
                            (userData.role === "data" || userData.role === "admin") ? (
                              <MDButton variant="gradient" color="error" onClick={handleReject}>
                                reject
                              </MDButton>
                            ) : (
                              <MDBox />
                            )}
                            {isLastStep &&
                            userData?.role === "admin" &&
                            !isOnHold &&
                            (marketInfoRequest.StatusId === 2 ||
                              marketInfoRequest.StatusId === 3 ||
                              marketInfoRequest.StatusId === 6) ? (
                              <MDButton
                                variant="gradient"
                                color="success"
                                onClick={() => handleApprove(values)}
                                sx={{ marginLeft: "40px", marginRight: "40px" }}
                              >
                                {calculateTextLabel()}
                              </MDButton>
                            ) : (
                              <MDBox />
                            )}
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MarketRequestMarketTrading;

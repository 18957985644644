/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { useField, useFormikContext } from "formik";
import MDTypography from "components/MDTypography";

const CheckboxWrapper = ({ name, label, legend, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const configCheckbox = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    sx: { fontWeight: 400 },
  };

  const configFormControl = {};
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <FormControl {...configFormControl}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...configCheckbox} />}
          label={
            <MDTypography variant="inherit" fontWeight="regular" opacity={0.8}>
              {label}
            </MDTypography>
          }
        />
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxWrapper;

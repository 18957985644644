import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import http from "services/http";
import { useMaterialUIController, validateRole } from "context";
import moment from "moment";

// Material Dashboard 2 React components
import MDLoader from "components/MDLoader";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableRowSelectSubscriptionsOverview from "examples/Tables/DataTableRowSelectSubscriptionsOverview";

// Columns
import subscriptionsTableData from "layouts/tables/data/subscriptionsTableData";

const getSubscriptions = async (
  token,
  purchasedDate,
  purchasedCompareDate,
  membershipType,
  userRegistrationDate
) => {
  if (
    !Array.isArray(purchasedDate) ||
    purchasedDate.length !== 2 ||
    purchasedDate[0] === undefined ||
    purchasedDate[1] === undefined
  ) {
    return [];
  }
  const result = await http({
    path: `/subscriptions?startDate=${purchasedDate[0]}&endDate=${purchasedDate[1]}&startCompareDate=${purchasedCompareDate[0]}&endCompareDate=${purchasedCompareDate[1]}&membershipType=${membershipType}&userRegistrationDate=${userRegistrationDate}`,
    accessToken: token,
  });
  if (result.ok && result.body) {
    return result.body;
  }
  return [];
};

const { columns3, columns4 } = subscriptionsTableData();

function SubscriptionsOverview() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData, fixedNavbar } = controller;
  const { pathname } = useLocation();
  const [purchasedDate, setPurchasedDate] = useState([
    moment().subtract(30, "days").format("MM-DD-YYYY"),
    moment().format("MM-DD-YYYY"),
  ]);
  const [purchasedCompareDate, setPurchasedCompareDate] = useState(["", ""]);
  const [userRegistrationDate, setUserRegistrationDate] = useState(["", ""]);

  // eslint-disable-next-line no-unused-vars
  const [purchasedDateGlobal, setPurchasedDateGlobal] = useState([undefined, undefined]);
  const [purchasedCompareDateGlobal, setPurchasedCompareDateGlobal] = useState([
    undefined,
    undefined,
  ]);
  const [userRegistrationDateGlobal, setUserRegistrationDateGlobal] = useState([
    undefined,
    undefined,
  ]);

  const [showCompare, setShowCompare] = useState(false);
  const [timeframe, setTimeframe] = useState("last30days");
  const [membershipType, setMembershipType] = useState("");

  const [radio, setRadio] = useState(null);

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  const calculatePurchasedCompareDate = (value) => {
    if (value === "preceding") {
      switch (timeframe) {
        case "yesterday":
          setPurchasedCompareDate([
            moment(purchasedDate[0]).subtract(1, "days").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(1, "days").format("MM-DD-YYYY"),
          ]);
          break;
        case "last5days":
          setPurchasedCompareDate([
            moment(purchasedDate[1]).subtract(10, "days").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(5, "days").format("MM-DD-YYYY"),
          ]);
          break;
        case "last30days":
          setPurchasedCompareDate([
            moment(purchasedDate[1]).subtract(60, "days").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(30, "days").format("MM-DD-YYYY"),
          ]);
          break;
        case "last90days":
          setPurchasedCompareDate([
            moment(purchasedDate[1]).subtract(180, "days").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(90, "days").format("MM-DD-YYYY"),
          ]);
          break;
        case "lastyear":
          setPurchasedCompareDate([
            moment(purchasedDate[0]).subtract(1, "years").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(1, "years").format("MM-DD-YYYY"),
          ]);
          break;
        case "ytd":
          setPurchasedCompareDate([
            moment(purchasedDate[0])
              .subtract(moment(purchasedDate[1]).diff(moment(purchasedDate[0]), "days"), "days")
              .format("MM-DD-YYYY"),
            moment(purchasedDate[0]).format("MM-DD-YYYY"),
          ]);
          break;
        case "ttm":
          setPurchasedCompareDate([
            moment(purchasedDate[0]).subtract(1, "years").format("MM-DD-YYYY"),
            moment(purchasedDate[1]).subtract(1, "years").format("MM-DD-YYYY"),
          ]);
          break;
        default:
          setPurchasedCompareDate(["", ""]);
          break;
      }
    } else if (value === "year-over-year") {
      const startDate = moment(purchasedDate[0]).subtract(1, "year").format("MM-DD-YYYY");
      const endDate = moment(purchasedDate[1]).subtract(1, "year").format("MM-DD-YYYY");
      setPurchasedCompareDate([startDate, endDate]);
    } else {
      setPurchasedCompareDate(["", ""]);
    }
  };

  useEffect(() => {
    if (
      purchasedDate[0] &&
      purchasedDate[1] &&
      (purchasedDate[0] === "" || purchasedDate[1] === "")
    ) {
      setPurchasedDateGlobal([undefined, undefined]);
      if (purchasedCompareDate[0] !== "" || purchasedCompareDate[0] !== "") {
        setPurchasedCompareDate(["", ""]);
      }
    }
    if (purchasedDate[0] === "" || purchasedDate[1] === "") {
      setPurchasedDateGlobal([undefined, undefined]);
      if (purchasedCompareDate[0] !== "" || purchasedCompareDate[0] !== "") {
        setPurchasedCompareDate(["", ""]);
      }
    }
    if (
      purchasedDate[0] &&
      purchasedDate[1] &&
      (purchasedDate[0] !== "" || purchasedDate[1] !== "")
    ) {
      setPurchasedDateGlobal(purchasedDate);
      if (purchasedCompareDate[0] !== "" || purchasedCompareDate[0] !== "") {
        if (radio !== null) {
          calculatePurchasedCompareDate(radio);
        }
      }
    }
  }, [purchasedDate]);

  useEffect(() => {
    if (
      purchasedCompareDate[0] &&
      purchasedCompareDate[1] &&
      (purchasedCompareDate[0] === "" || purchasedCompareDate[1] === "")
    ) {
      setShowCompare(false);
      setPurchasedCompareDateGlobal([undefined, undefined]);
    }
    if (purchasedCompareDate[0] === "" || purchasedCompareDate[1] === "") {
      setShowCompare(false);
      setPurchasedCompareDateGlobal([undefined, undefined]);
    }
    if (
      purchasedCompareDate[0] &&
      purchasedCompareDate[1] &&
      (purchasedCompareDate[0] !== "" || purchasedCompareDate[1] !== "")
    ) {
      setShowCompare(true);
      setPurchasedCompareDateGlobal(purchasedCompareDate);
    }
  }, [purchasedCompareDate]);

  useEffect(() => {
    if (
      userRegistrationDate[0] &&
      userRegistrationDate[1] &&
      (userRegistrationDate[0] === "" || userRegistrationDate[1] === "")
    ) {
      setUserRegistrationDateGlobal([undefined, undefined]);
    }
    if (userRegistrationDate[0] === "" || userRegistrationDate[1] === "") {
      setUserRegistrationDateGlobal([undefined, undefined]);
    }
    if (
      userRegistrationDate[0] &&
      userRegistrationDate[1] &&
      (userRegistrationDate[0] !== "" || userRegistrationDate[1] !== "")
    ) {
      setUserRegistrationDateGlobal(userRegistrationDate);
    }
  }, [userRegistrationDate]);

  const [subscriptionsOverview] = useQueries({
    queries: [
      {
        queryKey: [
          "subscriptionsOverview",
          purchasedDateGlobal,
          purchasedCompareDateGlobal,
          membershipType,
          userRegistrationDateGlobal,
        ],
        queryFn: () => {
          if (
            purchasedDateGlobal[0] &&
            purchasedDateGlobal[1] &&
            (purchasedDateGlobal[0] === undefined || purchasedDateGlobal[1] === undefined)
          ) {
            return Promise.resolve([]);
          }
          if (purchasedDateGlobal[0] === undefined || purchasedDateGlobal[1] === undefined) {
            return Promise.resolve([]);
          }
          return getSubscriptions(
            userData.token,
            purchasedDateGlobal,
            purchasedCompareDateGlobal,
            membershipType,
            userRegistrationDateGlobal
          );
        },
      },
    ],
  });

  if (subscriptionsOverview.isLoading) return <MDLoader />;
  if (subscriptionsOverview.error) return <p>Error: {subscriptionsOverview.error.message}</p>;

  // eslint-disable-next-line no-unused-vars
  const { subscriptions } = subscriptionsOverview.data;
  const { data } = subscriptions ? subscriptions[0] : [];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTableRowSelectSubscriptionsOverview
        table={{ columns: showCompare ? columns4 : columns3, rows: data || [] }}
        isSorted
        entriesPerPage
        showTotalEntries
        noEndBorder
        canSearch={false}
        purchasedDate={purchasedDate}
        setPurchasedDate={setPurchasedDate}
        purchasedCompareDate={purchasedCompareDate}
        setPurchasedCompareDate={setPurchasedCompareDate}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        fixedNavbar={fixedNavbar}
        membershipType={membershipType}
        setMembershipType={setMembershipType}
        userRegistrationDate={userRegistrationDate}
        setUserRegistrationDate={setUserRegistrationDate}
        showCompare={showCompare}
        radio={radio}
        setRadio={setRadio}
        calculatePurchasedCompareDate={calculatePurchasedCompareDate}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default SubscriptionsOverview;

// Custom sort function for handling negative values
export default function customSort(rowA, rowB, columnId) {
  const a = rowA.values[columnId];
  const b = rowB.values[columnId];

  if (a === b) {
    return 0;
  }

  if (a == null) {
    return 1;
  }

  if (b == null) {
    return -1;
  }

  // Convert to numbers and sort
  const aNumber = parseFloat(a);
  const bNumber = parseFloat(b);

  if (aNumber > bNumber) return 1;
  if (aNumber < bNumber) return -1;
  return 0;
}

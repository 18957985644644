/* eslint-disable react/prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { formatDate } from "utils/dateUtil";

// Material Dashboard 2 React components

export default function data({ setModal, setRecordDetail }) {
  return {
    columns1: [
      {
        Header: "Date",
        accessor: (d) => formatDate(d.Date),
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
      },
      {
        Header: "Open",
        accessor: "Open",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "High",
        accessor: "High",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Low",
        accessor: "Low",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Close",
        accessor: "Close",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Action",
        accessor: "Action",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Cell: ({ row }) => (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            mb={0}
            mt={0}
          >
            <MDBox ml={0}>
              <MDButton
                size="small"
                variant="gradient"
                color="error"
                onClick={() => {
                  setModal((prev) => !prev);
                  setRecordDetail(row.original);
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          </MDBox>
        ),
        Filter: "",
        isSorted: false,
      },
    ],
    columns2: [
      {
        Header: "Date",
        accessor: (d) => formatDate(d.Date),
        align: "left",
        maxWidth: 160,
        minWidth: 160,
        Filter: "",
      },
      {
        Header: "Open",
        accessor: "Open",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "High",
        accessor: "High",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Low",
        accessor: "Low",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
      {
        Header: "Close",
        accessor: "Close",
        align: "left",
        maxWidth: 150,
        minWidth: 150,
        Filter: "",
        isSorted: false,
      },
    ],
  };
}

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useState, useEffect } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChartSnapshots configurations
import configs from "examples/Charts/PieChartDashboard/configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

function PieChartDashboard({ icon, title, description, height, chart }) {
  // eslint-disable-next-line no-unused-vars
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: chart.labels,
      datasets: [
        {
          data: data.datasets[0].data,
          backgroundColor: ["#1A73E8", "#D81B60", "#fb8c00", "#66BB6A", "#EF5350", "#42424A"],
          hoverOffset: 11,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          fill: false,
          // datalabels: {
          //   color: "#FFCE56",
          // },
        },
      ],
    });
    setChartOptions({
      // animation to false because an issue with the library is not fixed yet
      animation: true,
      responsive: true,
      maintainAspectRatio: false,
      layout: { padding: { bottom: 60, left: 60, right: 60, top: 60 } },
      type: "pie",
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: "#42424A",
          labels: {
            index: {
              align: "end",
              anchor: "end",
              offset: 7,
              // display: "auto",
              // font: { size: 18 },
              formatter(value, ctx) {
                let total = 0;
                ctx.dataset.data.forEach((item) => {
                  total += item;
                });
                return ctx.active
                  ? `${ctx.chart.legend.legendItems[ctx.dataIndex].text} ${(
                      value / total
                    ).toLocaleString(undefined, {
                      style: "percent",
                      maximumFractionDigits: 2,
                    })}`
                  : `${ctx.chart.legend.legendItems[ctx.dataIndex].text}  [${value}] markets`;
              },
              opacity(ctx) {
                return ctx.active ? 1 : 0.5;
              },
            },
            // name: {
            // align: "top",
            // font: { size: 16 },
            // formatter(value, ctx) {
            // return ctx.active ? "name" : ctx.chart.data.labels[ctx.dataIndex];
            // },
            // },
            // value: {
            //   align: "bottom",
            //   backgroundColor(ctx) {
            //     const value = ctx.dataset.data[ctx.dataIndex];
            //     return value > 50 ? "white" : null;
            //   },
            //   borderColor: "white",
            //   borderWidth: 2,
            //   borderRadius: 4,
            //   color(ctx) {
            //     const value = ctx.dataset.data[ctx.dataIndex];
            //     return value > 50 ? ctx.dataset.backgroundColor : "white";
            //   },
            //   formatter(value, ctx) {
            //     return ctx.active ? "value" : Math.round(value * 1000) / 1000;
            //   },
            //   padding: 4,
            // },
          },
        },
        tooltip: {
          callbacks: {
            // footer: (tooltipItems) => {
            //   let sum = 0;

            //   tooltipItems.forEach((tooltipItem) => {
            //     sum += tooltipItem.parsed.y;
            //   });
            //   return `Sum: ${sum}`;
            // },
            // title: (tooltipItem, data1) => {
            //   console.log(tooltipItem, data1);
            //   return "title";
            //   // return data['labels'][tooltipItem[0]['index']];
            // },
            label: (item) => {
              let total = 0;
              item.dataset.data.forEach((el) => {
                total += el;
              });
              return `${item.chart.legend.legendItems[item.dataIndex].text} ${(
                item.dataset.data[item.dataIndex] / total
              ).toLocaleString(undefined, {
                style: "percent",
                maximumFractionDigits: 2,
              })}`;
              // return data['datasets'][0]['data'][tooltipItem['index']];
            },
            // afterLabel: (tooltipItem, data3) => {
            //   console.log(tooltipItem, data3);
            //   return "afterLabel";
            //   // var dataset = data['datasets'][0];
            //   // var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
            //   // return '(' + percent + '%)';
            // },
            // footer: (item, ctx) => {
            //   console.log(item, ctx);
            //   return "footer";
            // },
          },
        },
      },
    });
  }, [data.datasets[0].data]);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={1} pt={1}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            {/* <Pie data={data} options={options} /> */}
            <Pie options={chartOptions} data={chartData} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChartDashboard
PieChartDashboard.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the PieChartDashboard
PieChartDashboard.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default PieChartDashboard;

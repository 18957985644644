// react
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import { Grid, Card, Divider } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCardReversalMapping from "examples/Cards/StatisticsCards/ComplexStatisticsCardReversalMapping";

function BullishBearishDetail() {
  const location = useLocation();
  const { data } = location.state;
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={3}>
            <MDBox>
              <ComplexStatisticsCardReversalMapping
                color="warning"
                icon="api"
                title={`${data?.PEITicker}`}
                analysisdate={`Category: ${data?.Category}`}
                count={`${data?.Name}`}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/* active reversals */}
      <MDBox mt={1} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Active Bullish Reversals
                </MDTypography>
                <Divider />
                <MDBox px={2} pb={1}>
                  {data?.latestReversal?.Direction0Details?.filter((value) => value !== 0).map(
                    (value) => (
                      <MDTypography variant="caption" color="text">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    )
                  )}
                  {data?.latestReversal?.Direction0Details?.filter((value) => value !== 0).length >
                  0 ? (
                    ""
                  ) : (
                    <MDTypography variant="caption" color="text">
                      There Are No Active Reversals
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Active Bearish Reversals
                </MDTypography>
                <Divider />
                <MDBox px={2} pb={1}>
                  {data?.latestReversal?.Direction1Details?.filter((value) => value !== 0).map(
                    (value) => (
                      <MDTypography variant="caption" color="text">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    )
                  )}
                  {data?.latestReversal?.Direction1Details?.filter((value) => value !== 0).length >
                  0 ? (
                    ""
                  ) : (
                    <MDTypography variant="caption" color="text">
                      There Are No Active Reversals
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* reversal table */}
      <MDBox mt={1} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Daily Reversal Table Bullish
                </MDTypography>
                <Divider />
                <MDBox px={2} pb={1}>
                  {data?.DailyReversalTable?.Minor?.Bullish.concat(
                    data?.DailyReversalTable?.Major?.Bullish
                  )
                    .filter((value) => value !== 0)
                    .map((value) => (
                      <MDTypography variant="caption" color="text">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Daily Reversal Table Bearish
                </MDTypography>
                <Divider />
                <MDBox px={2} pb={1}>
                  {data?.DailyReversalTable?.Minor?.Bearish.concat(
                    data?.DailyReversalTable?.Major?.Bearish
                  )
                    .filter((value) => value !== 0)
                    .map((value) => (
                      <MDTypography variant="caption" color="text">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* comparsion */}
      <MDBox mt={1} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Missing Bullish
                </MDTypography>
                <Divider />

                <MDBox px={2} pb={1}>
                  {data.DailyReversalTable.Minor.Bullish.filter(
                    (value) => !data.latestReversal?.Direction0Details?.includes(value)
                  )
                    .concat(
                      data.DailyReversalTable.Major.Bullish.filter(
                        (value) => !data.latestReversal?.Direction0Details?.includes(value)
                      )
                    )
                    .filter((value) => value !== 0)
                    .map((value) => (
                      <MDTypography variant="caption" color="error" fontWeight="bold">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card id="filter">
              <MDBox pt={2} px={2} pb={1}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase">
                  Missing Bearish
                </MDTypography>
                <Divider />

                <MDBox px={2} pb={1}>
                  {data.DailyReversalTable.Minor.Bearish.filter(
                    (value) => !data.latestReversal?.Direction1Details?.includes(value)
                  )
                    .concat(
                      data.DailyReversalTable.Major.Bearish.filter(
                        (value) => !data.latestReversal?.Direction1Details?.includes(value)
                      )
                    )
                    .filter((value) => value !== 0)
                    .map((value) => (
                      <MDTypography variant="caption" color="error" fontWeight="bold">
                        {value}&nbsp; &nbsp; &nbsp;
                      </MDTypography>
                    ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BullishBearishDetail;

const baseURL = process.env.REACT_APP_baseURL;

const logError = async (requestPath, response) => {
  const contentType = response.headers.get("content-type");
  let body;
  if (contentType && contentType.indexOf("application/json") !== -1) {
    body = await response.json();
  } else {
    body = await response.text();
  }
  // eslint-disable-next-line no-console
  console.error(`Error requesting ${requestPath} method PUT`, body);
};

const httpPut = async (config) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${config.accessToken}`);

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: config.raw,
    redirect: "follow",
  };

  const response = await fetch(`${baseURL}${config.path}`, requestOptions);
  if (response.ok) {
    const body = await response.json();
    return { ok: response.ok, body };
  }
  logError(`${baseURL}${config.path}`, response);
  return { ok: response.ok };
};

export default httpPut;

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import "./index.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

function MDDatePickerMDY({ input, ...rest }) {
  return (
    <Flatpickr
      options={{
        dateFormat: "m-d-Y",
        minDate: input.mindate ? input.mindate : null,
        maxDate: input.maxdate ? input.maxdate : null,
      }}
      {...rest}
      render={({ defaultValue }, ref) => (
        <MDInput {...input} defaultValue={defaultValue} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of MDDatePickerMDY
MDDatePickerMDY.defaultProps = {
  input: {},
};

// Typechecking props for the MDDatePickerMDY
MDDatePickerMDY.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePickerMDY;

const form = {
  formId: "new-user-form",
  formField: {
    marketTags: {
      name: "marketTags",
      label: "MarketTags",
      type: "array",
      errorMsg: "Market Tag is required.",
      invalidMsg: "Market Tag should not be empty",
    },
  },
};

export default form;

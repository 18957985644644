// react
import { useEffect, useState } from "react";
// react-router-dom components
import { useParams, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";

import http from "services/http";
import httpPut from "services/httpPut";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CommentsList from "examples/Lists/CommentsList";
import { formatDateInNewYork } from "utils/dateUtil";

function EditFailedMarket() {
  const navigate = useNavigate();
  const { marketId, timeframeId } = useParams();
  const [controller] = useMaterialUIController();
  const { userData } = controller;

  const [failedMarket, setFailedMarket] = useState({});
  const [comments, setComments] = useState([]);
  // const [resultOfPutFailedMarket, setResultOfPutFailedMarket] = useState([]);

  const [isLoading, setisLoading] = useState(true);

  const getFailedMarket = async () => {
    const result = await http({
      path: `/failedmarkets/${marketId}/timeframe/${timeframeId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const getComments = async () => {
    const result = await http({
      path: `/comments/${marketId}`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const [loadedFailedMarket, loadedComments] = await Promise.all([
            getFailedMarket(),
            getComments(),
          ]);

          setFailedMarket({
            ...loadedFailedMarket,
            Summary: "",
            ReasonForFailure: loadedFailedMarket.ReasonForFailure || "source",
            Status: loadedFailedMarket.Status || "open",
            MarketFixed: loadedFailedMarket.MarketFixed || false,
            TimeFrame: timeframeId,
          });
          setComments(loadedComments);
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, []);

  const putComments = async () => {
    const result = await httpPut({
      // eslint-disable-next-line no-underscore-dangle
      path: `/comments/`,
      method: "PUT",
      raw: JSON.stringify({ ...failedMarket, name: userData.name, username: userData.username }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const handleChangeSelect = (event) => {
    setFailedMarket(() => ({
      ...failedMarket,
      ReasonForFailure: event.target.value,
    }));
  };

  const handleChangeSelectStatus = (event) => {
    setFailedMarket(() => ({
      ...failedMarket,
      Status: event.target.value,
    }));
  };

  const handleChangeRadio = (event) => {
    setFailedMarket(() => ({
      ...failedMarket,
      MarketFixed: event.target.value === "true",
    }));
  };

  const handleChangeTextField = (event) => {
    setFailedMarket(() => ({
      ...failedMarket,
      Summary: event.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(failedMarket.Summary === "" && failedMarket.MarketFixed)) {
      const newComments = failedMarket.Comments || [];
      newComments.push({
        Time: Date.now(),
        Comment: failedMarket.Summary,
        Name: userData.name,
      });
      setFailedMarket(() => ({
        ...failedMarket,
        Comments: newComments,
      }));

      // setComments(failedMarket.Comments);
      document.getElementById("outlined-multiline-static").value = "";
      // eslint-disable-next-line no-unused-vars
      const result2 = await putComments();
      // setResultOfPutFailedMarket(result);
      navigate("/failed-markets/timeframe/daily");
    } else {
      alert("error, please capture some comments");
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    navigate("/failed-markets/timeframe/daily");
  };

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edit Failed Market
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="PEI Ticker"
                        value={failedMarket.PEITicker}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Market Name"
                        value={failedMarket.MarketName}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Sector"
                        value={failedMarket.Sector}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Region"
                        value={failedMarket.Region}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Market Overview Date"
                        value={formatDateInNewYork(failedMarket.MarketOverviewDate)}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MDInput
                        type="text"
                        label="Market Last Update Date"
                        value={formatDateInNewYork(failedMarket.MarketLastUpdateDate)}
                        disabled
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel id="demo-simple-select-label" sx={{ width: "100%" }}>
                        Select Reason for failure
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={failedMarket.ReasonForFailure || "source"}
                        label="Age"
                        onChange={handleChangeSelect}
                        sx={{ width: "100%", minHeight: "35px" }}
                      >
                        <MenuItem value="source">Source data download failed</MenuItem>
                        <MenuItem value="model">Model run failed</MenuItem>
                        <MenuItem value="data">Data importer failed</MenuItem>
                        <MenuItem value="download">CSI data download failed</MenuItem>
                        <MenuItem value="holidays">Holidays</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel id="demo-simple-select-label" sx={{ width: "100%" }}>
                        Select Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={failedMarket.Status || "open"}
                        label="Status"
                        onChange={handleChangeSelectStatus}
                        sx={{ width: "100%", minHeight: "35px" }}
                      >
                        <MenuItem value="select">Select status</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="inprogress">In Progress</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="rejected">Rejected</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel id="demo-simple-select-label" sx={{ width: "100%" }}>
                        Market fixed?
                      </InputLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={failedMarket.MarketFixed || false}
                        onChange={handleChangeRadio}
                        row
                      >
                        <FormControlLabel value control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Summary"
                        multiline
                        rows={4}
                        defaultValue=""
                        // value={failedMarket.Summary}
                        onChange={handleChangeTextField}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={1} mb={1}>
                    <Grid container alignItems="stretch" justifyContent="center" space={2}>
                      <Grid item px={2}>
                        <MDButton variant="gradient" color="info" fullWidth type="submit">
                          save
                        </MDButton>
                      </Grid>
                      <Grid item px={2}>
                        <MDButton variant="gradient" color="info" fullWidth onClick={handleCancel}>
                          cancel
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mt={1} mb={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} xl={12}>
                        {comments && comments.length > 0 && (
                          <CommentsList title="Comments" comments={comments} shadow={false} />
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditFailedMarket;

const form = {
  formId: "new-user-form",
  formField: {
    category: {
      name: "category",
      label: "Category",
      type: "text",
      errorMsg: "Category is required.",
      invalidMsg: "Category should not be empty",
    },
  },
};

export default form;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const selectData = {
  GICSSector: [
    "Communication Services",
    "Consumer Discretionary",
    "Consumer Staples",
    "Energy",
    "Health Care",
    "Financials",
    "Industrials",
    "Information Technology",
    "Materials",
    "Real Estate",
    "Utilities",
  ],
  GICSIndustryGrp: [
    "Telecommunication Services",
    "Media & Entertainment",
    "Automobiles & Components",
    "Consumer Durables & Apparel",
    "Consumer Services",
    "Retailing",
    "Food & Staples Retailing",
    "Food Beverage & Tobacco",
    "Household & Personal Products",
    "Energy",
    "Health Care Equipment & Services",
    "Pharmaceuticals Biotechnology & Life Sciences",
    "Banks",
    "Diversified Financials",
    "Insurance",
    "Capital Goods",
    "Commercial & Professional Services",
    "Transportation",
    "Software & Services",
    "Technology Hardware & Equipment",
    "Semiconductors & Semiconductor Equipment",
    "Materials",
    "Real Estate",
    "Utilities",
  ],
  GICSIndustry: [
    "Aerospace & Defense",
    "Air Freight & Logistics",
    "Airlines",
    "Auto Components",
    "Automobiles",
    "Banks",
    "Beverages",
    "Biotechnology",
    "Building Products",
    "Capital Markets",
    "Chemicals",
    "Commercial Services & Supplies",
    "Communications Equipment",
    "Construction Materials",
    "Construction & Engineering",
    "Consumer Finance",
    "Containers & Packaging",
    "Distributors",
    "Diversified Consumer Services",
    "Diversified Financial Services",
    "Diversified Telecommunication Services",
    "Electric Utilities",
    "Electrical Equipment",
    "Electronic Equipment Instruments & Components",
    "Energy Equipment & Services",
    "Entertainment",
    "Equity Real Estate Investment Trusts (REITs)",
    "Food & Staples Retailing",
    "Food Products",
    "Gas Utilities",
    "Health Care Equipment & Supplies",
    "Health Care Providers & Services",
    "Health Care Technology",
    "Hotels Restaurants & Leisure",
    "Household Durables",
    "Household Products",
    "Independent Power and Renewable Electricity Producers",
    "Industrial Conglomerates",
    "Insurance",
    "Interactive Media & Services",
    "Internet & Direct Marketing Retail",
    "IT Services",
    "Leisure Products",
    "Life Sciences Tools & Services",
    "Machinery",
    "Marine",
    "Media",
    "Metals & Mining",
    "Mortgage Real Estate Investment Trusts (REITs)",
    "Multiline Retail",
    "Multi-Utilities",
    "Oil Gas & Consumable Fuels",
    "Paper & Forest Products",
    "Personal Products",
    "Pharmaceuticals",
    "Professional Services",
    "Real Estate Management & Development",
    "Road & Rail",
    "Semiconductors & Semiconductor Equipment",
    "Software",
    "Specialty Retail",
    "Technology Hardware Storage & Peripherals",
    "Textiles Apparel & Luxury Goods",
    "Thrifts & Mortgage Finance",
    "Tobacco",
    "Trading Companies & Distributors",
    "Transportation Infrastructure",
    "Water Utilities",
    "Wireless Telecommunication Services",
  ],
};

export default selectData;

// react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMaterialUIController, validateRole } from "context";

import http from "services/http";
import httpPost from "services/httpPost";

import MDLoader from "components/MDLoader";

// @mui material components
import { Grid, Card } from "@mui/material";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTableSnapshots from "components/Tables/DataTableSnapshots";
import PieChartMarkets from "examples/Charts/PieChartMarkets";

// Data
// import dataTableData from "layouts/snapshotsoverviewII/data/dataTableData";
import snapshotsTableData from "layouts/tables/data/snapshotsTableData";

import { MultiSelect } from "react-multi-select-component";
import "./index.css";
import DateRangePickerCompSnapshots from "examples/DateRangePickerSnapshots";
import { addDays } from "date-fns";
import defineds from "examples/DateRangePicker/datesConfiguration";

// eslint-disable-next-line no-unused-vars
import Steps from "layouts/snapshotsoverview/components/Steps";

import { themeColors } from "utils/productUtil";

const { columns2 } = snapshotsTableData();

function SnapshotsOverview() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { userData } = controller;
  const { pathname } = useLocation();

  const [isLoading, setisLoading] = useState(true);
  const [snapshotsRegions, setSnapshotsRegions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [snapshotsData, setSnapshotsData] = useState({});
  const [timeframeId, setTimeframeId] = useState("last30days");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [filterByRange, setFilterByRange] = useState(false);

  const sideBarCustomObject = [
    {
      label: "Today Only",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "Yesterday Only",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      label: "3 Days",
      range: () => ({
        startDate: defineds.startOfLastThreeDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "5 Days",
      range: () => ({
        startDate: defineds.startOfLastFiveDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 Week",
      range: () => ({
        startDate: defineds.startOfLastSevenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "2 Weeks",
      range: () => ({
        startDate: defineds.startOfLastFourteenDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 Month",
      range: () => ({
        startDate: defineds.startOfLastThirtyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "90 Days",
      range: () => ({
        startDate: defineds.startOfLastNintyDay,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: "1 year",
      range: () => ({
        startDate: defineds.startOflastYear,
        endDate: defineds.endOfToday,
      }),
    },
  ];

  const getSnapshotsRegions = async () => {
    const result = await http({
      path: `/snapshots/regions`,
      accessToken: userData.token,
    });
    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  const postSnapshots = async () => {
    const result = await httpPost({
      path: `/snapshots`,
      method: "POST",
      raw: JSON.stringify({ selectedRegions, timeframeId, filterByRange, range }),
      accessToken: userData.token,
    });

    if (result.ok && result.body) {
      return result.body;
    }
    return [];
  };

  useEffect(() => {
    if (userData !== null) {
      const { role } = userData;
      const { access, goBack } = validateRole({ role, pathname });
      if (!access) navigate(goBack);
    }
  }, [userData]);

  useEffect(() => {
    if (userData !== null) {
      const load = async () => {
        try {
          const loadedSnapshotsRegions = await getSnapshotsRegions();
          const loadedSnapshots = await postSnapshots();
          setSnapshotsData(loadedSnapshots);
          // eslint-disable-next-line no-underscore-dangle
          setSnapshotsRegions(loadedSnapshotsRegions.map((el) => el._id));
          setisLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      };
      load();
    }
  }, [selectedRegions, timeframeId, range]);

  const handleChangeSelectTimeframe = (event, newSelectTimeframe) => {
    setTimeframeId(newSelectTimeframe);
    setFilterByRange(false);
  };

  if (isLoading) return <MDLoader />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h5">FILTERS</MDTypography>
                <MDBox mt={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDBox mb={2}>
                        <MDBox pt={1} px={1}>
                          <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                            Select timeframe
                          </MDTypography>
                        </MDBox>
                        <MDBox pt={1} px={2}>
                          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                            <ToggleButtonGroup
                              size="small"
                              color="info"
                              value={timeframeId}
                              exclusive
                              onChange={handleChangeSelectTimeframe}
                              aria-label="text alignment"
                            >
                              <ToggleButton value="all" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  All
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="yesterday" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Yesterday
                                </MDTypography>
                              </ToggleButton>
                              {/* <ToggleButton value="justify" aria-label="justified" disabled> */}
                              <ToggleButton value="last5days" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Last 5 days
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="last30days" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Last 30 days
                                </MDTypography>
                              </ToggleButton>
                              <ToggleButton value="last60days" aria-label="centered">
                                <MDTypography variant="caption" color="text" fontWeight="regular">
                                  Last 60 days
                                </MDTypography>
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDBox mb={2}>
                        <MDBox pt={1} px={1}>
                          <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                            Select region
                          </MDTypography>
                        </MDBox>
                        <MDBox component="ul" display="flex" flexDirection="column" pt={1} m={0}>
                          <Stack spacing={2} width="350px">
                            <MultiSelect
                              // className="dark"
                              options={snapshotsRegions.map((el) => ({
                                label: el,
                                value: el,
                              }))}
                              value={selectedRegions}
                              onChange={setSelectedRegions}
                              labelledBy="Select"
                            />
                          </Stack>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox mt={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <MDBox mb={1}>
                        <MDBox px={1}>
                          <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                            Select range
                          </MDTypography>
                        </MDBox>
                        <MDBox pt={0} pb={2} px={2}>
                          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                            <DateRangePickerCompSnapshots
                              range={range}
                              setRange={setRange}
                              sideBarCustomObject={sideBarCustomObject}
                              setFilterByRange={setFilterByRange}
                            />
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox py={1}>
                  <MDBox px={1}>
                    <MDTypography variant="h6" fontWeight="light" textTransform="uppercase">
                      Performance Overview
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        <Steps
                          title={
                            snapshotsData && snapshotsData.type && snapshotsData.type.length > 0
                              ? snapshotsData.type[0].count
                              : "0"
                          }
                          description={
                            snapshotsData && snapshotsData.type && snapshotsData.type.length > 0
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total snapshots for ${snapshotsData.type[0]._id}`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Steps
                          title={
                            snapshotsData &&
                            snapshotsData.type &&
                            snapshotsData.type.length > 0 &&
                            snapshotsData.type[1]
                              ? snapshotsData.type[1].count
                              : "0"
                          }
                          description={
                            snapshotsData &&
                            snapshotsData.type &&
                            snapshotsData.type.length > 0 &&
                            snapshotsData.type[1]
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total snapshots for ${snapshotsData.type[1]._id}`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Steps
                          title={
                            snapshotsData &&
                            snapshotsData.type &&
                            snapshotsData.type.length > 0 &&
                            snapshotsData.type[2]
                              ? snapshotsData.type[2].count
                              : "0"
                          }
                          description={
                            snapshotsData &&
                            snapshotsData.type &&
                            snapshotsData.type.length > 0 &&
                            snapshotsData.type[2]
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total snapshots for ${snapshotsData.type[2]._id}`
                              : "No records found"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Steps
                          title={
                            snapshotsData && snapshotsData.type && snapshotsData.type.length > 0
                              ? `${snapshotsData.type.reduce((acc, type) => acc + type.count, 0)} `
                              : "0"
                          }
                          description={
                            snapshotsData && snapshotsData.type && snapshotsData.type.length > 0
                              ? // eslint-disable-next-line no-underscore-dangle
                                `Total snapshots`
                              : "No records found"
                          }
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
              {/* selectedRegions: {JSON.stringify(selectedRegions)}
              <hr />
              timeframeId: {timeframeId}
              <hr />
              filterByRange: {filterByRange ? "true" : "false"}
              <hr />
              {JSON.stringify(range)} */}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              {snapshotsData && snapshotsData.region && snapshotsData.region.length > 0 ? (
                <PieChartMarkets
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Region"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: snapshotsData.region.map((r) => r._id),
                    datasets: {
                      label: "Projects",
                      backgroundColors: themeColors,
                      data: snapshotsData.region.map((r) => r.count),
                    },
                  }}
                />
              ) : (
                <div>No records found</div>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {snapshotsData && snapshotsData.type && snapshotsData.type.length > 0 ? (
                <PieChartMarkets
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Type"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: snapshotsData.type.map((r) => r._id),
                    datasets: {
                      label: "Projects",
                      backgroundColors: themeColors,
                      data: snapshotsData.type.map((r) => r.count),
                    },
                  }}
                />
              ) : (
                <div>No records found</div>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {snapshotsData && snapshotsData.sector && snapshotsData.sector.length > 0 ? (
                <PieChartMarkets
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Sector"
                  description=""
                  chart={{
                    // eslint-disable-next-line no-underscore-dangle
                    labels: snapshotsData.sector.map((r) => r._id),
                    datasets: {
                      label: "Projects",
                      backgroundColors: themeColors,
                      data: snapshotsData.sector.map((r) => r.count),
                    },
                  }}
                />
              ) : (
                <div>No records found</div>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <MDBox pt={1} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Snapshots Overview
            </MDTypography>
          </MDBox>
          <DataTableSnapshots
            table={{
              columns: columns1,
              rows:
                snapshotsData && snapshotsData.data && snapshotsData.data.length > 0
                  ? snapshotsData.data
                  : [],
            }}
            isSorted
            entriesPerPage
            showTotalEntries
            noEndBorder
            canSearch
          />
        </Card>
      </MDBox> */}
      <MDBox pt={1} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Snapshots Overview
            </MDTypography>
          </MDBox>
          <DataTableSnapshots
            table={{
              columns: columns2,
              rows:
                snapshotsData && snapshotsData.dataGrouped && snapshotsData.dataGrouped.length > 0
                  ? snapshotsData.dataGrouped
                  : [],
            }}
            isSorted
            entriesPerPage
            showTotalEntries
            noEndBorder
            canSearch
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SnapshotsOverview;
